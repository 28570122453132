/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useLayoutEffect, useMemo, useEffect, useState, useCallback, useRef } from "react";
import { Button, Dropdown, Modal, Form, Image, Tab, Tabs, Nav } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { fetchConvos, updateConvo }                         from "../../../../app/modules/Convos/pages/convos/convosSlice";
import { fetchTasks, updateTask }                           from "../../../../app/modules/Tasks/pages/tasks/tasksSlice";
import { fetchReviews, updateReview }                       from "../../../../app/modules/Reviews/pages/reviews/reviewsSlice";
import { fetchResponses, updateResponse}                    from "../../../../app/modules/Feedback/pages/responses/responsesSlice";
import { fetchFeedbackSettings, updateFeedbackSettings}     from "../../../../app/modules/Feedback/pages/settings/feedbackSettingsSlice";
import { fetchQuestions, updateQuestion}                    from "../../../../app/modules/Feedback/pages/questions/questionsSlice";
import { fetchTemplates, updateTemplate}                    from "../../../../app/modules/Templates/pages/templates/templatesSlice";
import { fetchAttributes, updateAttribute}                  from "../../../../app/modules/Templates/pages/attributes/attributesSlice";
import { fetchTopics, updateTopic}                          from "../../../../app/modules/Topics/pages/topics/topicsSlice";
import { fetchUsers, updateUser}                            from "../../../../app/modules/BusinessSettings/pages/users/usersSlice";
import { fetchTeams, updateTeam}                            from "../../../../app/modules/BusinessSettings/pages/teams/teamsSlice";
import { fetchSites, updateSite}                            from "../../../../app/modules/Reviews/pages/sites/sitesSlice";
import { fetchCompetitors, updateCompetitor}                from "../../../../app/modules/Reviews/pages/competitors/competitorsSlice";
import { fetchReviewsSettings, updateReviewsSettings}       from "../../../../app/modules/Reviews/pages/settings/reviewsSettingsSlice";
import { fetchPayments, updatePayment}                      from "../../../../app/modules/Payments/pages/payments/paymentsSlice";
import { fetchPaymentsSettings, updatePaymentsSettings}     from "../../../../app/modules/Payments/pages/settings/paymentsSettingsSlice";
import { fetchGeneral, updateGeneral, fetchLocations, updateLocation}                       from "../../../../app/modules/BusinessSettings/pages/general/generalSlice";
import { fetchHours, updateHours}                           from "../../../../app/modules/BusinessSettings/pages/hours/hoursSlice";
import { fetchAutoResponder, updateAutoResponder}           from "../../../../app/modules/BusinessSettings/pages/hours/hoursSlice";
import { fetchBizAttributes,  updateBizAttribute, fetchTextopiaRoles, fetchTextopiaReviewSites}         from "../../../layout/components/header/locationSlice";
import { getTenant, updateTenant }                          from "../../../../app/modules/Channels/pages/channels/channelsSlice";
import { fetchTextopiaPlans, fetchTenants }                  from "../../../../app/modules/TextopiaAdmin/pages/tenants/tenantsSlice";



import { KTUtil } from "../components/util.js";
import * as Textopia  from "./textopia_utils.js";
import * as Constants  from "./TextopiaConstants";
import * as Queries  from "./TextopiaQueries";

import Parse from "parse";

//--> this component is inserted into the layout tree because it manages live query subscriptions for all those items for which we need live updated like
//--> convos, reviews, feedback, tasks, etc. - anything that we want to show notifications, badges, etc.
//--> the reason is that if the screen is refreshed, redux is emptied, and we stop getting updates, but this way, we are always live even after a refresh
export function TextopiaLiveQueryLoader() {
    
    const { location } = useSelector(
    (state) =>  ({
      location: state.location.location  
    }),
    //shallowEqual //--> an important discovery that shallowEqual prevents us from looking deeper into objects like Parse objects or even lists of items
  ) 

    
    //----------//
    //--> load up each of the entities and start monitoring them
    //--> upon page refresh this happens again
    //--> if location is changed, we force a page refresh, because the user is entering a whole different env, and the following happens again to load for the new location (after location has been set in user profile)
    let dispatch = useDispatch();

    let others = {dispatch: dispatch, debug : true};

    useEffect(() => {
        others.debug && console.log("CLQ Loader Called...")
        dispatch(fetchConvos());
        dispatch(fetchTasks());    //--> in dispatch calls DONT forget to put the parentheses after the method name!!        
        dispatch(fetchReviews());  
        dispatch(fetchResponses());
        dispatch(fetchFeedbackSettings());
        //dispatch(fetchQuestions());
        dispatch(fetchTemplates());
        dispatch(fetchAttributes());
        dispatch(fetchBizAttributes());
        dispatch(fetchTopics());
        dispatch(fetchUsers());
        dispatch(fetchTeams());
        dispatch(fetchSites());
        dispatch(fetchCompetitors());
        dispatch(fetchReviewsSettings());
        dispatch(fetchPayments());        
        dispatch(fetchPaymentsSettings());
        dispatch(fetchLocations()).then(() => dispatch(fetchGeneral()));        
        dispatch(fetchHours());
        dispatch(fetchAutoResponder());
        dispatch(getTenant());

        //--> static lookups, no subs for these
        dispatch(fetchTextopiaReviewSites());
        dispatch(fetchTextopiaRoles());

        

        //--> discovered a big issue to the asyc nature of the subscribe call, was adding all of them one by one into an array, turns out they were all getting the same sub id, and the system was losing track of them (memory leak);
        //--> so now have to sequence them, either using separate useEffects, or in a then() chain to make sure each sub happens after the previous one has been issues by LQ with a unique sub id
        //--> losing track means we cannot unsubscribe and eventually we'll run out of memory
        
        const getDispatchCall = (className) => {
            if (className === "Task")                    return updateTask;
            if (className === "Customer")                return updateConvo;
            if (className === "ReviewAppInstance")       return updateReview; 
            if (className === "FeedbackAppInstance")     return updateResponse;
            if (className === "FeedbackAppSettings")     return updateFeedbackSettings;
            //if (className === "FeedbackQuestion")        return updateQuestion;
            if (className === "Template")                return updateTemplate;
            if (className === "Topic")                   return updateTopic;
            if (className === "ReviewAppSettings")       return updateReviewsSettings;
            if (className === "LocationReviewSite")      return updateSite;
            if (className === "Competitor")              return updateCompetitor;
            if (className === "Attribute")               return updateAttribute;
            if (className === "BusinessAttribute")       return updateBizAttribute;
            if (className === "PaymentAppInstance")      return updatePayment;
            if (className === "PaymentAppSettings")      return updatePaymentsSettings;
            if (className === "User")                    return updateUser;
            if (className === "Team")                    return updateTeam;
            if (className === "Location")                return updateLocation;
            if (className === "Hours")                   return updateHours;
            if (className === "AutoResponder")           return updateAutoResponder;
            if (className === "Tenant")                  return updateTenant;
        }

        var client = new Parse.LiveQueryClient({
            applicationId: '6AJfa2enUPtiqpzcGWzeGenVVUMeI9JUkxCuz5H8',
            serverURL: 'wss://' + 'textopia.b4a.io',
            javascriptKey: 'HyqMpblsidWCv10X2cUvcHavsblCldY45log2cmW'
        });
        client.open();

        let subs = [];
        async function setupSubs() { 

            const queries = await Parse.Cloud.run("fetchLQQueries");

            for (var i = 0; i < queries.length; i++) {

                let myQuery = new Parse.Query(queries[i].className);
                Object.assign(myQuery, queries[i].query); //--> the .query is very important, objects coming back form cloud
                subs.push(await Textopia.setupSubs(client, { query: myQuery, dispatchCall: getDispatchCall(queries[i].className), ...others })); 
  
            }
            //--> some of the above can be setup on the page itself, for optimization
            //--> [can be setup on the page itself] - means we are loading up all the data above for every user for every login, even if they don't visit a page, 
            //-->       some data is central, used across pages like user lists, locations, attributes, etc., other data is more locatlized like competitors, reviews sites, etc.
            //-->       as a matter of optimization we can localize fethces to these items within those pages, and sub and unsub from there also
            //-->       Reviews and Feedbacks and Convos and Tasks could be big items, so to optimize, instead of loading these up in advance, 
            //-->       potentially susbscribe to changes in some external notification table, and only load these items when the pages are visited
          }
          setupSubs();                
                              
        others.debug && console.log(subs);
        return () => { 
            if (subs && subs.length > 0) {
                subs.map(sub => { Textopia.unsubscribe(client, others.debug, sub)}); // unsubscribe all the LQ subscriptions

            } 
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, location]);

    return null;
}