import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Parse } from 'parse';


// initial state which is shared by all component using 
const initialState = {
  
}

// server API calling dispatch
export const fetchUserSummary = createAsyncThunk('leaderboard/fetchUserSummary', async (values) => {
  let cloudResponse = await Parse.Cloud.run("fetchUserSummary", values);
  return cloudResponse;
})

const entityType = "leaderboard";
// Then, handle actions in your reducers:
export const leaderboardSlice = createSlice({
  name: entityType,
  initialState: initialState,
  reducers: {
    setParams: (state, action) => {
      state.queryParams = action.payload; // mutate the state all you want with immer
      // state.reportData = [];
      // state.pageStatus = 'idle';
    },
    setPage: (state, action) => {
      state.curentPage = action.payload;
    },
  },
  extraReducers: {
    // all callback from server for each API call
    // state managemet - pending/fulfilled/rejected callback from with API name [method-name.state]   
    [fetchUserSummary.pending]: (state) => { state.pageStatus = 'loading' },
    [fetchUserSummary.fulfilled]: (state, action) => fetchUserSummaryFulfilled(state, action),
    [fetchUserSummary.rejected]: (state, action) => fetchUserSummaryRejected(state, action),

  }
})

const fetchUserSummaryFulfilled = (state, action) => {
  state.pageStatus = 'succeeded'
  const result = action.payload;
    state.leaderboardData = result.data;
}

const fetchUserSummaryRejected = (state, action) => {
  state.pageStatus = 'failed'
  state.pageError = action.error.message
  console.log(action.error.message);
}

export default leaderboardSlice.reducer