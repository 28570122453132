import React , { useState } from "react";
import {Link} from "react-router-dom";
import { Parse } from "parse";

import {toAbsoluteUrl} from "../../../_helpers";
import {ChangeLocationForm} from "../header/ChangeLocationForm";
import {TextopiaPullout, TextopiaOKModal } from "../../../_assets/js/textopia/TextopiaControls.js";
import * as Textopia from "../../../_assets/js/textopia/textopia_utils.js";
import { Beacon } from "../Beacon";

export function HeaderMobile() {
  const [showLocationPullout, setShowLocationPullout] = useState(false);
  const {actionStatus} = Textopia.useGetPageStatusAndError("location");
  const [pullout, setPullout] = useState('');
  const [show, setShow] = useState(false); //--> to show the welcome conf modal

  const showBeacon = () => {
    window.Beacon('init', 'fdebe83a-808a-4fd1-a37e-99de494e4afe')       
    window.Beacon('once', 'close', () => window.Beacon('destroy')); //--> when the close button is clicked, remove the beacon also

    window.Beacon('toggle'); 
  }


  return (
      <>
        {/*begin::Header Mobile*/}
        <div id="kt_header_mobile" className="header-mobile align-items-center header-mobile-fixed bg-primary" >
          {/*begin::Logo*/}
          <Link to="/">
            <img alt="logo" src={toAbsoluteUrl("/media/Textopia/Logos/textopia_white_logo.png")} width="85"/>
          </Link>
          {/*end::Logo*/}

          {/*begin::Toolbar*/}
          <div className="d-flex flex-row align-items-center justify-content-end">
            
            <div className="btn btn-xs btn-circle font-weight-bold btn-icon btn-white text-dark" onClick={() => showBeacon()}><i className="fas fa-question" /></div> 
                      
            <div className={`d-block d-md-none p-0 m-0`}>
              <div className="d-flex flex-row align-items-center border border-secondary rounded-pill pl-1 pr-2 py-0 mr-3 ml-3 bg-light cursor-pointer" onClick={() => setShowLocationPullout(true)} >
                <i className="flaticon2-location icon-md text-primary mr-1" />
                <div className="d-flex flex-column">
                  <div className="text-dark font-size-sm font-weight-bold limit-chars max-chars-10">{Parse.User.current() && Parse.User.current().get("primaryLocationPtr") && Parse.User.current().get("primaryLocationPtr").get("shortName")}</div>
                </div>
                {actionStatus && actionStatus === "saving" && <span className="ml-1 mr-4 spinner spinner-sm spinner-warning"></span>}
              </div>
            </div>         


            {/*begin::Aside Mobile Toggle*/}
            <button className="btn btn-xs burger-icon burger-icon-left p-0 mr-3" id="kt_aside_mobile_toggle">
              <span/>
            </button>
            {/*end::Aside Mobile Toggle*/}

            {/*begin::Topbar Mobile Toggle*/}
            <button className="btn btn-xs btn-text-white btn-hover-text-white p-0 burger-icon icon-2x mr-1" id="kt_header_mobile_topbar_toggle" >
              <span />
            </button>
            {/*end::Topbar Mobile Toggle*/}

          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Header Mobile*/}


        <TextopiaPullout show={showLocationPullout} 
                      componentEl="subheader_change_location"
                      setPullout={setPullout}
                      scrollEl="location_form"
                      title={"Change Location"}
                      titleIcon="flaticon2-location icon-lg"
                      { ...({subTitle: `currently @ ${Parse.User.current() && Parse.User.current().get("primaryLocationPtr").get("shortName")}`} ) }
                      onHide={() => {setShowLocationPullout(false)}} >
        <ChangeLocationForm pullout={pullout} setShow={setShow} />
      </TextopiaPullout>     
      <TextopiaOKModal show={show && show.show ? true : false} title="Location Changed" confStatement=<span className="text-danger">Welcome to...</span> entityTitle={show && show.value} setOnHide={setShow} noButtons={false} />

      </>
  );
}
