/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import {OverlayTrigger, Tooltip, Image} from "react-bootstrap";
import {useSelector, shallowEqual} from "react-redux";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import Parse  from "parse";

export function QuickUserToggler() {
  
  const { actionStatus, myUser } = useSelector( //--> looking for actionStatus to re-render this component so that changes are reflected.
      (state) =>  ({
        actionStatus: state["location"]["actionStatus"],
      }),
      shallowEqual
    )

  //const [user, setUser] = useState(Parse.User.current()); //--> on a refresh, the redux stored user will go away, so we'll fall back on the Parse user.

  useEffect(() => {}, []);  //--> keeping this here to resolve a re-render issue, makes the component re-render - Yep, confirmed, this component needs to re-render with changes in the actionStatus.
  
  //--> first time page loads up, the above params are empty, but the below params are filled, 
  //--> subsequent edits affect both above and below, but above is immediate, and below is maitained as dirty for some time

  let user = Parse.User.current();
  let myFirstName = user && user.get("firstName");
  let myLastName = user && user.get("lastName");
  let myProfilePic = user && user.get("profilePic") && user.get("profilePic")._url;
  
  return (
    <>
        {/*<OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="quick-user-tooltip">Your settings</Tooltip>}
        >*/}
          <div className="topbar-item">
            <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
              <>

                <span className="text-dark-50 font-weight-normal font-size-base d-none d-md-inline mr-1">Hi,</span>
                <span className="text-dark font-weight-bold font-size-base d-none d-md-inline mr-3">
                  {myFirstName}
                </span>
                <span className="symbol symbol-35 symbol-light-success">
                    {
                      myProfilePic && (<Image src={myProfilePic} />) //--> persisting in redux (we are persisting the whole location slice) changes the object names, for example, instead of ._url, we now have .url
                    } 
                    {!myProfilePic && (
                      <div className="symbol-label font-size-h4 font-weight-bold">{(myFirstName && myFirstName[0]) + (myLastName && myLastName[0])}</div>
                    )}
                </span>
              </>
            </div>
          </div>
        {/*</OverlayTrigger>*/}
      </>
  );
}
