import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";
import * as Queries from "../../../../../_metronic/_assets/js/textopia/TextopiaQueries";
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";
import {KTUtil} from "../../../../../_metronic/_assets/js/components/util";


const initialState = {

  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,

  competitors: [],
  updatedAt: new Date(),

};

export const fetchCompetitors = createAsyncThunk('competitors/fetchCompetitors', async () => {
  let cloudResponse = await Parse.Cloud.run("fetchCompetitors");
  return cloudResponse;
})

export const saveCompetitor = createAsyncThunk('competitors/saveCompetitor', async (values) => {
  let cloudResponse = await Parse.Cloud.run("saveCompetitor", {values: values});
  return cloudResponse;
})

export const deleteCompetitor = createAsyncThunk('competitors/deleteCompetitor', async (id) => {
  let cloudResponse = await Parse.Cloud.run("deleteCompetitor", {competitorId: id});
  return cloudResponse;
})

//--> used by LQ
export const updateCompetitor = createAsyncThunk('competitors/updateCompetitor', async (values) => {
  return values;
})


const entityType = "competitors";

export const competitorsSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
      // All competitors for a location
      [fetchCompetitors.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchCompetitors.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state[entityType] = action.payload //Query returns an array of objects
        state.updatedAt = new Date();
      },
      [fetchCompetitors.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },

      // Update competitor //--> used by LQ
      [updateCompetitor.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updateCompetitor.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
          // nothing
          state.updatedAt = new Date();
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          //console.log('new competitor');  
          if(!state[entityType].find(competitor => competitor.id == action.payload.obj.id)) {
            state[entityType].push(action.payload.obj); // Add the new one to the list of users
          }    
          state.updatedAt = new Date();    
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {
          //console.log('competitor removed');
          state.competitors = state.competitors && state.competitors.filter(o => o.id !== action.payload.obj.id);
          state.updatedAt = new Date();
        }

      },
      [updateCompetitor.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Edit competitor
      [saveCompetitor.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveCompetitor.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        if(!state[entityType].find(competitor => competitor.id === action.payload.id)) {
          state[entityType].push(action.payload); //--> note that the normal call gives us the new object in action.payload whereas LQ gives us action.payload.obj
        }  
        state.updatedAt = new Date();    
      },
      [saveCompetitor.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Delete competitor
      [deleteCompetitor.pending]: (state, action) => {
        state.actionStatus = 'deleting'
      },
      [deleteCompetitor.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded';

        // Remove the deleted entity
        state[entityType] = state[entityType].filter(o => o.id !== action.payload.id);
        state.updatedAt = new Date();
      },
      [deleteCompetitor.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      }


    },




    reducers: {


  }
});

export default competitorsSlice.reducer
