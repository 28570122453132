"use strict";


import {KTUtil} from "../components/util";
import * as Textopia from "./textopia_utils.js";
import * as Constants from "./TextopiaConstants";
import { Parse } from "parse";

export const addReply1 = async (values, objType) => {
  if (!values) return;

  let q = new Parse.Query(Parse.Object.extend(objType));
  q.equalTo("objectId", values.id);
  let obj = await q.first();  
  if (obj) obj.add("replies", {"repliedByUserId": Parse.User.current(), "repliedByUserName": Textopia.getUserName(Parse.User.current()), "replyBody": values.body, "repliedOnDate": new Date()});
  let savedObj = await obj.save();
  
  return savedObj;

}