import React, { useEffect, useLayoutEffect, useState } from "react";
// Import Layout components
import {Header} from "./header/Header";
import {HeaderMobile} from "./header-mobile/HeaderMobile";
import {Aside} from "./aside/Aside";
import {Footer} from "./footer/Footer";
import {LayoutInit} from "./LayoutInit";
import {QuickUser} from "./extras/offcanvas/QuickUser";
import {TextopiaLiveQueryLoader} from "../../_assets/js/textopia/TextopiaLiveQueryLoader";
import {useLocation} from "react-router-dom";

export function Layout({ children }) {

    const location = useLocation();
    const [isFluid, setIsFluid] = useState(false);

     useLayoutEffect(() => {

       //if (location.pathname === "/convos/convos") setIsFluid(true);
       //else setIsFluid(false);

         //eslint-disable-next-line
    }, [location.pathname]);   


  
    return (
        <>
            {/*begin::Main*/}
            <HeaderMobile/>
            <div className="d-flex flex-column flex-root">
                {/*begin::Page*/}
                <div className="d-flex flex-row flex-column-fluid page">
                    <Aside/>
                    {/*begin::Wrapper*/}
                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                        <Header/>
                        <TextopiaLiveQueryLoader />
                        {/*begin::Content*/}
                        <div className="content d-flex flex-column flex-column-fluid pb-0 pb-lg-8 pt-0 pt-lg-8" id="kt_content">

                            {/*begin::Entry*/}
                            <div className="d-flex flex-column-fluid">
                                {/*begin::Container*/}
                                <div className={` container${isFluid ? "-fluid" : ""} `}>
                                    {children}
                                </div>
                                {/*end::Container*/}
                            </div>
                            {/*end::Entry*/}
                        </div>
                        {/*end::Content*/}
                        <Footer/>
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Page*/}
            </div>

            <QuickUser/>
            
            {/*<ScrollTop/>*/}

            {/*end::Main*/}
     
            <LayoutInit />
        </>
    );
}
