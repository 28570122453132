import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as textopia from "../../../_assets/js/textopia/textopia_utils";
import * as Constants from "../../../_assets/js/textopia/TextopiaConstants";
import * as Queries from "../../../_assets/js/textopia/TextopiaQueries";
import {KTUtil} from "../../../_assets/js/components/util";
import { useHistory } from "react-router-dom";

import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const initialState = {
  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,

  status: "",
  error: "",
  code: "",
  user: undefined,
  userId: undefined,

  roles: [],
  sites: [],
  attributes: [],
  updatedAt: new Date(),
  userUpdatedAt: new Date(),
};


export const logoutUser = createAsyncThunk('location/logoutUser', async (values) => {
   
   Parse.User.logOut().then(() => {
     return;
   });

})


export const saveUser = createAsyncThunk('location/saveUser', async (values) => {

  if (values.profilePic) {   
    if (Parse.User.current().get("profilePic") && (values.profilePic._url === Parse.User.current().get("profilePic")._url )) {} //--> do nothing if the pic is the same, unchanged
    else { 
      let ext = values.profilePic.name.endsWith("jpg") ? "jpg" : values.profilePic.name.endsWith("png") ? "png" : values.profilePic.name.endsWith("gif") ? "gif" : values.profilePic.name.endsWith("jpeg") ? "jpeg" : "";
      let parseFile = new Parse.File("profilePic." + ext, values.profilePic);      
      Parse.User.current().set("profilePic", parseFile);         
    }
  } else if (!values.profilePic) {
    Parse.User.current().set("profilePic", null);
  }
  
  Parse.User.current().set("firstName", values.firstName);
  Parse.User.current().set("lastName", values.lastName);
  Parse.User.current().set("email", values.email);
  Parse.User.current().set("phone", values.phone);
  Parse.User.current().set("jobTitle", values.jobTitle);

  let savedUser = await Parse.User.current().save();
  //await Parse.User.current().fetch();
  console.log(savedUser);
  return savedUser;
  
})

export const savePassword = createAsyncThunk('location/savePassword', async (values) => {
   Parse.User.current().set("password", values.newPassword); //--> the values have already been verified and sanitized in the form, so just save here
  
   let savedUser = await Parse.User.current().save();
   return savedUser;
})

export const saveNotifPrefs = createAsyncThunk('location/saveNotifPrefs', async (values) => {
   //console.log(values);

   let notifs = [];
   for (var prop in values) {
     if (values.hasOwnProperty(prop)) {
       if (typeof prop === "string") {
         notifs.push({prefName: prop, prefValue: values[prop]}); //--> iterate through all the prefs and insert them in this array, true and false are both needed because we will overwrite the current value
       }
     }
   }
   Parse.User.current().set("notifPrefs", notifs);
   let savedUser = await Parse.User.current().save();
   return savedUser;
})


export const setLocation = createAsyncThunk('location/setLocation', async (values) => {
  return values;
})

export const saveLocation = createAsyncThunk('location/saveLocation', async (values) => {
    let cloudResponse = await Parse.Cloud.run("saveLocation", {primaryLocationId: values.primaryLocationId});    
    if (values.showConf) values.showConf({show: true, value: cloudResponse.location.get("shortName")});     //--> cloudresponse is an object containing location, attributes
    return cloudResponse;
})

export const fetchBizAttributes = createAsyncThunk('location/fetchBizAttributes', async () => {
  let cloudResponse = await Parse.Cloud.run("fetchBizAttributes");
  return cloudResponse;
})


//--> this one used by LQ
export const updateBizAttribute = createAsyncThunk('location/updateBizAttribute', async (values) => {
  return values;
})

export const saveBizAttribute = createAsyncThunk('location/saveBizAttribute', async (values) => {
  let cloudResponse = await Parse.Cloud.run("saveBizAttribute", {attrId: values && values.myAttr && values.myAttr.id, attrValue: values.attrValue, attributePtrId: values.attributePtr && values.attributePtr.id});
  return cloudResponse;   
})



//--> static lookup for Textopia tables
export const fetchTextopiaRoles = createAsyncThunk('location/fetchTextopiaRoles', async (values) => {
  const cloudResponse = await Parse.Cloud.run("fetchTextopiaRoles");
  return cloudResponse;
})

export const fetchTextopiaReviewSites = createAsyncThunk('location/fetchTextopiaReviewSites', async (values) => {
  const cloudResponse = await Parse.Cloud.run("loadReviewSites");
  return cloudResponse;
})





const entityType = "location";

export const locationSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
            
      [saveLocation.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveLocation.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'   
        //console.log(action.payload.get("shortName"));    
        state.location = action.payload.location;
        state.attributes = action.payload.attributes; 
        state.updatedAt = new Date();
        
      },
      [saveLocation.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      [setLocation.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [setLocation.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'   
        state.location = action.payload;
        state.updatedAt = new Date();
        
      },
      [setLocation.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      [fetchBizAttributes.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [fetchBizAttributes.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'           
        state.attributes = action.payload; //--> load up the attribute values for this location 
        state.updatedAt = new Date();
        
      },
      [fetchBizAttributes.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      [saveBizAttribute.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveBizAttribute.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'           
        if (!state.attributes.find(attribute => attribute.id == action.payload.id)) { //--> add to the list if this is a new one
          state.attributes.unshift(action.payload); 
        } 
        state.updatedAt = new Date();
        
      },
      [saveBizAttribute.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      [updateBizAttribute.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updateBizAttribute.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'   
        
        //--> used by LQ

        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) { //--> explicitly handling BusinessAttribute updates so React can see the changes, otherwise they were not being registered
          let index = state.attributes.findIndex(attr => attr.id === action.payload.obj.id);
          if (index != -1) { state.attributes[index] = action.payload.obj; }
          state.updatedAt = new Date();
        
          // nothing
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          console.log('new biz attribute');

          //--> check for duplicates before adding
          if (!state.attributes.find(attribute => attribute.id == action.payload.obj.id)) {
            state.attributes.unshift(action.payload.obj); 
          } 
          state.updatedAt = new Date();
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {
          console.log('biz attribute removed');          
          state.attributes = state.attributes && state.attributes.filter(o => o.id !== action.payload.obj.id);
          state.updatedAt = new Date();
        }

        
      },
      [updateBizAttribute.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },


      [saveUser.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveUser.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'           
        state.user = action.payload; //--> save the user in this location slice, even though we always have access via Parse
        state.userUpdatedAt = new Date();
      },
      [saveUser.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      [savePassword.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [savePassword.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'           
        state.user = action.payload; //--> save the user in this location slice, even though we always have access via Parse (for some reason, changes are not immediately reflected in the Parse obj, but immediate when saved to redux)
        state.userUpdatedAt = new Date();
      },
      [savePassword.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      [saveNotifPrefs.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveNotifPrefs.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'           
        state.user = action.payload; //--> save the user in this location slice, even though we always have access via Parse
        state.userUpdatedAt = new Date();
      },
      [saveNotifPrefs.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },     

      [fetchTextopiaRoles.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [fetchTextopiaRoles.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'           
        state.roles = action.payload; //--> load up roles
        
      },
      [fetchTextopiaRoles.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      [fetchTextopiaReviewSites.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [fetchTextopiaReviewSites.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'           
        state.sites = action.payload; //--> load up review Sites
      },
      [fetchTextopiaReviewSites.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },
      
    },


    // BELOW NOT USED - KEPT FOR REFERENCE ONLY

    reducers: {
   
  }
});
export default locationSlice.reducer;
//export default persistReducer(persistConfig, locationSlice.reducer); //--> wrapping this state slice with persist so userID can be persisited to indicate logged in state
