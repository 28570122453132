import React from "react";
import {AsideMenuList} from "./AsideMenuList";

export function AsideMenu({disableScroll}) {

  return (
    <>
      {/* begin::Menu Container */}
      <div  id="kt_aside_menu"
            data-menu-vertical="1"
            data-menu-scroll="1"
            data-menu-dropdown-timeout="300"
            className="aside-menu mb-4 mt-0 pt-0"
            >
        <AsideMenuList />
      </div>
      {/* end::Menu Container */}
    </>
  );
}
