import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { TextopiaButton, TextopiaPullout, TextopiaOKModal } from "../../../_assets/js/textopia/TextopiaControls";
import * as Textopia from "../../../_assets/js/textopia/textopia_utils";
import { ChangeLocationForm } from "./ChangeLocationForm";

import { Parse } from "parse";




export function TextopiaChangeLocation({componentEl, className}) {
  const [showLocationPullout, setShowLocationPullout] = useState(false);
  const {pageStatus, pageError, actionStatus, actionError} = Textopia.useGetPageStatusAndError("location");

  let dispatch = useDispatch();

  const [show, setShow] = useState(false); //--> to show the welcome conf modal
  const [pullout, setPullout] = useState('');

  return (
  <>
    <div className={` ${className ? className : ""} p-0 m-0`}>
      <div className="d-flex flex-row align-items-center   border border-secondary border-1 rounded-lg px-4 py-1 bg-hover-light-primary bg-light cursor-pointer" onClick={() => setShowLocationPullout(true)} >
        <i className="flaticon2-location icon-xl text-primary mr-2" />
        <div className="d-flex flex-column">
          <div className="text-dark font-size-lg font-weight-bold limit-chars max-chars-20 mb-n1">{Parse.User.current().get("primaryLocationPtr").get("shortName")}</div>
          <div className="font-size-xs text-dark-75">Current location</div>
        </div>
        {actionStatus && actionStatus === "saving" && <span className="ml-2 mr-3 spinner spinner-sm spinner-warning"></span>}
      </div>
    </div>
    

    <TextopiaPullout show={showLocationPullout} 
                    componentEl={componentEl}
                    setPullout={setPullout}
                    scrollEl="location_form"
                    title={"Change Location"}
                    titleIcon="flaticon2-location icon-lg"
                    { ...({subTitle: `currently @ ${Parse.User.current() && Parse.User.current().get("primaryLocationPtr").get("shortName")}`} ) }
                    onHide={() => {setShowLocationPullout(false)}} >
      <ChangeLocationForm pullout={pullout} setShow={setShow} />
    </TextopiaPullout>

    <TextopiaOKModal show={show && show.show ? true : false} title="Location Changed" confStatement=<span className="text-danger">Welcome to...</span> entityTitle={show && show.value} setOnHide={setShow} noButtons={false} />
  </>
  )
}