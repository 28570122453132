import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as Textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";
import * as Queries from "../../../../../_metronic/_assets/js/textopia/TextopiaQueries";
import {KTUtil} from "../../../../../_metronic/_assets/js/components/util";


const initialState = {
  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,

  templates: [],
  updatedAt: new Date(),

};

export const fetchTemplates = createAsyncThunk('templates/fetchTemplates', async () => {
  let cloudResponse = await Parse.Cloud.run("fetchTemplates");
  return cloudResponse; 
})

//--> this one used by LQ to give realtime updates
export const updateTemplate = createAsyncThunk('templates/updateTemplate', async (values) => {
  return values
})


export const saveTemplate = createAsyncThunk('templates/saveTemplate', async (values) => {
  let cloudResponse = await Parse.Cloud.run('saveTemplate', values);
  return cloudResponse;
})

export const deleteTemplate = createAsyncThunk('templates/deleteTemplate', async (id) => {
  let cloudResponse = await Parse.Cloud.run('deleteTemplate', {templateId: id});
  return cloudResponse;   
})

const entityType = "templates";

export const templatesSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
      [fetchTemplates.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchTemplates.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state[entityType] = action.payload //Query returns an array of objects
        state.updatedAt = new Date();
      },
      [fetchTemplates.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },

      [saveTemplate.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveTemplate.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'        
        
        //--> the below is no longer needed (but we're keeping it because for local changes, the UI will be snappier = but we check for duplicates in both places, here and LQ) as we'll let LQ do all the work for us - it tells us in updateTemplate below

        // If this is a new template, push into the templates Array
        if(!state[entityType].find(template => template.id == action.payload.id)) {
          state[entityType].push(action.payload); // Add the new one to the list of users
        }
        state.updatedAt = new Date();
      },
      [saveTemplate.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },
      
      //--> this one used by LQ
      [updateTemplate.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updateTemplate.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'        
        
        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
          // nothing
          state.updatedAt = new Date();
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          //console.log('new template');
          if(!state[entityType].find(template => template.id == action.payload.obj.id)) {
            state[entityType].push(action.payload.obj); // Add the new one to the list of users
          }
          state.updatedAt = new Date();
          //state.templates && state.templates.push(action.payload.obj); //--> TBD - check  if unshift puts it in the right place
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {
          //console.log('template removed');          
          state.templates = state.templates && state.templates.filter(o => o.id !== action.payload.obj.id);
          state.updatedAt = new Date();
        }

      },
      [updateTemplate.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      [deleteTemplate.pending]: (state, action) => {
        state.actionStatus = 'deleting'
      },
      [deleteTemplate.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded';

        // Remove the deleted user from the users list //--> let LQ do the work //--> but for local updates, the UI will be snappy if we do it right here, 2 deletes dont clash, so its OK
        state[entityType] = state[entityType].filter(o => o.id !== action.payload.id);
        state.updatedAt = new Date();
      },
      [deleteTemplate.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      }


    },


    // BELOW NOT USED - KEPT FOR REFERENCE ONLY

    reducers: {

    catchError: (state, action) => {
      state.error = action.payload.error;
      console.log(state.error);
    },
  }
});

export default templatesSlice.reducer
