/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

export function AsideMenuListSection({sectionTitle}) {

  return (
      <>
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">{sectionTitle}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        </>
  );
}
