/* eslint-disable */

"use strict";

import { KTUtil } from "./../../components/util.js";
import KTOffcanvas from "./../../components/offcanvas.js";

var KTLayoutQuickUser = function() {
    // Private properties
    var _element;
    var _offcanvasObject;
    var _profileElement;
    var _notifsElement;
    var _passwordElement;
    var _logoutElement;


    var _init = function() {
        _offcanvasObject = new KTOffcanvas(_element, {
            overlay: true,
            baseClass: 'offcanvas',
            placement: 'right',
            closeBy: ['kt_quick_user_close', 'kt_quick_user_close2', 'kt_quick_user_close3'],
            toggleBy: 'kt_quick_user_toggle'
        });
    }

    // Private functions

    var _getContentHeight = function() {
        var height;

        var header = KTUtil.find(_element, '.offcanvas-header');
        var content = KTUtil.find(_element, '.offcanvas-content');

        var height = parseInt(KTUtil.getViewPort().height);

        if (header) {
            height = height - parseInt(KTUtil.actualHeight(header));
            height = height - parseInt(KTUtil.css(header, 'marginTop'));
            height = height - parseInt(KTUtil.css(header, 'marginBottom'));
        }

        if (content) {
            height = height - parseInt(KTUtil.css(content, 'marginTop'));
            height = height - parseInt(KTUtil.css(content, 'marginBottom'));
        }

        height = height - parseInt(KTUtil.css(_element, 'paddingTop'));
        height = height - parseInt(KTUtil.css(_element, 'paddingBottom'));

        height = height - 2;

        return height;
    }


    var _initProfile = function() {
        KTUtil.scrollInit(_profileElement, {
            mobileNativeScroll: true,
            resetHeightOnDestroy: true,
            handleWindowResize: true,
            height: function() {
                return _getContentHeight();
            }
        });
    }

    var _initNotifs = function() {
        KTUtil.scrollInit(_notifsElement, {
            mobileNativeScroll: true,
            resetHeightOnDestroy: true,
            handleWindowResize: true,
            height: function() {
                return _getContentHeight();
            }
        });
    }

    var _initPassword = function() {
        KTUtil.scrollInit(_passwordElement, {
            mobileNativeScroll: true,
            resetHeightOnDestroy: true,
            handleWindowResize: true,
            height: function() {
                return _getContentHeight();
            }
        });
    }

    var _initLogout = function() {
        KTUtil.scrollInit(_logoutElement, {
            mobileNativeScroll: true,
            resetHeightOnDestroy: true,
            handleWindowResize: true,
            height: function() {
                return _getContentHeight();
            }
        });
    }

    var _updateScrollbars = function() {
        KTUtil.find(_element, '[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            KTUtil.scrollUpdate(_profileElement);
            KTUtil.scrollUpdate(_notifsElement);
            KTUtil.scrollUpdate(_passwordElement);
            KTUtil.scrollUpdate(_logoutElement);
        });
    }


    // Public methods
    return {
        init: function(id) {
            _element = KTUtil.getById(id);
            _profileElement = KTUtil.getById('aaaa');
            _notifsElement = KTUtil.getById('bbbb');
            _passwordElement = KTUtil.getById('cccc');
            _logoutElement = KTUtil.getById('dddd');

            if (!_element) {
                return;
            }

            // Initialize
            _init();

            _initProfile();
            _initNotifs();
            _initPassword();
            _initLogout();

            //_updateScrollbars();
        },

        getElement: function() {
            return _element;
        }
    };
}();

// Webpack support
if (typeof module !== 'undefined') {
	//module.exports = KTLayoutQuickUser;
}

export default KTLayoutQuickUser;
