/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {useEffect} from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage, ShopifyConfirmation } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { Apps } from "./modules/Apps/Apps";

export function Routes() {
    
    const {isAuthorized} = useSelector( state => ({isAuthorized: state.user.userId ? true : false}), shallowEqual ); //--> do we really need this?  YES! for persistence between reloads!!
   
    return (
    <>
        <Switch>
            
            <Route path="/a/:id" component={Apps} />
            <Route path="/a/" component={Apps} />
            <Route path="/shopifyConfirmation" component={AuthPage} />
            <Route path="/shopifyNewLocation" component={AuthPage} />
            
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/convos/convos"/>
            )}

            <Route path="/error" component={ErrorsPage}/>
            <Route path="/logout" component={Logout}/>
            


            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login"/>
            ) : (
                <Layout>
                    <BasePage/>
                </Layout>
            )}
        </Switch>
        
    </>
    );
}

