import React, {useEffect, useLayoutEffect, useState} from "react";
import {KTUtil} from "../../_assets/js/components/util.js";

export function Beacon({}) {
    //--> had to make some edits to the code provided by helpscout to make it work here
    useEffect(()=>{
        //--> put the code into a useEffect to load up on component load one time only

        ((e,t,n)=>{
            //--> changed this to anonymous function, immediately executed variety
            function a() {
                var e = t.getElementsByTagName("script")[0]
                  , n = t.createElement("script");
                n.type = "text/javascript";
                //--> replace the commans in these lines with semicolons
                n.async = !0;
                n.src = "https://beacon-v2.helpscout.net";
                e.parentNode.insertBefore(n, e);
            }
            if (e.Beacon = n = function(t, n, a) {
                e.Beacon.readyQueue.push({
                    method: t,
                    options: n,
                    data: a
                })
            }
            ,
            n.readyQueue = [],
            "complete" === t.readyState)
                return a();
            e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1)
        }
        )(window, document, window.Beacon || function() {}
        );
        //--> we are manually handling the opening and closing of the beacon, so the following is not used
        if (KTUtil.isBreakpointUp('lg')) {
            window.Beacon('init', 'fdebe83a-808a-4fd1-a37e-99de494e4afe')
        }

    }
    , [])

    return null;
}
