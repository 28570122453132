import React from "react";

import Parse from "parse";

import 'react-animation/dist/keyframes.css';
import { animations } from 'react-animation';
import { AnimateOnChange } from 'react-animation';

export function ShopifyConfirmation(props) {


  return (

        <div className="card card-custom max-w-lg-600px max-h-lg-600px w-100 h-100 shadow-lg" style={{backgroundImage: 'linear-gradient(60deg, #663399 0%, #7643a9 60%, #9663c9 100%)'}}>
            <div className="card-body d-flex p-0">
                <div className="flex-grow-1 p-12 pb-40 card-rounded flex-grow-1 bgi-no-repeat" style={{backgroundPosition: 'calc(100% + 0.5rem) bottom', backgroundSize: '50% auto', backgroundImage: 'url(media/svg/patterns/rhone.svg)'}}>
                    <p className="text-white pt-10 pb-5 display-4 font-weight-bolder line-height-lg">CONGRATULATIONS!</p>
                    {/*<p className="text-white font-size-h1 line-height-lg font-weight-bolder">Your Textopia account is all setup, please check your email for login details</p>
                    <p className="line-height-lg pt-5">
                        <a href="https://app.textopia.com" className="btn btn-lg btn-white font-weight-bolder py-4 px-8">LOGIN NOW</a>
                    </p>
                    <p className="text-white pt-10 max-w-300px font-size-h5 line-height-lg font-weight-bold">Also check your spam folders and allow emails from <b>help@luv2txt.textopia.com</b></p>
                    */}
                    <p className="text-white font-size-h1 line-height-lg font-weight-bolder">Your Textopia account is all setup.  Please login below.</p>
                    <p className="line-height-lg pt-5">
                        <a href="https://app.textopia.com" className="btn btn-lg btn-white font-weight-bolder py-4 px-8">LOGIN NOW</a>
                    </p>
                    <p className="text-white font-weight-bold">Username: shopify_test@textopia.com
                    <br/>Password:  shopify_test@textopia.com</p>
                    
                </div>
            </div>
        </div>
  
  );
}


export function ShopifyNewLocation(props) {


  return (

        <div className="card card-custom max-w-lg-600px max-h-lg-600px w-100 h-100 shadow-lg" style={{backgroundImage: 'linear-gradient(60deg, #663399 0%, #7643a9 60%, #9663c9 100%)'}}>
            <div className="card-body d-flex p-0">
                <div className="flex-grow-1 p-12 pb-40 card-rounded flex-grow-1 bgi-no-repeat" style={{backgroundPosition: 'calc(100% + 0.5rem) bottom', backgroundSize: '50% auto', backgroundImage: 'url(media/svg/patterns/rhone.svg)'}}>
                    <p className="text-white pt-10 pb-5 display-4 font-weight-bolder line-height-lg">CONGRATULATIONS!</p>
                    
                    {/*<p className="text-white font-size-h1 line-height-lg font-weight-bolder">New store added to your Textopia account, and it is all set to go</p>
                    <p className="line-height-lg pt-5">
                        <a href="https://app.textopia.com" className="btn btn-lg btn-white font-weight-bolder py-4 px-8">LOGIN NOW</a>
                    </p>
                    
                    <p className="text-white pt-10 max-w-300px font-size-h5 line-height-lg font-weight-bold">Thanks for being part of the Textopia community</p>
                    */}
                    
                    <p className="text-white font-size-h1 line-height-lg font-weight-bolder">Your Textopia account is all setup.  Please login below.</p>
                    <p className="line-height-lg pt-5">
                        <a href="https://app.textopia.com" className="btn btn-lg btn-white font-weight-bolder py-4 px-8">LOGIN NOW</a>
                    </p>
                    <p className="text-white font-weight-bold">Username: shopify_test@textopia.com
                    <br/>Password:  shopify_test@textopia.com</p>

                </div>
            </div>
        </div>
  
  );
}
