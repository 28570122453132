/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Link, Switch, Redirect} from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {ContentRoute} from "../../../../_metronic/layout"
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import { ShopifyConfirmation, ShopifyNewLocation } from "./ShopifyConfirmation";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

export function AuthPage() {
  return (
      <>
        <div className="d-flex flex-column flex-root">
          {/*begin::Login*/}
          <div
              className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
              id="kt_login"
          >
            {/*begin::Aside*/}
            <div
                className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10"
                style={{
                  backgroundImage: 'linear-gradient(60deg, #663399 0%, #7643a9 60%, #9663c9 100%)'
                }}
            >
              {/*begin: Aside Container*/}
              <div className="d-flex flex-row-fluid flex-column justify-content-between">
                {/* start:: Aside header */}
                <Link to="/" className="flex-column-auto">
                  <img
                      alt="Logo"
                      className="max-w-200px"
                      src={toAbsoluteUrl("/media/Textopia/Logos/textopia_white_logo.svg")}
                  />
                </Link>
                {/* end:: Aside header */}

                {/* start:: Aside content */}
                <div className="flex-column-fluid d-flex flex-column justify-content-center">
                  <h3 className="d-none d-lg-block font-size-h1 mb-5 text-white">
                    Welcome to Textopia
                  </h3>
                  <p className="font-weight-lighter text-white opacity-80">
                    Because customers luv2txt
                  </p>
                </div>
                {/* end:: Aside content */}

                <img src="https://textopia.com/assets/images/features/textopia_home_img-min.png" height="auto" width="100%" className="d-none d-lg-block" alt="image_not_found" />

                {/* start:: Aside footer for desktop */}
                <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                  <div className="text-white">
                    <a href="https://textopia.com" className="text-white" >&copy; 2021 Textopia</a>
                  </div>
                  <div className="d-flex">
                    <a href="https://textopia.com/privacy.html" className="text-white">
                      Privacy
                    </a>
                    <a href="https://textopia.com/tos.html" className="text-white ml-10">
                      Terms
                    </a>
                    <a href="mailto:help@luv2txt.textopia.com" className="text-white ml-10">
                      info@textopia.com
                    </a>
                  </div>
                </div>
                {/* end:: Aside footer for desktop */}
              </div>
              {/*end: Aside Container*/}
            </div>
            {/*begin::Aside*/}

            {/*begin::Content*/}
            <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
              {/*begin::Content header*/}

              {/* begin::Content body */}
              <div className="d-flex flex-column-fluid flex-center mt-lg-0">
                <Switch>
                    <ContentRoute path="/shopifyConfirmation" component={ShopifyConfirmation}/>    
                    <ContentRoute path="/shopifyNewLocation" component={ShopifyNewLocation}/>           
                    <ContentRoute path="/auth/login" component={Login}/>               
                    <ContentRoute
                        path="/auth/forgot-password"
                        component={ForgotPassword}
                    />
                    <ContentRoute path="/auth/reset-password" component={ResetPassword} />
                    <Redirect from="/auth" exact={true} to="/auth/login"/>
                    <Redirect to="/auth/login"/>
                </Switch>
              </div>
              {/*end::Content body*/}

              {/* begin::Mobile footer */}
              <div
                  className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                <div className="text-white font-weight-bold order-2 order-sm-1 my-2">
                  &copy; 2021 Textopia.com
                </div>
                <div className="d-flex order-1 order-sm-2 my-2">
                  <a href="https://textopia.com/privacy.html" className="text-white">
                    Privacy
                  </a>
                  <a
                      href="https://textopia.com/tos.html"
                      className="text-white ml-4"
                  >
                    Terms
                  </a>
                  <a
                      href="mailto:help@luv2txt.textopia.com"
                      className="text-white ml-4"
                  >
                    help@luv2txt.textopia.com
                  </a>
                </div>
              </div>
              {/* end::Mobile footer */}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Login*/}
        </div>
      </>
  );
}
