"use strict";

import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { components } from "react-select";
import makeAnimated from 'react-select/animated';

import {KTUtil} from "../components/util";
import { Parse } from "parse";
import * as yup from "yup";
import * as dayjs from "dayjs";

import * as Queries from "./TextopiaQueries";
import * as Constants from "./TextopiaConstants";

import { nanoid } from '@reduxjs/toolkit';
import parsePhoneNumber from 'libphonenumber-js';


export const lockScroll = () => {
  const body = document.querySelector('body');
  let scrollPosition = 0;

  scrollPosition = window.pageYOffset;
  body.style.overflow = 'scroll';
  //body.style.position = 'fixed';
  body.style.top = `-${scrollPosition}px`;
  body.style.width = '100%';
  return scrollPosition;
}


export const unlockScroll = (pos) => {
  const body = document.querySelector('body');
  let scrollPosition = pos ? pos : 0;
  
  body.style.removeProperty('position');
  body.style.removeProperty('top');
  body.style.removeProperty('width');
  window.scrollTo(0, scrollPosition);
}

export const getLocationList = function(user) {
  if (!user) return;

  let locArray = user.get("locationList");
  let returnArray = [];
  if (!locArray || locArray.length < 1) return returnArray;

  locArray.map(item => (
    returnArray.push(item.get("shortName"))
  ))

  return returnArray;

}

export const getAddressString = function(obj) {
  return  obj.get("address1") +
          ( (obj.get("address2") && obj.get("address2") != "") ? (", " + obj.get("address2")) : "" ) +
          ( (obj.get("address3") && obj.get("address3") != "") ? (", " + obj.get("address3")) : "" ) +
          ( (obj.get("city") && obj.get("city") != "") ? (", " + obj.get("city")) : "" ) +
          ( (obj.get("state") && obj.get("state") != "") ? (", " + obj.get("state")) : "" ) +
          ( (obj.get("zipcode") && obj.get("zipcode") != "") ? (" " + obj.get("zipcode")) : "" );
}

// This list is needed for both versions of UI permission check (with or without DB)
// This is the lookup list that each widget will refer to when requesting confirmation of permission
export const UIPermissions = {
  ENABLE_USER_LIST:   "ENABLE_USER_LIST",
  ENABLE_NEW_USER:    "ENABLE_NEW_USER",
  ENABLE_EDIT_USER:   "ENABLE_EDIT_USER",
  ENABLE_DELETE_USER: "ENABLE_DELETE_USER"
};

// Check the current user's UI permissions to see or not particular widgets
export const checkUIPermission = function(permission) {
  const currentUser = Parse.User.current();
  const userPermissions = currentUser.get("userRolePtr").get("rolePermissions");
  let obj = userPermissions.find (o => o.get("name") === permission);
  if (obj) return true;
  return false;
}

// IF we maintain all the UI permissions inside the code and not in the DB - this is the DB
// All we need to know is the user role
export const UIPermissionsDB = {
  LOCATION_MANAGER : {ENABLE_ALL_INBOX:true, ENABLE_NEW_USER:true, ENABLE_EDIT_USER:true, ENABLE_DELETE_USER:true, ENABLE_USER_LIST:true, ENABLE_EDIT_TEAM:true, ENABLE_EDIT_HOURS: true, ENABLE_EDIT_GENERAL: true ,ENABLE_EDIT_TEMPLATES: true, ENABLE_EDIT_ATTRIBUTES: true, ENABLE_EDIT_TASKS: true, ACCESS_ALL_TASKS: true, ENABLE_DELETE_TASKS: true, ENABLE_EDIT_QUESTIONS: true, ENABLE_VIEW_FEEDBACK: true, ENABLE_NEW_FEEDBACK_NOTES: true, ENABLE_EDIT_TOPICS: true, ENABLE_EDIT_REVIEW_SETTINGS: true, ENABLE_EDIT_JOB_TITLE: true, ENABLE_VIEW_REVIEWS: true,},
  OWNER : {ENABLE_ALL_INBOX:true, ENABLE_NEW_USER:true, ENABLE_EDIT_USER:true, ENABLE_DELETE_USER:true, ENABLE_USER_LIST:true, ENABLE_EDIT_TEAM:true, ENABLE_EDIT_HOURS: true, ENABLE_EDIT_GENERAL: true, ENABLE_EDIT_TEMPLATES: true, ENABLE_EDIT_ATTRIBUTES: true, ENABLE_EDIT_TASKS: true, ACCESS_ALL_TASKS: true, ENABLE_DELETE_TASKS: true, ENABLE_EDIT_QUESTIONS: true, ENABLE_VIEW_FEEDBACK: true, ENABLE_NEW_FEEDBACK_NOTES: true, ENABLE_EDIT_TOPICS: true, ENABLE_EDIT_REVIEW_SETTINGS: true, ENABLE_EDIT_JOB_TITLE: true, ENABLE_VIEW_REVIEWS: true,},
  TEAM_MEMBER : {ENABLE_ALL_INBOX:false, ENABLE_USER_LIST:true, ENABLE_EDIT_USER:true, ENABLE_NEW_USER:true, ENABLE_EDIT_TEAM:true, ENABLE_EDIT_HOURS: true, ENABLE_EDIT_GENERAL: true, ENABLE_EDIT_TEMPLATES: true, ENABLE_EDIT_ATTRIBUTES: true, ENABLE_EDIT_TASKS: true, ACCESS_ALL_TASKS: true, ENABLE_DELETE_TASKS: true,  ENABLE_EDIT_QUESTIONS: true, ENABLE_VIEW_FEEDBACK: true, ENABLE_NEW_FEEDBACK_NOTES: true, ENABLE_EDIT_TOPICS: true, ENABLE_EDIT_REVIEW_SETTINGS: true, ENABLE_VIEW_REVIEWS: true,},
};

// This is the method to use if the UI permissions are being kept in the code, outside the DB
export const checkUIPermissionAlt = function(permission) {
  const currentUser = Parse.User.current();
  const userRole = currentUser.get("userRolePtr").get("name_id");
  if (UIPermissionsDB[userRole][permission]) return true;
  return false;
}

// Get a list of current user's UI permissions
export const useCurrentUserUIPermissions1 = function() {
  const currentUser = Parse.User.current();
  const userRole = currentUser && currentUser.get("userRolePtr").get("name_id");

  // we'll return an object so the values are easy to lookup
  return UIPermissionsDB[userRole];
}

export const useRole = function() {
    const role = Parse.User.current().get("userRolePtr");
    return role.attributes;
}



let timeSlotTest = yup.mixed().test({
  name: 'timeSlotTest',
  message: 'Check Open and Close times',
  test: function (value) {
    let day = this.path.split("_")[0];
    if (!this.parent[day+"_on_off"]) return true; // If we are closed on this day, then the individual slots need not be tested

    let slot = this.path.split("_")[1].charAt(0); // This (this.path) is the key information from which we determine which dropdown validation is being called for
    const startVal = this.parent[this.path.split("_")[0] + "_" + slot + "1"];
    const endVal = this.parent[this.path.split("_")[0] + "_" + slot + "2"];

    if (startVal && startVal != "" && (!endVal || endVal == "") ) return false;
    if (endVal && endVal != "" && (!startVal || startVal == "") ) return false;

    if (startVal && (startVal != "") && endVal && (endVal != "")) {
      //console.log(this.parent);
      const startValNum = parseInt(startVal.split(":")[0])*60 + parseInt(startVal.split(":")[1]);
      const endValNum = parseInt(endVal.split(":")[0])*60 + parseInt(endVal.split(":")[1]);

      //if (startValNum >= endValNum) return false; // No enforced, perhaps some businesses operate at night so AM - PM
    }
    return true;
  }
})

let slotRequired = yup.mixed().test({
  name: 'slotRequired',
  message: 'Setup at least one set of Opening Hours below',
  test: function (value) {
    const {slot_11, slot_21, slot_31} = this.parent;
    if (value && (!slot_11 || slot_11 == "") && (!slot_21 || slot_21 == "") && (!slot_31 || slot_31 == "")) return false; // If open is selected but no time slots are setup
    return true;
  }
})

let regularSlotRequired = yup.mixed().test({
  name: 'regularSlotRequired',
  message: 'Setup at least one set of Opening Hours below',
  test: function (value) {
    let day = this.path.split("_")[0]; // This is the day prefix for all the slots
    const slot1 = this.parent[day+"_11"];
    const slot2 = this.parent[day+"_21"];
    const slot3 = this.parent[day+"_31"];
    if (value && (!slot1 || slot1 == "") && (!slot2 || slot2 == "") && (!slot3 || slot3 == "")) return false; // If open is selected but no time slots are setup
    return true;
  }
})


export const specialHoursValidationSchema = yup.object({
  specialDate: yup.date().required('Select a date'),
  slot_on_off: slotRequired,
  slot_11: timeSlotTest,
  slot_12: timeSlotTest,
  slot_21: timeSlotTest,
  slot_22: timeSlotTest,
  slot_31: timeSlotTest,
  slot_32: timeSlotTest,
});

export const regularHoursValidationSchema = yup.object({
  monday_on_off: regularSlotRequired,
  tuesday_on_off: regularSlotRequired,
  wednesday_on_off: regularSlotRequired,
  thursday_on_off: regularSlotRequired,
  friday_on_off: regularSlotRequired,
  saturday_on_off: regularSlotRequired,
  sunday_on_off: regularSlotRequired,
  monday_11: timeSlotTest,
  monday_12: timeSlotTest,
  monday_21: timeSlotTest,
  monday_22: timeSlotTest,
  monday_31: timeSlotTest,
  monday_32: timeSlotTest,
  tuesday_11: timeSlotTest,
  tuesday_12: timeSlotTest,
  tuesday_21: timeSlotTest,
  tuesday_22: timeSlotTest,
  tuesday_31: timeSlotTest,
  tuesday_32: timeSlotTest,
  wednesday_11: timeSlotTest,
  wednesday_12: timeSlotTest,
  wednesday_21: timeSlotTest,
  wednesday_22: timeSlotTest,
  wednesday_31: timeSlotTest,
  wednesday_32: timeSlotTest,
  thursday_11: timeSlotTest,
  thursday_12: timeSlotTest,
  thursday_21: timeSlotTest,
  thursday_22: timeSlotTest,
  thursday_31: timeSlotTest,
  thursday_32: timeSlotTest,
  friday_11: timeSlotTest,
  friday_12: timeSlotTest,
  friday_21: timeSlotTest,
  friday_22: timeSlotTest,
  friday_31: timeSlotTest,
  friday_32: timeSlotTest,
  saturday_11: timeSlotTest,
  saturday_12: timeSlotTest,
  saturday_21: timeSlotTest,
  saturday_22: timeSlotTest,
  saturday_31: timeSlotTest,
  saturday_32: timeSlotTest,
  sunday_11: timeSlotTest,
  sunday_12: timeSlotTest,
  sunday_21: timeSlotTest,
  sunday_22: timeSlotTest,
  sunday_31: timeSlotTest,
  sunday_32: timeSlotTest,
});

export const loadQuestionsForUI = function(setInState, questions) {
  let resultsToReturn = [];
  questions && questions.map( item => (
    resultsToReturn.push({"label":item.get("body"), "subLabel":item.get("numResponses") + " responses" + (item.get("averageScore") ? "  |  " + item.get("averageScore") + " average": ""), "value":item}) // Filter out already added sites, since each site can only be added once         
  ))

  setInState(resultsToReturn);
}



export const loadLocationReviewSitesForUI = function(setInState, sites) {
  let resultsToReturn = [];
  sites && sites.map( item => (
    resultsToReturn.push({"label":item.get("reviewSitePtr").get("title"), "image":item.get("reviewSitePtr").get("logo"), "value":item.get("reviewSitePtr").id})          
  ))
  setInState(resultsToReturn);
    
}

export const loadPlansForUI = function(setInState, plans) {
  let resultsToReturn = [];
  plans && plans.map( item => (
    resultsToReturn.push({"label":item.get("title"), "subLabel": `${item.get("currency")} ${item.get("price")} ${item.get("isMonthly") ? "Monthly" : "Yearly"} | ${item.get("numMessagesIncluded")} msgs | ${item.get("pricePerMessage")} / msg`, "value":item.id})
  ))
  setInState(resultsToReturn);
  
}


export const loadRolesForUI = function(setInState, roles) {
  let resultsToReturn = [];
  roles && roles.map( item => (
    resultsToReturn.push({"label":item.get("name"), "subLabel":item.get("subLabel"), "value":item.id})
  ))
  setInState(resultsToReturn);
  
}

export const loadTenantLocationsForUI = function (setInState, locations) {
  let resultsToReturn = [];
  locations && locations.map( item => (
    resultsToReturn.push({"label":item.get("shortName") + (!item.get("isActive") ? " *INACTIVE* " : ""), "subLabel":getAddressString(item), "value":item})
  ))
  setInState(resultsToReturn);  
}

export const loadCurrentUserLocationsForUI = function (setInState) {
  const results = Parse.User.current().get("locationList"); //await loadCurrentUserLocations();
  let resultsToReturn = [];
  results && results.map( item => (
    resultsToReturn.push({"label":item.get("shortName") + (!item.get("isActive") ? " *INACTIVE* " : ""), "subLabel":getAddressString(item), "value":item.id})
  ))
  setInState(resultsToReturn);
}

export const loadTopicsForUI = function(setInState, topics) {
  
  let resultsToReturn = [];
  topics && topics.map( item => (
    resultsToReturn.push({"label": item.get("topic"), "value": item.id})
  ))
  
  setInState(resultsToReturn);  
}

export const loadLocationUsersAndTeamsObjsForUI = function(setInState, users, teams) { 

  // Combine the two arrays into one that can be rendered in a UI select list

  let resultsToReturn = [];
  teams && teams.map( team => (
    resultsToReturn.push({"label": team.get("title"), "subLabel": "Team", "type": "team", "value":team , "labelColor":"primary-d-3"})
  ))
  //--> filter users to include only those from this location (users store in redux contains tenant-wide users)
  let filtered = users && users.filter(user => user.get("locationList") && user.get("locationList").find(item => item.id === Parse.User.current().get("primaryLocationPtr").id));
  filtered && filtered.map( user => (
    resultsToReturn.push({"label": getUserName(user), "subLabel": user.get("jobTitle"), "type": "user", "value":user, "labelColor":"primary"})
  ))

  setInState(resultsToReturn);    
}

export const loadLocationTemplatesForConvoUI = (templates) => {
  let resultsToReturn = [];
  templates && templates.map( template => (
    resultsToReturn.push({"label": template.get("title"), "value": template.get("content"), "subLabel": template.get("category"), "id": template.id})
  ))
  return resultsToReturn;
}


export const loadCustomersForUI = function(setInState, customers) {
 
  let resultsToReturn = [];
  customers && customers.map( customer => (
    resultsToReturn.push({"label": getCustomerName(customer), "subLabel": customer.get("email"), "value":customer.id})
  ))
  
  setInState(resultsToReturn);
}


export const cleanupAll = function (funcs) {
  if (funcs) funcs.map(func => { if (typeof func === 'function') func() });
  return;
}

// Central function for CRUD submit
export const useHandleSubmit = async function (values, actions, componentEl, statusToCheck, dispatch, dispatchCall, noReset, cleanup) {
  //console.log('called');
  if (statusToCheck != "saving") {
    if (Array.isArray(dispatchCall)) {
      // A chain of dispatches
      dispatchCall.map(async (item)  => {        
        values.dispatchObj = item.dispatchObj;
        await dispatch(item.dispatchCall(values));
      })  
    } else if (dispatch) { //--> going to redux
      await dispatch(dispatchCall(values));   
    } else await dispatchCall(values); //--> not going to redux, just a simple function call
    
    actions && actions.setSubmitting(false);
    !noReset && actions && actions.resetForm();
    cleanup && cleanupAll(cleanup);

    if (typeof componentEl === 'string') KTUtil.getById(componentEl + '_close').click(); //--> legacy click on the close button
    else componentEl && componentEl.hide(); //--> newer call the hide function on the containing pullout

  }
}

export const useHandleDeleteSubmit = function (e, actionStatus, dispatch, dispatchCall, itemToDelete, setShowDeleteModal) {
  e.preventDefault();
  if (actionStatus != "deleting") {
    if (Array.isArray(itemToDelete)) {
      dispatch(dispatchCall(itemToDelete)); // We could be deleting a bunch of objects at once (this will be an array)
    }
    else {
      dispatch(dispatchCall(itemToDelete.id)); // Or we could be deleting only one object
    }
    setShowDeleteModal(false); // Delay the closing by a small amount to give a sense to user
  }
}

export const useHandleConfirmationSubmit = function (e, actionStatus, dispatch, dispatchCall, item, setOnHide, dispatchParams) {  
  e.preventDefault();
  if (actionStatus == "succeeded" || actionStatus == "failed" || actionStatus == "idle" || actionStatus == "") {
    dispatch(dispatchCall(dispatchParams ? dispatchParams : item.id));   
    setOnHide(false); // Delay the closing by a small amount to give a sense to user
  }
}

export const useGetActionStatusAndError = function (stateSlice) {
  const { actionStatus, actionError } = useSelector(
    (state) => ({
      actionStatus: state[stateSlice]["actionStatus"],
      actionError: state[stateSlice]["actionError"]
    }),
    shallowEqual
  )
  return {actionStatus, actionError};
}

export const useGetPageStatusAndError = function (stateSlice) {
  const { pageStatus, pageError, actionStatus, actionError  } = useSelector(
    (state) =>  ({
      pageStatus: state[stateSlice]["pageStatus"],
      pageError: state[stateSlice]["pageError"],
      actionStatus: state[stateSlice]["actionStatus"],
      actionError: state[stateSlice]["actionError"]
    }),
    shallowEqual
  )
  return { pageStatus, pageError, actionStatus, actionError };
}

export const sortNewestFirst = function(entities) {
  if (!entities) return;
  return entities.slice().sort((a, b) => (b.createdAt - a.createdAt));
}

export const sortNewestUpdated = function(entities) {
  if (!entities) return;
  return entities.slice().sort((a, b) => (b.updatedAt - a.updatedAt));
}

export const sortOldestFirst = function(entities) {
  if (!entities) return;
  return entities.slice().sort((a, b) => (a.createdAt - b.createdAt));
}

export const showCRUDDialog = function (e, item, action, setEditEntity, setShowEditPullout, setShowDeleteModal){
  e.preventDefault();
  if(item) {
    setEditEntity(item); // This coud be a single item or an array
  }
  if (action === "edit") {
    setShowEditPullout(true);
  } else if (action === "delete") {
    setShowDeleteModal(true);
  } else { // New item
    setEditEntity("");
    setShowEditPullout(true);
  }
}

export const dayOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
export const lookupDayOfWeek = function (key) {
  return dayOfWeek[key];
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  s = s.replace('_', ' ');
  return s.charAt(0).toUpperCase() + s.slice(1)
}
export const lookupTimeOfDay = (key) => {
  if (!key) return "";
  var keyHr = key.split(':')[0];
  var keyMin = key.split(':')[1];
  var time = keyHr - 12;
  var amPm = time >= 0 ? "PM" : "AM";
  var newTime = time > 0 ? time : ( keyHr == "00" ? "12" : keyHr );
  //newTime = newTime.toString().charAt(0) === '0' ? newTime.toString().slice(1) : newTime.toString(); // Remove initial '0' that may show up in numbers
  return newTime + ":" + keyMin + " " + amPm;
}
export const timeOfDay = [
                    "12:00 AM", "12:30 AM", "1:00 AM", "1:30 AM", "2:00 AM", "2:30 AM", "3:00 AM", "3:30 AM",
                    "4:00 AM", "4:30 AM", "5:00 AM", "5:30 AM", "6:00 AM", "6:30 AM", "7:00 AM", "7:30 AM",
                    "8:00 AM", "8:30 AM", "9:00 AM", "9:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM",
                    "12:00 PM", "12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM", "3:00 PM", "3:30 PM",
                    "4:00 PM", "4:30 PM", "5:00 PM", "5:30 PM", "6:00 PM", "6:30 AM", "7:00 PM", "7:30 PM",
                    "8:00 PM", "8:30 AM", "9:00 PM", "9:30 AM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM",
                  ];


export const checkIfDatesAreEqual = function (date1, date2) {
  if (!date1 || !date2) {
    return false;
  }
  if ( date1.getFullYear() == date2.getFullYear() && date1.getMonth() == date2.getMonth() && date1.getDate() == date2.getDate() ) {
    return true;
  }
  return false;
}

export const managePlural = function (list, title) {
  return (list ? list.length : '0') + " " + title + (list && list.length > 1 ? "s" : "");
}
export const managePluralWord = function (list, title) {
  return title + (list && list.length > 1 ? "s" : "");
}

export const getDDOptions = function (editTrue, deleteTrue, showEditDialog, itemVal, outline) {
  let arrayToRet = [];
  if (deleteTrue) arrayToRet.push(
                                {
                                  title: "Delete",
                                  icon: "la la-trash",
                                  onClick: showEditDialog,
                                  action: "delete",
                                  item: itemVal,
                                  //color: outline ? "outline-secondary" : "light-danger"
                                  color: "light-danger"
                                }
                            );
  if (editTrue) arrayToRet.push(
                                {
                                  title: "Edit",
                                  icon: "la la-pen",
                                  onClick: showEditDialog,
                                  action: "edit",
                                  item: itemVal,
                                  //color: outline ? "outline-secondary" : "light-primary"
                                  color: "light-primary"
                                }
                            );

                              

                        
  return arrayToRet;
}


export const groupByPtr = function (xs, key) {
  return xs.reduce(function (rv, x) {
    let v = x.get(key).id;
    let el = rv.find((r) => r && r.key === v);
    if (el) {
      el.values.push(x);
    }
    else {
      rv.push({ key: v, values: [x] });
    }
    return rv;
  }, []);
}


export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    let v = x.get(key);
    let el = rv.find((r) => r && r.key === v);
    if (el) {
      el.values.push(x);
    }
    else {
      rv.push({ key: v, values: [x] });
    }
    return rv;
  }, []);
}

export const groupByPlainObjs = function (xs, key) {
  return xs.reduce(function (rv, x) {
    let v = x[key]; //--> x.key instead of x.get(key) - due to plain objects
    let el = rv.find((r) => r && r.key === v); //--> note that this remains r.key, the only change from above is x[key] vs x.get(key)
    if (el) {
      el.values.push(x);
    }
    else {
      rv.push({ key: v, values: [x] });
    }
    return rv;
  }, []);
}




export const getDateRange = function (param, jsDate) {
  let toRet;

  if (param === "Today") {
    toRet = {start: dayjs(new Date()).startOf('day').toDate()};
  }
  if (param === "Yesterday") {
    toRet = {start: dayjs(new Date()).startOf('day').subtract(1, 'day').toDate(), end: dayjs(new Date()).startOf('day')}    
  }
  if (param === "This Week") {
    toRet = {start: dayjs(new Date()).startOf('week').toDate()}
  }
  if (param === "Last Week") {
    toRet = {start: dayjs(new Date()).startOf('week').subtract(1, 'week').toDate(), end: dayjs(new Date()).startOf('week')}
  }
  if (param === "This Month") {
    toRet = {start: dayjs(new Date()).startOf('month').toDate()}
  }
  if (param === "Last Month") {
    toRet = {start: dayjs(new Date()).startOf('month').subtract(1, 'month').toDate(), end: dayjs(new Date()).startOf('month')}
  }
  if (param === "This Year") {
    toRet = {start: dayjs(new Date()).startOf('year').toDate()}
  }
  if (param === "Last Year") {
    toRet = {start: dayjs(new Date()).startOf('year').subtract(1, 'year').toDate(), end: dayjs(new Date()).startOf('year')}
  }
  if (param === "Last 12 Months") {
    toRet = {start: dayjs(new Date()).subtract(366, 'day')}
  }
  if (toRet && jsDate) return toRet.toDate(); // If js date requested, convert and send
  if (toRet) return toRet;
}

export const getQueryDateRange = function (q, dateHolder, param) {
  if (["Today", "This Week", "This Month", "This Year", "Last 12 Months"].includes(param)) {
    const { start, end } = getDateRange(param, true);
    q.greaterThanOrEqualTo(dateHolder, start);
  }
  else if (["Yesterday", "Last Week", "Last Month", "Last Year"].includes(param)) {
    const { start, end } = getDateRange(param, true);
    q.greaterThanOrEqualTo(dateHolder, start);
    q.lessThanOrEqualTo(dateHolder, end);
  }
}

export const getIsObjectDateInRange = function (obj, dateHolder, param) {
  if (["Today", "This Week", "This Month", "This Year", "Last 12 Months"].includes(param)) {
    const { start, end } = getDateRange(param); // Not requesting js date so we get dayjs objects, so no need to reconvert
    if (dateHolder === "createdAt" || dateHolder === "updatedAt") {//--> createdAt and updatedAt are special attributes that do  not require a 'get'
      if (dayjs(obj[dateHolder]).isAfter(start)) return true;
    } else {
      if (dayjs(obj.get(dateHolder)).isAfter(start)) return true;
    }
  }
  else if (["Yesterday", "Last Week", "Last Month", "Last Year"].includes(param)) {
    const { start, end } = getDateRange(param);
    if (dateHolder === "createdAt" || dateHolder === "updatedAt") {
      if (dayjs(obj[dateHolder]).isAfter(start) && dayjs(obj[dateHolder]).isBefore(end)) return true;  
    }
    else {
      if (dayjs(obj.get(dateHolder)).isAfter(start) && dayjs(obj.get(dateHolder)).isBefore(end)) return true;  
    }
    
  }
  return false;
}

export const getSubheaderHeight = function() {
  let subheader = KTUtil.getById("subheader");
  let height = 0;
  if (subheader) height = parseInt(KTUtil.css(subheader, 'height'));
  return height;
}


export const getHeaderHeight = function() {
  let header = KTUtil.getById("kt_header");
  let height = 0;
  if (header) height = parseInt(KTUtil.css(header, 'height'));
  
  return height;
}

export const getMobileHeaderHeight = function() {
  let header = KTUtil.getById("kt_header_mobile");
  let height = 0;
  if (header) height = parseInt(KTUtil.css(header, 'height'));
  
  return height;
}

export const getFooterHeight = function() {
  let footer = KTUtil.getById("kt_footer");
  let height = 0;
  if (footer) height = parseInt(KTUtil.css(footer, 'height'));
  if (footer) height = height - parseInt(KTUtil.css(footer, 'margin-top'));
  if (footer) height = height - parseInt(KTUtil.css(footer, 'margin-bottom'));
  
  return height;
}

export const getLgHeight = function() {
  let height = KTUtil.getViewPort().height;                        
  height = height - getHeaderHeight();
  height = height - getSubheaderHeight();
  height = height - getFooterHeight();
  let pageContainer = KTUtil.getById('Textopia_page_container');
  if (pageContainer) {
    height = height - parseInt(KTUtil.css(pageContainer, 'padding-top')) - parseInt(KTUtil.css(pageContainer, 'padding-bottom'));
    height = height - parseInt(KTUtil.css(pageContainer, 'margin-top')) - parseInt(KTUtil.css(pageContainer, 'margin-bottom'));
  }
  const contentEl = KTUtil.getById('kt_content');                        
  if (contentEl) {
      height = height - parseInt(KTUtil.css(contentEl, 'padding-top')) - parseInt(KTUtil.css(contentEl, 'padding-bottom'));
      height = height - parseInt(KTUtil.css(contentEl, 'margin-top')) - parseInt(KTUtil.css(contentEl, 'margin-bottom'));
  }
  //height = height - 20; // bottom margin added by TextopiaPageContainer   
  return height;     
}

export const getSmHeight = function() {
  let height = KTUtil.getViewPort().height;   
  
  //height = height - getMobileHeaderHeight();
  //height = height - getSubheaderHeight();
  //height = height - getFooterHeight();
  let pageContainer = KTUtil.getById('Textopia_page_container');
  if (pageContainer) {
    height = height - parseInt(KTUtil.css(pageContainer, 'padding-top')) - parseInt(KTUtil.css(pageContainer, 'padding-bottom'));
    height = height - parseInt(KTUtil.css(pageContainer, 'margin-top')) - parseInt(KTUtil.css(pageContainer, 'margin-bottom'));
  }
  const contentEl = KTUtil.getById('kt_content');                        
  if (contentEl) {
      height = height - parseInt(KTUtil.css(contentEl, 'padding-top')) - parseInt(KTUtil.css(contentEl, 'padding-bottom'));
      height = height - parseInt(KTUtil.css(contentEl, 'margin-top')) - parseInt(KTUtil.css(contentEl, 'margin-bottom'));
  }   
  
  return height;     
}

// Create a name string using a priority order of strings
export const getCustomerName = function(customer) {

  if (!customer) return "";

  var name = "";
  name += customer.get("firstName") ? customer.get("firstName") + ' ' : '';
  name += customer.get("lastName") ? customer.get("lastName") : '';

  if (name === "") {
    name += customer.get("fbFirstName") ? customer.get("fbFirstName") + ' ' : '';
    name += customer.get("fbLastName") ? customer.get("fbLastName") : '';
  }

  if (name === "") {
    name += customer.get("phone") && customer.get("phone") !== "" ? parsePhoneNumber(customer.get("phone")) && parsePhoneNumber(customer.get("phone")).formatInternational() : "";
  }

  if (name === "") {
    name += customer.get("email") ? customer.get("email") : "";
  }

  if (name === "") {
    name += customer.get("twitterUsername") ? customer.get("twitterUsername") : "";
  }

  if (name === "") {
    name += customer.get("facebookId") ? customer.get("facebookId") : "";
  }

  if (name === "") {
    name += customer.get("instagramAddress") ? customer.get("instagramAddress") : "";
  }

  if (name === "") return "";
  else return name;
}

export const getCustomerInitials = function(customer) {
  if (!customer || customer === "") return "";
  let initials = getCustomerInitialsNoIcon(customer);
  if (!initials || initials === "") { initials = (!customer.get("firstName") && !customer.get("lastName")) && <i className="flaticon2-user icon-lg text-dark-50" /> }
  return initials;
}

export const getCustomerInitialsNoIcon = function(customer) {
  if (!customer || customer === "") return "";
  let initials = ""; 
  initials = (customer.get("firstName") || customer.get("lastName")) && (customer.get("firstName") ? customer.get("firstName").charAt(0) : "") + (customer.get("lastName") ? customer.get("lastName").charAt(0) : "");  
  return initials || "";
}

export const getUserName = function(user) {
  if (!user || user === "") return "";
  return (   
    (user.id == Parse.User.current().id ? "You" :  (user.get("firstName") ? user.get("firstName") : "") + (user.get("lastName") ? ((user.get("firstName") ? " " : "") + user.get("lastName")) : ""))
  )
}

export const getInitials = function(user) {
  if (!user) return "";
  return (
    (user.get("firstName") ? user.get("firstName").charAt(0) : "") +
    (user.get("lastName") ? user.get("lastName").charAt(0) : "")
  )
}



export const fromNow = function(date, format, toSubtract) {
  var relativeTime = require('dayjs/plugin/relativeTime');
  dayjs.extend(relativeTime);
  
  if (dayjs(date).isBefore(dayjs(new Date()).subtract(toSubtract ? toSubtract : 14, 'day'))) 
    return (dayjs(date).format(format ? format : Constants.DATE_FORMAT_DEFAULT));
  else
    return dayjs(date).fromNow();
}

export const clientConfig = {
        applicationId: '6AJfa2enUPtiqpzcGWzeGenVVUMeI9JUkxCuz5H8',
        serverURL: 'wss://' + 'textopia.b4a.io',
        javascriptKey: 'HyqMpblsidWCv10X2cUvcHavsblCldY45log2cmW'            
    };

export const setupSubs = async function(client, params) { 
  if (!params || !params.query || !params.dispatch || !params.dispatchCall) {
    console.log("Incorrect usage of 'setupSubs' ->  Check 'params' -> queryData is optional.");
    return;
  } 

  //console.log("setup subs called in Textopia.")

  //--> dispatchParams are optional //--> use them to tell the .slice what to do after the dispatch, such as to reset state to something because DB updates can affect current UI that the user is seeing
  
  


  let q = params.query;
  //console.log(q);
  
  //let subs = await q.subscribe();
  let subs = await client.subscribe(q);
  params.debug && console.log("sub..." + subs.id + "..." + subs.query.className);
  subs.on(Constants.LQ_OPEN, () => { });
  subs.on(Constants.LQ_UPDATE, (obj) => { 
                                            params.debug && console.log("LQ Update..." + subs.id + "..." + obj.id + "..." + obj.className); 
                                            params.dispatch(params.dispatchCall({obj: obj, dispatchParams: params.dispatchParams, mode: Constants.LQ_UPDATE})) 
                                        });
  subs.on(Constants.LQ_CREATE, (obj) => { 
                                            params.debug && console.log("LQ Create..." + subs.id + "..." + obj.id + "..." + obj.className);
                                            params.dispatch(params.dispatchCall({obj: obj, mode: Constants.LQ_CREATE})) 
                                        });
  subs.on(Constants.LQ_ENTER,  (obj) => { 
                                            params.debug && console.log("LQ Enter..." + subs.id + "..." + obj.id + "..." + obj.className);
                                            params.dispatch(params.dispatchCall({obj: obj, mode: Constants.LQ_ENTER})) 
                                        });  
  subs.on(Constants.LQ_DELETE, (obj) => { 
                                            params.debug && console.log("LQ Delete..." + subs.id + "..." + obj.id + "..." + obj.className);
                                            params.dispatch(params.dispatchCall({obj: obj, mode: Constants.LQ_DELETE})) 
                                        });
  subs.on(Constants.LQ_LEAVE,  (obj) => { 
                                            params.debug && console.log("LQ Leave..." + subs.id + "..." + obj.id + "..." + obj.className);
                                            params.dispatch(params.dispatchCall({obj: obj, mode: Constants.LQ_LEAVE})) 
                                        });  
  subs.on(Constants.LQ_CLOSE, () => { });

  return subs;

}

export const unsubscribe = function(client, debug, sub) {
  debug && console.log("unsub..." + sub.id); 
  client.unsubscribe(sub) ;
  return;
}

export const validateEmail = function(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const getTagifyTemplates = function() {
  return {
        dropdownItem : function(tagData){
            try{
            return `<div class='font-size-sm bg-hover-light hover-bg-light border-left border-left-white border-left-hover-${tagData.type==="Customer" ? "primary" : "primary-d-3"} border-3 rounded-0 tagify__dropdown__item ${tagData.class ? tagData.class : ""}' tagifySuggestionIdx="${tagData.tagifySuggestionIdx}">
                      <span class='pl-3 h-100 d-flex flex-row align-items-center justify-content-start ${tagData.type==="Customer" ? "text-primary-l-1" : "text-primary-d-3"}'><i class="flaticon2-arrow icon-sm text-${tagData.type==="Customer" ? "primary" : "primary-d-3"} mr-2"></i>${tagData.title}</span>
                    </div>`
            }
            catch(err){}
        },

        tag : function(tagData){
            try{
            return `<tag title='${tagData.value}' contenteditable='false' spellcheck="false" class='tagify__tag ${tagData.type==="Customer" ? "bg-light-primary" : "bg-primary-d-4"} rounded-lg px-1' ${this.getAttributes(tagData)}>
                        <x title='remove tag' class='tagify__tag__removeBtn'></x>
                        <div>
                            ${tagData.code ?
                            `<img onerror="this.style.visibility='hidden'" src='https://lipis.github.io/flag-icon-css/flags/4x3/${tagData.code.toLowerCase()}.svg'>` : ''
                            }
                            <span class='tagify__tag-text'>${tagData.value}</span>
                        </div>
                    </tag>`
            }
            catch(err){}
        },
    }    
}




export const getAttributesForTemplates = function (setInState, attrType, attrs) { //--> gets all the attributes for this tenant/location, meaning, not just those with values, but all defined and standard - used to create lookups and select lists.

  
  let attributes = attrs;
  //console.log(attributes);

  let myAttributes = [];

  const addCustomerAttr = (title, value, category, templateAttr, fieldName) => {
    addAttr(title, value, category, templateAttr, "Customer", fieldName);    
  }

  const addBizAttr = (title, value, category, templateAttr) => {
    addAttr(title, value, category, templateAttr, "Business");    
  }

  const addAttr = (title, value, category, templateAttr, type, fieldName) => {
    myAttributes.push({title: title, value: value, category: category, templateAttribute: templateAttr, type: type, fieldName: fieldName}) //--> this is a purely lookup structure, templateAttr here will not have any filled-in values
  }

  if (!attrType || attrType === "ALL" || attrType === "CUSTOMER") {
    addCustomerAttr( "First Name",  "First Name",  Constants.ATTR_CAT_CONTACT, null, "firstName");
    addCustomerAttr( "Last Name",   "Last Name",   Constants.ATTR_CAT_CONTACT, null, "lastName");
    addCustomerAttr( "Email",       "Email",       Constants.ATTR_CAT_CONTACT, null, "email");
    addCustomerAttr( "Phone",       "Phone",       Constants.ATTR_CAT_CONTACT, null, "phone");
    addCustomerAttr( "Address1",    "Address1",    Constants.ATTR_CAT_CONTACT, null, "address1");
    addCustomerAttr( "Address2",    "Address2",    Constants.ATTR_CAT_CONTACT, null, "address2");
    addCustomerAttr( "City",        "City",        Constants.ATTR_CAT_CONTACT, null, "city");
    addCustomerAttr( "State",       "State",       Constants.ATTR_CAT_CONTACT, null, "state");
    addCustomerAttr( "Zipcode",     "Zipcode",     Constants.ATTR_CAT_CONTACT, null, "zipcode");

    addCustomerAttr( "SMS/MMS",     "SMS/MMS",     Constants.ATTR_CAT_CONNECTIONS, null, "phone");
    addCustomerAttr( "Email",       "Email",       Constants.ATTR_CAT_CONNECTIONS, null, "email");
    addCustomerAttr( "Facebook",    "Facebook",    Constants.ATTR_CAT_CONNECTIONS, null, "facebookId");
    addCustomerAttr( "Instagram",   "Instagram",   Constants.ATTR_CAT_CONNECTIONS, null, "instagramAddress");    
    addCustomerAttr( "Twitter",     "Twitter",     Constants.ATTR_CAT_CONNECTIONS, null, "twitterUsername");
    addCustomerAttr( "Shopify",     "Shopify",     Constants.ATTR_CAT_CONNECTIONS, null, "shopifyId");


    //--> custom attributes created by this tenant/location NO customer values assigned  //--> we are passing in the item in these record, so other Attribute info like type, category, etc. can be looked up  
    attributes && attributes.map( attribute => (
      attribute.get("type") === "Customer" && addCustomerAttr( attribute.get("title"), attribute.get("title"), attribute.get("category"), attribute) //--> custom attributes can also have categories (but only from those have already defined, customer cats not supported yet)
    )); 
  }

  if (!attrType || attrType === "ALL" || attrType === "BUSINESS") {
    addBizAttr( "Biz Short Name",   "Biz Short Name",  Constants.ATTR_CAT_GENERAL);
    addBizAttr( "Biz Name",         "Biz Name",        Constants.ATTR_CAT_GENERAL);
    addBizAttr( "Biz Phone",        "Biz Phone",       Constants.ATTR_CAT_GENERAL);
    addBizAttr( "Biz Website",      "Biz Website",     Constants.ATTR_CAT_GENERAL);
    addBizAttr( "Biz City",         "Biz City",        Constants.ATTR_CAT_GENERAL);
    addBizAttr( "Biz State",        "Biz State",       Constants.ATTR_CAT_GENERAL);
    addBizAttr( "Biz Zipcode",      "Biz Zipcode",     Constants.ATTR_CAT_GENERAL);
    addBizAttr( "Biz State",        "Biz State",       Constants.ATTR_CAT_GENERAL);
    addBizAttr( "Biz Address1",     "Biz Address1",    Constants.ATTR_CAT_GENERAL);
    addBizAttr( "Biz Address2",     "Biz Address2",    Constants.ATTR_CAT_GENERAL);
    addBizAttr( "Biz SMS",          "Biz SMS",         Constants.ATTR_CAT_GENERAL);

    //--> business attributes for this tenant/location (attributes are currently tenant-wide)  
    attributes && attributes.map( attribute => (
      attribute.get("type") === "Business" && addBizAttr( attribute.get("title"), attribute.get("title"), Constants.ATTR_CAT_GENERAL, attribute) //--> we are passing in the item in these record, so other Attribute info like type, category, etc. can be looked up 
    )); 
  }
  
  setInState(myAttributes);

}


export const useGetCustomerAndBusinessAttributes = function(convoSelected, customerAttributes, businessAttributes, attrType){

    //--> load up the attributes for this customer
    //--> attributes are composed of: 
    //  (1) customer basic info like name, email, phone, address, etc
    //  (2) additional customer attributes defined for this customer in CustomerAttribute table
    //  (3) business basic info for this location
    //  (4) additional business attributes defined for this location in BusinessAttribute table
    
    let myAttributes = [];

    const addCustomerAttribute = (title, value, category, customerAttribute, fieldName) => {
      myAttributes.push({   title: title, 
                            value: value, 
                            type: "Customer", 
                            category: category, 
                            customerAttribute: customerAttribute ? customerAttribute.id : null, 
                            attributePtrId: customerAttribute && customerAttribute.get("attributePtr") && customerAttribute.get("attributePtr").id,
                            fieldName: fieldName ? fieldName : null}); //--> passing in the entire item because there are lots of parameters we can use elsewhere, best to have the whole object
    }

    const addBizAttribute = (title, value, category, isStandard, isComputed, businessAttribute) => {
      myAttributes.push({   
                            title: title, 
                            value: value, 
                            type: "Business", 
                            category: category ? category : Constants.ATTR_CAT_GENERAL, 
                            isStandard: isStandard ? true : false, 
                            isComputed: isComputed ? true : false, 
                            businessAttribute: businessAttribute ? businessAttribute.id : null
                       });
    }

    //--> standard profile attributes
    if (!attrType || attrType === "ALL" || attrType === "CUSTOMER") {
      if (convoSelected) {  //--> only those attributes are included in this list that are actually available for this convoSelected - so lists will be shorter or longer, but there should not be any empties - this should help reduce user errors
        if (convoSelected.get("firstName"))   addCustomerAttribute( "First Name",  convoSelected.get("firstName"),  Constants.ATTR_CAT_CONTACT); //--> for CONTACT attributes, we add the field name 
        if (convoSelected.get("lastName"))    addCustomerAttribute( "Last Name",   convoSelected.get("lastName"),   Constants.ATTR_CAT_CONTACT);
        if (convoSelected.get("email"))       addCustomerAttribute( "Email",       convoSelected.get("email"),      Constants.ATTR_CAT_CONTACT);
        if (convoSelected.get("phone"))       addCustomerAttribute( "Phone",       convoSelected.get("phone"),      Constants.ATTR_CAT_CONTACT);
        if (convoSelected.get("address1"))    addCustomerAttribute( "Address1",    convoSelected.get("address1"),   Constants.ATTR_CAT_CONTACT);
        if (convoSelected.get("address2"))    addCustomerAttribute( "Address2",    convoSelected.get("address2"),   Constants.ATTR_CAT_CONTACT);
        if (convoSelected.get("city"))        addCustomerAttribute( "City",        convoSelected.get("city"),       Constants.ATTR_CAT_CONTACT);
        if (convoSelected.get("state"))       addCustomerAttribute( "State",       convoSelected.get("state"),      Constants.ATTR_CAT_CONTACT);
        if (convoSelected.get("zipcode"))     addCustomerAttribute( "Zipcode",     convoSelected.get("zipcode"),    Constants.ATTR_CAT_CONTACT);

        if (convoSelected.get("phone"))             addCustomerAttribute( "SMS/MMS",     convoSelected.get("phone"),            Constants.ATTR_CAT_CONNECTIONS);                
        if (convoSelected.get("email"))             addCustomerAttribute( "Email",       convoSelected.get("email"),            Constants.ATTR_CAT_CONNECTIONS);    
        if (convoSelected.get("facebookId"))        addCustomerAttribute( "Facebook",    convoSelected.get("facebookId"),       Constants.ATTR_CAT_CONNECTIONS);    
        if (convoSelected.get("instagramAddress"))  addCustomerAttribute( "Instagram",   convoSelected.get("instagramAddress"), Constants.ATTR_CAT_CONNECTIONS);    
        if (convoSelected.get("twitterUsername"))   addCustomerAttribute( "Twitter",     convoSelected.get("twitterUsername"),  Constants.ATTR_CAT_CONNECTIONS);    
        if (convoSelected.get("shopifyId"))         addCustomerAttribute( "Shopify",     convoSelected.get("shopifyId"),        Constants.ATTR_CAT_CONNECTIONS);    
      } 

      //--> custom attributes created by this tenant/location and customer values assigned    
      customerAttributes && customerAttributes.map( customerAttribute => {
        addCustomerAttribute(customerAttribute.get("attributePtr") && customerAttribute.get("attributePtr").get("title"), customerAttribute.get("value"), customerAttribute.get("attributePtr") && customerAttribute.get("attributePtr").get("category"), customerAttribute) //--> this one carries the actual customerAttribute record also
      });
    } 

    if (!attrType || attrType === "ALL" || attrType === "BUSINESS") {
      //--> standard business attributes
      let location = Parse.User.current().get("primaryLocationPtr");
      if (location) {
        if (location.get("shortName"))        addBizAttribute( "Biz Short Name",   location.get("shortName")); 
        if (location.get("name"))             addBizAttribute( "Biz Name",         location.get("name"));
        if (location.get("phone"))            addBizAttribute( "Biz Phone",        location.get("phone"));
        if (location.get("website"))          addBizAttribute( "Biz Website",      location.get("website"));
        if (location.get("city"))             addBizAttribute( "Biz City",         location.get("city"));
        if (location.get("state"))            addBizAttribute( "Biz State",        location.get("state"));
        if (location.get("zipcode"))          addBizAttribute( "Biz Zipcode",      location.get("zipcode"));
        if (location.get("state"))            addBizAttribute( "Biz State",        location.get("state"));
        if (location.get("address1"))         addBizAttribute( "Biz Address1",     location.get("address1"));
        if (location.get("address2"))         addBizAttribute( "Biz Address2",     location.get("address2"));
        if (location.get("smsAddress"))       addBizAttribute( "Biz SMS",          location.get("smsAddress"));
      }


      //--> business attributes created by this tenant/location and business values assigned    
      //--> standard attributs are pulled from the StandardAttribute table, with  no tenant-specific values, those are assigned at runtime
      businessAttributes && businessAttributes.map( businessAttribute => {
        addBizAttribute( 
                          businessAttribute.get("isStandardAttribute") ? businessAttribute.get("title") : businessAttribute.get("attributePtr").get("title"), 
                          businessAttribute.get("isStandardAttribute") ? businessAttribute.get("title") : businessAttribute.get("value"), 
                          businessAttribute.get("isStandardAttribute") ? businessAttribute.get("category") : businessAttribute.get("attributePtr").get("category"), 
                          businessAttribute.get("isStandardAttribute") ? businessAttribute.get("isStandardAttribute") : businessAttribute.get("attributePtr").get("isStandardAttribute"), 
                          businessAttribute.get("isStandardAttribute") ? businessAttribute.get("isComputedAttribute") : businessAttribute.get("attributePtr").get("isComputedAttribute"),
                          businessAttribute
                       ) //--> biz attributes dont have categories - users can define categories by prefixing, if this like, example GEN - NAME, LOC - STREET
      }); 
    }

    return myAttributes;
}

export const getMessageTextFromMixed = async (mixed, attributes) => {
  let cloudResponse = await Parse.Cloud.run("getMessageTextFromMixed", {mixed: mixed, attributes: attributes});  
  //console.log(cloudResponse)
  return cloudResponse;
}

//--> generates a unique ID and URL to send back in response to tags that represent apps like feedback, review, etc.
export const getApplink = async (foundItem) => {
  let cloudResponse = await Parse.Cloud.run("getApplink", {foundItem: foundItem});
  return cloudResponse;
}

//--> super methods to create the requisite records in applink and appinstances (feedback or review, etc.) based on applinks that were generated due to tags in templates or tags in the live message box
export const processApplinks = async (links, messageText, customer) => {
  if (!links || !customer || !messageText) return; //--> bail early if wrongly called
  let cloudResponse = await Parse.Cloud.run("processApplinks", {links: links, messageText: messageText, customerId: customer && customer.id})  
}

export const processApplink = async (applinkObj, customer) => {
  const cloudResponse = Parse.Cloud.run("processApplink", {applinkObj: applinkObj, customerId: customer && customer.id});  
  return cloudResponse;
}


export const getFeedbackInviteStatus = (invite) => {
  if (!invite) return "";

  if (invite.get("isComplete")) return "COMPLETE";
  else if (invite.get("isFeedbackSubmitted")) return "STARTED";
  else if (invite.get("isLinkClicked")) return "CLICKED";
  else if (!invite.get("isLinkClicked")) return "INVITED";
}

export const getReviewInviteStatus = (invite) => {
  if (!invite) return "";

  if (invite.get("isComplete")) return "Complete";
  else if (invite.get("isReviewSubmitted")) return "Started";
  else if (invite.get("isLinkClicked")) return "Clicked";
  else if (!invite.get("isLinkClicked")) return "Invited";
}

export const getFeedbackSentimentLabelColors = (rating) => {
  if (!rating || !rating.get("sentiment")) return "primary";
  let toRet = {};

  toRet = 
    rating.get("sentiment").text === Constants.FEEDBACK_SENTIMENT_POSITIVE ?          {bg: "light-success", text: "success"} : 
    rating.get("sentiment").text === Constants.FEEDBACK_SENTIMENT_LEANING_POSITIVE ?  {bg: "light-success", text: "success"} :
    rating.get("sentiment").text === Constants.FEEDBACK_SENTIMENT_NEGATIVE ?          {bg: "light-danger", text: "danger"} :
    rating.get("sentiment").text === Constants.FEEDBACK_SENTIMENT_LEANING_NEGATIVE ?  {bg: "light-danger", text: "danger"} : 
                                                                                      {bg: "light-warning", text:"warning"}
  return toRet;
}

export const getFeedbackQuestionBorderColor = (question) => {
  if (!question) return;
  return question.format === "Smileys" ? "warning" : question.format === "Stars" ? "warning" : question.format === "Yes/No" ? "primary" : question.format === "Score" ? "primary-d-3" : "gray-600";
}

//--> these 3 methods used for processing media files for MMS messages
export const getUrlExtension = (url) => {
  return url.split(/[#?]/)[0].split('.').pop().trim();
}

export const isValidMMSImage = (url) => {
  let type = getUrlExtension(url);
  if (type) type = type.toLowerCase();
  if (['jpg', 'gif', 'png', 'jpeg', 'bmp'].find(ext => ext === type)) return true; // for file types of image, we will show a preview
  return false;
}

export const getMMSOutFilename = (url) => {
  let splitted = url.split("_"); //--> when parse uploads files, it adds a prefix '_' before the file name and an identifier befor that, so the original file name is whatever follows the first '_' // this works for outgoing messages
  if (splitted && splitted.length > 1) {
    return decodeURI(url.replace(splitted[0] + "_", "")); //--> decode URI before sending
  }
  return url; //--> if we cannot split, just send the input back
}

const LScolors=["-light-primary",  "-light-warning", "-light-success", "-light-danger", "-light-info", "-light-warning"]; //--> for rendering muti-colored series of symbols with light backgrounds LS == Light Symbol // notice the dash in front of the color for correctly formatting string 
  
export const getRandomLSColor = () => {
  const num = Math.floor(Math.random() * LScolors.length);
  return LScolors[num];
}

export const getSequentialLSColor = (seq) => {
  return LScolors[seq % LScolors.length];
}


export const getConvoCounts = (convos) => {
  //console.log('get convo counts called');
  let allUnreadCount = convos && convos[Constants.INBOX_ALL] && convos[Constants.INBOX_ALL].filter(convo => !convo.get("lastConvoEntryReadBy") || (convo.get("lastConvoEntryReadBy") && !convo.get("lastConvoEntryReadBy").find(readById => readById === Parse.User.current().id))).length; 
  let mineUnreadCount = convos && convos[Constants.INBOX_MINE] && convos[Constants.INBOX_MINE].filter(convo => !convo.get("lastConvoEntryReadBy") || (convo.get("lastConvoEntryReadBy") && !convo.get("lastConvoEntryReadBy").find(readById => readById === Parse.User.current().id))).length; 
  let myTeamUnreadCount = convos && convos[Constants.INBOX_MY_TEAM] && convos[Constants.INBOX_MY_TEAM].filter(convo => !convo.get("lastConvoEntryReadBy") || (convo.get("lastConvoEntryReadBy") && !convo.get("lastConvoEntryReadBy").find(readById => readById === Parse.User.current().id))).length; 
  let unassignedUnreadCount = convos && convos[Constants.INBOX_UNASSIGNED] && convos[Constants.INBOX_UNASSIGNED].filter(convo => !convo.get("lastConvoEntryReadBy") || (convo.get("lastConvoEntryReadBy") && !convo.get("lastConvoEntryReadBy").find(readById => readById === Parse.User.current().id))).length;    
  
  let allOpenCount = convos && convos[Constants.INBOX_ALL] && convos[Constants.INBOX_ALL].filter(convo => convo.get("isConvoOpen")).length; 
  let mineOpenCount = convos && convos[Constants.INBOX_MINE] && convos[Constants.INBOX_MINE].filter(convo => convo.get("isConvoOpen")).length; 
  let myTeamOpenCount = convos && convos[Constants.INBOX_MY_TEAM] && convos[Constants.INBOX_MY_TEAM].filter(convo => convo.get("isConvoOpen")).length; 
  let unassignedOpenCount = convos && convos[Constants.INBOX_UNASSIGNED] && convos[Constants.INBOX_UNASSIGNED].filter(convo => convo.get("isConvoOpen")).length;  

  return {allUnreadCount, mineUnreadCount, myTeamUnreadCount, unassignedUnreadCount, allOpenCount, mineOpenCount, myTeamOpenCount, unassignedOpenCount}
}

export const sortByLastConvoEntryCreatedAt = function(entities) {
  if (!entities) return;
  return entities.slice().sort((a, b) => (b.get("lastConvoEntryCreatedAtDate") - a.get("lastConvoEntryCreatedAtDate")));
}

//--> breaks up the full list of convos into individual inboxes
export function orderConvos(convos) { // Need to use the explicit function declaration here, arrow function does not work
  //console.log('order convos called');
    // Setup the various inboxes
    let   mine        = [], 
          myTeam      = [], 
          unassigned  = [], 
          allOpen     = [], 
          allClosed   = [], 
          mineOpen    = [], 
          mineClosed  = [], 
          myTeamOpen  = [], 
          myTeamClosed = [], 
          unassignedOpen = [], 
          unassignedClosed = [];


    if (convos){     

      convos = sortByLastConvoEntryCreatedAt(convos);
      
      for (var i = 0; i < convos.length; i++) {
        let convo = convos[i];
        if(convo.get("assignedToUserPtr") && (convo.get("assignedToUserPtr").id === Parse.User.current().id)) {
          mine.push(convo);
          if (convo.get("isConvoOpen")) mineOpen.push(convo);  
          else mineClosed.push(convo);          
        }
        else if (convo.get("assignedToTeamPtr") && (convo.get("assignedToTeamPtr").id === (Parse.User.current().get("teamPtr") && Parse.User.current().get("teamPtr").id))) { 
          myTeam.push(convo);
          if (convo.get("isConvoOpen")) myTeamOpen.push(convo);
          else myTeamClosed.push(convo);
        }
        else if (!convo.get("assignedToUserPtr") && !convo.get("assignedToTeamPtr")) {
          unassigned.push(convo);
          if (convo.get("isConvoOpen")) unassignedOpen.push(convo);
          else unassignedClosed.push(convo);
        }

        if (convo.get("isConvoOpen")) allOpen.push(convo);
        else allClosed.push(convo);

      }
    }

    let objToReturn = {};
    objToReturn[Constants.INBOX_ALL] = convos;
    objToReturn[Constants.INBOX_MINE] = mine;
    objToReturn[Constants.INBOX_MY_TEAM] = myTeam;
    objToReturn[Constants.INBOX_UNASSIGNED] = unassigned;

    objToReturn[Constants.INBOX_ALL_OPEN] = allOpen;
    objToReturn[Constants.INBOX_MINE_OPEN] = mineOpen;
    objToReturn[Constants.INBOX_MY_TEAM_OPEN] = myTeamOpen;
    objToReturn[Constants.INBOX_UNASSIGNED_OPEN] = unassignedOpen;

    objToReturn[Constants.INBOX_ALL_CLOSED] = allClosed;
    objToReturn[Constants.INBOX_MINE_CLOSED] = mineClosed;
    objToReturn[Constants.INBOX_MY_TEAM_CLOSED] = myTeamClosed;
    objToReturn[Constants.INBOX_UNASSIGNED_CLOSED] = unassignedClosed;

    return objToReturn; // Local entities will now have convos separated by inbox and open/closed
}

export const getAllUnreadCount = (convos) => {
  return convos && convos[Constants.INBOX_ALL] && convos[Constants.INBOX_ALL].filter(convo => !convo.get("lastConvoEntryReadBy") || (convo.get("lastConvoEntryReadBy") && !convo.get("lastConvoEntryReadBy").find(readById => readById === Parse.User.current().id))).length; 
}
export const getMineUnreadCount = (convos) => {
  return convos && convos[Constants.INBOX_MINE] && convos[Constants.INBOX_MINE].filter(convo => !convo.get("lastConvoEntryReadBy") || (convo.get("lastConvoEntryReadBy") && !convo.get("lastConvoEntryReadBy").find(readById => readById === Parse.User.current().id))).length; 
}
export const getTeamUnreadCount = (convos) => {
  return convos && convos[Constants.INBOX_MY_TEAM] && convos[Constants.INBOX_MY_TEAM].filter(convo => !convo.get("lastConvoEntryReadBy") || (convo.get("lastConvoEntryReadBy") && !convo.get("lastConvoEntryReadBy").find(readById => readById === Parse.User.current().id))).length; 
}
export const getUnassignedUnreadCount = (convos) => {
  return convos && convos[Constants.INBOX_UNASSIGNED] && convos[Constants.INBOX_UNASSIGNED].filter(convo => !convo.get("lastConvoEntryReadBy") || (convo.get("lastConvoEntryReadBy") && !convo.get("lastConvoEntryReadBy").find(readById => readById === Parse.User.current().id))).length;    
}

export const getAllOpenCount = (convos) => {
  return convos && convos[Constants.INBOX_ALL] && convos[Constants.INBOX_ALL].filter(convo => convo.get("isConvoOpen")).length; 
}
export const getMineOpenCount = (convos) => {
  return convos && convos[Constants.INBOX_MINE] && convos[Constants.INBOX_MINE].filter(convo => convo.get("isConvoOpen")).length; 
}
export const getTeamOpenCount = (convos) => {
  return convos && convos[Constants.INBOX_MY_TEAM] && convos[Constants.INBOX_MY_TEAM].filter(convo => convo.get("isConvoOpen")).length; 
}
export const getUnassignedOpenCount = (convos) => {
  return convos && convos[Constants.INBOX_UNASSIGNED] && convos[Constants.INBOX_UNASSIGNED].filter(convo => convo.get("isConvoOpen")).length;    
}

//--> works hand in hand with the method below (adjustConvoSelected) - this is called first, before the dispatch that changes a convo's state - this gets the index based on the current nature of the convo, then used later to move to next or previous in view
export const processConvos = (orderedConvos, inboxSelected, convoSelected, openClosed) => {
  //console.log('process convos called');
  
  let openConvos = orderedConvos && inboxSelected && orderedConvos[inboxSelected + "_OPEN"];
  let closedConvos = orderedConvos && inboxSelected && orderedConvos[inboxSelected + "_CLOSED"];
  let myIndex = convoSelected && inboxSelected && orderedConvos && openClosed && (openClosed === Constants.INBOX_OPEN ? openConvos && openConvos.findIndex(convo => convo.id === convoSelected.id) : closedConvos && closedConvos.findIndex(convo => convo.id === convoSelected.id)) 
  //console.log(myIndex);
  
  return {openConvos, closedConvos, myIndex};
}

//--> very important method, determine which convoSelected to show next once a convo is xferred/assigned/closed, etc.
//--> note that server-side changes don't remove the currently viewed convoSelected from view, even though its status (inbox/folder) might change (would be weird)
export const adjustConvoSelected = async (dispatch, changeConvoSelected, openClosed, openConvos, closedConvos, myIndex) => {

  if (openClosed && (openClosed === Constants.INBOX_OPEN) && openConvos && (openConvos.length > myIndex + 1)) {//--> select the next convo in line
    await dispatch(changeConvoSelected(openConvos[myIndex + 1])); // Zero out the current convoSelected from redux, since no longer at this location
  }
  else if (openClosed && (openClosed === Constants.INBOX_CLOSED) && closedConvos && (closedConvos.length > myIndex + 1)) {
    await dispatch(changeConvoSelected(closedConvos[myIndex + 1]));
  }
  else if (openClosed && (openClosed === Constants.INBOX_OPEN) && openConvos && (openConvos.length <= myIndex + 1)) {//--> we are the last one, select the previous one
    await dispatch(changeConvoSelected(myIndex > 0 ? openConvos[myIndex - 1] : '')); // if nothing to show, clear out
  }
  else if (openClosed && (openClosed === Constants.INBOX_CLOSED) && closedConvos && (closedConvos.length <= myIndex + 1)) {
    await dispatch(changeConvoSelected(myIndex > 0 ? closedConvos[myIndex - 1] : ''));
  }
  else {
    await dispatch(changeConvoSelected(''));
  }
}

//--> eased scrolling functions

export const chatScrollTo = (element, to, duration) => {
    var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 3;

        //console.log('to ' + to);

    var animateScroll = function(){
        currentTime += increment;
        var val = KTUtil.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
}

//Textopia
//t = current time
//b = start value
//c = change in value
//d = duration
export const easeInOutQuad = (t, b, c, d) => {
  t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
}

export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const currencyFormat = (num) => {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

export const getBranding = (values) => {

  let branding = {};
  if (values.bgColor) {
    branding.bgColor = values.bgColor;
  }
  else branding.bgColor = "#663399";

  if (values.activeColor) {
    branding.activeColor = values.activeColor;
  }
  else branding.activeColor = "#FFA800";

  if (values.textColor) {
    branding.textColor = values.textColor;
  }
  else branding.textColor = "#FFFFFF";

  branding.whiteLogoBg = values.whiteLogoBg;

  return branding;

}

export const setLogo = (settings, values) => {
    if (values.logo) {
        //--> if this is not a new file, meaning, logo hasn't changed, do nothing --> new filed have a file.name property, exiting files have a file._name property --> weird!
        if (values.logo._url) { }
        else {
          let ext = values.logo.name.endsWith("jpg") ? "jpg" : values.logo.name.endsWith("png") ? "png" : values.logo.name.endsWith("gif") ? "gif" : values.logo.name.endsWith("jpeg") ? "jpeg" : "";
          let parseFile = new Parse.File("logo." + ext, values.logo);      
          settings.set("logo", parseFile);
        }
      }
      else {
        settings.set("logo", null);
      }

    return settings;

}
