import React, { useLayoutEffect, useEffect, useState, useRef, useContext, useImperativeHandle, forwardRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {useLocation, useHistory, Link} from "react-router-dom";
import { KTUtil } from "../components/util.js";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {toAbsoluteUrl} from "../../../_helpers";
import KTOffcanvas from "../components/offcanvas.js";
import { Form, Button, Dropdown, Modal, Image, useAccordionToggle, Nav, Tab } from "react-bootstrap";
import AccordionContext from 'react-bootstrap/AccordionContext';
import DatePicker, {addDays} from "react-datepicker";
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { Parse } from "parse";
import ReportHelper from "../../../../_metronic/_assets/js/components/reportHelper"
import { CSVLink } from "react-csv";



import { Formik } from "formik";
import * as yup from "yup";

//import SearchBar from 'material-ui-search-bar';
import { nanoid } from '@reduxjs/toolkit';
import {CompactPicker, SwatchesPicker} from 'react-color';

import makeAnimated from 'react-select/animated';

import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';


import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

import 'react-animation/dist/keyframes.css';
import { animations } from 'react-animation';


import * as Textopia from "./textopia_utils.js";
import * as Queries from "./TextopiaQueries";
import * as Constants from "./TextopiaConstants";
import { LoadingDialog } from "../../../../_metronic/_partials/controls";


import Tags from "@yaireo/tagify/dist/react.tagify"
import "@yaireo/tagify/dist/tagify.css";

import GaugeChart from 'react-gauge-chart';
import Chart from "react-apexcharts";

import * as dayjs from "dayjs";

import TinyFlagReact from 'tiny-flag-react';
import PhoneFormatter from 'react-headless-phone-input';


// Error message for Formil+Yup forms
export const TextopiaErrorMessage = function({errors, touched, name, className, subName}) {
  // subName, if a sub-element of an object from react-select is to be checked like the value in label/value objects
    return (
      <div className={`font-size-sm text-danger mt-1 ${className ? className : ''}`}>{(errors[name] && touched[name]) ? (subName ? errors[name][subName] : errors[name]) : ""}</div>
    )
}

// Pullout control for Forms
export const TextopiaPullout = function({show, children, componentEl, scrollEl, title, subTitle, titleIcon, id, onHide, setScrollInitFunc, setPullout}) {

  // Private properties
  var _element = KTUtil.getById(componentEl);
  var _offcanvasObject;

  // Private functions
  var _getContentHeight = function() {
      var height;

      var header = KTUtil.find(_element, '.offcanvas-header');
      var content = KTUtil.find(_element, '.offcanvas-content');

      var height = parseInt(KTUtil.getViewPort().height);

      if (header) {
          height = height - parseInt(KTUtil.actualHeight(header));
          height = height - parseInt(KTUtil.css(header, 'marginTop'));
          height = height - parseInt(KTUtil.css(header, 'marginBottom'));
      }

      if (content) {
          height = height - parseInt(KTUtil.css(content, 'marginTop'));
          height = height - parseInt(KTUtil.css(content, 'marginBottom'));
      }

      height = height - parseInt(KTUtil.css(_element, 'paddingTop'));
      height = height - parseInt(KTUtil.css(_element, 'paddingBottom'));

      height = height - 2;

      return height;
  }

  _offcanvasObject = new KTOffcanvas(_element, {
      overlay: true,
      baseClass: 'offcanvas',
      placement: 'right',
      closeBy: componentEl + "_close",
      toggleBy: componentEl + "_toggle"
  });

  
  function scrollInit() {
    if(scrollEl) {
      const myScrollEl = KTUtil.getById(scrollEl);
      //console.log(myScrollEl);
      KTUtil.scrollInit(myScrollEl, {
          mobileNativeScroll: true,
          resetHeightOnDestroy: true,
          handleWindowResize: true,
          height: function() {
              return _getContentHeight();
          }

      });
    }
  }

  scrollInit();
  if (setScrollInitFunc) setScrollInitFunc(scrollInit);
  if (show) _offcanvasObject.show();
  if(_offcanvasObject.classBase) _offcanvasObject.on('beforeHide', () => {
          
      //_element && _element.focus(); //--> an elaborate scheme to scroll the window back down when the onscreen keyboard is being shown - was leaving the main window midair
      //setTimeout(() => {
          onHide();
                          
      //}, 200)

      }); //Close the pullout and reset the 'show' variable for next opening

     
  if (setPullout) setPullout(_offcanvasObject);

  //var _updateScrollbars = function() {
  //  KTUtil.scrollUpdate(_element);
  //}

  // Init the offcanvas modal with the above settings
  //useEffect(() => {
    //_initOffCanvas();

  //})

  const [scrollPos, setScrollPos] = useState('');
  //--> the show && children below saves us massive amounts of re-rendering for mounted forms inside pullouts!
  
  useEffect(() => { //--> this locks and unlocks the body scroll when a pullout is open, it preserves the scroll position before lock.  code was inside offcanvas.js (commented out there), now pulled here as a useEffect
    if(show) {
      setScrollPos(Textopia.lockScroll());
    }
    if(!show) {
       Textopia.unlockScroll(scrollPos);
    }
  }, [show])

  return (
      <div id={componentEl} className="offcanvas offcanvas-right overlay rounded-0">
        <div className="offcanvas-header d-flex flex-row flex-grow-1 align-items-center justify-content-between py-5 pl-0 pr-3 c-box-shadow">
  				<div className="d-flex flex-row flex-grow-1 align-items-center">
  					<div className="bg-primary d-flex flex-row align-items-center justify-content-center rounded rounded-left-0 w-60px h-40px">
  					   <i className={`${titleIcon} text-white`}></i>
  					</div>
                    <div className="d-flex flex-column justify-content-center align-items-start">
                      <div className="font-size-h4 font-weight-bold text-dark ml-3">{title}</div>
                      {subTitle && (<div className="d-flex flex-row align-items-center font-size-base font-weight-normail text-primary ml-3"><i className="la la-arrow-right icon-md mr-1 text-primary"></i><span className="limit-chars max-chars-25">{subTitle}</span></div>)}
                    </div>
  				</div>
          <div className="offcanvas-close d-flex flex-row flex-grow-1 align-items-center justify-content-end ml-5 mr-2">
                <a href="" className="btn btn-xs btn-clean bg-transparent btn-icon btn-icon-muted btn-hover-icon-primary" id={`${componentEl}_close`} onClick={(e) => {e.preventDefault(); _offcanvasObject.hide()}}>
                    <i className="la la-close icon-lg"></i>
                </a>
          </div>
        </div>

        <div className="offcanvas-content px-4 px-lg-8 mb-lg-10" >
          <div className="mr-n5 pr-5" id={scrollEl}>
            {show && children}
          </div>
        </div>
      </div>
  );
}


export const TextopiaFormValue = function ({label, name, value, formik, className, helpText, subfield, textColor, textSize, useLabel}) {
  return (
    <div className="cursor-default mb-n3 d-flex flex-row align-items-start justify-content-start">
      { subfield && (<i className="la la-rotate-left icon-lg fa-rotate-180 text-primary mr-2"></i>) }
      <Form.Group className={`d-flex flex-column text-dark-50 ${className ? className : ""}`}>
        <TextopiaFormLabel label={label} />
        {!useLabel && (<div className={`text-${textColor ? textColor : "dark"} font-size-${textSize ? textSize : "base"} `}>{value}</div>)}
        {useLabel && (
          <div className="label label-inline label-outline-secondary bg-light text-dark">{value}</div>
        )}
        {helpText && (<TextopiaFormInputHelpText helpText={helpText} />)}
        <TextopiaErrorMessage errors={formik.errors} touched={formik.touched} name={name} />
      </Form.Group>
    </div>
  )
}

export const TextopiaPhoneInput = function({label, name, formik, className, helpText, subfield, disabled, helpHTML, id, placeholder, labelColor, initVal}) {

  const [myPhone, setMyPhone] = useState(); //--> init to the initial value of this field from formik
  const [error, setError] = useState(false);
  //console.log(formik.values[name]);

  useEffect(() => { //--> this useffect needed to absord incoming initial value for this special input type, due to re-renders, the value was getting stuck at empty, so this useffect updates myphone on every re-render if value changes
    setMyPhone(initVal);
  }, [initVal]);


  useEffect(() => {
    formik.setFieldValue(name, myPhone);
    formik.setFieldTouched(name, true);
  }, [myPhone])
  
  if (error) formik.errors[name] = "Check phone number";
  //console.log("formik errors:", formik.errors);
  
  return (
    <PhoneFormatter defaultCountry="US" value={myPhone} onChange={setMyPhone}>
      {({ country, impossible, onBlur, onInputChange, inputValue }) => {
        if (impossible) setError(true);
        else if (!impossible) setError(false);

        if (formik && formik.values && formik.values[name] === "") inputValue = ""; //--> to handle formReset signals to formik


        return (            
           <div className="d-flex flex-row align-items-start justify-content-start">
              { subfield && (<i className="la la-rotate-left icon-lg fa-rotate-180 text-primary mr-2"></i>) }
              <Form.Group className={`w-100 text-dark-50 ${className ? className : ""}`}>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <TextopiaFormLabel label={label} labelColor={labelColor} />
                  <span className="mr-2">
                    {country ? ( <TinyFlagReact country={country} alt={country + " flag"} fallbackImageURL={`https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/${country}.svg`} /> ) : ( <></> )}
                   </span>
                </div>
                <Form.Control className={`form-control rounded-lg rounded-top-0 rounded-left-0 border-top border-top-${disabled ? "secondary" : "primary-l-1"} bg-${disabled ? "secondary" : "light"} text-dark`}
                              type="text"
                              placeholder={placeholder}
                              name={name}
                              value={inputValue}
                              onChange={e => {onInputChange(e.target.value)}}
                              onBlur={(e) => {onBlur(e)}}
                              {...(id && {id: id} ) }
                              disabled={disabled}
                              />
                              {helpText && (<TextopiaFormInputHelpText helpHTML={helpHTML} helpText={helpText} />)}
                              <TextopiaErrorMessage errors={formik && formik.errors} touched={formik && formik.touched} name={name} />
              </Form.Group>
            </div>                                    
        )
      }}
    </PhoneFormatter>
  )
}

// Text input control
export const TextopiaTextInput = function ({label, name, formik, className, helpText, subfield, disabled, helpHTML, id, password, placeholder, labelColor}) {
  

  return (
    <div className="d-flex flex-row align-items-start justify-content-start">
      { subfield && (<i className="la la-rotate-left icon-lg fa-rotate-180 text-primary mr-2"></i>) }
      <Form.Group className={`w-100 text-dark-50 ${className ? className : ""}`}>
        <TextopiaFormLabel label={label} labelColor={labelColor} />
        <Form.Control className={`form-control rounded-lg rounded-top-0 rounded-left-0 border-top border-top-${disabled ? "secondary" : "primary-l-1"} bg-${disabled ? "secondary" : "light"} text-dark`}
                      type={`${password ? "password" : "text"}`}
                      placeholder={placeholder}
                      name={name}
                      value={formik && formik.values[name]}
                      onChange={formik && formik.handleChange}
                      disabled={disabled}
                      { ...(id && {id: id} ) }
                      {... (password && {autoComplete: "current-password"})}
                      />
                     {helpText && (<TextopiaFormInputHelpText helpHTML={helpHTML} helpText={helpText} />)}
                    <TextopiaErrorMessage errors={formik && formik.errors} touched={formik && formik.touched} name={name} />
      </Form.Group>
    </div>
  )
}

// Text input control without formik value setting
export const TextopiaTextInputNoFormik = function ({label, name, value, handleChange, className, helpText, subfield, disabled, helpHTML, id, placeholder, labelColor}) {
  
  return (
    <div className="d-flex flex-row align-items-start justify-content-start">
      { subfield && (<i className="la la-rotate-left icon-lg fa-rotate-180 text-primary mr-2"></i>) }
      <Form.Group className={`w-100 text-dark-50 ${className ? className : ""}`}>
        <TextopiaFormLabel label={label} labelColor={labelColor} />
        <Form.Control className={`form-control rounded-lg rounded-top-0 rounded-left-0 border-top border-top-${disabled ? "secondary" : "primary-l-1"} bg-${disabled ? "secondary" : "light"} text-dark`}
                      placeholder={placeholder}
                      name={name}
                      value={value}
                      //value={formik && formik.values[name]}
                      onChange={(e) => handleChange(e, name)}
                      disabled={disabled}
                      { ...(id && {id: id} ) }
                      />
                     {helpText && (<TextopiaFormInputHelpText helpHTML={helpHTML} helpText={helpText} />)}
      </Form.Group>
    </div>
  )
}

export const TextopiaColorInput = function ({label, name, formik, className, helpText, subfield, disabled, helpHTML, id, labelColor, pickerType}) {
  const [showPicker, setShowPicker] = useState(false);

  const styles = {
       
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '2px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
    };

  const colors = ['#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']

  return (
    <div className="d-flex flex-row align-items-start justify-content-start">
      { subfield && (<i className="la la-rotate-left icon-lg fa-rotate-180 text-primary mr-2"></i>) }
      <Form.Group className={`w-100 text-dark-50 ${className ? className : ""}`}>
        <TextopiaFormLabel label={label} labelColor={labelColor} />
      
        <span className="d-block w-125px h-50px p-3 bg-light border-top border-top-primary rounded-top-0 border-left border-left-secondary border-right border-right-secondary border-bottom border-bottom-secondary rounded-lg rounded-left-0 cursor-pointer" onClick={() => setShowPicker(!showPicker)} >
          <span className="btn w-100 h-100" style={{backgroundColor: formik.values[name]}} />
        </span>
        {showPicker && (
          <div className="c_fadeIn" style={{position: 'absolute', zIndex: '1'}}>
            <div style={{position: 'fixed', top: '0px', bottom: '0px', right: '0px', left: '0px'}} onClick={() => setShowPicker(false)} />
            { (!pickerType || pickerType === "CompactPicker") &&  <CompactPicker colors={colors} color={formik.values[name]} onChangeComplete={(color, event) => formik.setFieldValue(name, color.hex)} />}
          </div>
        )}
        {helpText && (<TextopiaFormInputHelpText helpHTML={helpHTML} helpText={helpText} />)}
        <TextopiaErrorMessage errors={formik && formik.errors} touched={formik && formik.touched} name={name} />
      </Form.Group>
    </div>
  )
}

export const TextopiaSingleImageUpload = function ({name, formik, className, subfield, helpText, label, placeholder, clearFile, labelColor, placeholderWidth}) {

  const [file, setFile] = useState(null);
  const uploadSingleFile = function(e, formik) {  
    setFile(URL.createObjectURL(e.target.files[0]));    
    formik.setFieldValue(name, e.target.files[0])
  }

  useEffect(() => {
    if (clearFile) {
      formik.setFieldValue(name, null); 
      setFile(null)
    }
  }, [clearFile])

  return (
    <div className="d-flex flex-row align-items-start justify-content-start">
      { subfield && (<i className="la la-rotate-left icon-lg fa-rotate-180 text-primary mr-2"></i>) }
      <Form.Group className={`text-dark-50 ${className ? className : ""}`}>
        {label && (<TextopiaFormLabel label={label} className="mb-1" labelColor={labelColor} />)}
        <div className="d-flex flex-column align-items-start justify-content-center">
          
          <div className="d-flex flex-row align-items-center justify-content-start">

              
              <label htmlFor={name} className="d-flex flex-row justify-content-start">              
                <Image fluid 
                       className="cursor-pointer image-input image-input-outline border border-1 border-secondary py-2 px-2" 
                       src={file ? file : formik.values[name] && formik.values[name]._url ? formik.values[name]._url : formik.values[name] ? URL.createObjectURL(formik.values[name]) : placeholder ? toAbsoluteUrl(placeholder) : toAbsoluteUrl("/media/svg/picture.png")} 
                       style={{alignSelf: 'flex-start', height: 'auto', width: placeholderWidth ? placeholderWidth : '40%', maxWidth: placeholderWidth ? placeholderWidth : '40%'}} 
                       alt='No Image' />                          
                <div className="d-flex flex-column align-content-start justify-content-between flex-grow-1">
                  <span className="btn btn-xs btn-icon btn-circle btn-white btn-shadow btn-hover-icon-primary ml-n4 mt-n2" style={{zIndex: '1'}}>
                    <i className="la la-pen icon-md text-dark-50"></i>
                  </span>

                  <span className="btn btn-xs btn-icon btn-circle btn-white btn-shadow btn-hover-icon-primary ml-n4 mb-n2" style={{zIndex: '1'}} onClick={(e) => {formik.setFieldValue(name, null); setFile(null)}}>
                    <i className="ki ki-close icon-xs text-dark-50"></i>
                  </span>
                </div>
              </label>

              

          </div>

          <TextopiaFormInputHelpText helpText="jpeg, jpg, png" />
                    
          <input type="file" accept=".jpg, .jpeg, .png, .gif" className="hideMe" name={name} id={name} onChange={(e) => uploadSingleFile(e, formik)} style={{outline: '0px solid transparent'}}/> 
                             
          {helpText && (<TextopiaFormInputHelpText helpText={helpText} />)}
          <TextopiaErrorMessage errors={formik.errors} touched={formik.touched} name={name} />
        </div>

        
      </Form.Group>
    </div>
  )
}

export const TextopiaFormLabel = function({label, className, labelColor}) {
  return <Form.Label className={`text-${labelColor ? labelColor : "primary-l-1"} font-weight-bolder ${className ? className : ""} `}>{label}</Form.Label>
}

export const TextopiaTextAreaInput = function ({inputId, label, name, rows, className, formik, charCount, customStyle, helpText, helpHTML, children, placeholder, maxlength, labelColor, readOnly}) {

  function handleChange(e) {
    if (charCount) charCount(e);
    formik.handleChange(e);
  }

  return (
    <div className={className}>
      <Form.Group>
        <TextopiaFormLabel label={label} labelColor={labelColor} />
        <Form.Control className={`form-control text-dark rounded-${children ? "0" : "lg"}  ${customStyle ? customStyle : `rounded-top-0 rounded-left-0 border-top border-top-primary bg-light`} `}
                      { ...(inputId && {id: inputId} ) }
                      as="textarea"
                      name={name}
                      value={formik.values[name]}
                      onChange={(e) => handleChange(e)}
                      rows={rows ? rows : "3"}
                      style={{resize: 'none'}}
                      { ...(placeholder && {placeholder: placeholder})}
                      { ...(maxlength && {maxLength: maxlength})}
                      readOnly={readOnly}
                      />
                    {children}
                    {helpText && (<TextopiaFormInputHelpText helpHTML={helpHTML} helpText={helpText} />)}
                    <TextopiaErrorMessage errors={formik.errors} touched={formik.touched} name={name}/>
      </Form.Group>
    </div>
  )
}

export const TextopiaFormInputHelpText = function ({helpText, className, helpHTML}) {
  return (
    <span className={`d-flex flex-row align-items-center justify-content-start text-muted font-size-sm ${className ? className : ''}`} ><i className="la la-rotate-left fa-rotate-180 text-muted mr-2"></i>{helpHTML && (<div dangerouslySetInnerHTML={{__html: helpText}}></div>)}{!helpHTML && (helpText)}</span>
  )
}

// Functions and settings pertaining to TextopiaSelects

const withSubtextOption = props => {
  return (
    <components.Option {...props}>
      <div className="cursor-pointer d-flex flex-column align-items-left justify-content-center ml-1">
        {/*<div>
          <i className={`flaticon2-arrow mr-2 icon-sm text-${props.data.labelColor ? props.data.labelColor : "gray-500"} `}></i>
        </div>*/}
     
        <div className="d-flex flex-row align-items-center justify-content-start">
          {props.data.image && ( 
            <Image className="image-input image-input-outline border border-1 border-secondary mr-2" width="40" height="40" src={props.data.image._url} />
          )}
          <div className={`text-${props.data.labelColor ? props.data.labelColor : "dark"} font-size-base`}>{props.data.label}</div>          
        </div>
        <div className={`text-${props.data.subLabelColor ? props.data.subLabelColor : "dark-50"} font-size-xs`}>{props.data.subLabel}</div>
   
      </div>
    </components.Option>
  );
};

const singleValueWithImage = props => {
  return (
    <components.SingleValue {...props}>
      <div className="cursor-pointer d-flex flex-column align-items-left justify-content-center ml-1">

        <div className="d-flex flex-row align-items-center justify-content-start">
          {props.data.image && ( 
            <Image className="image-input image-input-outline border border-1 border-secondary mr-2" width="30" height="30" src={props.data.image._url} />
          )}
          <div className={`text-${props.data.labelColor ? props.data.labelColor : "dark"} font-size-base`}>{props.data.label}</div>          
        </div>        
   
      </div>     
    </components.SingleValue>
  )
}

const multiValueWithImage = props => {
  return (
    <components.MultiValue {...props}>
      <div className="cursor-pointer d-flex flex-column align-items-left justify-content-center ml-1">

        <div className="d-flex flex-row align-items-center justify-content-start">
          {props.data.image && ( 
            <Image className="image-input image-input-outline border border-1 border-secondary mr-2" width="30" height="30" src={props.data.image._url} />
          )}
          <div className={`text-${props.data.labelColor ? props.data.labelColor : "dark"} font-size-base`}>{props.data.label}</div>          
        </div>        
   
      </div>     
    </components.MultiValue>
  )
}


const formatOptionLabel = ({value, label, subLabel}) => {
  return (
    <div>
      <div className="text-dark font-size-base">{label}</div>
      <div className="text-dark-50 font-size-xs">{subLabel}</div>
    </div>
  );
};

export const customStyles3 = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled
      ? null
      : state.isSelected
      ? '#ccb3e63b'
      : state.isFocused
      ? '#F3F6F9'
      : '#FFF',
    color: state.isDisabled
      ? null
      : state.isSelected
      ? '#3F4254'
      : state.isFocused
      ? '#3F4254'
      : '#3F4254',
    padding: 10,
    fontSize: '0.9rem'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
  }),
  control: () => ({
    width: '100%',
    border: '1px solid #E4E6EF',
    borderRight: '1px solid #E4E6EF',
    borderBottom: '1px solid #E4E6EF',
    borderLeft: '1px solid #E4E6EF',
    borderRadius: '20px',
    padding: '1px 3px 1px 3px',
    display: 'flex',
    backgroundColor: '#E1F0FF'
  }),
  container: (styles, { data }) => {
    return {
      ...styles,
      width: '100%',
    };
  },
  placeholder: (styles, { data }) => {
    return {
      ...styles,
      fontSize: '0.9rem',
      color: '#187DE4',
      fontWeight: '400',
    };
  },
  valueContainer: (styles, { data }) => {
    return {
      ...styles,
      width: '100%',
    };
  },

}


export const customStyles2 = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled
      ? null
      : state.isSelected
      ? '#ccb3e63b'
      : state.isFocused
      ? '#F3F6F9'
      : '#FFF',
    color: state.isDisabled
      ? null
      : state.isSelected
      ? '#3F4254'
      : state.isFocused
      ? '#3F4254'
      : '#3F4254',
    padding: 10,
    fontSize: '0.9rem'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
  }),
  control: () => ({
    width: '100%',
    border: '1px solid #E4E6EF',
    borderRight: '1px solid #E4E6EF',
    borderBottom: '1px solid #E4E6EF',
    borderLeft: '1px solid #E4E6EF',
    borderRadius: '20px',
    padding: '1px 3px 1px 3px',
    display: 'flex',
    backgroundColor: '#ccb3e63b'
  }),
  container: (styles, { data }) => {
    return {
      ...styles,
      width: '100%',
    };
  },
  placeholder: (styles, { data }) => {
    return {
      ...styles,
      fontSize: '0.9rem',
      color: '#663399',
      fontWeight: '400',
    };
  },
  valueContainer: (styles, { data }) => {
    return {
      ...styles,
      width: '100%',
    };
  },

}

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? null : state.isSelected ? '#ccb3e63b' : state.isFocused ? '#F3F6F9' : '#FFF',
    color: state.isDisabled ? null : state.isSelected ? '#3F4254' : state.isFocused ? '#3F4254' : '#3F4254',
    padding: 10,
    borderLeft: state.isFocused ? '3px solid #663399' : state.isSelected ? '3px solid #663399' : '3px solid white',
    borderRadius: '0px 20px 20px 0px',   
  }),

  control: () => ({
    width: '100%',
    borderTop: '1px solid #663399',
    borderRight: '1px solid #E4E6EF',
    borderBottom: '1px solid #E4E6EF',
    borderLeft: '1px solid #E4E6EF',
    borderRadius: '0px 0px 13px 0px',
    padding: '3px 3px 3px 3px',
    display: 'flex',
    backgroundColor: '#ccb3e63b',
    cursor: 'pointer',
  }),
  container: (styles, { data }) => {
    return {
      ...styles,
      width: '100%', 
      cursor: 'pointer', 
    };
  },
  menu: (styles, { data }) => {
    return {
      ...styles,
      zIndex: '2',  
      borderRadius: '0px', 
      cursor: 'pointer',  
      //borderTop: '0.5px solid #ccb3e63b',
      //borderBottom: '0.5px solid #ccb3e63b'
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: '#ccb3e673',
      borderRadius: '5px',
      fontWeight: '500',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    //color: '#663399',
    color: '#3F4254',

  }),

  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: '#663399',
    borderRadius: '0px 5px 5px 0px',
    fontWeight: '500',
    marginLeft: '3px',
    ':hover': {
      backgroundColor: '#663399',
      color: 'white',
    },
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: '1px 2px 1px 2px',
    color: '#663399'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    cursor: 'pointer',
    padding: '1px 1px 1px 2px',
    color: '#663399'
  }),
}

// Single select
export const TextopiaSingleSelect = function ({label, name, options, loading, disabled, formik, helpText, className, subfield, expanded, expandedHeight, labelColor, sideEffect}) {

  return (

  <div className="d-flex flex-row align-items-start justify-content-start">
    { subfield && (<i className="la la-rotate-left icon-lg fa-rotate-180 text-primary mr-2"></i>) }
    <Form.Group className={`flex-grow-1 ${className ? className : ""}`}>
      
      <TextopiaFormLabel label={label} labelColor={labelColor} />
      <Select
        name={name}
        options={options}
        isDisabled={loading || disabled}
        onChange={(opt, action) => {
          // Set the actual value item from within the object
          formik.setFieldValue(name, action.action=="clear" ? "" : opt.value);  
          sideEffect && sideEffect();       
        }}
        onBlur={formik.handleBlur}
        value={ formik.values[name] ? options && options.find(opt => opt.value === formik.values[name]) : '' }
        styles={customStyles}
        components={makeAnimated({ Option: withSubtextOption, SingleValue: singleValueWithImage})}        
        isClearable
        isSearchable
        { ...(expanded && {defaultMenuIsOpen: true} ) }   
        { ...(expanded && expandedHeight && {maxMenuHeight: expandedHeight} ) }   
      />
      {helpText && (<TextopiaFormInputHelpText helpText={helpText} />)}
      <TextopiaErrorMessage errors={formik.errors} touched={formik.touched} name={name}/>
    </Form.Group>
 </div>
  )
}

// Multi select
export const TextopiaMultiSelect = function ({label, name, options, allOption, loading, formik, helpText, expanded, expandedHeight, labelColor}) {
  return (
    <Form.Group>
      <TextopiaFormLabel label={label} labelColor={labelColor} />
      <Select
        name={name}
        options={allOption ? [{"label":allOption, "value":"ALLOPTIONS", "subLabel":"Select all"}, ...options] : options}
        isDisabled={loading ? loading : false}
        onChange={(opt, action) => {
          // Handle the situations when the list is being cleared or last value being removed
          // Then create a new array containing only the value portions of each object and assign that to the value for this field
          formik.setFieldValue(name, ((action.action=="clear" || (action.action=="remove-value" && !opt)) ? [] : opt.length > 0 && opt[opt.length - 1].value === "ALLOPTIONS" ? options.map(x => x["value"]) : opt.map(x => x["value"])));
        }}
        onBlur={formik.handleBlur}
        value={ formik.values[name] && formik.values[name] != '' ? options && options.filter(opt => formik.values[name].indexOf(opt.value) > -1 ) : '' }
        styles={customStyles}
        components={makeAnimated({ Option: withSubtextOption, MultiValue: multiValueWithImage })}
        isClearable
        isSearchable
        isMulti
        { ...(expanded && {defaultMenuIsOpen: true} ) }   
        { ...(expanded && expandedHeight && {maxMenuHeight: expandedHeight} ) }   
      />
      {helpText && (<TextopiaFormInputHelpText helpText={helpText} />)}
      <TextopiaErrorMessage errors={formik.errors} touched={formik.touched} name={name}/>
    </Form.Group>
  )
}
//custom single select
export const TextopiaCustomSingleSelect = function ({label, name, options, loading, disabled, formik, helpText, className, subfield, expanded, expandedHeight, labelColor, sideEffect}) {

  return (

  <div className="d-flex flex-row align-items-start justify-content-start">
    { subfield && (<i className="la la-rotate-left icon-lg fa-rotate-180 text-primary mr-2"></i>) }
    <Form.Group className={`flex-grow-1 ${className ? className : ""}`}>
      
      <TextopiaFormLabel label={label} labelColor={labelColor} />
      <Select
        name={name}
        options={options}
        isDisabled={loading || disabled}
        onChange={(opt, action) => {
          // Set the actual value item from within the object
          formik.setFieldValue(name, action.action=="clear" ? "" : opt.value);  
          sideEffect && sideEffect();       
        }}
        onBlur={formik.handleBlur}
        value={ formik.values[name] ? options && options.find(opt => opt.value === formik.values[name]) : '' }
        styles={customStyles}
        components={makeAnimated({ Option: withSubtextOption, SingleValue: singleValueWithImage})}
        isClearable={false}
        isSearchable
        maxMenuHeight={170}
        { ...(expanded && {defaultMenuIsOpen: true} ) }
        { ...(expanded && expandedHeight && {maxMenuHeight: expandedHeight} ) }   
      />
      {helpText && (<TextopiaFormInputHelpText helpText={helpText} />)}
      <TextopiaErrorMessage errors={formik.errors} touched={formik.touched} name={name}/>
    </Form.Group>
 </div>
  )
}
//Custom MulTiselection
export const TextopiaCustomMultiSelect = function ({label, name, options, allOption, loading, formik, helpText, expanded, expandedHeight, labelColor}) {
  return (
    <Form.Group>
      <TextopiaFormLabel label={label} labelColor={labelColor} />
      <Select
        name={name}
        options={allOption ? [{"label":allOption, "value":"ALLOPTIONS", "subLabel":"Select all"}, ...options] : options}
        isDisabled={loading ? loading : false}
        onChange={(opt, action) => {
          // Handle the situations when the list is being cleared or last value being removed
          // Then create a new array containing only the value portions of each object and assign that to the value for this field
          formik.setFieldValue(name, ((action.action=="clear" || (action.action=="remove-value" && !opt)) ? [] : opt.length > 0 && opt[opt.length - 1].value === "ALLOPTIONS" ? options.map(x => x["value"]) : opt.map(x => x["value"])));
        }}
        onBlur={formik.handleBlur}
        value={ formik.values[name] && formik.values[name] != '' ? options && options.filter(opt => formik.values[name].indexOf(opt.value) > -1 ) : '' }
        styles={customStyles}
        components={makeAnimated({ Option: withSubtextOption, MultiValue: multiValueWithImage })}
        isClearable={false}
        isSearchable
        isMulti
        { ...(expanded && {defaultMenuIsOpen: true} ) }   
        { ...(expanded && expandedHeight && {maxMenuHeight: expandedHeight} ) }   
      />
      {helpText && (<TextopiaFormInputHelpText helpText={helpText} />)}
      <TextopiaErrorMessage errors={formik.errors} touched={formik.touched} name={name}/>
    </Form.Group>
  )
}

// Form button set
export const TextopiaButtonSet = function ({formik, save, cancel, clear, apply, preview, previewOnClick, componentEl, className, cleanup, toClose, saving, color}) {
  return (
    <div className={`btn-group btn-group-justified btn-block mt-6 mb-6 ${className ? className : ''}`}>
      { preview && (<Button onClick={() => previewOnClick()} disabled={(formik && formik.isSubmitting ? true : false) || (saving && saving === "saving" ? true : false) } className="btn font-weight-bold font-size-base btn-secondary">Preview {saving && saving === "saving" && <span className="ml-3 spinner spinner-white"></span>}</Button>) }      
      { save && (<Button disabled={(formik && formik.isSubmitting ? true : false) || (saving && saving === "saving" ? true : false) } className={`btn font-weight-bold font-size-base btn-${color ? color : "primary"}`} type="submit">Save {saving && saving === "saving" && <span className="ml-3 spinner spinner-white"></span>}</Button>) }
      { apply && (<Button disabled={(formik && formik.isSubmitting ? true : false) || (saving && saving === "saving" ? true : false) }  className="btn font-weight-bold font-size-base btn-primary" type="submit">Apply {saving && saving === "saving" && <span className="ml-3 spinner spinner-white"></span>}</Button>) }
      { cancel && (<Button className="btn font-weight-bold font-size-base btn-light-primary" type="reset" onClick={() => toClose ? toClose() : KTUtil.getById(componentEl + '_close').click()}>Cancel</Button>) }
      { clear && (<Button className="btn font-weight-bold font-size-base btn-outline-secondary" type="reset" {...(cleanup && cleanup()) }>Clear</Button>) }
    </div>
  )
}

export const TextopiaSavingSpinner = function ({status}) {
  return (
    (status == "saving") && (
      <div className="overlay-layer bg-transparent" style={{zIndex: '5'}}>
        <div className="spinner spinner-lg spinner-primary" />
      </div>
    )
  )
}

export function TextopiaButton({icon, text, addClasses, badge, pulse, pulseColor, buttonId, inverse, onClick, disabled, clearButton, clearButtonAddClasses, iconColor, textColor, noHover}) {
  let history = useHistory();
  return (
    <div className="d-flex flex-row align-items-center">
    <span className={`btn btn-icon bg-transparent ${inverse ? "btn-primary pt-1 pb-2 btn-text-white btn-icon-white" : disabled ? "btn-text-muted btn-icon-muted disabled" : clearButton ? "btn-text-danger btn-icon-danger" : "btn-text-dark btn-icon-primary "} ${noHover ? "" : "btn-hover-bg-light btn-hover-icon-primary-l-1"} ${addClasses && addClasses} ${pulse && "pulse pulse-" + (pulseColor ? pulseColor : "primary")} `}
      { ...(buttonId && {id: buttonId} ) }      
      { ...(!disabled && onClick && {onClick: (e) => onClick(e)} ) } 
    >
      <div className="d-flex flex-column align-items-center justify-content-stretch">

        {badge && (
          <span className="symbol symbol-20 symbol-label ml-5">
            <i className="symbol-badge bg-danger"></i>
          </span>
        )}

        {pulse && (
          <span className="pulse-ring"></span>
        )}

        <i className={`${icon} icon-lg ${!disabled && iconColor ? iconColor : ""} `}></i>
        <span className={`font-size-xs font-weight-bold h-10px ${textColor ? textColor: ""} `}>{text}</span>
      </div>
    </span>
    {clearButton && (<span className={` ${clearButtonAddClasses ? clearButtonAddClasses : ""} cursor-pointer bg-danger-o-30 rounded rounded-left-0 px-2 py-1 d-flex flex-row align-items-center bg-hover-danger text-hover-white`} onClick={() => clearButton()}><i className="flaticon2-cross text-danger icon-sm" /></span>)}
    </div>
  )
}

export function TextopiaLink({icon, text, addClasses, badge, pulse, pulseColor, buttonId, inverse, onClick, disabled, clearButton, iconColor, textColor, linkTo}) {
  return (
    <Link { ...(!disabled && linkTo && {to: {pathname: linkTo.pathname, obj: linkTo.obj, search: linkTo.search}} ) }>
      <div className="d-flex flex-row align-items-center">
        <span className={`btn btn-clean btn-icon bg-transparent ${inverse ? "btn-primary pt-1 pb-2 btn-text-white btn-icon-white" : disabled ? "btn-text-muted btn-icon-muted disabled" : clearButton ? "btn-text-danger btn-icon-danger" : "btn-text-dark btn-icon-primary btn-hover-icon-primary-l-1"} ${addClasses && addClasses} ${pulse && "pulse pulse-" + (pulseColor ? pulseColor : "primary")} `}
            //--> if this button is being used as a link to go somewhere, like the topbar buttons
        >
          <div className="d-flex flex-column align-items-center justify-content-stretch">

            {badge && (
              <span className="symbol symbol-20 symbol-label ml-5">
                <i className="symbol-badge bg-danger"></i>
              </span>
            )}

            {pulse && (
              <span className="pulse-ring"></span>
            )}

            <i className={`${icon} icon-lg ${!disabled && iconColor ? iconColor : ""} `}></i>
            <span className={`font-size-xs font-weight-bold h-10px ${textColor ? textColor: ""} `}>{text}</span>
          </div>
        </span>
        {clearButton && (<span className="cursor-pointer bg-danger-o-30 rounded rounded-left-0 px-2 py-1 d-flex flex-row align-items-center bg-hover-danger text-hover-white" onClick={() => clearButton()}><i className="flaticon2-cross text-danger icon-sm" /></span>)}
      </div>
    </Link>
  )
}

export function TextopiaPlainButton({icon, text, btnType, addClasses, buttonId}) {
  return (
    <>
    <span className="btn btn-primary btn-sm"
      { ...(buttonId && {id: buttonId} ) }
    >
      <div className="d-flex flex-row align-items-center justify-content-center">
        <i className={`${icon} icon-lg text-white`}></i>
        <span className="font-size-sm font-weight-bold text-white">{text}</span>
      </div>
    </span>
    </>
  )
}



export function TextopiaPageContainer({children}) {
   return ( //--> adjusting some height anomaly due to subheader
   <>
      <div className="d-md-none" style={{marginTop: '73px'}}></div>
      <div>{children}</div>
      <div className="mb-20" />
   </>
   )
}

export function TextopiaPageContainer2({children}) {
  let padding = KTUtil.getById("kt_header_mobile") ? 15 : 0 // Page padding for mobile 
  return (
    <div id="Textopia_page_container" className="mb-20" style={{marginTop: Textopia.getSubheaderHeight() + padding}}>
      {children}
    </div>
  )
}


export function TextopiaNotice({notice, className, color}) {
  return (
    <div className={`alert alert-custom alert-light-${color ? color : "primary"} alert-shadow fade show py-2 px-5 border-0 border-secondary rounded ${className ? className : ""}`} role="alert">
        <div className="alert-icon">
            <i className={`flaticon2-bell-4 icon-lg text-${color === "light" ? "dark-75" : color ? color : "primary"}`}></i>
        </div>
        <div className={`alert-text text-${color === "light" ? "dark-75" : color ? color : "primary"}`}>{notice}</div>
    </div>
  )
}

export function TextopiaLocationNotice2({entityType, locationName}) {
  return (
  <div className="d-md-none">
    <div className="d-flex flex-row align-items-center justify-content-start flex-grow-1 mb-5">
      <div className="d-flex flex-row align-items-center justify-content-middle bg-warning h-50px px-5 rounded-lg rounded-right-0 border border-warning"><i className="flaticon2-location icon-xl text-white"></i></div>
      <div className="h-50px px-5 border border-warning rounded-lg rounded-left-0 d-flex flex-column align-items-middle justify-content-center flex-grow-1" role="alert">
          <div className="text-dark font-weight-bold font-size-h5 limit-chars max-chars-40">{locationName}</div>
          <div className="text-dark-75 font-size-sm">Current location</div>
      </div>
    </div>
  </div>
  )
}


export function TextopiaLocationNotice({locationName}) {
  return (
  <div className="d-md-none">
    <div className="alert alert-custom bg-white py-2 px-2 rounded-lg rounded-left-0 border-left border-3 border-left-primary d-flex flex-row align-items-center" role="alert">
        <div className="text-white font-size-h5 pl-3 pr-4 mr-3 ml-n2 py-1 bg-primary rounded rounded-left-0"><i className="flaticon2-location icon-xl text-white"></i></div>
        <div className="border border-warning border-2 ml-n3" style={{width: '15px'}}></div>
        <div className="bg-warning py-2 px-5 rounded d-flex flex-row align-items-center justify-content-start flex-shrink-0">
          <div className="text-white font-size-h5 font-weight-bold limit-chars max-chars-15">{locationName}</div>
        </div>
    </div>
  </div>
  )
}



export function TextopiaLoadingDialog({stateSlice, stateToCheck}) {
  // Users Redux state
  const { isLoading } = useSelector(
    (state) => ( { isLoading: (state[stateSlice][stateToCheck] === 'succeeded'  || state[stateSlice][stateToCheck]  === 'failed'  || state[stateSlice][stateToCheck]  === 'pending' || state[stateSlice][stateToCheck] === 'idle') ? false : true} ),
    shallowEqual
  );
  // looking for loading/dispatch
  useEffect(() => {}, [isLoading]);
  //console.log(isLoading + " " + stateToCheck);
  return <LoadingDialog isLoading={isLoading} text="Loading ..." />;
}

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
export const DotDotToggle = React.forwardRef(({ children, onClick }, ref) => (

  <div
    className="btn btn-sm bg-primary bg-hover-light-primary btn-hover-icon-primary px-2 mt-n3 rounded rounded-top-0 text-center"
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {/* Render custom icon here */}
    <i className="pt-5 pr-0 pl-0 la la-angle-double-down text-center text-white"></i>
    {children}
  </div>
));

export const DotDotToggle2 = React.forwardRef(({ children, onClick }, ref) => (
  <div
    className="btn btn-sm bg-primary bg-hover-light-primary btn-hover-icon-primary px-2 mt-n3 rounded rounded-top-0 text-center"
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {/* Render custom icon here */}
    <i className="pt-5 pr-0 pl-0 la la-angle-double-down text-center text-white"></i>
    {children}
  </div>
));


export function TextopiaCRUDDropdown({options, ddDirection, whichToggle}) {
  if (!options) return;
  return (
      <Dropdown drop={ddDirection} style={{zIndex:1}} >
        <Dropdown.Toggle as={whichToggle} id="dropdown-basic" />
        <Dropdown.Menu className="border border-light-primary">
          <Dropdown.Header className="text-primary text-uppercase font-weight-bold font-size-sm pl-5 pr-10 mb-n3 mt-5">ACTIONS</Dropdown.Header>
          <Dropdown.Divider />
            { options.map ((opt, index) => (
              <Dropdown.Item key={`${opt.item.id}_crud_${index}`} href="" className="text-dark-75 text-hover-primary bg-white pl-5 pr-10" id={opt.id} onClick={(e) => opt.onClick(e, opt.item, opt.action)}>
                <div className="d-flex flex-row align-items-center justify-content-start">
                  <i className={`${opt.icon} mr-3`}></i>
                  <span className="font-size-sm">{opt.title}</span>
                </div>
              </Dropdown.Item>
            )) }
        </Dropdown.Menu>
      </Dropdown>

  )
}

export function TextopiaCRUDToolbar({disabledText, options, showTitles}) {
  if (!options) return;
  return (
    <div className="btn-toolbar">
      {disabledText && <span className="text-muted font-size-sm">{disabledText}</span>}
      {!disabledText && (
        <div className="btn-group">
        { options.map ((opt, index) => opt.item &&  (
          <button key={`${opt.item.id}_crud_${index}`} className={`d-flex flex-row align-items-center justify-content-middle flex-grow-1 h-25px btn btn-${opt.color ? opt.color : "default"}  `} id={opt.id} onClick={(e) => opt.onClick(e, opt.item, opt.action)}>
            <i className={`${opt.icon} m-0 p-0`}></i>{showTitles && (<span className="font-size-sm ml-1">{opt.title}</span>)}
          </button>                
        )) } 
        </div>       
      )}
    </div>
  )
}

export function TextopiaButtonToolbar({options}) {
  if (!options) return; 
  return (
    <div className="card-toolbar">
      { options.map ((opt, index) => opt.item &&  (

      <TextopiaButton key={`${opt.item.id}_crud_${index}`} disabled={opt.disabled} addClasses={`${index > 0 ? "ml-2" : ""}`} icon={opt.icon} text={opt.title} buttonId={opt.id} onClick={(e) => opt.onClick(e, opt.item, opt.action)} />
                   
      )) }        
    </div>
  )
}


export function TextopiaCreatedByFootnote ({by, date}) {
  return (
    <div className="d-flex flex-row mt-2">
      <span className="font-size-xs font-weight-normal">
        <span className="font-size-xs text-muted">Created</span>
        <span className="label label-sm label-dot label-secondary ml-1 mr-1"></span>
        <span className="font-size-xs text-muted">{dayjs(date).format("M/DD/YYYY h:mm a")}</span>
        <span className="label label-sm label-dot label-secondary ml-1 mr-1"></span>
        <span className="font-size-xs text-muted"><span className="text-dark-50 font-weight-normal ml-1">{by}</span></span>
        
      </span>
    </div>
  )
}

export function TextopiaDeleteModal ({show, title, actionStatus, editEntity, entityTitle, setShowDeleteModal, dispatch, dispatchCall}) {
  return (
    <Modal show={show} onHide={() => setShowDeleteModal(false)} >
      <Modal.Header className="border-0">
        <Modal.Title><div className="d-flex flex-row align-items-center jsutify-content-start"><i className="flaticon2-warning text-danger icon-3x mr-5"></i><span className="text-dark">{title}</span></div></Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block">
        {(actionStatus == "deleting") && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-primary" />
          </div>
        )}
        <h3 className="text-dark">Are you sure you want to delete</h3>
        {editEntity && (<h4><i className="la la-rotate-left icon-xl fa-rotate-180 text-primary mr-2"></i><span className="text-primary font-weight-bolder font-size-h4">{entityTitle}</span></h4>)}
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-group btn-group-justified btn-block">
        <Button className="btn btn-secondary font-weight-bold" onClick={() => setShowDeleteModal(false)}>
          Cancel
        </Button>
        <Button className="btn btn-danger font-weight-bold" onClick={(e) => Textopia.useHandleDeleteSubmit(e, actionStatus, dispatch, dispatchCall, editEntity, setShowDeleteModal)}>
          Yes, Delete
        </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export function TextopiaConfirmationModal ({show, title, actionStatus, editEntity, confQuestion, entityTitle, setOnHide, dispatch, dispatchCall, dispatchParams}) {
  return (
    <Modal show={show} onHide={() => setOnHide(false)} >
      <Modal.Header className="border-0 pb-3 px-10">
        <Modal.Title><div className="d-flex flex-row align-items-center justify-content-start"><i className="flaticon2-warning text-danger icon-3x mr-5"></i><span className="text-dark">{`${title ? title : "Are you sure ?"}`}</span></div></Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block pt-3 px-10">
        {(actionStatus != "succeeded") && (actionStatus != "failed") && (actionStatus != "idle") && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-primary" />
          </div>
        )}
        <h3 className="text-dark">{`${confQuestion ? confQuestion : "Please confirm..."}`}</h3>
        {editEntity && confQuestion && (<h3><i className="la la-rotate-left icon-xl fa-rotate-180 text-primary mr-2"></i><span className="text-primary font-weight-bold font-size-h4">{entityTitle}</span></h3>)}
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-group btn-group-justified btn-block">
        <Button className="btn btn-secondary font-weight-bold" onClick={() => setOnHide(false)}>
          Cancel
        </Button>
        <Button className="btn btn-danger font-weight-bold" onClick={(e) => Textopia.useHandleConfirmationSubmit(e, actionStatus, dispatch, dispatchCall, editEntity, setOnHide, dispatchParams)}>
          Yes, Confirmed
        </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}


export function TextopiaOKModal({show, title, confStatement, entityTitle, setOnHide, noButtons}) {
  return (
    <Modal show={show} onHide={() => setOnHide(false)} >
      <Modal.Header className="border-0 pb-3 px-10">
        <Modal.Title><div className="d-flex flex-row align-items-center justify-content-start"><i className="flaticon2-warning text-danger icon-3x mr-5"></i><span className="text-dark">{`${title ? title : "Are you sure ?"}`}</span></div></Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block pt-3 px-10">        
        <h3 className="text-dark">{confStatement ? confStatement : "Done..."}</h3>   
        {confStatement && entityTitle && (<h3><i className="la la-rotate-left icon-xl fa-rotate-180 text-primary mr-2"></i><span className="text-primary font-weight-bold font-size-h4">{entityTitle}</span></h3>)}     
      </Modal.Body>
      {!noButtons && (
        <Modal.Footer>
          <div className="btn-group btn-group-justified btn-block">
          <Button className="btn btn-primary font-weight-bold" onClick={() => setOnHide(false)}>
            OK
          </Button>        
          </div>
        </Modal.Footer>
      )}
    </Modal>
  )
}

export function TextopiaIntroModal({show, title, confStatement, entityTitle, setOnHide, dontShowAgain, noButtons}) {
  return (
    <Modal show={show} onHide={() => setOnHide(false)} centered>
      <Modal.Body className="p-0">        
        <div className="card card-custom card-stretch card-shadowless">
											
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bolder text-dark font-size-h3">Welcome to Textopia!</span>
                    <span className="text-muted mt-3 font-weight-bold font-size-sm">Get setup in a few quick steps and less than 15 mins</span>
                </h3>

            </div>

            <div className="card-body pt-8">

                <div className="d-flex align-items-center mb-10">

                    <div className="symbol symbol-60 symbol-light-primary mr-5">
                        <span className="symbol-label">
                            <span className="font-size-h1 font-weight-bolder">1</span>
                        </span>
                    </div>

                    <div className="d-flex flex-column font-weight-bold">
                        <span className="text-dark mb-1 font-size-lg">Connect SMS</span>
                        <span className="text-muted">So customers can send you texts from their phones.  Click Channels in the menu.</span>
                    </div>

                </div>

                <div className="d-flex align-items-center mb-10">

                    <div className="symbol symbol-60 symbol-light-warning mr-5">
                        <span className="symbol-label">
                            <span className="font-size-h1 font-weight-bolder">2</span>
                        </span>
                    </div>

                    <div className="d-flex flex-column font-weight-bold">
                        <span className="text-dark mb-1 font-size-lg">Connect Support Email</span>
                        <span className="text-muted">Provide support & service over email.  Click Channels.</span>
                    </div>

                </div>

                <div className="d-flex align-items-center mb-10">

                    <div className="symbol symbol-60 symbol-light-success mr-5">
                        <span className="symbol-label">
                            <span className="font-size-h1 font-weight-bolder">3</span>
                        </span>
                    </div>

                    <div className="d-flex flex-column font-weight-bold">
                        <span className="text-dark mb-1 font-size-lg">Connect Facebook, Instagram, Twitter</span>
                        <span className="text-muted">Engage customers anywhere, anytime, across all your social media. Click Channels.</span>
                    </div>

                </div>

                <div className="d-flex align-items-center mb-10">

                    <div className="symbol symbol-60 symbol-primary-d-4 mr-5">
                        <span className="symbol-label">
                            <span className="font-size-h1 font-weight-bolder text-primary-d-3">4</span>
                        </span>
                    </div>

                    <div className="d-flex flex-column font-weight-bold">
                        <span className="text-dark mb-1 font-size-lg">Add Team Members</span>
                        <span className="text-muted">Textopia is for your whole team.  Add as many users as you need. Browse to Business Settings.</span>
                    </div>

                </div>

                <div className="d-flex align-items-center mb-10">

                    <div className="symbol symbol-60 symbol-light-info mr-5">
                        <span className="symbol-label">
                            <span className="font-size-h1 font-weight-bolder">5</span>
                        </span>
                    </div>

                    <div className="d-flex flex-column font-weight-bold">
                        <span className="text-dark mb-1 font-size-lg">Connect Web2Txt</span>
                        <span className="text-muted">Capture leads from your website, turn web traffic into text conversations.  Click Channels.</span>
                    </div>

                </div>

                <div className="d-flex align-items-center mb-10">

                    <div className="symbol symbol-60 symbol-light-danger mr-5">
                        <span className="symbol-label">
                            <span className="font-size-h1 font-weight-bolder">6</span>
                        </span>
                    </div>

                    <div className="d-flex flex-column font-weight-bold">
                        <span className="text-dark mb-1 font-size-lg">Start Texting With Customers</span>
                        <span className="text-muted">Because customers #luv2txt!  Request reviews & feedback, get paid, answer questions, and more.</span>
                    </div>

                </div>

            </div>

        </div>



      </Modal.Body>
      {!noButtons && (
        <Modal.Footer>
          <div className="btn-group btn-group-justified btn-block">
              <Button className="btn btn-primary font-weight-bold" onClick={() => setOnHide && setOnHide()}>
                Close
              </Button>  
              <Button className="ml-3 btn btn-light-primary font-weight-bold" onClick={() => dontShowAgain && dontShowAgain()}>
                Dont Show Again
              </Button>      
          </div>
        </Modal.Footer>
      )}
    </Modal>
  )
}


export function TextopiaFormInputLabel ({title, className}) {
  return (
    <label className={`text-dark-50 form-label mb-1 ${className ? className : ''}`}>{title}</label>
  )
}

export function TextopiaDatePicker ({name, label, showTimeSelect, formik, popUp, labelColor}) {

  const [startDate, setStartDate] = useState(new Date( (new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 18, 0 )); // today's date, at 6:00pm
  useEffect(() => {formik.values[name] = startDate});
  //const [startDate, setStartDate] = useState(new Date());
  
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();

  const handleDateChange = (date) => {
    setStartDate(date);
    formik.values[name] = date;
  }

  return (
    <>
    {label && (
      <TextopiaFormLabel label={label} labelColor={labelColor}/>
    )}
    <DatePicker selected={startDate}
                //selected={formik.values[name]}
                name={name}
                onChange={date => handleDateChange(date)}
                shouldCloseOnSelect={false}
                inline={popUp ? false : true}
                minDate={new Date()}
                calendarClassName="border-top border-top-primary border-right border-right-secondary border-left border-left-secondary border-bottom border-bottom-secondary rounded-bottom-right-xl rounded-top-0 rounded-left-0"
                showTimeInput={showTimeSelect ? true : false}
                injectedTimes={[new Date()]}
                timeFormat="HH:mm"
                timeInterval={30}
                />
    <TextopiaErrorMessage errors={formik.errors} touched={formik.touched} name={name} />
    </>
  )
}

export function TextopiaNothingToShow ({content, className}) {
  return (
   
    <div className={`d-flex flex-row flex-grow-1 align-items-center justify-content-center border-left border-0 border-left-secondary border-right border-right-secondary bg-light py-15 rounded-0 rounded-left-0 ${className ? className : ''}`} 
         style={{position: 'absolute', top: 0, bottom: 0, right:0, left:0}}>
      <span className="text-muted font-size-h1 font-weight-normal">{content}</span>
    </div>
   
  )
}

export function TextopiaNothingToShowAlt ({content, className}) {
  return (
   
    <div className={`d-flex flex-row flex-grow-1 align-items-center justify-content-center border-left border-0 border-left-secondary border-right border-right-secondary bg-light py-20 rounded-0 ${className ? className : ''}`} 
         style={{position: 'absolute', right:0, left:0}}>
      <span className="text-muted font-size-h1 font-weight-normal">{content}</span>
    </div>
   
  )
}

export function TextopiaOnOffSwitch({name, title, label, className, labels, helpText, formik, labelColor, color}) {
  
  return (
    <Form.Group className={`text-dark-50 ${className ? className : ""}`}>
      {(title  || label) && ( <TextopiaFormLabel label={title || label} labelColor={labelColor} />)}
      
      <span className={`switch switch-icon d-flex flex-row align-items-left justify-content-start switch-${color ? color : "primary"} `}>
        <label>
          <input type="checkbox" name={name} onChange={formik.handleChange} checked={formik.values[name]}/>
          <span></span>
        </label>
      {formik.values[name] && labels && labels.showActive && (<><div className="ml-2 label label-inline label-light-primary border border-light-primary label-pill font-weight-bold" style={{minWidth: '65px'}}>{labels.activeLabel}</div></>)}
      {!formik.values[name] && labels && labels.showInactive && (<div className="ml-2 label label-inline label-outline-danger label-pill font-weight-bold" style={{minWidth: '65px'}}>{labels.inactiveLabel}</div>)}
      </span>
     
      {helpText && (<TextopiaFormInputHelpText helpText={helpText} />)}
      <TextopiaErrorMessage errors={formik.errors} touched={formik.touched} name={name} />
    </Form.Group>
  )
}

export function TextopiaOnOffSwitchList({label, helpText, className, title, children, formik, name, labelColor}) {
  //console.log(children);
  return (
    <Form.Group className={`text-dark-50 ${className ? className : ""}`}>
      {(title  || label) && ( <TextopiaFormLabel label={title || label} labelColor={labelColor}/> )}
      {children}
      {helpText && (<TextopiaFormInputHelpText helpText={helpText} />)}
      <TextopiaErrorMessage errors={formik.errors} touched={formik.touched} name={name} />
    </Form.Group>
  )  
}

export function TextopiaOnOffSwitchListItem({name, labels, formik, className,}) {
  return (   
      <span className={`cursor-pointer switch switch-sm switch-primary switch-icon d-flex flex-row align-items-left justify-content-start ${className ? className : ""}`}>
        <label className="d-flex flex-row align-items-center cursor-pointer">
          <input type="checkbox" name={name} onChange={formik.handleChange} checked={formik.values[name]}/>
          <span></span>
        
          {formik.values[name] && labels && labels.showActive && (<div className={`ml-2 label label-inline label-${labels.activeColor ? labels.activeColor : "light-primary"} ${labels.activeTextColor ? labels.activeTextColor : "" } border border-light-primary label-pill font-weight-normal`} style={{minWidth: '65px'}}>{labels.activeLabel}</div>)}
          {!formik.values[name] && labels && labels.showInactive && (<div className={`ml-2 label label-inline label-${labels.inactiveColor ? labels.inactiveColor : "outline-danger"} ${labels.inactiveTextColor ? labels.inactiveTextColor : "" } label-pill font-weight-normal`} style={{minWidth: '65px'}}>{labels.inactiveLabel}</div>)}
        </label>
      </span>
  )
}

export function TextopiaCheckboxList({label, helpText, className, title, children, formik, name}) {
  return (
    <TextopiaOnOffSwitchList name={name} label={label} helpText={helpText} className={className} title={title} formik={formik}>
      <div className="checkbox-list pt-1">
        {children}
      </div>
    </TextopiaOnOffSwitchList>
  )
}

export function TextopiaCheckboxListItem({name, label, formik, className, color, showOnlyArr, setFilterObj}) {

  const showOnly = () => {
    let statusArr = showOnlyArr;
    let myObj = formik.values;
    for (var i = 0; i < statusArr.length; i++) {
      if (name === statusArr[i]) {
        myObj[statusArr[i]] = true;
      } else {
        myObj[statusArr[i]] = false;
      }
    }
    if(setFilterObj) setFilterObj(myObj);
    formik.handleSubmit();
  }

  return (
  <div className={`${showOnlyArr && "d-flex align-items-baseline"}`}>
    <label className={`${!showOnlyArr && "mt-3"} d-flex flex-row align-items-center checkbox checkbox-outline checkbox-outline-2x checkbox-${color ? color : "primary"}  checkbox-rounded text-dark ${className ? className : ""}`}>
      <input type="checkbox" name={name} onChange={formik.handleChange} checked={formik.values[name]} />
      <span></span>
      {label} 
    </label>
    {showOnlyArr && <span className="cursor-pointer ml-2 font-size-xs text-primary bg-hover-light-primary px-1 rounded" onClick={() => showOnly()}>Only</span>}
  </div>
  )
} 

export const TextopiaCheckboxSet = forwardRef(({label, helpText, className, formik, name, color, labelColor, includeAll, includeAllLabel, options}, ref) => {
  const [allChecked, setAllChecked] = useState(false);
  //console.log("formik reviewSites: " + name);
  //console.log(formik.values[name]);
  const [myArray, setMyArray] = useState(formik.values[name] ? formik.values[name] : []);

  //console.log("myArray: " + name);
  //console.log(myArray);
 
  const handleAllChecked = (e) => {
    if (e.target.checked) {
      let newArray = options.map(item => item.value);
      setMyArray(newArray);
      formik.setFieldValue(name, newArray);
      setAllChecked(true);
    }
    else {
      setMyArray([]);
      setAllChecked(false);
    }
  }

  const handleAllCheckedByChild = (e) => {

    if (e.target.checked) {
      let newArray = myArray;
      newArray.push(e.target.value);
      setMyArray(newArray);
      formik.setFieldValue(name, newArray);
    }
    else {
      let newArray = myArray;
      newArray.splice(myArray.indexOf(e.target.value), 1)
      setMyArray(newArray);
      formik.setFieldValue(name, newArray);
    }
    
    if (allChecked) {    
      setAllChecked(false);
    }
  }

  useImperativeHandle(ref, () => {
    return {
      cleanup: cleanup
    };
  });

  const cleanup = () => {
    formik.values[name] = [];
    setMyArray([]);
    setAllChecked(false);
  }

  return (
    <Form.Group className={`text-dark-50 ${className ? className : ""}`}>
      {label && ( <TextopiaFormInputLabel title={label}  labelColor={labelColor}/> )}
      <div className="checkbox-list pt-1">
        {includeAll && (
          <label className={`mt-1 d-flex flex-row align-items-center checkbox checkbox-outline checkbox-outline-2x checkbox-${color ? color : "primary"}  checkbox-rounded text-dark`}>
            <input type="checkbox" name="includeAll" value="includeAll" checked={allChecked} onChange={(e) => handleAllChecked(e)} />
            <span></span>
            {includeAllLabel ? includeAllLabel : "All"}
          </label>
        )}
        {options && options.map((item, index) => (
          <TextopiaCheckboxSetItem key={`site_${index}`} className={`${!includeAll && index == 0 ? "mt-1" : "mt-0"}`} myArray={myArray} name={name} color={color} item={item} formik={formik} allChecked={allChecked} handleAllCheckedByChild={handleAllCheckedByChild} />
        ))}       
      </div>
      {helpText && (<TextopiaFormInputHelpText helpText={helpText} />)}
      <TextopiaErrorMessage errors={formik.errors} touched={formik.touched} name={name} />
    </Form.Group>
  )
});

export function TextopiaCheckboxSetItem({name, item, formik, color, className, handleAllCheckedByChild, myArray}) {
  
  return (
    <label className={`d-flex flex-row align-items-center checkbox checkbox-outline checkbox-outline-2x checkbox-${color ? color : "primary"}  checkbox-rounded text-dark ${className ? className : ""}`}>
      <input type="checkbox" value={item.value} onChange={(e) => handleAllCheckedByChild(e)} checked={myArray && myArray.length > 0 && myArray.indexOf(item.value) > -1} />
      <span></span>
      {item.image && (<><Image className="image-input image-input-outline border border-1 border-secondary mr-2" width="30" height="30" src={item.image._url} />{item.label}</>)}
    </label>
  )
} 



export function TextopiaRadioList({label, helpText, className, title, children, formik, name, labelColor}) {
  return (
    <TextopiaOnOffSwitchList name={name} label={label} helpText={helpText} className={className} title={title} formik={formik} labelColor={labelColor}>
      <div className="radio-list pt-1">
        {children}        
      </div>
    </TextopiaOnOffSwitchList>
  )
}

export function TextopiaRadioListItem({name, value, label, formik, className, color, labelColor}) {
  return (
    <label className={`d-flex flex-row align-items-center mb-0 radio radio-outline radio-outline-2x radio-${color ? color : "primary"} radio-rounded ${labelColor} ${className ? className : ""}`}>
      <input type="radio" name={name} value={value} onChange={formik.handleChange} checked={formik.values[name] == value ? true : false} />
      <span></span>
      {label}
    </label>
  )
} 



export function TextopiaRadioListItem2({name, value, label, formik, className}) {
  return (
    <label className={`d-flex flex-row align-items-center mb-0 radio radio-outline radio-outline-2x radio-primary radio-rounded text-dark ${className ? className : ""}`}>
      <input type="radio" name={name} value={value} onChange={formik.handleChange} checked={formik.values[name] == value ? true : false} />
      <span></span>
        <div className={`ml-2 label label-inline label-${formik.values[name] == value ? "light-primary": "light"} text-${formik.values[name] == value ? "primary": "dark-50"} ${formik.values[name] == value ? "border border-light-primary": ""} label-pill font-weight-normal`} style={{minWidth: '0px'}}>{label}</div>
    </label>
  )
} 




export function TextopiaTimeSlots() {
  return (
    <>
    <option value="">--</option>
    <option value="9:00">09:00 AM</option>
    <option value="9:30">09:30 AM</option>
    <option value="10:00">10:00 AM</option>
    <option value="10:30">10:30 AM</option>
    <option value="11:00">11:00 AM</option>
    <option value="11:30">11:30 AM</option>
    <option value="12:00">12:00 PM</option>
    <option value="12:30">12:30 PM</option>
    <option value="13:00">01:00 PM</option>
    <option value="13:30">01:30 PM</option>
    <option value="14:00">02:00 PM</option>
    <option value="14:30">02:30 PM</option>
    <option value="15:00">03:00 PM</option>
    <option value="15:30">03:30 PM</option>
    <option value="16:00">04:00 PM</option>
    <option value="16:30">04:30 PM</option>
    <option value="17:00">05:00 PM</option>
    <option value="17:30">05:30 PM</option>
    <option value="18:00">06:00 PM</option>
    <option value="18:30">06:30 PM</option>
    <option value="19:00">07:00 PM</option>
    <option value="19:30">07:30 PM</option>
    <option value="20:00">08:00 PM</option>
    <option value="20:30">08:30 PM</option>
    <option value="21:00">09:00 PM</option>
    <option value="21:30">09:30 PM</option>
    <option value="22:00">10:00 PM</option>
    <option value="22:30">10:30 PM</option>
    <option value="23:00">11:00 PM</option>
    <option value="23:30">11:30 PM</option>
    <option value="00:00">12:00 AM</option>
    <option value="00:30">12:30 AM</option>
    <option value="1:00">01:00 AM</option>
    <option value="1:30">01:30 AM</option>
    <option value="2:00">02:00 AM</option>
    <option value="2:30">02:30 AM</option>
    <option value="3:00">03:00 AM</option>
    <option value="3:30">03:30 AM</option>
    <option value="4:00">04:00 AM</option>
    <option value="4:30">04:30 AM</option>
    <option value="5:00">05:00 AM</option>
    <option value="5:30">05:30 AM</option>
    <option value="6:00">06:00 AM</option>
    <option value="6:30">06:30 AM</option>
    <option value="7:00">07:00 AM</option>
    <option value="7:30">07:30 AM</option>
    <option value="8:00">08:00 AM</option>
    <option value="8:30">08:30 AM</option>
    </>
  )
}

export function TextopiaCard({desktopOnly, title, titleIcon, color, titleColor, toolbar, showSearch, children}) {
  const role = Parse.User.current().get("userRolePtr").attributes;
  let toolbarContent;
  if (toolbar) {
    toolbarContent = toolbar.map( (item, index) => (
      item && role[item.permission] && (
        <div className={`${index > 0 ? "ml-lg-3 ml-2" : ""}`}  key={`toolbar_button_${index}`}>
          <TextopiaButton text={item.label} iconColor={item.iconColor} icon={item.icon} onClick={item.onClick} disabled={item.disabled} pulse={item.pulse} addClasses={item.addClasses} clearButton={item.clearButton} />
        </div>
      )
    ))
  }

  return (
    <div className="card card-custom card-stretch gutter-b wave-0 wave-animate wave-animate-slower wave-warning rounded">
      <div className="card-header py-5 px-0 d-flex flex-row align-items-center justify-content-between border-bottom border-bottom-1">
        <div className="card-title pl-0 ml-0 align-items-center flex-row justify-content-start">
          <div className={`bg-${color ? color : "primary"} d-flex flex-row align-items-center justify-content-center rounded rounded-left-0 w-60px h-40px`}><i className={`${titleIcon} icon-lg text-white`}></i></div>
          <span className={`card-label font-weight-lighter text-${titleColor ? titleColor : "primary"} font-size-h1 ml-3`}>{title}</span>
        </div>
        { (toolbar || showSearch ) && (
          <>
            <div className="d-flex align-items-center flex-grow-1 justify-content-end mr-lg-8 mr-5">            
              {showSearch && ( <div className={`${desktopOnly ? "d-none d-lg-block" : ""} mr-lg-2`}><TextopiaSearchField showSearch={showSearch} /></div> )}
              <div className={`${desktopOnly ? "d-none d-lg-block" : ""}`}>{toolbarContent}</div>
            </div>
          </>
         )}
        
      </div>
      <div className="card-body px-5 px-lg-8 pt-2">
        {children}
      </div>
    </div>
  )
}


export function TextopiaSearchField({showSearch, className, width}) { // showSearch contains handleSearch which is the caller's method to use the search term to search //
  const [searchTerm, setSearchTerm] = useState('');
  const [showClearSearch, setShowClearSearch] = useState(false);

  return (
    <div className={`${className ? className : ""} `}>
      <div className={`input-group input-group-solid border ${width ? width : "w-lg-300px"}`}>

        <input type="text" className="form-control form-control-sm pl-4 text-dark" value={searchTerm} placeholder={`${showSearch && showSearch.placeholder ? showSearch.placeholder : "Search ..."} `}
               onChange={(e) => {
                 (!e.target.value || e.target.value == "") ? setShowClearSearch(false) : setShowClearSearch(true);
                 setSearchTerm(e.target.value);
                 showSearch.handleSearch(e.target.value)}}
                 />
          {showClearSearch && (
            <div className="input-group-append border-0"
                 onClick={() => {
                   setSearchTerm('');
                   showSearch.handleSearch('');
                   setShowClearSearch(false)}}
                   >
              <span className="btn btn-sm btn-clean btn-transparent btn-icon"><i className="flaticon2-cross icon-sm" /></span>
            </div>
          )}
          {!showClearSearch && (
          <div className="input-group-append border-0">
            <span className="input-group-text border-0">
              <i className="flaticon2-search-1 text-primary" />
            </span>
          </div>
          )}
      </div>
    </div>
  )
}

export function TextopiaGoogleAutocompleteField({label, className, id, clearCallback, placeholder, helpHTML, helpText}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [showClearSearch, setShowClearSearch] = useState(false);

  return (
    <div className="d-flex flex-column form-group">
        <div className="d-flex flex-row align-items-start justify-content-start mb-1">
          <div className="input-group input-group-solid w-100 rounded-lg rounded-top-0 rounded-left-0 border-top border-top-primary border-left border-left-secondary border-right border-right-secondary border-bottom border-bottom-secondary bg-light text-dark">

            <input type="text" {...(id && (id: id))} className="form-control border-0" value={searchTerm} placeholder={`${placeholder ? placeholder : "Search ..."} `}
                   onChange={(e) => {
                       (!e.target.value || e.target.value == "") ? setShowClearSearch(false) : setShowClearSearch(true);
                       setSearchTerm(e.target.value);
                     }}
                     />

            {showClearSearch && (
              <div className="input-group-append border-0"
                   onClick={() => {
                       setSearchTerm('');
                       setShowClearSearch(false);
                       clearCallback();
                     }}
                     >
                <span className="btn btn-sm btn-clean btn-transparent btn-icon"><i className="flaticon2-cross icon-sm" /></span>
              </div>
            )}

            {!showClearSearch && (
              <div className="input-group-append border-0">
                <span className="input-group-text border-0">
                  <i className="flaticon2-search-1 text-primary" />
                </span>
              </div>
            )}

          </div>

        </div>
      {helpText && (<TextopiaFormInputHelpText helpHTML={helpHTML} helpText={helpText} />)}
    </div>
  )
}

export function TextopiaTextSearch({label, className, id, name, formik, placeholder, helpHTML, helpText, setParentSearchTerm, handleSearch, initialValue, labelColor}) {
  const [searchTerm, setSearchTerm] = useState(initialValue ? initialValue : '');
  const [showClearSearch, setShowClearSearch] = useState(initialValue ? true : false);

  return (
    <div className="d-flex flex-column form-group flex-grow-1">
        {label && (<TextopiaFormLabel label={label} labelColor={labelColor} />)}
        <div className="d-flex flex-row align-items-start justify-content-start mb-1">                  
          <div className="input-group input-group-solid w-100 rounded-lg rounded-top-0 rounded-left-0 border-top border-top-primary border-left border-left-secondary border-right border-right-secondary border-bottom border-bottom-secondary bg-light text-dark">

            <input type="text" {...(id && (id: id))} name={name} className="form-control border-0" value={searchTerm} placeholder={`${placeholder ? placeholder : "Search ..."} `}
                   onChange={(e) => {
                       (!e.target.value || e.target.value == "") ? setShowClearSearch(false) : setShowClearSearch(true);
                       setSearchTerm(e.target.value);
                       if (setParentSearchTerm) setParentSearchTerm(e.target.value);
                       if (handleSearch) handleSearch(e.target.value);
                       if (name && formik) formik.values[name] = e.target.value;
                     }}
                     />

            {showClearSearch && (
              <div className="input-group-append border-0"
                   onClick={() => {
                       setSearchTerm('');
                       if (setParentSearchTerm) setParentSearchTerm('');
                       if (handleSearch) handleSearch('');
                       if (name && formik) formik.values[name] = '';
                       setShowClearSearch(false);
                     }}
                     >
                <span className="btn btn-sm btn-clean btn-transparent btn-icon"><i className="flaticon2-cross icon-sm" /></span>
              </div>
            )}

            {!showClearSearch && (
              <div className="input-group-append border-0">
                <span className="input-group-text border-0">
                  <i className="flaticon2-search-1 text-primary" />
                </span>
              </div>
            )}

          </div>

        </div>
      {helpText && (<TextopiaFormInputHelpText helpHTML={helpHTML} helpText={helpText} />)}
    </div>
  )
}

// Components to show consistent cards in various lists
export const TextopiaListCard = function({children, spacingLg, spacingSm, noStretch, insideCard, ribbonClip, noGutter, className}) { // Use insideCard for listCards that are contained in a TextopiaCard to avoid the extra margin and padding
  return (
    <>
    <div className={`${insideCard ? "mx-0 px-0": ""} col-xl-${spacingLg ? spacingLg : '4'} col-lg-${spacingLg ? spacingLg : '4'} col-md-${spacingSm ? spacingSm : '4'} col-sm-${spacingSm ? spacingSm : '4'}`}>
      <div className={`card card-custom ${noStretch ? "" : "card-stretch"} ${noGutter ? "" : "gutter-b"} p-0 rounded bg-white ${ribbonClip ? "ribbon ribbon-clip ribbon-left" : "ribbon ribbon-top"} ${className ? className : ""}`}>
        <div className="card-body px-5 py-5 px-lg-8 pb-lg-5 pt-lg-5 m-0">
          {children}
        </div>
      </div>
    </div>
    </>
  )
}

export function TextopiaListCardHeader2({permission, ddOptions, ddColor, useToolbar, noLabel, headerText, labelText, labelColor, labelTextColor, children}) {
  return (
    <div className="d-flex flex-column justify-content-center align-items-left px-0">

      <div className="d-flex flex-row align-items-center justify-content-between mx-lg-n8 mx-n5 pr-lg-8 pr-5">
        <div className="d-flex flex-row align-items-center justify-content-start px-lg-8 px-5 rounded-left-0">
          { !useToolbar && permission && ( 
            <div className={`d-flex flex-row justify-content-middle mt-n5 mr-4 rounded rounded-top-0`}>
              <div className="pt-3"> 
                <TextopiaCRUDDropdown options={ddOptions} ddDirection="right" whichToggle={DotDotToggle}/>
              </div>
            </div> 
          ) }
          { useToolbar && ( 
            <div className={`d-flex flex-row justify-content-middle mt-n5 mr-4 bg-${ddColor ? ddColor: "primary"} rounded rounded-top-0`}>
              <div className="pt-3">
                <div className="btn btn-sm btn-transparent btn-icon btn-icon-white btn-hover-transparent px-0 py-0 mr-0 my-0">
                  <i className="px-0 py-0 la la-angle-double-down text-white"></i>
                </div>
              </div>
             </div> 
           ) }
          <div className="d-flex flex-column align-items-center justify-content-middle">
            <span className="font-size-h5 text-dark font-weight-normal limit-chars max-chars-20">{headerText}</span>            
          </div>

        </div>
        { useToolbar && permission && ( <TextopiaCRUDToolbar options={ddOptions} /> ) }
        {!noLabel && (
          <div>
             <span className={`label label-inline label-pill label-${labelColor ? labelColor : "light-primary"} text-${labelTextColor ? labelTextColor : "dark"} font-weight-normal`}>{labelText}</span>
          </div>
        )}
        
      </div>
      {children}
    </div>
  )
}

export function TextopiaListCardHeader({permission, ddOptions, ddColor, useToolbar, headerText, noLabel, labelText, labelColor, labelTextColor, children, noBorder, toolbar, showSearch, specialHeader, charLimit}) {
  
  let toolbarContent;
  const role = Parse.User.current().get("userRolePtr").attributes;

  if (toolbar) {
    toolbarContent = toolbar.map( (item, index) => (
      item && role[item.permission] && (
        <div className={`${index > 0 ? "ml-lg-3 ml-2" : ""}`}  key={`toolbar_button_${index}`}>
          <TextopiaButton text={item.label} icon={item.icon} onClick={item.onClick} disabled={item.disabled} pulse={item.pulse} addClasses={item.addClasses} clearButton={item.clearButton} />
        </div>
      )
    ))
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-left px-0">

      <div className="d-flex flex-row align-items-center justify-content-between pb-4"> 
        <div className={`${showSearch ? "d-none d-lg-block" : ""}`}>  {/*} don't show the header title on mobiles if search is shown, since there is likely not enough horizontal space     */}
          <div className="d-flex flex-row align-items-center justify-content-start rounded-left-0">          
            <div className={`d-flex flex-column align-items-center justify-content-middle ${specialHeader ? "bg-light-primary px-2 text-dark rounded " : "text-dark"}`}>
              <span className={`font-size-h1 font-weight-lighter text-primary  limit-chars max-chars-${charLimit ? charLimit : "15"} `}>{headerText}</span>         
            </div>
          </div>
        </div>
        { useToolbar && permission && ( <TextopiaCRUDToolbar options={ddOptions} /> ) }
        {!noLabel && (
          <div>
             <span className={`label label-inline label-pill label-${labelColor ? labelColor : "light-primary"} text-${labelTextColor ? labelTextColor : "dark"} font-weight-normal`}>{labelText}</span>
          </div>        
        )}
        { noLabel && (toolbar || showSearch ) && (
          <>
            <div className="d-flex align-items-center flex-grow-1 justify-content-lg-end justify-content-between">            
              {showSearch && ( <div className={`${!showSearch.searchOnMobile ? "d-none d-lg-block" : ""} mr-lg-2`}><TextopiaSearchField showSearch={showSearch} /></div> )}
              {toolbarContent}
            </div>
          </>
         )}
      </div>

      {!noBorder && (<div className="separator separator-solid separator-secondary mx-n5 mx-lg-n8" /> ) }
      {children}
    </div>
  )
}

export function TextopiaInfoLine({name, value, labelColor, labelTextColor, useLabel, textColor}) {
  return (
    <div className="d-flex flex-row align-items-top justify-content-between mt-2">
      <span className="text-muted font-weight-normal font-size-base mr-5">{name}</span>
      <div className="text-right">
        { Array.isArray(value) && (value.length > 0) && value.map( (item, index) => ( // Explicityly say useLabel={false} to not use labels in in infoline.
            <span className={`${(useLabel===false) ? 'font-size-sm font-weight-normal mx-2 text-' + `${textColor ? textColor : 'dark'}` : 'label label-inline label-pill bg-' + `${labelColor ? labelColor : 'light'}` + ' text-' + `${labelTextColor ? labelTextColor : 'dark'}`} `} key={name + item + index}>{item}</span>
        ))}
        { !Array.isArray(value) && (
          <span className={`${(useLabel===false) ? 'font-size-sm font-weight-normal mx-2 text-' + `${textColor ? textColor : 'dark'}` : 'label label-inline label-pill bg-' + `${labelColor ? labelColor : 'light'}` + ' text-' + `${labelTextColor ? labelTextColor : 'dark'}`} `}>{value}</span>
        )}
      </div>
    </div>
  )
}



export function TextopiaListCardBody({color, className, border, children}) {
  return (
    <div className={`${className ? className : ""} ${border ? "pl-4" : ""} py-0 py-lg-2 bg-transparent ${border ? "border-left border-3 border-left-" + `${color ? color : "gray-500"}` : ""} text-dark font-size-base text-left`}>
      {children}
    </div>
  )
}

export function TextopiaListCardBody2({color, className, children}) {
  return (
    <div className={`${className ? className : ""} pl-4 py-0 py-lg-2 bg-transparent border-left border-3 border-left-${color ? color : "gray-500"} text-dark font-size-base text-left`}>
      {children}
    </div>
  )
}

export function TextopiaDiv({children}) {
  return (
    <div className="bg-light" style={{height: '4rem'}}>
      {children}
    </div>
  )
}

export function TextopiaListCardBodyAlt({className, color, children}) {
  return (
    <div className={`mt-3 border-top border-top-${color ? color: "light"} py-2 ${className ? className : ""}`}>
      {children}
    </div>
  )
}

export function TextopiaReactQuill({label, name, formik, className, helpText, placeholder, labelColor, id}) {
  
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '+1'}],
      //['image'],
    ],
  }
  
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 
    //'image',
  ]

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const toggleEmojiPicker = () => {
    if (showEmojiPicker) setShowEmojiPicker(false);
    else setShowEmojiPicker(true);
    myRef && myRef.current && myRef.current.editor && myRef.current.editor.focus();
  }
  
  const insertAtCaret = function(emoji) {
    const cursorPosition = myRef && myRef.current && myRef.current.editor && myRef.current.editor.getSelection() && myRef.current.editor.getSelection().index;
    if (cursorPosition) {
      myRef.current.editor.insertText(cursorPosition, emoji.native);
      //myRef.current.editor.setSelection(cursorPosition + 1);
    }
    toggleEmojiPicker();
  }

  const handleChange = function(content, formik) {
    myRef && myRef.current && myRef.current.getEditor() && myRef.current.getEditor().getText().replaceAll("\n", "").length > 0 ?  
    formik.setFieldValue(name, content) :
    formik.setFieldValue(name, "");
  }

  const myRef = useRef(null);

  return (
    <Form.Group className={`cursor-text text-dark ${className ? className : ""}`}>
      {label && (<TextopiaFormLabel label={label} labelColor={labelColor} />)}
      <div className="d-flex flex-column flex-grow-1 justify-content-between bg-light rounded-lg rounded-top-0 rounded-left-0 border-bottom border-bottom-secondary border-right border-right-secondary border-left border-left-secondary border-top border-top-primary">
        <ReactQuill theme="snow" 
                    name={name}
                    value={formik.values[name]} 
                    onChange={(content) => handleChange(content, formik)}                   
                    modules={modules}
                    formats={formats}
                    ref={myRef}  
                    id={id}
                    placeholder={placeholder ? placeholder : ""}                 
                    />

        <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-between pl-4 pr-5 mb-2">
          <span>
            <span className="btn btn-sm btn-clean bg-transparent text-hover-primary px-0 py-1 w-30px w-lg-35px" onClick={toggleEmojiPicker}><i className="la la-smile icon-lg text-dark"></i></span>                
            <span className="btn btn-sm btn-clean bg-transparent text-hover-primary px-0 py-1 w-30px w-lg-35px" onClick={() => formik.setFieldValue(name, "")}><i className="la la-trash icon-lg text-dark"></i></span>
          </span>          
        </div>

        {showEmojiPicker && ( 
        <Picker set='apple' 
                showSkinTones={false}
                sheetSize={16}
                perLine={8}
                style={{borderRadius: '0px 0px 10px 0px', borderBottom: '0px', borderColor: '#E4E6EF', borderLeft: '0px', borderRight: '0px', marginTop: '3px', width: '100%', animation: animations.bounceIn}}
                showPreview={false}
                onSelect={(emoji) => {insertAtCaret(emoji)}} 
        />
        )}

       </div>     
 
       {helpText && (<TextopiaFormInputHelpText helpText={helpText} />)}
       <TextopiaErrorMessage errors={formik.errors} touched={formik.touched} name={name} />
    </Form.Group>
    
  )
}


export const TextopiaTaskCard = function({task, ddOptions}) {
  const role = Parse.User.current().get("userRolePtr").attributes;
  let isLateNow = task && !task.get("isMarkedDone") && dayjs(new Date()).isAfter(dayjs(task.get("dueOnDate"))) ? true : false
  let wasCompletedLate = task && task.get("isMarkedDone") && dayjs(task.get("markedDoneOnDate")).isAfter(dayjs(task.get("dueOnDate"))) ? true : false      
  return (
    <div className="d-flex flex-column justify-content-center align-items-left px-0">

      <div className="d-flex flex-row align-items-center justify-content-between">
          <div className={`d-flex flex-row flex-grow-1 align-items-center justify-content-start symbol symbol-60 symbol-circle`}>            
            <span className={`symbol-label text-${wasCompletedLate ? "danger" : task.get("isMarkedDone") ? "primary" : isLateNow ? "white" : "white"} font-size-base font-weight-bolder flex-row align-items-center bg-${wasCompletedLate ? "light-danger" : task.get("isMarkedDone") ? "light-primary" : isLateNow ? "danger" : "warning"} mr-3`}>#{task.get("number")}</span>             
            <div className="d-flex flex-column mr-auto">
                <span className="card-title text-hover-primary font-weight-bolder font-size-h5 text-dark mb-1">{task.get("type") === "Customer" ? "Customer Task" : "General Task"}</span>
                {task.get("type") === "Customer" && (
                  <span className="text-muted font-weight-bold">
                    <Link to={{pathname: "/convos/convos", customerPtr: task.get("customerPtr") }}>
                      <span className="d-flex flex-row align-items-center btn btn-link-primary"><span>{task.get("customerPtr") && Textopia.getCustomerName(task.get("customerPtr"))}</span><i className="la la-external-link-square-alt ml-1"></i></span>
                    </Link>
                  </span>
                )}
            </div>
          </div>
          <div className={`d-flex flex-row flex-grow-1 align-items-center justify-content-end `}>   
          <span className={`label label-sm label-inline label-${wasCompletedLate ? "light-danger" : task.get("isMarkedDone") ? "primary" : isLateNow ? "danger" : "warning"} font-weight-normal`}>{wasCompletedLate ? "COMPLETED LATE" : task.get("isMarkedDone") ? "DONE" : isLateNow ? "PENDING LATE" : "PENDING"}</span>                     
                             
          </div>
      </div>

      <div className="mt-5 mt-lg-8 py-2 px-4 rounded-xl border border-secondary">
        <span className="d-block">
            <div dangerouslySetInnerHTML={{__html: task.get("body")}} className="text-dark font-size-h6 font-weight-normal" style={{whiteSpace: 'pre-wrap'}} /> 
        </span>        
      </div>

      <div className="d-flex flex-wrap mt-lg-8 mt-5">
          <div className="mr-12 d-flex flex-column mb-5">
              <span className="d-block font-weight-bold mb-4">Assigned To</span>
              <span className="label label-inline label-lg label-light font-weight-bold">{task.get("assignedToUserPtr") ? Textopia.getUserName(task.get("assignedToUserPtr")) : task.get("assignedToTeamPtr") ? task.get("assignedToTeamPtr").get("title") : ""} </span>
          </div>
          <div className="mr-12 d-flex flex-column mb-5">
              <span className="d-block font-weight-bold mb-4">Due</span>
              <span className={`label label-inline label-lg label-light${isLateNow || wasCompletedLate ? "-danger" : ""} font-weight-bold`}>{dayjs(task.get("dueOnDate")).format('M/D/YYYY h:mm a')} </span>
          </div>
      </div>
    
      {role.EDIT_TASKS && ddOptions && (
        <TextopiaFooterToolbar ddOptions={ddOptions} toolbarType="CRUD" showTitles={true} />
      )}        

    </div>         
  )
}

export const TextopiaFooterToolbar = function ({disabledText, ddOptions, toolbarType, showTitles, noNegative}) {

  return (
    <>
      <div className="separator separator-secondary separator-solid mt-4 mx-lg-n8 mx-n5" />
      <div className={`${!noNegative ? "mb-n2" : ""} mt-3 d-flex flex-row flex-grow-1 justify-content-end`}>              
        { toolbarType === "CRUD" && (<TextopiaCRUDToolbar disabledText={disabledText} options={ddOptions} showTitles={showTitles} />) }
        { toolbarType === "BUTTON" && (<TextopiaButtonToolbar disabledText={disabledText} options={ddOptions} showTitles={showTitles} />) }
        { toolbarType === "DD" && (<TextopiaCRUDDropdown /> )}
      </div>
    </>
  )

}

export const TextopiaSymbolDataWidget = function ({icon, iconText, data, title, useLabel, labelColor, labelTextColor, textColor}) {

  return (
    <div className="d-flex flex-column align-items-top justify-content-start">
        <div className="border-left border-left-secondary min-h-30px ml-7" />
        <div className="d-flex flex-row align-items-top justify-content-start">

          <div className="symbol symbol-50 symbol-white mr-3 flex-shrink-0">
              <div className="symbol-label border border-secondary">
                  {icon && (<i className={`${icon} icon-2x text-muted d-block`}></i>)}
                  {iconText && (<span className="text-dark font-size-xs">{iconText}</span>)}
              </div>
          </div>

          <div>
              <div className="font-size-h5 text-primary font-weight-bold">{data}</div>
              <div className="font-size-sm text-muted font-weight-normal">{title}</div>
          </div>
        </div>

    </div>
  )
}

export const TextopiaSymbolSmallDataWidget = function ({icon, iconText, data, title, useLabel, labelColor, labelTextColor, textColor}) {

  return (
    <div className="d-flex flex-column align-items-top justify-content-start">
        <div className="border-left border-left-secondary min-h-30px ml-7" />
        <div className="d-flex flex-row align-items-top justify-content-start">

          <div className="symbol symbol-50 symbol-white mr-3 flex-shrink-0">
              <div className="symbol-label border border-secondary">
                  {icon && (<i className={`${icon} icon-2x text-muted d-block`}></i>)}
                  {iconText && (<span className="text-dark font-size-xs">{iconText}</span>)}
              </div>
          </div>

          <div>
              <div className="font-size-sm text-muted font-weight-normal mt-1 mb-1">{title}</div>
              <div className="font-size-sm text-dark font-weight-normal">
              { Array.isArray(data) && (data.length > 0) && data.map( (item, index) => ( // Explicityly say useLabel={false} to not use labels in in infoline.
                  <span className={`${!useLabel ? 'font-size-sm font-weight-normal ' + `${index > 0 ? " mx-2" : " mr-2"}` + ' text-' + `${textColor ? textColor : 'dark'}` : 'label label-inline label-pill bg-' + `${labelColor ? labelColor : 'light'}` + ' text-' + `${labelTextColor ? labelTextColor : 'dark'}`} `} key={title + item + index}>{item}</span>
              ))}
              { !Array.isArray(data) && (
                <span className={`${!useLabel ? 'font-size-sm font-weight-normal mx-2 text-' + `${textColor ? textColor : 'dark'}` : 'label label-inline label-pill bg-' + `${labelColor ? labelColor : 'light'}` + ' text-' + `${labelTextColor ? labelTextColor : 'dark'}`} `}>{data}</span>
              )}
              </div>              
          </div>
        </div>

    </div>
  )
}

export const TextopiaVerticalSymbolDataWidget = function ({icon, iconText, title, spacingSm, spacingLg, lastRow, lastItem, addButton, children}) {

  return (
  <div className={`col-xl-${spacingLg ? spacingLg : '6'} col-lg-${spacingLg ? spacingLg : '6'} col-md-${spacingSm ? spacingSm : '12'} col-sm-${spacingSm ? spacingSm : '12'}`}>
    <div className="d-flex flex-column align-items-top justify-content-start"  style={{height: '100%'}}>
        <div className="border-left border-left-secondary ml-7 min-h-30px" />

        <div className="d-flex flex-row align-items-top justify-content-start" style={{height: '100%'}}>

          <div className="d-flex flex-column">
            <div className="symbol symbol-50 symbol-white mr-3 flex-shrink-0">
                <div className="symbol-label border border-secondary">
                    {icon && (<i className={`${icon} icon-2x text-dark-50 d-block`}></i>)}
                    {iconText && (<span className="text-dark font-size-xs">{iconText}</span>)}
                </div>
            </div>
            
            <div className={`${lastRow ? "d-lg-none" : ""} ${lastItem ? "d-none" : ""} border-left border-left-secondary ml-7`} style={{height: '100%'}}/>                    
          </div>
          <div>
              <div className="d-flex flex-row align-items-center justify-content-start font-size-base text-dark-50 font-weight-normal mt-0 mb-0">
                {title} 
                {addButton && (<button className="ml-1 btn btn-sm btn-icon btn-clean bg-transparent btn-hover-icon-warning" onClick={(e) => addButton.method(addButton.params)}><i className="la la-plus-circle icon-lg" /></button>)}
              </div>
              <div className="font-size-base text-dark font-weight-normal">
              {children}
              </div>              
          </div>   
        </div>
     </div>
  </div>

  )
}

export const TextopiaTextContentWidget = function({title, content, choices}) {
  return (
    <div className="mt-10 p-4 pr-7 pb-7 rounded border border-secondary rounded-xl">
        <span className="d-block">
            {title && (<span className="text-muted font-weight-normal font-size-lg mr-2 d-block mb-2">{title}</span> )}
            <span className="font-weight-bold text-dark font-size-h6 d-block">{content}</span>
            {choices && (<span className="font-weight-bold text-dark-50 font-size-h6 d-block">{choices}</span>)}

        </span>
    </div>
  )
}

export const TextopiaFormSeparator = function({space}) {
  return (
    <div className={`separator separator-solid separator-secondary mt-${space ? space : "0"} mb-${space ? space : "6"} `} />
  )
}

export const TextopiaTopicsWidget = function({formik, label, name, helpText, helpHTML, subfield, className}) {
  const { topics } = useSelector( state =>  ({ topics: state.topics.topics }), shallowEqual )
  const [myTopics, setMyTopics] = useState('');
  useEffect( () => {
    Textopia.loadTopicsForUI(setMyTopics, topics); // immediate invokation of inline function
  }, [topics]);

  return (
    <TextopiaMultiSelect label={label ? label : "Topics"} name={name ? name : "topics"} helpText={helpText} helpHTML={helpHTML} subfield={subfield} options={myTopics} formik={formik} className={className} />
  )
}

export const TextopiaFeedbackResponse = function({response, isRead}) {
  let question = response.question;

  const getBgColor = (question) => {
    return  question.format === "Smileys"   ? "light-danger" : 
            question.format === "Stars"     ? "light-warning" : 
            question.format === "Yes/No"    ? "light-primary" : 
            question.format === "Score"     ? "primary-d-4" : 
            question.format === "Choices" || question.format === "Multiple Choices" ? "light-success" :
            "light";
  }

  const getQuestionFormat = () => {
    
  }

  const getDarkBgColor = (question) => {
    return  question.format === "Smileys"   ? "danger" : 
            question.format === "Stars"     ? "warning" : 
            question.format === "Yes/No"    ? "primary" : 
            question.format === "Score"     ? "primary-d-3" : 
            question.format === "Choices" || question.format === "Multiple Choices" ? "success" :
            "gray-300";
  }

  const getResponseValue = (question) => {
    let response = question.response;
    let format = question.format;

    if (format === "Write-In") return <div className={`text-dark-75 font-weight-${!isRead ? "bolder" : "bold"} font-size-base px-2 py-2`}>{response}</div>;
    else if (format === "Stars") {
      let numStars = parseInt(response);
      let toRet = "";
      let arr = [0,1,2,3,4];
      return <div className="d-flex flex-column align-items-center justify-content-middle"><i className="fas fa-star text-white icon-lg" /><span className="text-white font-weight-bolder font-size-sm">{numStars}-Star</span></div>    
    } else if (format === "Yes/No") {
      if (response === "Yes") return <i className="far fa-thumbs-up icon-lg text-white" />
      else return <i className="far fa-thumbs-down text-white icon-lg" />
    } else if (format === "Choices") {
      return <span className={`font-size-base font-weight-${!isRead ? "bolder" : "bold"} text-white`}>{response}</span>
    } else if (format === "Multiple Choices") {
      return <span className={`font-size-base font-weight-${!isRead ? "bolder" : "bold"} text-white`}>{response && Array.isArray(response) && response.length > 0 && response.map((item, index) => (index > 0 ? ", " : "" ) + item )}</span>
    } else if (format === "Score") {
      return <span className={`text-white font-weight-${!isRead ? "bolder" : "bold"} font-size-h3`}>{response}</span>
    } else if (format === "Smileys") {
      let smiley = parseInt(response);
      if      (smiley === 1)  return <i className="far fa-angry text-white icon-2x" />
      else if (smiley === 2)  return <i className="far fa-frown text-white icon-2x" />
      else if (smiley === 3)  return <i className="far fa-meh text-white icon-2x" />
      else if (smiley === 4)  return <i className="far fa-smile text-white icon-2x" />
      else if (smiley === 5)  return <i className="far fa-laugh text-white icon-2x" />
    }
  }

  const getTextColor = (question) => {

  }

  const getNPSColor = (response) => {
    return response.get("sentiment") && 
           (
           response.get("sentiment").percent >= 0.8 ? "text-success" : 
           response.get("sentiment").percent >= 0.6 ? "text-success-lighter" : 
           response.get("sentiment").percent >= 0.5 ? "text-muted" : 
           response.get("sentiment").percent >= 0.4 ? "text-danger-lighter" : 
           "text-danger"
           );
  }

  return (
  <>
    <div className="d-flex justify-content-lg-start justify-content-center flex-grow-1 align-items-middle  mt-lg-3 mt-3 mb-lg-0 mb-2 mr-0">                    
      <div className="d-flex flex-column flex-grow-1 align-items-center mr-lg-10 mr-0">                                        
        <span className={`display-2 text-center mb-lg-3 font-weight-bolder ${getNPSColor(response)} `}>{response.get("sentiment") && response.get("sentiment").percent && Math.round(response.get("sentiment").percent*10)}</span>
        <TextopiaGuageChart id={response.id} percent={response.get("sentiment") && response.get("sentiment").percent} />
      </div>                  
    </div>  

    <div className="d-flex flex-column w-75 flex-grow-1">
      {response.get("questions") && (response.get("questions").length > 0) && response.get("questions").map( (question, index) => (
        <div key={question.id + "_" + index} className={`mt-lg-3 mt-3 py-0 bg-${getBgColor(question)} rounded`}>
            <div className="d-flex flex-row align-items-center">

              <div className={`w-75 d-flex align-items-center justify-content-start flex-wrap py-1 px-5 min-h-40px rounded`}>
                <span className={`text-dark-75 font-weight-${!isRead ? "bolder" : "bold"} font-size-base`}>{question.body}</span>
              </div>

              <div className={`d-flex flex-row align-self-stretch justify-content-center flex-grow-1 bg-${getDarkBgColor(question)} min-h-40px rounded py-1 px-2`}>                  
                    <div className="d-flex align-items-center">{getResponseValue((question))}</div>
              </div>

            </div>
        </div>  
      ))}
    </div>
  </>
  )
}


export const TextopiaGuageChart = function({id, percent, width}) {

  const chartProps =  {
                        colors: ["#F64E60", "#F9949F", "#E4E6EF", "#76DCD7", "#1bc5bd"],
                        nrOfLevels: 5,
                        needleColor: "#663399",
                        needleBaseColor: "#663399",
                        hideText: true,
                        textColor: "#7E8299",
                        formatTextValue: value => value,                        
                      }

  return(
    <GaugeChart id={id} {...chartProps} percent={percent} style={{width: width ? width : 100}} />
  )

}


export const TextopiaFeedbackResponseFormat = function({question}) {
  let response = question.response;
  let format = question.format;

  if (format === "Write-In") return response;
  else if (format === "Stars") {
    let numStars = parseInt(response);
    let toRet = "";
    let arr = [0,1,2,3,4];
    return arr.slice(0, numStars).map((item, index) => <i key={"question-star"+index} className="la la-star icon-2x text-warning mt-1" />)    
  } else if (format === "Yes/No") {
    if (response === "Yes") return <div className="d-flex flex-row align-items-center justify-content-start mt-1"><i className="la la-thumbs-up icon-2x text-primary" /></div>
    else return <div className="d-flex flex-row align-items-center justify-content-start mt-1"><i className="la la-thumbs-down text-primary icon-2x" /></div>
  } else if (format === "Choices") {
    return <div className="d-flex flex-row align-items-center justify-content-start"><span className="font-size-h6 text-dark">{response}</span></div>
  } else if (format === "Multiple Choices") {
    return <div className="d-flex flex-row align-items-center justify-content-start"><span className="font-size-h6 text-dark">{response && response.length > 0 && response.map((item, index) => (index > 0 ? ", " : "" ) + item )}</span></div>
  } else if (format === "Score") {
    return <div className="d-flex flex-row align-items-center justify-content-middle mt-2"><span className="text-primary-d-3 font-weight-bolder font-size-h5">{response}</span></div>
  } else if (format === "Smileys") {
    let smiley = parseInt(response);
    if (smiley === 1) return <i className="la la-angry text-warning icon-2x mt-1 ml-n1" />
    else if (smiley === 2) return <i className="la la-frown text-warning icon-2x mt-1 ml-n1" />
    else if (smiley === 3) return <i className="la la-meh text-warning icon-2x mt-1 ml-n1" />
    else if (smiley === 4) return <i className="la la-smile text-warning icon-2x mt-1 ml-n1" />
    else if (smiley === 5) return <i className="la la-laugh text-warning icon-2x mt-1 ml-n1" />
  }
   
}

export const TextopiaReviewStars = function({numStars}) {

  if (!numStars || numStars == 0) return <span className="text-dark font-size-base">Star rating is not available</span>
  else if (numStars == 1) return <span className="d-block"><i className="fas fa-star icon-sm text-warning" /></span>
  else if (numStars == 2) return <span className="d-block"><i className="fas fa-star icon-sm text-warning" /><i className="fas fa-star icon-sm text-warning" /></span>
  else if (numStars == 3) return <span className="d-block"><i className="fas fa-star icon-sm text-warning" /><i className="fas fa-star icon-sm text-warning" /><i className="fas fa-star icon-sm text-warning" /></span>
  else if (numStars == 4) return <span className="d-block"><i className="fas fa-star icon-sm text-warning" /><i className="fas fa-star icon-sm text-warning" /><i className="fas fa-star icon-sm text-warning" /><i className="fas fa-star icon-sm text-warning" /></span>
  else if (numStars == 5) return <span className="d-block"><i className="fas fa-star icon-sm text-warning" /><i className="fas fa-star icon-sm text-warning" /><i className="fas fa-star icon-sm text-warning" /><i className="fas fa-star icon-sm text-warning" /><i className="fas fa-star icon-sm text-warning" /></span>

}

export const TextopiaViewNotesWidget = function({notes}) { // Many notes

  return (
      <>
      { notes && notes.map((note, index) => (
        <div key={note.id + "_note_" + index}>
            <TextopiaViewNoteWidget note={note} />
            <div className="separator separator-solid separator-secondary mt-4 mb-4"></div>
        </div>
      ))}
      </>
  )

}

export const TextopiaViewNoteWidget = function({note}) { // Single note

  return (  
    <div className="d-flex flex-column justify-content-start align-items-left bg-warning-o-30 border-left border-left-warning border-3 px-4 py-3 rounded-lg rounded-left-0">
        <span dangerouslySetInnerHTML={{__html: note.note ? note.note : note.get("body") ? note.get("body") : ""}} className="text-dark font-size-base font-weight-normal" style={{whiteSpace: 'pre-wrap'}} />            
        <div className="d-flex flex-row">
            <span className="font-size-xs font-weight-bold">
                <span className="label label-pill label-inline label-white bg-white text-dark-50">{note.by ? note.by : note.get("createdByUserPtr") ? Textopia.getUserName(note.get("createdByUserPtr")) : ""}</span>
                <span className="label label-sm label-dot label-primary ml-1 mr-1"></span>
                <span className="label label-pill label-inline label-white bg-white text-dark-50 ">{dayjs(note.on ? note.on : note.createdAt ? note.createdAt : new Date()).format("MMM D. YYYY")}</span>
            </span>
        </div>
    </div>   
  )
}



export const TextopiaLocationUsersWidget = function({formik, label, helpText, name, helpHTML, subfield, className, multi}) {
  
  const { myUsers } = useSelector( state =>  ({ myUsers: state.users.users }) ) //--> get all users from redux and then filter down for this location 

  const [users, setUsers] = useState('');
  useEffect( () => {
      let filtered = myUsers && myUsers.filter(user => user.get("locationList") && user.get("locationList").find(item => item.id === Parse.User.current().get("primaryLocationPtr").id));
      let resultsToReturn = [];
      filtered && filtered.map( item => (
        resultsToReturn.push({"label":Textopia.getUserName(item), "subLabel":item.get("jobTitle"), "value":item.id})
      ))
      setUsers(resultsToReturn);
  }, [myUsers]);
   
  return (
  <>
    {multi && <TextopiaMultiSelect label={label ? label : "Users"} name={name ? name : "users"} helpText={helpText} subfield={subfield} helpHTML={helpHTML} options={users} formik={formik} className={className} />}
    {!multi && <TextopiaSingleSelect label={label ? label : "Users"} name={name ? name : "users"} helpText={helpText} subfield={subfield} helpHTML={helpHTML} options={users} formik={formik} className={className} />}
  </>
  )
}

export const TextopiaLocationUsersAndTeamsWidget = function({formik, label, helpText, name, helpHTML, subfield, className, multi}) {
  
  const { myUsers, myTeams } = useSelector( state =>  ({ myUsers: state.users.users, myTeams: state.teams.teams }) ) //--> get all users from redux and then filter down for this location 
  
  const [list, setList] = useState('');
  useEffect( () => {
      let filtered = myUsers && myUsers.filter(user => user.get("locationList") && user.get("locationList").find(item => item.id === Parse.User.current().get("primaryLocationPtr").id));
      let resultsToReturn = [];
      myTeams && myTeams.map( team => (
        resultsToReturn.push({"label": team.get("title"), "subLabel": "Team", "type": "team", "value":team.id + "|" + "team", "labelColor":"primary-d-3"})
      ))
      filtered && filtered.map( user => (
        resultsToReturn.push({"label": Textopia.getUserName(user), "subLabel": user.get("jobTitle"), "type": "user", "value":user.id + "|" + "user", "labelColor":"primary"})
      ))
      setList(resultsToReturn);

  }, [myUsers, myTeams]);

   
  return (
  <>
    {multi && <TextopiaMultiSelect label={label ? label : "Users"} name={name ? name : "users"} helpText={helpText} subfield={subfield} helpHTML={helpHTML} options={list} formik={formik} className={className} />}
    {!multi && <TextopiaSingleSelect label={label ? label : "Users"} name={name ? name : "users"} helpText={helpText} subfield={subfield} helpHTML={helpHTML} options={list} formik={formik} className={className} />}
  </>
  )
}


export function TextopiaChevronAccordionToggle({ eventKey, title, callback, color}) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey, () => {});

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <span onClick={toggleOnClick} className="cursor-pointer">
      <i className={`la la-chevron-circle-right icon-lg ${isCurrentEventKey ? "text-primary" : color ? color : ""}`} style={{transition: 'transform 0.3s ease', transform: isCurrentEventKey && 'rotate(90deg)'}}></i>
    </span>
  );
}

export function TextopiaAccordionToggle({ eventKey, title, callback, className, active}) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey, () => {});

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div  className={`mx-lg-5 font-size-sm font-weight-bold ${className ? className : ""} bg-hover-light-primary ${isCurrentEventKey && "bg-light-primary btn-text-primary btn-icon-primary"} border-0 rounded py-1 d-flex flex-grow-1 btn btn-xs btn-default font-weight-bold align-items-center justify-content-center`} onClick={toggleOnClick} >
      {active && <i className="fas fa-bolt mr-2 text-danger" />}Options      
      <i className={`ml-1 la la-angle-down icon-md`}></i>
    </div>
  );
}

export function TextopiaAccordionSimpleToggle({ eventKey, title, callback, className, active, icon}) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey, () => {});

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div onClick={toggleOnClick} className={`${className ? className : ""}`}>        
      <i className={`${icon ? icon : "la la-angle-down icon-md"}`}></i>
      {active && <div className="label label-sm label-dot label-warning ml-1" style={{position: 'relative'}} />}    
    </div>
  );
}



export function TextopiaAccordionToggle3({ eventKey, title, callback, className}) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey, () => {});

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div  className={`${className ? className : ""}  d-flex flex-row align-items-center justify-content-between text-dark-50 text-hover-primary py-1 bg-${isCurrentEventKey ? "gray-200" : "light"} px-4 rounded-lg rounded-left-0 border-left border-left-hover-primary border-left-${isCurrentEventKey ? "primary" : "secondary"} border-3`} onClick={toggleOnClick} >
      <span className={`${isCurrentEventKey ? "text-dark" : "" } font-size-lg font-weight-normal`}>
        {title}
      </span>
      <span>
        <i className={`fas fa-chevron-right  ${isCurrentEventKey ? "text-primary" : ""} icon-sm`} style={{transition: 'transform 0.3s ease', transform: isCurrentEventKey && 'rotate(90deg)'}}></i>
      </span>
    </div>
  );
}

export function TextopiaAccordionToggle2({ eventKey, title, callback, className}) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey, () => {});

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div  className={`cursor-pointer ${className ? className : ""}  d-flex flex-row align-items-center justify-content-between text-hover-primary  py-1 bg-${isCurrentEventKey ? "white" : "white"} px-4 rounded-0 rounded-left-0 border-left-0 border-bottom-hover-warning border-bottom border-bottom-${isCurrentEventKey ? "warning" : "secondary"} border-1`} onClick={toggleOnClick} >
      <span className={`${isCurrentEventKey ? "text-primary" : ""} font-size-lg font-weight-normal text-hover-primary  `}>
        {title}
      </span>
      <span>
        <i className={`fas fa-chevron-down  ${isCurrentEventKey ? "text-primary" : "text-gray-500"} icon-sm`} style={{transition: 'transform 0.3s ease', transform: isCurrentEventKey && 'rotate(180deg)'}}></i>
      </span>
    </div>
  );
}

export function TextopiaCreatableSelect() {
  const components = {
    DropdownIndicator: null,
  };

  const createOption = (label: string) => ({
    label,
    value: label,
  });

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);

  const handleChange = (value: any, actionMeta: any) => {
    setValue(value);
  }
  const handleInputChange = (inputValue: string) => {
    setInputValue(inputValue);
  }

  const handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {    
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':        
        setInputValue('');
        setValue([...value, createOption(inputValue)]);
        event.preventDefault();
    }
  }

  return (
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type something and press enter..."
        value={value}
      />

  )


}

export function TextopiaTagInput({label, name, lines, helpText, subfield, formik, labelColor}) {

const settings = {
    //editTags: null,   
    editTags: { clicks: 1 },
    templates : {       

        tag : function(tagData){
            try{
            return `<tag title='${tagData.value}' contenteditable='false' spellcheck="false" class='cursor-pointer tagify__tag bg-light-primary rounded px-1 py-1 d-flex flex-row align-items-center' ${this.getAttributes(tagData)}>
                        <x title='remove tag' class='tagify__tag__removeBtn'></x>
                        <div>
                            ${tagData.code ?
                            `<img onerror="this.style.visibility='hidden'" src='https://lipis.github.io/flag-icon-css/flags/4x3/${tagData.code.toLowerCase()}.svg'>` : ''
                            }
                            <span class='tagify__tag-text mr-1'>${tagData.value}</span>
                        </div>
                    </tag>`
            }
            catch(err){}
        },
    },   
  }

  const clearContent = (formik) => {
    tagifyRef.current.removeAllTags();    
    formik.setFieldValue(name, ""); 
  }

  const tagifyRef = useRef(null);

  return (
    <div className="d-flex flex-row align-items-start justify-content-start">
        { subfield && (<i className="la la-rotate-left icon-lg fa-rotate-180 text-primary mr-2"></i>) }
        <Form.Group className={`w-100 text-dark-50`}>
          <TextopiaFormLabel label={label} labelColor={labelColor} />
            <Tags
              tagifyRef={tagifyRef}
              settings={settings}
              className={`bg-light border-top border-top-primary border-left border-left-secondary border-right border-right-secondary border-bottom border-bottom-secondary rounded-bottom-right-xl px-2 py-2 w-100 ${lines == "sm" ? "smallTagInput" : lines == "md" ? "mediumTagInput" : "largeTagInput"}`}
              value={tagifyRef && tagifyRef.current && tagifyRef.current.value[0] && (tagifyRef.current.value[0].value != "null" ? tagifyRef.current.value : formik.values[name])}                                       
              onChange={(e) => {formik.values[name] = tagifyRef.current.value }}
            />
            {helpText && (<TextopiaFormInputHelpText helpText={helpText} />)}
            <TextopiaErrorMessage errors={formik.errors} touched={formik.touched} name={name} />
        </Form.Group>
    </div> 
  )

}

export const TextopiaDateRangeWidget = function({label, name, helpText, helpHTML, subfield, formik}) {
  let dateRangeOptions = [
    {"label": "Today", "value": "Today"},
    {"label": "Yesterday", "value": "Yesterday"},
    {"label": "This Week", "value": "This Week"}, 
    {"label": "Last Week", "value": "Last Week"},
    {"label": "This Month", "value": "This Month"},
    {"label": "Last Month", "value": "Last Month"},
    {"label": "This Year", "value": "This Year"}, 
    {"label": "Last Year", "value": "Last Year"},
    {"label": "Last 12 Months", "value": "Last 12 Months"},            
  ]
  return (
    <TextopiaSingleSelect label={label} name={name} options={dateRangeOptions} formik={formik} helpText={helpText} helpHTML={helpHTML} subfield={subfield} />
  )
}

export const TextopiaBackButton = function ({action, formik, label}) {
  return (
    <div className="mb-5 w-100px border border-secondary border-0 rounded-lg py-0 cursor-pointer d-flex align-items-center justify-content-left text-dark text-hover-primary" onClick={(e) => action(e, formik)}>
      <i className="la la-angle-double-left icon-lg text-primary"></i>
      <div className="font-size-sm font-weight-bold">{label ? label : "Back"}</div>
    </div>
  )  
}

export const TextopiaSearchList = function({formik, actionCallback, name, items, icon, takeAction, skipItemId, label, noSearch}) {

  const [searchTerm, setSearchTerm] = useState('');

  const handleClick = (e, item) => {

    formik && formik.setFieldValue(name, item.value);  //--> if formik is passed, this is part of a form
    if(takeAction) { 
      formik && formik.handleSubmit(); 
      //formik.handleReset() 
    }
    if(actionCallback) {console.log('here')
      actionCallback(item.value); //--> if actionCallback is passed, then this is purely a search list and we just want to get the value of the selected item back
    }
  }

  return (
    <>    
      {!noSearch && <TextopiaTextSearch label={label ? label : ""} setParentSearchTerm={setSearchTerm} />}
      {items && items.map( (item, index) => (
      <div key={`site_${index}`}>
        {(!skipItemId || (skipItemId && (skipItemId != item.value.id && skipItemId != item.value))) && item.label.toLowerCase().includes(searchTerm.toLowerCase()) && (
        <div onClick={(e) => handleClick(e, item)} className={`cursor-pointer hover-icon-container ${index>0 ? "mt-5" : "mt-0"} d-flex flex-row align-items-center justify-content-between border-left border-3 border-left-primary border-left-hover-gray-500 rounded-top-right-xl rounded-bottom-right-xl rounded-left-0 bg-primary-o-30 bg-hover-light pl-5 pr-5 py-2`}>
            <div className="d-flex flex-row align-items-center justify-content-start w-100">
              {item.image && (<Image className="image-input image-input-outline border border-1 border-secondary mr-2" width="50" height="50" src={item.image._url} />)}
              {icon && ( <i className={` ${icon} text-primary`} /> )}
              {!item.subLabel && (<span className="text-dark font-size-h6 ml-3">{item.label}</span>)}
              {item.subLabel && (
                <div className="d-flex flex-column ml-3">
                  <span className="text-dark font-size-h6 mb-n1">{item.label}</span>
                  <span className="text-dark-50 font-size-sm limit-chars max-chars-25 max-chars-lg-100">{item.subLabel}</span>
                </div>
              )}
            </div>                
            <span className="hover-icon"><i className="la la-check-double text-primary icon-2x" /></span>
        </div>
        )}
      </div>
      ))}
    </>
  )
}

export function TextopiaCustomerProfileAccordionToggle({ eventKey, title, id, callback, className}) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey, () => {});

  const isCurrentEventKey = currentEventKey === eventKey;

  const handleClick = () => {
        let myEl = KTUtil.getById(eventKey);      
        let customerProfileEl = KTUtil.getById('customer_profile_aside');
        let scrollEl = KTUtil.find(customerProfileEl && customerProfileEl, '.scroll')
        
        scrollEl.scrollTop = KTUtil.offset(myEl);  // IMPORTANT // Find the offset to the currently selected accordion        
		var ps = KTUtil.data(scrollEl).get('ps');
		if(ps) ps.update();	 // Update the scroll so that the current accordion is in view
       
		toggleOnClick();
    }

  return (
  <>
    <div className={`card-header ${className ? className : ""} bg-hover-light bg-${isCurrentEventKey ? "light" : "light-primary"}  px-4 rounded-lg rounded-left-0 border-bottom-0 border-left border-3 border-left-hover-secondary-0 border-left-${isCurrentEventKey ? "primary" : "primary"}`} id={eventKey} onClick={() => {handleClick()}} >
      <div className="card-title d-flex flex-row align-items-center justify-content-between px-0">
        <div className="card-label text-dark d-flex flex-grow-1">{title}</div>
        <i className={`la la-angle-double-right icon-lg mr-0 ${isCurrentEventKey ? "text-primary" : ""} `} style={{transition: 'transform 0.3s ease', transform: isCurrentEventKey && 'rotate(90deg)'}}></i>
      </div>
    </div>    
  </> 
  );
}

export function TextopiaSpinner({pageStatus, color, type, saving}) {

  return (
  <>
  { pageStatus && pageStatus != "succeeded" && pageStatus != "failed" && pageStatus != "idle" && (
    <div className="card card-custom overlay overlay-block h-100">
      <div className="card-body overlay-wrapper">
          <div className="overlay-layer bg-dark-o-10">
            <div className={`spinner ${color ? "spinner-"+color: ""} ${type ? "spinner-"+type : ""} spinner-lg`} />    
          </div>
      </div>
    </div>
  )}
  { saving && saving === "saving" && (
    <div className="card card-custom overlay overlay-block h-100">
      <div className="card-body overlay-wrapper">
          <div className="overlay-layer bg-dark-o-10">
            <div className={`spinner ${color ? "spinner-"+color: ""} ${type ? "spinner-"+type : ""} spinner-lg`} />    
          </div>
      </div>
    </div>
  )}
  </>
  )
}

export function TextopiaSpinnerCardless({pageStatus, color, type, className, style, saving}) {
  return (
    <>
    {pageStatus && pageStatus != "succeeded" && pageStatus != "failed" && pageStatus != "idle" && (    
      <div style={style} className={`overlay-layer ${className ? className : ""} `}><div className={`spinner ${color ? "spinner-"+color: ""} ${type ? "spinner-"+type : ""} spinner-lg`} /></div>    
    )}
    {saving && saving === "saving" && (    
      <div style={style} className={`overlay-layer ${className ? className : ""} `}><div className={`spinner ${color ? "spinner-"+color: ""} ${type ? "spinner-"+type : ""} spinner-lg`} /></div>    
    )}
    </>
  )
}


export function TextopiaDotSeparator({content, className, lineColor, dotColor}) {
  return(
    <div className={`d-flex flex-row align-items-center justify-content-end ${className ? className : ""} `}>
      <div className={`h-1 border-bottom border-bottom-${lineColor ? lineColor : "secondary"} flex-grow-1 mr-2`} />
      <div className="d-flex flex-row justify-content-between align-items-center ">
        <span className={`label label-dot label-${dotColor ? dotColor : "primary"} mr-2`} />
          {content}
        <span className={`label label-dot label-${dotColor ? dotColor : "primary"} ml-2`} />
      </div>
      <div className={`h-1 border-bottom border-bottom-${lineColor ? lineColor : "secondary"} flex-grow-1 ml-2`} />
    </div>
  )
}

export function TextopiaRenderTemplate({content, prefix}) {

  // String sequences to help split up tags and templates
  const tagStart = "|-_^^|";
  const splitStart = "|;;,,|";
  const tagSplit = "|}{;'|";

  //--> this version does not lookup values of tags, rather it just converts a piece of content into text and tags for rendering
  const getDryTags = function (str) {
      //console.log("input: " + str);
      let strToRet = str;
      var re = /\[{2}(.*?)\]{2}/g; // To extract the entire tag

      var myArray = strToRet.match(re) || [];    // Extract the tags
      //console.log(myArray);
      for (var i = 0; i < myArray.length; i++) { // For each tag extracted
        //console.log(myArray[i].substring(2, myArray[i].length-2));
        var myObj = JSON.parse(myArray[i].substring(2, myArray[i].length-2)); // Convert to JSON object
        //console.log(myObj);

        let val2, val3 = [];
        val2 = myObj.value;
        val3 = myObj.type;

        // Replace the original tag pattern in the string with our custom string
        strToRet = strToRet.replace(myArray[i], splitStart + tagStart + val2 + tagSplit + val3 + splitStart );      
      }

      let strToRetArray = strToRet.split(splitStart); // Tokenize based on our split sequence and create an array to return

      //console.log(strToRetArray);
      return strToRetArray;
  }

   
  return (
    getDryTags(content).map( (item, index) => 
      (item.startsWith(tagStart) // For each tag, split to get value and type and then determine color based on type, and render value
      ? 
      <span className={`bg-${item.split(tagSplit)[1] == "Customer" ? "light-primary" : "primary-d-4"} py-0 px-2 font-size-sm text-dark rounded-lg`} 
            key={prefix+"_"+index} 
            style={{whiteSpace: 'pre-wrap'}}>{(item.split(tagSplit)[0]).substring(tagStart.length, (item.split(tagSplit)[0]).length)}</span>
      : 
      <span className="text-dark" style={{whiteSpace: 'pre-wrap'}} key={prefix+"_"+index}>{item}</span>
    ))
  )
}

export function TextopiaConfBox({message}) {
  return (
  <div className="overlay-layer">
    <div className="d-flex flex-column align-items-center justify-content-middle bg-white border border-secondary p-10 rounded-lg c_fadeIn shadow-lg" style={{zIndex: 9999, animation: animations.bounceIn}} >
      <div className="symbol symbol-circle symbol-55 symbol-warning mb-3">
        <div className="symbol-label"><i className="la la-check icon-3x text-white" /></div>
      </div>
      <span className="font-size-h3 font-weight-bold text-warning">{message ? message : "Changes Saved"}</span>
    </div>
  </div>
  )
}

export function TextopiaCustomizeLook({entityType, dispatchCall}) {

  const {pageStatus, pageError, actionStatus, actionError} = Textopia.useGetPageStatusAndError(entityType);

  const {mySettings, updatedAt} = useSelector ( state =>  ({ mySettings: state[entityType].settings, updatedAt: state[entityType].updatedAt }) );
  const [settings, setSettings] = useState('');  
  useEffect(() => setSettings(mySettings), [mySettings, updatedAt]);

  var initialValues = ({
    settings: settings,
    
   
    logo: settings && settings.get("logo") ? settings.get("logo") : '',
    whiteLogoBg: (settings && settings.get("branding") && settings.get("branding").whiteLogoBg === false) ? false : true, //--> defaulting to white bg for logo
    
    bgColor: settings && settings.get("branding") && settings.get("branding").bgColor ? settings.get("branding").bgColor : '#663399',
    activeColor: settings && settings.get("branding") && settings.get("branding").activeColor ? settings.get("branding").activeColor : '#FFA800',
    textColor: settings && settings.get("branding") && settings.get("branding").textColor ? settings.get("branding").textColor : '#FFFFFF',
  })



  const [confLook, setConfLook] = useState(false);
  const [formikDirty, setFormikDirty] = useState(false);
  const [formikValues, setFormikValues] = useState('');
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const showPreview = (formik) => {
    setFormikValues(formik && formik.values);
    if (formik.dirty) setFormikDirty(true);
    setShowPreviewModal(true);
  }

  let dispatch = useDispatch();

  async function handleSubmitLook(values, actions) {
    //console.log(values);
    await dispatch(dispatchCall(values));
    if (actionStatus && actionStatus != "saving") {
              setFormikDirty(false);
              setConfLook(true);
              setTimeout(() => setConfLook(false), 1000);
    }  
  }

  return (
  <>
  {pageStatus === "succeeded" && 
      <Formik
          initialValues={initialValues}
          onSubmit={handleSubmitLook}
          enableReinitialize={true}
      >
      { formik => (
        <>

         <TextopiaListCard spacingLg="6" spacingSm="12" title="Reviews Settings" titleIcon="flaticon2-gear icon-lg">
            <TextopiaListCardHeader headerText="Customize App Look" charLimit={20} noLabel={true} noBorder={true} />
            <TextopiaNotice notice="Customize with your logo and brand colors" />

            <Form className="pt-3" noValidate onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
              {confLook && (<div className="overlay-layer" style={{zIndex: '9999'}}><TextopiaConfBox /></div>)}
              <TextopiaSingleImageUpload label="Upload Your Logo" name="logo" formik={formik} subfield={false} />
              
              <TextopiaOnOffSwitch label="Use white background for logo?" name="whiteLogoBg" formik={formik} />

              <div className="separator separator-solid separator-secondary mt-5 mb-5" />
              <div className="d-flex flex-row">
                <TextopiaColorInput className="" label="Background Color" formik={formik} name="bgColor"/>
              </div>
              <div className="d-flex flex-row">
                <TextopiaColorInput label="Active Color" formik={formik} name="activeColor"/>
              </div>
              <div className="d-flex flex-row">
                <TextopiaColorInput label="Text Color" formik={formik} name="textColor"/>
              </div>
              
              {formikDirty && <div className="d-flex flex-row justify-content-center text-danger">Changes not saved yet!</div>}
              <TextopiaButtonSet save preview previewOnClick={() => showPreview(formik)} saving={actionStatus} />

            </Form>

         </TextopiaListCard>

         <TextopiaAppPreviewModal showPreviewModal={showPreviewModal} setShowPreviewModal={setShowPreviewModal} formikValues={formikValues} />
        </>
      )}
      </Formik>
  }
  </>
  )
}

export function TextopiaAppPreviewModal({showPreviewModal, setShowPreviewModal, formikValues}) {

  const [previewQ, setPreviewQ] = useState('Yes');
  const [hoverStateYes, setHoverStateYes] = useState(false);
  const [hoverStateNo, setHoverStateNo] = useState(false);
  const toggleHoverYes = () => setHoverStateYes(!hoverStateYes);
  const toggleHoverNo = () => setHoverStateNo(!hoverStateNo);


  return (

    <Modal centered={true} size="xl" show={showPreviewModal} onHide={() => setShowPreviewModal(false)} className="p-0 m-0">
      <div className="px-lg-8 px-2 py-lg-8 py-5 pb-lg-12">      
        <div className="d-flex flex-row flex-grow-1 justify-content-end mt-n3">
          <div className="btn btn-icon btn-clean p-0 m-0" onClick={() => setShowPreviewModal(false)}><i className="la la-close icon-lg" /></div>
        </div>

        <div className="d-flex flex-row flex-grow-1 justify-content-center mt-2">
          
          <div id="app-container-preview" className="h-lg-800px h-600px rounded-lg" style={{border: "3px solid " + (formikValues.bgColor ? formikValues.bgColor : "#663399"), backgroundColor: formikValues && (formikValues.bgColor ? formikValues.bgColor : "#987678")}}>              
                
            <div id="app-header-preview">
                  <div className="app-header-content pt-5">
                    <div className="font-size-h5 font-weight-light text-white">Thanks for choosing</div> 

                    { (!formikValues.logo) && <div className="font-size-h2 font-weight-bold text-white">{Parse.User.current().get("primaryLocationPtr").get("name")}</div>}

                    { formikValues.logo && <div className="py-2 justify-content-center align-items-center mt-1" style={{backgroundColor: formikValues.whiteLogoBg && "#FFFFFF"}}><img src={(formikValues.logo._url ? formikValues.logo._url : formikValues.logo.name ? URL.createObjectURL(formikValues.logo) : "")} style={{maxHeight: "65px", maxWidth: "100%"}} /></div>}
                    
                  
                  </div>                    
            </div>

            <div id="main-content-preview" className="px-8 px-lg-15">                    

                    <div className="text-white font-size-h1 font-weight-bold text-center">
                        <div className="font-size-h2 c_fadeIn mb-10 text-white">Are you satisfied with our service?</div>

                        <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-center">
                            <span className={`btn shadow-xs ${previewQ === "No" ? "btn-shadow" : ""} btn-xs py-8 px-8 btn-icon mr-10 border border-2 border-white`} 
                                  onClick={() => setPreviewQ('No')}
                                  onMouseEnter={toggleHoverNo} 
                                  onMouseLeave={toggleHoverNo} 
                                  style={{  backgroundColor: hoverStateNo ? (formikValues.activeColor ? formikValues.activeColor : "warning" ) : (previewQ === "No") ? (formikValues.activeColor ? formikValues.activeColor : "warning") : "white" }} 
                                  >
                                    <i className="la la-thumbs-down icon-3x" style={{color: hoverStateNo ? "white" : previewQ === "No" ? "white" : formikValues.bgColor ? formikValues.bgColor : "#663399"}} />
                            </span>
                            <span className={`btn shadow-xs ${previewQ === "Yes" ? "btn-shadow" : ""} btn-xs py-8 px-8 btn-icon border border-2 border-white`} 
                                  onClick={() => setPreviewQ('Yes')}
                                  onMouseEnter={toggleHoverYes} 
                                  onMouseLeave={toggleHoverYes} 
                                  style={{  backgroundColor: hoverStateYes ? (formikValues.activeColor ? formikValues.activeColor : "warning" ) : (previewQ === "Yes") ? (formikValues.activeColor ? formikValues.activeColor : "warning") : "white" }} 
                                  >
                                    <i className="la la-thumbs-up icon-3x" style={{color: hoverStateYes ? "white" : previewQ === "Yes" ? "white" : formikValues.bgColor ? formikValues.bgColor : "#663399"}} />
                            </span>            
                        </div>


                    </div>
            </div>

            <div id="app-footer-preview">
                <div className="app-footer-content pb-5">
                    <div className="font-size-h5 font-weight-light mb-1 text-white app-logo">Powered By</div>
                    <img src="/media/Textopia/Logos/logo_white.svg" className="w-lg-125px w-100px app-logo" />
                </div>
            </div>
          
          </div>

        </div>             
      </div>      
    </Modal> 

  )
}

export function TextopiaIncludeImage({entityType, dispatchCall}) {

  const {pageStatus, pageError, actionStatus, actionError} = Textopia.useGetPageStatusAndError(entityType);

  const {mySettings, updatedAt} = useSelector ( state =>  ({ mySettings: state[entityType].settings, updatedAt: state[entityType].updatedAt }) );
  const [settings, setSettings] = useState('');  
  useEffect(() => setSettings(mySettings), [mySettings, updatedAt]);

  var initialValues = ({
    settings: settings,

    includeImage: settings && settings.get("includeImage") ? settings.get("includeImage") : false,
    includedImage: settings ? settings.get("includedImage") : '',
  })



  const [imagesLoading, setImagesLoading] = useState(true);
  const [images, setImages] = useState('');

  useEffect( () => {
    let unmounted = false;    
    ( async () =>  {
                      let images = await Parse.Cloud.run("fetchFeedbackImages");   

                      if (!unmounted) {
                        setImages(images);
                        setImagesLoading(false);
                      }
                    }
    ) ();

    return () => {
      unmounted = true;
    };
  }, []);


  const [confImage, setConfImage] = useState(false);

  let dispatch = useDispatch();

  async function handleSubmitImage(values, actions) {
    //console.log(values);
    await dispatch(dispatchCall(values));
    if (actionStatus && actionStatus != "saving") {
              setConfImage(true);
              setTimeout(() => setConfImage(false), 1000);
    }  
  }

  let imageRequired = yup.mixed().test({
    name: 'imageRequired',
    message: 'Choose image to include',
    test: function (value) {
      const includedImage = this.parent["includedImage"];
      if (value && (!includedImage || includedImage === '')) return false; // If drip campaing, then num reminders is needed
      return true;
    }
  }) 

  const validationSchema = yup.object({
    includeImage: imageRequired,
  });


  return (
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitImage}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
      { formik => (
        <>
         <TextopiaListCard spacingLg="6" spacingSm="12" title="Feedback Settings" titleIcon="flaticon2-gear icon-lg">
            <TextopiaListCardHeader headerText="Invite Image" noLabel={true} noBorder={true} />
            <TextopiaNotice notice="Increase customer attention by including an image with your invite" />
            <Form className="pt-3" noValidate onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                {confImage && (<div className="overlay-layer" style={{zIndex: '9999'}}><TextopiaConfBox /></div>)}
                
                <TextopiaOnOffSwitch label="Include image in invite?" labelColor="warning" name="includeImage" labels={{showActive: "true", activeLabel: "Yes", showInactive: "true", inactiveLabel: "No"}} formik={formik} />
                {formik.values.includeImage && (
                  <>
                  <TextopiaSingleImageUpload label="Upload Your Own" labelColor="warning" className="c_fadeIn" name="includedImage" formik={formik} subfield={false} />
                  <TextopiaFormLabel label="Or pick one here" labelColor="warning"  />

                  <TextopiaImageList formik={formik} images={images} />
                 </>
                )}
              <TextopiaButtonSet save saving={actionStatus} />
            </Form>
         </TextopiaListCard>
         </>
       )}
       </Formik>

  )

}

export function TextopiaImageList({images, formik}) {

  useEffect(() => {
    KTUtil.scrollInit(KTUtil.getById('image-list'), {
             windowScroll: true, // allow browser scroll when the scroll reaches the end of the side
             mobileNativeScroll: true,  // enable native scroll for mobile
             desktopNativeScroll: true, // disable native scroll and use custom scroll for desktop
             resetHeightOnDestroy: true,  // reset css height on scroll feature destroyed
             handleWindowResize: true, // recalculate hight on window resize
             rememberPosition: true, // remember scroll position in cookie
             height: KTUtil.isBreakpointUp('lg') ? 500 : 300,
    });
  })

  return (
    <div className="d-flex flex-column flex-grow-1 radio-list bg-light-primary rounded-0 border-top border-top-primary border-left border-left-secondary border-right border-right-secondary border-bottom border-bottom-secondary rounded-bottom-right-xl px-5 px-lg-8" id="image-list" >
      {images && images.map( (image, index) => (

        <label key={"image_" + index} className="radio radio-outline radio-outline-2x radio-primary my-2 mx-2">
          <input type="radio" name="stockImage" onClick={() => {formik.setFieldValue("includedImage", image.get("file"))} }/>
          <span></span>
          <Image className={`ml-2 shadow-xs rounded-lg border border-${formik.values.includedImage && formik.values.includedImage._url === image.get("file")._url ? "warning" : "white"} border-2 border-left-hover-warning border-right-hover-warning border-top-hover-warning border-bottom-hover-warning`}
                 src={image.get("file")._url} 
                 style={{width: '50%', maxWidth: '50%'}} 
                 
                 />
        </label>
      ))}
    </div>
  )
}

export function TextopiaDripCampaign({entityType, dispatchCall}) {

  const {pageStatus, pageError, actionStatus, actionError} = Textopia.useGetPageStatusAndError(entityType);

  const {mySettings, updatedAt} = useSelector ( state =>  ({ mySettings: state[entityType].settings, updatedAt: state[entityType].updatedAt }) );
  const [settings, setSettings] = useState('');  
  useEffect(() => setSettings(mySettings), [mySettings, updatedAt]);

  var initialValues = ({
    settings: settings,
   
    dripCampaignActive : settings && settings.get("dripCampaignActive") ? settings.get("dripCampaignActive") : false,
    numReminders: settings ? settings.get("numReminders") && settings.get("numReminders").toString() : 1,    
  })



  const [confDrip, setConfDrip] = useState(false);

  let dispatch = useDispatch();

  async function handleSubmitDrip(values, actions) {
    //console.log(values);
    await dispatch(dispatchCall(values));
    if (actionStatus && actionStatus != "saving") {
              setConfDrip(true);
              setTimeout(() => setConfDrip(false), 1000);
    }  
  }

  let reminderOptions = [
    {"label": "1", "value": "1"},
    {"label": "2", "value": "2"},
    {"label": "3", "value": "3"},
  ]
 
  let numRequired = yup.mixed().test({
    name: 'numRequired',
    message: 'Required',
    test: function (value) {
      const drip = this.parent["dripCampaignActive"];
      if (drip && (!value || value === "" || value < 1)) return false; // If drip campaing, then num reminders is needed
      return true;
    }
  })

  const validationSchema = yup.object({
    numReminders: numRequired,
  });


  return (
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitDrip}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
      { formik => (
        <>
          <TextopiaListCard spacingLg="6" spacingSm="12" title="Reviews Settings" titleIcon="flaticon2-gear icon-lg">
            <TextopiaListCardHeader headerText="Drip Campaign" noLabel={true} noBorder={true} />
            <TextopiaNotice notice="Send automatic gentle reminders after initial invite" />
            <Form className="pt-3" noValidate onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                {confDrip && (<div className="overlay-layer" style={{zIndex: '9999'}}><TextopiaConfBox /></div>)}

                <TextopiaOnOffSwitch label="Drip-Campaign Active?" name="dripCampaignActive" labels={{showActive: "true", activeLabel: "Yes", showInactive: "true", inactiveLabel: "No"}} formik={formik} />
                {formik.values.dripCampaignActive && ( <TextopiaSingleSelect label="How many reminders?" name="numReminders" options={reminderOptions} formik={formik} subfield={true} helpText="Sent 3 days apart"/>)}
              <TextopiaButtonSet save saving={actionStatus} />
            </Form>
          </TextopiaListCard>
        </>
       )}
       </Formik>
  )
}

export function TextopiaCustomizeContent({entityType, dispatchCall}) {

  const {pageStatus, pageError, actionStatus, actionError} = Textopia.useGetPageStatusAndError(entityType);

  const {mySettings, updatedAt} = useSelector ( state =>  ({ mySettings: state[entityType].settings, updatedAt: state[entityType].updatedAt }) );
  const [settings, setSettings] = useState('');  
  useEffect(() => setSettings(mySettings), [mySettings, updatedAt]);

  var initialValues = ({
    settings: settings,

    intro: settings && settings.get("messaging") && settings.get("messaging").intro ? settings.get("messaging").intro : '',

    thanksOptions: settings && settings.get("messaging") && settings.get("messaging").format  ? (settings.get("messaging").format === "Same" ? "Same" : settings.get("messaging").format === "Different" ? "Different" : "Same") : "",

    allThanks: settings && settings.get("messaging") && settings.get("messaging").allThanks ? settings.get("messaging").allThanks : '',
    allThanksLink: settings && settings.get("messaging") && settings.get("messaging").allThanksLink ? settings.get("messaging").allThanksLink : '',
    allThanksLinkText: settings && settings.get("messaging") && settings.get("messaging").allThanksLinkText ? settings.get("messaging").allThanksLinkText : '',

    negThanks: settings && settings.get("messaging") && settings.get("messaging").negThanks ? settings.get("messaging").negThanks : '',
    negThanksLink: settings && settings.get("messaging") && settings.get("messaging").negThanksLink ? settings.get("messaging").negThanksLink : '',
    negThanksLinkText: settings && settings.get("messaging") && settings.get("messaging").negThanksLinkText ? settings.get("messaging").negThanksLinkText : '',

    passThanks: settings && settings.get("messaging") && settings.get("messaging").passThanks ? settings.get("messaging").passThanks : '',
    passThanksLink: settings && settings.get("messaging") && settings.get("messaging").passThanksLink ? settings.get("messaging").passThanksLink : '',
    passThanksLinkText: settings && settings.get("messaging") && settings.get("messaging").passThanksLinkText ? settings.get("messaging").passThanksLinkText : '',

    posThanks: settings && settings.get("messaging") && settings.get("messaging").posThanks ? settings.get("messaging").posThanks : '',
    posThanksLink: settings && settings.get("messaging") && settings.get("messaging").posThanksLink ? settings.get("messaging").posThanksLink : '',
    posThanksLinkText: settings && settings.get("messaging") && settings.get("messaging").posThanksLinkText ? settings.get("messaging").posThanksLinkText : '',
  })


  const [confMessaging, setConfMessaging] = useState(false);

  let dispatch = useDispatch();

  async function handleSubmitMessaging(values, actions) {
    //console.log(values);
    await dispatch(dispatchCall(values));
    if (actionStatus && actionStatus != "saving") {
              setConfMessaging(true);
              setTimeout(() => setConfMessaging(false), 1000);
    }  
  }

  const [activePill, setActivePill] = useState(0);

  return (
    <Formik
          initialValues={initialValues}
          onSubmit={handleSubmitMessaging}
          enableReinitialize={true}
      >
      { formik => (
        <>

         <TextopiaListCard spacingLg="6" spacingSm="12" title="" titleIcon="flaticon2-gear icon-lg">
            <TextopiaListCardHeader headerText="Customize App Content" charLimit="50" noLabel={true} noBorder={true} />
            <TextopiaNotice notice="Speak your brand here" />
            <Form className="pt-3" noValidate onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
              
              {confMessaging && (<div className="overlay-layer" style={{zIndex: '9999'}}><TextopiaConfBox /></div>)}
              {entityType !== "paymentsSettings" &&
              <>
                <TextopiaTextAreaInput formik={formik} rows="2" label="Intro Content" labelColor="warning" name="intro" helpText="Limit 50 chars" maxlength={50} placeholder={`Thanks for choosing ${Parse.User.current().get("primaryLocationPtr").get("name")} `}/>
              
                <div className="separator separator-solid separator-secondary mt-5 mb-5" />
              </>
              }

              { (entityType != "reviewsSettings") && (entityType != "paymentsSettings") && (
              <>
              <TextopiaRadioList label="Thank You Page" labelColor="warning" formik={formik}>
                <TextopiaRadioListItem label="Same content for all" name="thanksOptions" value="Same" checked={true} formik={formik} color="warning" />
                <TextopiaRadioListItem className="mt-1" label="Content based on score" name="thanksOptions" value="Different" formik={formik} color="warning" />
              </TextopiaRadioList>
                
              <div className="separator separator-solid separator-secondary mt-5 mb-5" />
              </>
              )}
            
              {(entityType === "reviewsSettings" || entityType === "paymentsSettings" || (formik.values.thanksOptions && formik.values.thanksOptions === "Same")) && (
              <div className="c_fadeIn">
                <TextopiaTextAreaInput formik={formik} name="allThanks" label="Thank You Content" labelColor="warning" rows="2" helpText="Limit 200 chars" maxlength={200} placeholder="We're so glad you're loving our service. Please visit us again!" />
                <TextopiaTextInput formik={formik} name="allThanksLinkText" label="Link Text" labelColor="warning" helpText="Limit 50 chars. Send users to referral, promo, review site, etc" maxlength={50} placeholder="Checkout our latest promo!"/>
                <TextopiaTextInput formik={formik} name="allThanksLink" label="Link URL" labelColor="warning" helpText="Link Text links to this URL" placeholder="http://www.acme.com/promo" />
              </div>
              )}
              {formik.values.thanksOptions && formik.values.thanksOptions === "Different" && (
              <div className="c_fadeIn">
                <Tab.Container defaultActiveKey={activePill}>
       
        
                    <Nav variant="pills" className="border border-secondary bg-light shadow-xs rounded d-flex flex-row align-items-center w-100 justify-content-center justify-content-lg-between py-3 px-5 px-lg-8 mb-6 mb-lg-7" onSelect={(eventKey) => setActivePill(eventKey)}>
                      <div className="d-flex">
                      <Nav.Item className="nav-item mr-5">
                        <Nav.Link eventKey="0" className={`m-0 py-1 px-1 rounded-lg ${activePill == "0" ? "border border-2 border-light-primary bg-primary-o-20" : "border border-transparent border-2"} `}>
                          <TextopiaButton addClasses="w-auto" icon="far fa-smile icon-2x" text="Promoters" iconColor={` ${activePill == "0" ? "text-primary" : "text-success"} `} textColor={` ${activePill == "0" ? "text-primary" : ""} `}  />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-item mr-5">
                        <Nav.Link eventKey="1" className={`m-0 py-1 px-1 rounded-lg  ${activePill == "1" ? "border border-2 border-light-primary bg-primary-o-20" : "border border-transparent border-2"} `}>
                          <TextopiaButton addClasses="w-auto" icon="far fa-meh icon-2x" text="Passives"  iconColor={` ${activePill == "1" ? "text-primary" : "text-warning"} `} textColor={` ${activePill == "1" ? "text-primary" : ""} `}/>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-item mr-5">
                        <Nav.Link eventKey="2" className={`m-0 py-1 px-1 rounded-lg  ${activePill == "2" ? "border border-2 border-light-primary bg-primary-o-20" : "border border-transparent border-2"} `}>
                          <TextopiaButton addClasses="w-auto" icon="far fa-frown icon-2x" text="Detractors"  iconColor={` ${activePill == "2" ? "text-primary" : "text-danger"} `} textColor={` ${activePill == "2" ? "text-primary" : ""} `}/>
                        </Nav.Link>
                      </Nav.Item> 
                      </div>                     
                    </Nav>

                    <Tab.Content className="bg-transparent">                 
                      <Tab.Pane eventKey="0">      
                        <TextopiaTextAreaInput formik={formik} name="posThanks" rows="2" label="Content" labelColor="warning" helpText="Limit 200 chars" maxlength={200} placeholder="We're so glad you're loving our service. Please visit us again!" />
                        <TextopiaTextInput formik={formik} name="posThanksLinkText" label="Link Text" labelColor="warning" helpText="Limit 50 chars. Send users to referral, promo, review site, etc" maxlength={50} placeholder="Share your experience, refer a friend!"/>
                        <TextopiaTextInput formik={formik} name="posThanksLink" label="Link URL" labelColor="warning" helpText="Link Text links to this URL" placeholder="http://www.acme.com/referral"/>
                      </Tab.Pane>

                      <Tab.Pane eventKey="1">
                        <TextopiaTextAreaInput formik={formik} name="passThanks" rows="2" label="Content" labelColor="warning" helpText="Limit 200 chars" maxlength={200} placeholder="Sorry to hear that our service was less than perfect.  Help us do better." />
                        <TextopiaTextInput formik={formik} name="passThanksLinkText" label="Link Text" labelColor="warning" helpText="Limit 50 chars. Send users to referral, promo, review site, etc" maxlength={50} placeholder="Talk to support"/>
                        <TextopiaTextInput formik={formik} name="passThanksLink" label="Link URL" labelColor="warning" helpText="Link Text links to this URL" placeholder="http://www.acme.com/support"/>
                      </Tab.Pane>

                      <Tab.Pane eventKey="2">
                        <TextopiaTextAreaInput formik={formik} name="negThanks" rows="2" label="Content" labelColor="warning" helpText="Limit 200 chars" maxlength={200} placeholder="So sorry we haven't met your expectations, we hope to do better.  We'll be in touch soon." />
                        <TextopiaTextInput formik={formik} name="negThanksLinkText" label="Link Text" labelColor="warning" helpText="Limit 50 chars. Send users to referral, promo, review site, etc" maxlength={50} placeholder="Reach out to our support team"/>
                        <TextopiaTextInput formik={formik} name="negThanksLink" label="Link URL" labelColor="warning" helpText="Link Text links to this URL" placeholder="http://www.acme.com/supportLive"/>
                      </Tab.Pane>                      

                    </Tab.Content>
                  </Tab.Container>       
              </div>
              )}

              <TextopiaButtonSet save saving={actionStatus} />

            </Form>
         </TextopiaListCard>
        </>
      )}
      </Formik>


  )
}

export function FeedbackGuageWidget({rating}) {

  return (
    <div className="d-flex  justify-content-lg-middle justify-content-middle flex-grow-1 align-items-center">  
      <span className={`display-3 text-center mr-3 font-weight-bolder text-${rating.get("sentiment") && (rating.get("sentiment").percent >= 0.8 ? "success" : rating.get("sentiment").percent >= 0.6 ? "warning" : "danger")}   `}>{rating.get("sentiment") && Math.round((rating.get("sentiment").percent)*10)}</span>                  
      <div className="d-flex flex-column">                                                          
        <TextopiaGuageChart id={rating.id} percent={rating.get("sentiment") && rating.get("sentiment").percent} width={75} />
      </div>                  
    </div>  
  )
}

export function FeedbackSmallRender({rating}) {

  return (

    <div className="d-flex flex-row align-items-center justify-content-start px-5 px-lg-8">
      <div className="d-flex flex-column justify-content-center align-items-left px-0 px-lg-0 w-100">
        <div className="d-flex flex-row align-items-center justify-content-between">
          
          { rating.get("isFeedbackSubmitted") && ( //--> at least one question answered so we have some data to look at 
            <FeedbackGuageWidget rating={rating} />
          )}
          <span className="label label-inline label-pill label-light-primary text-dark">{Textopia.getFeedbackInviteStatus(rating)}</span>
        </div>                

        { rating.get("isFeedbackSubmitted") && ( //--> to show this, the feedback has to at least have been started, indicated by isFeedbackSubmitted, meaning at least one question answered
        <div className="mt-2 px-4 pb-2 rounded-lg rounded-left-0 bg-white border-left border-3 border-left-gray-500 text-dark font-size-base text-left">

          {rating.get("questions") && rating.get("questions").sort((a, b) => (a.positionInList > b.positionInList) ? 1 : -1).map( (question, index) => (
          <div key={`q_${rating.id}_${index}`} className={`d-flex flex-column align-items-left justify-content-middle ${index === 0 ? "" : "mt-3"} `}>
            <span className="text-dark-75 font-weight-normal font-size-base">{question.body}</span>
            <span className="text-dark font-weight-bold font-size-base justify-content-center">
              <div className={`border-left border-left-${Textopia.getFeedbackQuestionBorderColor(question)} border-3 pl-2`}><TextopiaFeedbackResponseFormat question={question} /></div>
            </span>
          </div>
          ))}          

        </div>
        )}

        <div className="d-flex flex-row align-items-center justify-content-between mt-3">
          <div className="text-dark-50 font-size-xs d-flex justify-content-start">#{rating.get("number")}</div>
          <span className="font-size-xs text-dark">{Textopia.fromNow(rating.get("feedbackSubmittedOnDate"), Constants.DATE_FORMAT_1)}</span>
        </div>
      </div>
   </div>
   
  )
}

export function ReviewSmallRender({rating}) {

  return (
    <div className="d-flex flex-row align-items-center justify-content-start px-5 px-lg-8 mt-5">
      <div className="d-flex flex-column justify-content-center align-items-left px-0 px-lg-0 w-100">
        <div className="d-flex flex-row align-items-center justify-content-between mt-2">
          <div className="d-flex flex-row align-items-center justify-content-start m-0">
            <TextopiaReviewStars numStars={rating.get("numStars")} />
          </div>
          {rating.get("reviewSitePtr") && <span className="label label-inline label-pill label-light-warning text-dark">{rating.get("reviewSitePtr") && rating.get("reviewSitePtr").get("title")}</span>}
        </div>
        <div className="mt-3 pl-4 pb-2 rounded-lg rounded-left-0 bg-white border-left border-3 border-left-gray-500 text-dark font-size-base text-left">
          <div className="d-flex flex-row align-items-center justify-content-start mt-2">
            <span className="text-dark font-weight-normal font-size-base">{rating.get("body")}</span>
          </div>
        </div>
        <div className="d-flex flex-column flex-grow-1 align-items-end mb-2">                                   
          {rating.get("replies") && rating.get("replies").map((reply, index) => (
          <div key={rating.id + "_reply_" + index} className="">
            <div className="d-flex flex-column justify-content-left ml-15 ml-lg-15 mt-2 pr-5 pl-5 border-right border-right-primary border-3 rounded-lg rounded-right-0 max-w-75 ">
              <span className="text-dark-50 font-size-xs"><i className="flaticon2-reply-1 icon-1x mr-2"></i>{reply.repliedByUserName} on {dayjs(reply.repliedOnDate).format("MMM D, YYYY")}</span>
              <span className="text-dark font-size-base mt-1" dangerouslySetInnerHTML={{__html: reply.replyBody}} style={{whiteSpace: 'pre-wrap'}} />
            </div>
          </div>
          ))} 
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between mt-3">
          <div className="text-dark-50 font-size-xs d-flex justify-content-start">#{rating.get("number")}</div>
          <span className="font-size-xs text-dark">{Textopia.fromNow(rating.get("reviewSubmittedOnDate"), Constants.DATE_FORMAT_1, 14)}</span>
        </div>
      </div>
    </div>
  )
}


export function ShopifyOrderSmallRender({order, customer, cancelShopifyOrder, refundShopifyOrder, actionStatus}) {
  
  const role = Parse.User.current().get("userRolePtr").attributes;
  
  let items = order && order.line_items;
  
  let refundAmount = 0;
  order && order.refunds && order.refunds.map(refund => {
    refund.transactions && refund.transactions.map(item => {
      refundAmount += Number(item.amount ? item.amount : 0) + Number(item.total_tax ? item.total_tax : 0); 
    })
  })


  return (
    <div className="d-flex flex-row align-items-center justify-content-start px-5 mt-5">
      <div className="d-flex flex-column justify-content-center align-items-left px-0 px-lg-0 w-100">
        <div className="d-flex flex-row align-items-center justify-content-between mt-2">
          <div className="d-flex flex-row align-items-center justify-content-start m-0">
            <i className="fas fa-truck text-success mr-2" />
            <span className="font-size=lg font-weight-bolder text-success">{dayjs(order.created_at).format("MMM, D YYYY")}</span>
          </div>
          <span className="label label-inline label-pill bg-success-o-40 text-dark">#{order.order_number}</span>
        </div>
        <div className="pb-2 rounded-lg rounded-left-0 bg-white border-left border-0 border-left-gray-500 text-dark font-size-base text-left">
          <div className="d-flex flex-column align-items-center justify-content-start mt-2">
            <div className="mt-4 w-100">
          
              <div className="d-flex flex-row align-items-center justify-content-start">
                <div className="font-weight-normal mr-3 text-dark w-150px w-lg-200px">Item</div>          
                <div className="font-weight-normal mr-3 text-dark w-50px">Qty</div>          
                <div className="font-weight-normal text-dark w-50px w-lg-100px text-right flex-grow-1">Price</div>                  
              </div>
              <div className="separator separator-solid separator-secondary mt-2" />

              {items && items.length > 0 && items.map((item, index) => (
                <div key={`${item.title}_${item.price}_${index}`} className="d-flex flex-row align-items-top justify-content-start mt-2">
                  <div className="w-150px w-lg-200px mr-3">
                    <div className="font-weight-normal text-dark font-size-base text-left">{item.title}</div>
                    {item.variant_title && <div className="font-weight-normal text-dark-50 font-size-xs text-left">{item.variant_title}</div>}
                  </div>
                  
                  <div className="w-50px mr-3 font-weight-normal text-dark text-left">{item.quantity}</div>
                  <div className="w-50px w-lg-100px font-weight-normal text-dark text-right flex-grow-1">{Number(item.price).toLocaleString('en-US',{style:'currency',currency: order.currency})}</div>                        
                </div>
              ))}

              
              <>
                <div className="separator separator-solid separator-secondary mt-2" />

                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="w-150px w-lg-200px mr-6 font-weight-normal text-dark-50 font-size-xs">Discount</div>
                  <div className="w-100px w-lg-100px font-weight-normal text-right text-dark-50 font-size-xs">{Number(order.total_discounts).toLocaleString('en-US',{style:'currency',currency: order.currency})}</div>     
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="w-150px w-lg-200px mr-6 font-weight-normal text-dark-50 font-size-xs">Subtotal</div>
                  <div className="w-100px w-lg-100px font-weight-normal text-right text-dark-50 font-size-xs">{Number(order.subtotal_price).toLocaleString('en-US',{style:'currency',currency: order.currency})}</div>     
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="w-150px w-lg-200px mr-6 font-weight-normal text-dark-50 font-size-xs">Tax, Shipping, Tips</div>
                  <div className="w-100px w-lg-100px font-weight-normal text-right text-dark-50 font-size-xs">{Number(Number(order.total_tax) + Number(order.total_shipping_price_set ? order.total_shipping_price_set.presentment_money.amount : 0) + Number(order.total_tip_received ? order.total_tip_received : 0)).toLocaleString('en-US',{style:'currency',currency: order.currency})}</div>     
                </div>

                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="w-150px w-lg-200px mr-6 font-weight-bolder text-dark">Total</div>
                  <div className="w-100px w-lg-100px font-weight-bolder text-right">{Number(order.total_price).toLocaleString('en-US',{style:'currency',currency: order.currency})}</div>     
                </div>
                
                <div className="separator separator-solid separator-secondary mt-2" />

                {(order.financial_status === "paid" || order.financial_status === "partially_paid") &&
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <div className="mr-6 font-weight-bold text-success">PAID</div>
                   <div className="font-weight-bolder text-right text-success">{Number(order.total_price).toLocaleString('en-US',{style:'currency',currency: order.currency})}</div>
                </div>
                }
                {(order.financial_status === "refunded" || order.financial_status === "partially_refunded") &&
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <div className="mr-6 font-weight-bold text-primary-d-3">REFUNDED</div>
                   <div className="font-weight-bolder text-right text-primary-d-3">{Number(refundAmount).toLocaleString('en-US',{style:'currency',currency: order.currency})}</div>
                </div>
                }
                {order.cancelled_at &&
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <div className="mr-6 font-weight-bold text-danger">CANCELED</div>
                   <div className="font-weight-bold text-right text-danger">{dayjs(order.cancelled_at).format("MMM D, YYYY")}</div>
                </div>
                }
              </>
              
              { items.length <= 0  &&
                <div className="font-weight-bold text-muted mt-2">No items</div>
              }

            </div>

          </div>
        </div>
        <div className="mb-2 d-flex justify-content-end flex-grow-1">
          <div className="text-dark-50 font-size-xs d-flex justify-content-start">{!order.fulfillment_status ? "Not Fulfilled" : order.fulfillment_status === "fulfilled" ? "Fulfilled" : order.fulfillment_status === "partial" ? "Partially Fulfilled" : ""}</div> 
          {/*<span className="font-size-xs text-dark ">Created {dayjs(order.created_at).format('MMM D, YYYY')}</span>                       */}
        </div>        
        <div className="d-flex flex-row align-items-center justify-content-end flex-grow-1 mt-1">

          {actionStatus === "saving" && <div className="d-flex flex-row align-items-center justify-content-center flex-grow-1"><div className="spinner spinner-warning" /></div>}
          {actionStatus !== "saving" && 
          <div className="d-flex flex-row align-items-center">
            <button className="btn btn-sm btn-transparent-success font-size-xs font-weight-bold px-2 mr-2" onClick={() => window.open(order.order_status_url)}>Status</button>

            {role.EDIT_SHOPIFY && !["partially_refunded", "refunded", "voided"].includes(order.financial_status) && !order.cancelled_at && (!order.fulfillments || (order.fulfillments && order.fulfillments.length < 1)) && <button className="btn btn-sm btn-transparent-success font-size-xs font-weight-bold px-2 mr-2" onClick={() => cancelShopifyOrder({shopifyShop: customer.get("shopifyShop"), orderId: order.id, customerId: customer.id})}>Cancel</button>}
            {role.EDIT_SHOPIFY && !["refunded", "voided"].includes(order.financial_status) && !order.cancelled_at && (!order.fulfillments || (order.fulfillments && order.fulfillments.length < 1)) && <button className="btn btn-sm btn-transparent-success font-size-xs font-weight-bold px-2 mr-2" onClick={() => refundShopifyOrder({shopifyShop: customer.get("shopifyShop"), orderId: order.id, customerId: customer.id})}>Refund</button>}
            <button className="btn btn-sm btn-transparent-success font-size-xs font-weight-bold px-2" onClick={() => window.open(`https://${customer && customer.get("shopifyShop")}/admin/orders/${order && order.id}`)}>Details</button>                    
          </div>
          }
        </div>
      </div>
    </div>
  )
}


export function PaymentSmallRender({payment, setShowWidgetPullout}) {

  let items = payment.get("lineItems");

  const getTotal = () => {
    let total = 0;

    for (var i = 0; i < items.length; i++) {
      total += items[i].price * items[i].qty;
    }

    return total;
  }

  return (
    <div className="d-flex flex-row align-items-center justify-content-start px-5 mt-5">
      <div className="d-flex flex-column justify-content-center align-items-left px-0 px-lg-0 w-100">
        <div className="d-flex flex-row align-items-center justify-content-between mt-2">
          <div className="d-flex flex-row align-items-center justify-content-start m-0">
            <i className="fas fa-dollar-sign text-success mr-2" />
            <span className="font-size=lg font-weight-bolder text-success">PAYMENT</span>
          </div>
          {payment.get("invoiceNumber") && <span className="label label-inline label-pill bg-success-o-40 text-dark">INV# {payment.get("invoiceNumber")}</span>}
        </div>
        <div className="pb-2 rounded-lg rounded-left-0 bg-white border-left border-0 border-left-gray-500 text-dark font-size-base text-left">
          <div className="d-flex flex-column align-items-center justify-content-start mt-2">
            <div className="mt-4 w-100">
          
              <div className="d-flex flex-row align-items-center justify-content-start">
                <div className="font-weight-normal mr-3 text-dark w-150px w-lg-200px">Item</div>          
                <div className="font-weight-normal mr-3 text-dark w-50px">Qty</div>          
                <div className="font-weight-normal text-dark w-50px w-lg-100px text-right flex-grow-1">Price</div>                  
              </div>
              <div className="separator separator-solid separator-secondary mt-2" />

              {items && items.length > 0 && items.map((item, index) => (
                <div key={`${item.name}_${item.price}_${index}`} className="d-flex flex-row align-items-top justify-content-start mt-2">
                  <div className="w-150px w-lg-200px mr-3 font-weight-normal text-dark font-size-base text-left">{item.name}</div>
                  <div className="w-50px mr-3 font-weight-normal text-dark text-left">{item.qty}</div>
                  <div className="w-50px w-lg-100px font-weight-normal text-dark text-right flex-grow-1">{Number(item.price).toLocaleString('en-US',{style:'currency',currency:'USD'})}</div>                        
                </div>
              ))}

              {getTotal() > 0 && 
              <>
                <div className="separator separator-solid separator-secondary mt-2" />

                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="w-150px w-lg-200px mr-6 font-weight-bolder text-dark">Total Due</div>
                  <div className="w-100px w-lg-100px font-weight-bolder text-right">{getTotal().toLocaleString('en-US',{style:'currency',currency:'USD'})}</div>     
                </div>
                {payment.get("paidAmount") &&
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <div className="mr-6 font-weight-bold text-success">PAID</div>
                   <div className="font-weight-bolder text-right text-success">{Number(payment.get("paidAmount")/100).toLocaleString('en-US',{style:'currency',currency:'USD'})}</div>
                </div>
                }
                {payment.get("refundAmount") &&
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <div className="mr-6 font-weight-bold text-primary-d-3">REFUNDED</div>
                   <div className="font-weight-bolder text-right text-primary-d-3">{Number(payment.get("refundAmount")/100).toLocaleString('en-US',{style:'currency',currency:'USD'})}</div>
                </div>
                }
                {payment.get("isCanceled") &&
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <div className="mr-6 font-weight-bold text-danger">CANCELED</div>
                   <div className="font-weight-bold text-right text-danger">{dayjs(payment.get("canceledOnDate")).format("MMM D, YYYY")}</div>
                </div>
                }
              </>
              }
              { (getTotal() <= 0 ) &&
                <div className="font-weight-bold text-muted mt-2">No items</div>
              }

            </div>

          </div>
        </div>        
        <div className="d-flex flex-row align-items-top justify-content-between mt-1">
          <div>
            <div className="text-dark-50 font-size-xs d-flex justify-content-start">#{payment.get("number")}</div> 
            <span className="font-size-xs text-dark ">Created {dayjs(payment.createdAt).format('MMM D, YYYY')}</span>                       
          </div>
          <button  className="btn btn-sm btn-transparent-success font-size-sm font-weight-bold px-2" 
                   onClick={()=> setShowWidgetPullout({type: "Payment Actions", widgetObj: payment, widgetObjType: "payment"})}
                   >Actions</button>                                  
        </div>
      </div>
    </div>
  )
}

export function TextopiaRealtimeDash({series, colors, format, colorsText}) {

  let options = {
              chart: {
                type: 'bar',
                //height: 'auto',
                width: '100%',
                stacked: true,
                toolbar: { show: false},
                parentHeightOffset: -20,
                offsetX: -7,
                //offsetY: -30,
                stacked: true,
                stackType: '100%',   
                dropShadow: {
                  enabled: false,
                  top:0,
                  left:0,
                  blur:3,
                  color:'#000',
                  opacity: 0.35
                }            
              },

              colors: colors,             
              plotOptions: {
                bar: {
                  horizontal: true,
                  barHeight: '100%',
                  //endingShape: "rounded",
                  dataLabels: {
                    position: 'center',
                    orientation: 'horizontal',
                  }
                },
              },
              stroke: {
                width: 1,
                colors: ['#fff']
              },
              xaxis: {
                lines: { show: false },
                labels: { show: false },
                axisBorder: { show: false },
                axisTicks: { show: false},
              },
              yaxis: {
                lines: { show: false},
                labels: { show: false },
                axisBorder: { show: false },
                axisTicks: {show: false},
              },
              fill: {
                opacity: 1                
              },
              legend: { 
                show: false,
                position: 'bottom',
                horizontalAlign: 'center',
                offsetY: -12,
                offsetX: 7,
                fontSize: '12px',

              },
              grid: { show: false },  
              dataLabels: {
                enabled: true,              
              }          
            }

  return (
    <div className="d-flex flex-column mx-auto">
      <Chart options={options} series={series} type="bar" height="90px" width='100%'/>
      <TextopiaDashLegend format={format} colorsText={colorsText} />
    </div>
  )
}

function TextopiaDashLegend({format, className, colorsText}) {
  
  return (
  <>
  {![Constants.QUESTION_FORMAT_SMILEYS, Constants.QUESTION_FORMAT_YES_NO, Constants.QUESTION_FORMAT_SCORE, Constants.QUESTION_FORMAT_STARS].includes(format) && ""}

  { format === Constants.QUESTION_FORMAT_SMILEYS &&
   <div className="d-flex flex-grow-1 justify-content-center"> 
    <div className="d-flex align-items-center flex-shrink-0 justify-content-middle mt-n2 bg-white rounded-pill py-1 px-2">
      <i className={`far fa-angry  circle-radius   text-${colorsText[0]} icon-2x mr-5 bg-white p-1`} />
      <i className={`far fa-frown  circle-radius   text-${colorsText[1]} icon-2x mr-5 bg-white p-1`} />
      <i className={`far fa-meh    circle-radius   text-${colorsText[2]} icon-2x mr-5 bg-white p-1`}  />
      <i className={`far fa-smile  circle-radius   text-${colorsText[3]} icon-2x mr-5 bg-white p-1`} />
      <i className={`far fa-laugh  circle-radius   text-${colorsText[4]} icon-2x bg-white p-1`} />
    </div>   
   </div>
  }
  { format === Constants.QUESTION_FORMAT_YES_NO && 
   <div className="d-flex flex-grow-1 justify-content-center"> 
    <div className="d-flex align-items-center flex-shrink-0 justify-content-middle mt-n2 bg-white rounded-pill py-1 px-2">
      <i className={`far fa-thumbs-down  circle-radius text-${colorsText[0]} icon-2x mr-5 bg-white p-1`} />
      <i className={`far fa-thumbs-up  circle-radius text-${colorsText[1]} icon-2x bg-white p-1`} />
    </div>
   </div>
  }
  { format === Constants.QUESTION_FORMAT_SCORE && 
   <div className="d-flex flex-grow-1 justify-content-center"> 
    <div className="d-flex align-items-center flex-shrink-0 justify-content-middle mt-n2 bg-white rounded-pill py-1 px-2">
      <span className="d-flex align-items-center text-dark-50 font-weight-bold font-size-sm mr-5"><i className={`fas fa-circle circle-radius text-${colorsText[0]} icon-lg bg-white p-1 mr-1`} />Detractors</span>
      <span className="d-flex align-items-center text-dark-50 font-weight-bold font-size-sm mr-5"><i className={`fas fa-circle circle-radius text-${colorsText[1]} icon-lg bg-white p-1 mr-1`} />Passives</span>
      <span className="d-flex align-items-center text-dark-50 font-weight-bold font-size-sm"     ><i className={`fas fa-circle circle-radius text-${colorsText[2]} icon-lg bg-white p-1 mr-1`} />Promoters</span>
    </div>
   </div>
  }
  { format === Constants.QUESTION_FORMAT_STARS &&
   <div className="d-flex flex-grow-1 justify-content-center"> 
    <div className="d-flex align-items-center flex-shrink-0 justify-content-middle mt-n2 bg-white rounded-pill py-1 px-5">
      <div className="d-flex flex-column align-items-center justify-content-middle"><i className={`fas fa-star  circle-radius         text-${colorsText[0]} icon-lg bg-white`} /><span className={`text-${colorsText[0]}  font-weight-bolder font-size-xs`}>1-Star</span></div>
      <div className="d-flex flex-column align-items-center justify-content-middle ml-7"><i className={`fas fa-star  circle-radius    text-${colorsText[1]} icon-lg bg-white`} /><span className={`text-${colorsText[1]}  font-weight-bolder font-size-xs`}>2-Star</span></div>
      <div className="d-flex flex-column align-items-center justify-content-middle ml-7"><i className={`fas fa-star  circle-radius    text-${colorsText[2]} icon-lg bg-white`}  /><span className={`text-${colorsText[2]}  font-weight-bolder font-size-xs`}>3-Star</span></div>
      <div className="d-flex flex-column align-items-center justify-content-middle ml-7"><i className={`fas fa-star  circle-radius    text-${colorsText[3]}  icon-lg bg-white`} /><span className={`text-${colorsText[3]}  font-weight-bolder font-size-xs`}>4-Star</span></div>
      <div className="d-flex flex-column align-items-center justify-content-middle ml-7"><i className={`fas fa-star  circle-radius    text-${colorsText[4]}  icon-lg bg-white`} /><span className={`text-${colorsText[4]}  font-weight-bolder font-size-xs`}>5-Star</span></div>
    </div>   
   </div>
  }

  </>
  )
}

export function TextopiaRealtimeDashSummary({title, value, color, desc}) {

  return (
    <div className="d-flex flex-shrink-0 mb-n5 mt-4">
      <div className={`d-flex flex-row flex-shrink-0 align-items-center mx-auto btn btn-${color} btn-text-white rounded-lg px-4 py-0 py-0 cursor-pointer`}>
        <span className="font-weight-bolder font-size-h4 mr-2">{value ? value : "N/A"}</span>
        <div className="d-flex flex-row align-items-center">
          <div className="font-size-lg font-weight-bold">{title}</div>
          <div className="font-size-base font-weight-bold ml-1 mr-1">|</div>                          
          <div className="font-size-xs">{desc ? desc : "30 DAY AVG"}</div>
        </div>
      </div>
    </div>
  )
}


export function TextopiaRealtimeDashWidget({value, format, series}) {

  let darkerSuccess   = "#107671";
  let darkSuccess     = "#159D97";
  let success         = "#1BC5BD";
  let lightSuccess    = "#48D0CA";
  let lighterSuccess  = "#76DCD7";

  let darkerDanger   = "#932E39";
  let darkDanger     = "#C43E4C";
  let danger         = "#F64E60";
  let lightDanger    = "#F7717F";
  let lighterDanger  = "#F9949F"; 
  
  const myColors = [ danger, lightDanger, "#7E8299", lightSuccess, success];        

  const myColorsText = ["danger", "danger-light", "gray-600", "success-light", "success"]; //-- these text values are used everywhere else

  //--> used by the actual horizontal bar, needs hex values
  let colors = format === Constants.QUESTION_FORMAT_STARS || 
               format === Constants.QUESTION_FORMAT_SMILEYS   ? myColors :
               format === Constants.QUESTION_FORMAT_SCORE     ? [myColors[0], myColors[2], myColors[4]] :
               format === Constants.QUESTION_FORMAT_YES_NO    ? [myColors[0], myColors[4]] :
               [] ;

  //--> used by the summary button at the top center of the horizontal bar, needs text values (from our template)
  let color =  format === Constants.QUESTION_FORMAT_STARS || 
               format === Constants.QUESTION_FORMAT_SMILEYS   ? (value >= 5 ? myColorsText[4] : value >= 4 ? myColorsText[3] : value >= 3 ? myColorsText[2] : value >= 2 ? myColorsText[1] : myColorsText[0]) :
               format === Constants.QUESTION_FORMAT_SCORE     ? (value >= 80 ? myColorsText[4] : value >= 60 ? myColorsText[3] : value >= 50 ? myColorsText[2] : value >= 40 ? myColorsText[1] : myColorsText[0]) :
               format === Constants.QUESTION_FORMAT_YES_NO    ? (value && value !== NaN && value.toString().slice(0, -1) >= 50 ? myColorsText[4] : myColorsText[0]) :
               "" ;

  //--> used by the legend at the bottom of the horizontal bar, needs text values (from our template)
  let colorsText = format === Constants.QUESTION_FORMAT_STARS || 
                 format === Constants.QUESTION_FORMAT_SMILEYS   ? myColorsText :
                 format === Constants.QUESTION_FORMAT_SCORE     ? [myColorsText[0], myColorsText[2], myColorsText[4]] :
                 format === Constants.QUESTION_FORMAT_YES_NO    ? [myColorsText[0], myColorsText[4]] :
                 [] ;
  
  let title =  format === Constants.QUESTION_FORMAT_STARS     ? "STARS" :
               format === Constants.QUESTION_FORMAT_SMILEYS   ? "SENTIMENT" :
               format === Constants.QUESTION_FORMAT_SCORE     ? "NPS" :
               format === Constants.QUESTION_FORMAT_YES_NO    ? "THUMBS UP" :
               "N/A" ;


  return (
    <div className="card rounded mb-lg-8 mb-5 px-0 py-0 w-100 mx-3 bg-light border border-secondary">
      <div className="card-body pb-lg-7 pb-5 pt-lg-2 pt-0 px-lg-4 mx-n1 px-0">

          <TextopiaRealtimeDashSummary title={title} value={value} color={color} />
          <TextopiaRealtimeDash format={format} series={series} colors={colors} colorsText={colorsText} />

      </div>
    </div>
  )
}

export function TextopiaRealtimePaymentsDash({dashData}) {

  return (
     <div className="card rounded w-100 px-5 mb-5 pb-lg-4 pb-3 pt-lg-2 pt-2 bg-light border border-secondary mx-2 mx-lg-0">
      <div className="card-body px-0 py-0 mx-0 my-0">

          <TextopiaRealtimeDashSummary color="success" title="Net" value={dashData && Number(dashData.net/100).toLocaleString('en-US',{style:'currency',currency: 'usd'})} desc="Last 30 Days" />

          <div className="d-flex align-items-center justify-content-center flex-grow-1 flex-wrap mt-7">
            <div className="d-flex flex-column align-items-center justify-content-center mr-10 mt-2">
              <span className="font-size-xs text-muted font-weight-bold">REQUESTED</span>
              <span className="font-size-base text-dark-75 font-weight-bolder">{dashData && Number(dashData.requested/100).toLocaleString('en-US',{style:'currency',currency: 'usd'})}</span>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center mr-10 mt-2">
              <span className="font-size-xs text-muted font-weight-bold">PAID</span>
              <span className="font-size-base text-success font-weight-bolder">{dashData && Number(dashData.paid/100).toLocaleString('en-US',{style:'currency',currency: 'usd'})}</span>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center mr-10 mt-2">
              <span className="font-size-xs text-muted font-weight-bold">MARKED PAID</span>
              <span className="font-size-base text-success font-weight-bolder">{dashData && Number(dashData.paidOutsideSystem/100).toLocaleString('en-US',{style:'currency',currency: 'usd'})}</span>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center mr-10 mt-2">
              <span className="font-size-xs text-muted font-weight-bold">CANCELED</span>
              <span className="font-size-base text-danger font-weight-bolder">{dashData && Number(dashData.canceled/100).toLocaleString('en-US',{style:'currency',currency: 'usd'})}</span>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center mt-2">
              <span className="font-size-xs text-muted font-weight-bold">REFUNDED</span>
              <span className="font-size-base text-primary-d-3 font-weight-bolder">{dashData && Number(dashData.refunded/100).toLocaleString('en-US',{style:'currency',currency: 'usd'})}</span>
            </div>
          </div>       

      </div>
    </div>
  )
}


export function TextopiaCustomerSymbol({colorify, index, customer, className}) {
  
  return (
  <div className={`${className ? className : ""}`}>
    <div className={`symbol symbol-circle symbol${colorify ? Textopia.getSequentialLSColor(index ? index : 0) : "-light"} ${colorify ? "" : "border border-secondary"} symbol-lg-40 symbol-30 mt-2`}>
        {customer && customer.get("profilePic") &&         
          <Image src={customer.get("profilePic").url()} className="w-auto h-auto"/>          
        }
        {customer && !customer.get("profilePic") && 
          <span className={`symbol-label ${colorify ? "" : "text-dark-75"} font-weight-bold font-size-h6`}>
            {customer && customer.get("firstName") === "Instagram" && customer.get("instagramAddress") &&
              <i className="socicon-instagram icon-xl" style={{color: '#e1306c'}}/>
            }
            {customer && (customer.get("firstName") != "Instagram") && (Textopia.getCustomerInitialsNoIcon(customer) === "" ? <span className="flaticon2-user icon-md" /> : <span className="font-size-h5 font-weight-bold">{Textopia.getCustomerInitialsNoIcon(customer)}</span>)}
          </span>
        }
    </div> 
  </div>
  )
}

export function TextopiaUserSymbol({user}) {

  return (
    <div className="symbol symbol-circle symbol-light-primary border border-secondary symbol-lg-40 symbol-30 ml-4 mt-2">
      
        {user && user.get("profilePic") && 
          <Image className="image-input-wrapper" src={user.get("profilePic").url()}/>
        }
        {user && !user.get("profilePic") && 
          <span className="symbol-label text-dark-75 font-weight-bold font-size-h6">            
            {user && Textopia.getInitials(user)}
           
          </span>
        }
        {!user && 
          <span className="symbol-label text-dark-75 font-weight-bold font-size-h6">            
            AI           
          </span>
        }
    </div>)
}

export function TextopiaInviteActivity({ data }) {
  const getColor = (data) => {
      var color = "light"
      if (data.isComplete === true) {
          color = "success";
      }
      else if (data.isLinkClicked === true) {
          color = "warning";
      }
      else {
          color = "light";
      }
      return color
  }

  return (
      <>
          <div className={`card overlay overlay-block card-custom mb-4 p-0 pl-6 pr-2 py-lg-6 py-6 mx-lg-0 mx-2 d-flex flex-grow-1 rounded-left-0 border-left border-left-${getColor(data)} border-4 border-top-0 border-right-0 border-bottom-0`}>
              <div className="card-body overlay-wrapper px-0 py-0 mx-0 my-0">
                  <div className="rounded border-0 border-secondary rounded-lg rounded-bottom-0 border-bottom-0">
                      <div className="d-flex justify-content-start align-items-center">

                          <div className="d-flex flex-row flex-grow-1 align-items-top justify-content-start flex-wrap">

                              <div className="w-lg-150px w-150px d-flex flex-column mt-4 mt-lg-0">
                                  <span className="text-muted font-size-xs font-weight-bold">CONTACT</span>
                                  <span className=" font-weight-bolder font-size-h6 text-dark text-capitalize">{data.name}</span>
                                  <span className=" font-size-sm font-weight-bold text-dark">{data.phone}</span>
                              </div>
                              <div className="w-lg-150px w-150px d-flex flex-column mt-4 mt-lg-0">
                                  <span className="text-muted font-size-xs font-weight-bold">SENT BY</span>
                                  <span className=" font-size-sm font-weight-bold text-dark">{data.sendBY}</span>
                              </div>
                              <div className="w-lg-150px w-150px d-flex flex-column  mt-4 mt-lg-0">
                                  <span className="text-muted font-size-xs font-weight-bold">SENT ON</span>
                                  <span className=" font-size-sm font-weight-bold text-dark">{data.timeSent}</span>
                              </div>
                              <div className="w-lg-150px w-150px d-flex flex-column mt-4 mt-lg-0">
                                  <span className="text-muted font-size-xs font-weight-bold">FOLLOWED LINK</span>
                                  {
                                      data.isLinkClicked &&
                                      <i className="fas fa-check pl-6 text-warning icon-md"></i>
                                  }
                                  {
                                      !data.isLinkClicked &&
                                      <i className="fas fa-check pl-6 text-dark-25 icon-md"></i>
                                  }
                              </div>
                              <div className="w-lg-150px w-150px d-flex flex-column mt-4 mt-lg-0">
                                  <span className="text-muted font-size-xs font-weight-bold">COMPLETE</span>
                                  {
                                      data.isComplete  &&
                                      <i className="fas fa-check pl-4 text-success icon-md"></i>
                                  }
                                  {
                                      !data.isComplete&&
                                      <i className="fas fa-check pl-4 text-dark-25 icon-md"></i>
                                  }
                              </div>

                          </div>

                      </div>
                  </div>
              </div>

          </div>
      </>
  )
}

export function TextopiaLeaderboard({ data,type }) {
  const getColor = (data) => {
    var color 
    if (data === 0) {
        color = "primary";
    }
    else if (data === 1) {
        color = "success";
    }
    else if (data === 2) {
        color = "danger";
    }
    else if (data === 3) {
      color = "warning";
    }
    else if (data ===4){
      color ="primary-d-3";
    }
    return color
  }

  return (
      <>
          <div className={`card overlay overlay-block card-custom mb-4 p-0 pl-6 pr-2 py-lg-6 py-6 mx-lg-0 mx-2 d-flex flex-grow-1 rounded-left-0 border-left border-left-${getColor(Math.floor(Math.random() *5))} border-4 border-top-0 border-right-0 border-bottom-0`}>
              <div className="card-body overlay-wrapper px-0 py-0 mx-0 my-0">
                  <div className="rounded border-0 border-secondary rounded-lg rounded-bottom-0 border-bottom-0">
                      <div className="d-flex justify-content-start align-items-center">

                          <div className="d-flex flex-row flex-grow-1 align-items-top justify-content-start flex-wrap">

                              <div className="w-lg-150px w-150px d-flex flex-column  mt-4 mt-lg-0 ml-lg-4 pb-lg-3">
                                  <span className="text-muted font-size-xs font-weight-bold">NAME</span>
                                  <span className="font-weight-bolder font-size-h6 text-dark text-capitalize">{data.name}</span>
                                  {type!=="location"&&<span className="font-size-sm font-weight-bold text-dark">{data.title}</span>}
                                  {type==="location"&&<span className="font-size-sm font-weight-bold text-dark">{data.address1}</span>}
                                  {type==="location"&&<span className="font-size-sm font-weight-bold text-dark">{data.address2}</span>}
                              </div>
                              <div className="w-lg-150px w-150px d-flex flex-column mt-4 ml-lg-4 mt-lg-0 pb-lg-3">
                                  <span className="text-muted font-size-xs font-weight-bold">RANK</span>
                                  <span className="font-size-sm font-weight-bold text-dark">{data.rank}</span>
                              </div>
                              {type==="location"&&<div className="w-lg-150px w-150px d-flex flex-column  mt-4 mt-lg-0  ml-lg-4 pb-lg-3">
                                  <span className="text-muted font-size-xs font-weight-bold">INBOUND LEADS</span>
                                  <span className="font-size-sm font-weight-bold text-dark">{data.totalInboundLeadMessageCount}</span>
                              </div>}
                              <div className="w-lg-150px w-150px d-flex flex-column mt-4 mt-lg-0 ml-lg-4 pb-lg-3">
                                  <span className="text-muted font-size-xs font-weight-bold">NUM CONVOS</span>
                                  <span className="font-size-sm font-weight-bold text-dark">{data.totalConvoCount}</span>
                              </div>
                              {type!=="location"&&<div className="w-lg-150px w-150px d-flex flex-column mt-4 ml-lg-4 mt-lg-0">
                                  <span className="text-muted font-size-xs font-weight-bold">OUTBOUND MSG</span>
                                  <span className="font-size-sm font-weight-bold text-dark">{data.totalOutboundMessageCount}</span>
                              </div>}
                              <div className="w-lg-150px w-150px d-flex flex-column mt-4 ml-lg-4 mt-lg-0">
                                  <span className="text-muted font-size-xs font-weight-bold">REVIEW INVITES</span>
                                  <span className="font-size-sm font-weight-bold text-dark">{data.totalReviewInviteCount}</span>
                              </div>
                              <div className="w-lg-150px w-150px d-flex flex-column mt-4 ml-lg-4 mt-lg-0">
                                  <span className="text-muted font-size-xs font-weight-bold">FEEDBACK INVITES</span>
                                  <span className="font-size-sm font-weight-bold text-dark">{data.totalFeedbackInviteCount}</span>
                              </div>
                              <div className="w-lg-150px w-150px d-flex flex-column mt-4 ml-lg-4 mt-lg-0">
                                  <span className="text-muted font-size-xs font-weight-bold">PAYMENT REQUESTS</span>
                                  <span className="font-size-sm font-weight-bold text-dark">{data.totalPaymentCount}</span>
                              </div>
                              <div className="w-lg-150px w-150px d-flex flex-column mt-4 ml-lg-4 mt-lg-0">
                                  <span className="text-muted font-size-xs font-weight-bold">TOTAL PROCESSED</span>
                                  <span className="font-size-sm font-weight-bold text-dark">{data.totalAmountProcessed}</span>
                              </div>
                              {type!=="location"&&<div className="w-lg-150px w-150px d-flex flex-column ml-lg-4 mt-4 mt-lg-0">
                                  <span className="text-muted font-size-xs font-weight-bold">TASKS COMPLETED</span>
                                  <span className="font-size-sm font-weight-bold text-dark">{data.totalCompletedTask}</span>
                              </div>}
                          </div>

                      </div>
                  </div>
              </div>

          </div>
      </>
  )
}

export function TextopiaChart({ chartData, chartType, monthToDate, last12Months, reportType }) {
  let randomKey = Math.floor(Math.random() * 5);
  return (
    <>
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className="card card-custom card-stretch">
            <div className="card-body p-4 p-lg-8">
              {/*<div className="row d-flex justify-content-end text-left">
                <div className="col-lg-4 col-sm-12 mb-lg-n40  pl-7 pl-lg-n2 border-bottom border-bottom-lg-0 pb-4 pb-lg-0">
                  <div>
                    <span className="font-size-h5">Month to Date</span>
                    <span className="font-weight-boldest font-size-h5 pl-2">
                   { reportType == ReportHelper.ReportType.customerResponseTime ||
                    reportType == ReportHelper.ReportType.inboundLead ||
              reportType == ReportHelper.ReportType.avgResponseTime ||
              reportType == ReportHelper.ReportType.totalResponseTime 
              ? ReportHelper.FormatCount(Math.round(monthToDate)) + ' ' + "Min" : ReportHelper.FormatCount(Math.round(monthToDate))
                   }
                    </span>
                  </div>
                  <div>
                    <span className="font-size-h5">Last 12 Month&nbsp;&nbsp;</span>
                    <span className="font-weight-boldest font-size-h5 pl-2">
                   { reportType == ReportHelper.ReportType.customerResponseTime ||
                    reportType == ReportHelper.ReportType.inboundLead ||
                    reportType == ReportHelper.ReportType.avgResponseTime ||
                    reportType == ReportHelper.ReportType.totalResponseTime 
                    ? ReportHelper.FormatCount(Math.round(last12Months)) + ' ' + "Min" : ReportHelper.FormatCount(Math.round(last12Months))
                   }
                    </span>
                  </div>
                </div>
                  </div>*/}
              <Chart
                key={chartType === "area" ? chartData.options.chart.id + randomKey : chartData.options.chart.id}
                options={chartData.options}
                series={chartData.series}
                type={chartType}
                width="100%"
                height={600}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const TextopiaReportDateRangeWidget = function ({ label, name, helpText, helpHTML, subfield, formik }) {
  return (
      <TextopiaCustomSingleSelect label={label} name={name} options={ReportHelper.reportBy} formik={formik} helpText={helpText} helpHTML={helpHTML} subfield={subfield} />
  )
}

export const TextopiaLocationReportWidget = function ({ label, name, helpText, helpHTML,type ,subfield,isLeaderBoard,formik }) {
  const [locations, setLocations] = useState([{ label: "No locations found", value: "" }]);
  useEffect(() => Textopia.loadCurrentUserLocationsForUI(setLocations), []);
  const [lslocations, setLsLocations] = useState([{ label: "No locations found", value: "" }]);
  useEffect(() =>setLsLocations(locations.map((data, key) => {return {label:data.label,subLabel:data.subLabel,value:"Location$"+data.value}})), [locations]);
  useEffect(() => {

    if(formik.values.location.length<1){
    formik.setFieldValue("location",[Parse.User.current() && "Location$" + Parse.User.current().get("primaryLocationPtr").id])
    }
}, [formik.values.location])
  
  return (
    <TextopiaCustomMultiSelect label={label} name={name} options={lslocations} formik={formik} helpText={helpText} helpHTML={helpHTML} subfield={subfield} isClearable={false} />
    )
}

export const TextopiaLocationLeaderBoardWidget = function ({ label, name, helpText, helpHTML,type ,subfield,isLeaderBoard,formik }) {
  const [locations, setLocations] = useState([{ label: "No locations found", value: "" }]);
  useEffect(() => Textopia.loadCurrentUserLocationsForUI(setLocations), []);
  const [lslocations, setLsLocations] = useState([{ label: "No locations found", value: "" }]);
  useEffect(() =>setLsLocations(locations.map((data, key) => {return {label:data.label,subLabel:data.subLabel,value:"Location$"+data.value}})), [locations]);

  useEffect(() => {

    if (formik.values.showAs==="employee" ) {

        formik.setFieldValue("location", Parse.User.current() && "Location$" + Parse.User.current().get("primaryLocationPtr").id)
    } else {
      formik.setFieldValue("location",[Parse.User.current() && "Location$" + Parse.User.current().get("primaryLocationPtr").id])
    }

}, [formik.values.showAs])

 useEffect(() => {
      if (formik.values.showAs==="location" ) 
      {
      if(formik.values.location.length<1){
      formik.setFieldValue("location",[Parse.User.current() && "Location$" + Parse.User.current().get("primaryLocationPtr").id])
      }
  }
}, [formik.values.location])
  return (
    <>
    {formik.values.showAs==="location" ?
    <TextopiaCustomMultiSelect label={label} name={name} options={lslocations} formik={formik} helpText={helpText} helpHTML={helpHTML} subfield={subfield} isClearable={false} />
    :
     <TextopiaCustomSingleSelect label={label} name={name} options={lslocations} formik={formik} helpText={helpText} helpHTML={helpHTML} subfield={subfield} isClearable={false} />
    }
    </>  )
}

export const TextopiaReportGroupWidget = function ({ label, name, helpText, helpHTML, subfield, formik }) {
  let reportBy = formik.values.dateRange;
  const dateRange = ReportHelper.getDateRange(reportBy);
  let filterTtypeDaysSelection = ReportHelper.typeDaysSelection;
  var fromdt = dayjs(dateRange.fromDate);
  var todt = dayjs(dateRange.toDate);
  let difference = todt.diff(fromdt, 'days')
  if (difference > 31) {
      filterTtypeDaysSelection = filterTtypeDaysSelection.filter(t => t.position > 0);
  }else{
    filterTtypeDaysSelection = filterTtypeDaysSelection.filter(t => t.position === 0);
  }
  useEffect(() => {

      if (difference > 31 ) {

          formik.setFieldValue("groupBy", "week")
      } else {
          formik.setFieldValue("groupBy", "day")
      }

  }, [formik.values.dateRange])

  return (
      <TextopiaCustomSingleSelect label={label} name={name} options={filterTtypeDaysSelection} formik={formik} helpText={helpText} helpHTML={helpHTML} subfield={subfield} />
  )
}

export const TextopiaLeaderboardShowAs = function ({ formik }) {
  return (
    <TextopiaRadioList label="Show As" formik={formik}>
    <TextopiaRadioListItem className="mt-1" name="showAs" value="employee" formik={formik} label="Employees Wise"  color="primary" />
    <TextopiaRadioListItem className="mt-1" name="showAs" value="location" formik={formik} label="Locations Wise"  color="primary" />
      </TextopiaRadioList>  )
}

export const TextopiaRatingStars = function ({ totalStars, color, numStars }) {


  const getWithoutHalfStar = (selectedStars, color, totalStars) => {

    return [...Array(totalStars)].map((el, i) =>
      i < selectedStars ? (
        <i key={i} className={`fas fa-star icon-nm text-${color==="basedOnRating"?ReportHelper.getStarColor(selectedStars):color} pl-1`} />
      ) : (
          <i key={i} className={`far fa-star icon-nm text-${color==="basedOnRating"?ReportHelper.getStarColor(selectedStars):color} pl-1`} />
        )
    );
  };

  const getWithHalfStar = (selectedStars, color, totalStars) => {
    // implement the code for full, empty and half stars here.
    return [...Array(totalStars)].map((el, i) =>
      // check if current star should be half
      i < selectedStars && i + 1 > selectedStars ? (
        (selectedStars % 1) < 0.2 ? (
          <i key={i} className={`far fa-star icon-nm text-${color==="basedOnRating"?ReportHelper.getStarColor(selectedStars):color} pl-1`} />
        ) :
          (selectedStars % 1) > 0.7 ? (
            <i key={i} className={`fas fa-star icon-nm text-${color==="basedOnRating"?ReportHelper.getStarColor(selectedStars):color} pl-1`} />
          ) :
            (<i key={i} className={`fas fa-star-half-alt icon-nm text-${color==="basedOnRating"?ReportHelper.getStarColor(selectedStars):color} pl-1`} />)
      ) : // check if current star should be full
        i < selectedStars ? (
          <i key={i} className={`fas fa-star icon-nm text-${color==="basedOnRating"?ReportHelper.getStarColor(selectedStars):color} pl-1`} />
        ) : (
            // else, current star should be empty
            <i key={i} className={`far fa-star icon-nm text-${color==="basedOnRating"?ReportHelper.getStarColor(selectedStars):color} pl-1`} />
          )
    );
  };

  return <span className="text-dark font-size-base">{
    Number.isInteger(numStars)
      ? getWithoutHalfStar(numStars, color, totalStars)
      : getWithHalfStar(numStars, color, totalStars)}</span>
} 

export function TextopiaCsv({exportCsv}) {
  let history = useHistory();
  const role = Parse.User.current().get("userRolePtr").attributes;
  let exportCsvContent;
  if (exportCsv) {
    exportCsvContent = exportCsv.map( (item, index) => (
      item && role[item.permission] && (
        <div className={`${index > 0 ? "ml-lg-3 ml-2" : ""}`}  key={`subheader_button_${index}`}>
          <TextopiaButton text={item.label}
                         textColor={item.textColor}
                         icon={item.icon}
                         iconColor={item.iconColor} 
                         onClick={item.onClick} 
                         disabled={item.disabled} />
        </div>
      )
    ))
  }

  return (
    <CSVLink data={exportCsv[0].csvData}  filename={exportCsv[0].csvFileName} >  
       {exportCsvContent}
    </CSVLink>
      )
}


export function RatingCustomProgress({ratingList}) {

return(ratingList&&ratingList.map((data, key) => {
  return <div key={key} className="d-flex">
       <span className={`font-size-sm text-dark-75 pr-${data.typeStar===1 ? 3 : 2}`}>{data.typeStar} Star</span>
       <OverlayTrigger
           placement="bottom"
           overlay={
             <Tooltip id="tooltip-bottom">
             {data.typeStar}  Star: <strong>{data.value}</strong>
           </Tooltip>
           }
         >
         <div className="progress rounded-0 mt-1 w-lg-200px w-150px h-10px">
             <div className={`progress-bar bg-${ReportHelper.getStarColor(data.typeStar)}`}
               //style={{width: data.value/Math.max.apply(Math, ratingList.map(function(d) { return d.value; }))*100+ '%'}}
              style={{width: data.value/ratingList.reduce((sum, current) => sum + current.value, 0)*100+ '%'}}
               role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
         </div>
         </OverlayTrigger>
           <span className="font-size-sm text-dark-75 pl-2  w-lg-50px">{data.value}</span>
   </div>
    }))
    }