import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";
import * as Queries from "../../../../../_metronic/_assets/js/textopia/TextopiaQueries";
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";
import {KTUtil} from "../../../../../_metronic/_assets/js/components/util";


const initialState = {

  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,

  sites: [],
  updatedAt: new Date(),

};


export const toggleSite = createAsyncThunk('sites/toggleSite', async (id) => {
  let cloudResponse = await Parse.Cloud.run("toggleLocationReviewSite", {siteId: id});
  return cloudResponse;
})

export const fetchSites = createAsyncThunk('sites/fetchSites', async () => {  
  let cloudResponse = await Parse.Cloud.run("fetchLocationReviewSites");
  return cloudResponse;
})

export const saveSite = createAsyncThunk('sites/saveSite', async (values) => {
  if (values && values.reviewSitePtr) {
    values.siteId = values.reviewSitePtr.id; //--> confirming to cloud requirements and minimizing data transfer
    values.reviewSitePtr = null;
  } 
  let cloudResponse = await Parse.Cloud.run("saveLocationReviewSite", {values: values});
  return cloudResponse; 
})

export const deleteSite = createAsyncThunk('sites/deleteSite', async (id) => {  
  let cloudResponse = await Parse.Cloud.run("deleteLocationReviewSite", {siteId: id});
  return cloudResponse;
})

//--> used by LQ
export const updateSite = createAsyncThunk('sites/updateSite', async (values) => {  
  return values;    
})

const entityType = "sites";

export const sitesSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
      // All sites for a location
      [fetchSites.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchSites.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state[entityType] = action.payload //Query returns an array of objects
        state.updatedAt = new Date();
      },
      [fetchSites.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },

      // Edit site
      [saveSite.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveSite.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'

        if(!state[entityType].find(site => site.id == action.payload.id)) {
          state[entityType].push(action.payload);
        }
        state.updatedAt = new Date();
      },
      [saveSite.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // update site //--> used by LQ
      [updateSite.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updateSite.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'

        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
          // nothing
          state.updatedAt = new Date();
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          //console.log('new location review site');
          //--> check for duplicate before adding - this is for snappy local UI but to avoid duplicates
          if(!state[entityType].find(site => site.id == action.payload.obj.id)) {
            state[entityType].unshift(action.payload.obj); // Add the new one to the list of topics
          }  
          state.updatedAt = new Date();        
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {
          //console.log('location review site removed');          
          state.sites = state.sites && state.sites.filter(o => o.id !== action.payload.obj.id);
          state.updatedAt = new Date();
        }

      },
      [updateSite.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },


      // Toggle site
      [toggleSite.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [toggleSite.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [toggleSite.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Delete site
      [deleteSite.pending]: (state, action) => {
        state.actionStatus = 'deleting'
      },
      [deleteSite.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded';

        // Remove the deleted entity
        state[entityType] = state[entityType].filter(o => o.id !== action.payload.id);
        state.updatedAt = new Date();
      },
      [deleteSite.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      }


    },




    reducers: {


  }
});

export default sitesSlice.reducer
