import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { useParams, Route } from "react-router-dom";
import {ContentRoute} from "../../../_metronic/layout"
import Parse from "parse";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Form } from "react-bootstrap";

import { TextopiaSingleSelect } from "../../../_metronic/_assets/js/textopia/TextopiaControls";

import 'react-animation/dist/keyframes.css';
import { animations } from 'react-animation';
import { AnimateOnChange } from 'react-animation';

import * as dayjs from "dayjs";

export function FeedbackApp({applink, settings, setError}) {

    const constants = {
        FORMAT_STARS:               "Stars",
        FORMAT_CHOICES:             "Choices",
        FORMAT_MULTIPLE_CHOICES:    "Multiple Choices",
        FORMAT_WRITE_IN:            "Write-In",
        FORMAT_YES_NO:              "Yes/No",
        FORMAT_SCORE:               "Score",
        FORMAT_SMILEYS:             "Smileys",

    }

    const [app, setApp] = useState('');
    const [questions, setQuestions] = useState('');
    const [questionNum, setQuestionNum] = useState('');
    const [saving, setSaving] = useState(false);

    const lookupApp = async () => {
        if (applink) {
            let q = new Parse.Query("FeedbackAppInstance");
            q.equalTo("objectId", applink.get("feedbackAppInstancePtr").id);
            q.include("locationPtr");
            q.include("tenantPtr");

            let feedbackAppInstance = await q.first();
            if (feedbackAppInstance && !feedbackAppInstance.get("isLinkClicked") && !feedbackAppInstance.get("linkClickedOnDate")) {
                feedbackAppInstance.set("isLinkClicked", true);
                feedbackAppInstance.set("linkClickedOnDate", new Date());
                await feedbackAppInstance.save();
            }
            //console.log(feedbackAppInstance);
            setApp(feedbackAppInstance);

            if (feedbackAppInstance && !feedbackAppInstance.get("isComplete")) {
                let q2 = new Parse.Query("FeedbackQuestion");
                q2.equalTo("tenantPtr", feedbackAppInstance.get("tenantPtr"));
                //q2.containsAll("locationList", [ feedbackAppInstance.get("locationPtr") ]); //--> the lookup list has to be an array, remember! //--> not restricting by location
                q2.equalTo("isActive", true);
                q2.ascending("positionInList");

                let questions = await q2.find();
                if (questions) { //--> figure out if this feedback was partially answered before and start from the right question
                    setQuestions(questions);

                    if (feedbackAppInstance.get("questions") && feedbackAppInstance.get("questions").length > 0) {
                        setQuestionNum(feedbackAppInstance.get("questions").length + 1); //--> starting from the next question in the survey
                    }
                    else {
                        setQuestionNum(1); //--> starting from the beginning of the survey
                    }
                }
                else {
                    setError(true); //--> no questions found error, feedback invite sent without questions being setup or active
                }
            }
            else if (!feedbackAppInstance) { //--> some sort of error so that we cannot find the app instance
                setError(true);
            }
            else {
                setQuestionNum(-2); //--> already completed survey, so forward to thankyou message
            }
        }
    }
    useEffect(() => {
        lookupApp()
    }, [applink]);

    const handleClick = (question, num) => {

    }

    const handleSubmit = async (values, actions) => {       
        setSaving(true);
        
        //--> update the customer record to indicate this as the most recent activity in that convo
        let customer = app.get("customerPtr");
        if (!app.get("isFeedbackSubmitted") && customer) { //--> create these records only for the first question answered
            customer.set("lastConvoEntryType", "MESSAGE");
            let message = new (Parse.Object.extend("Message"))();
            message.set("tenantPtr", app.get("tenantPtr"));
            message.set("customerPtr", customer);
            message.set("locationPtr", app.get("locationPtr"));
            message.set("body", `Feedback submitted for request #${app.get("number")}`);
            message.set("isInbound", true);
            message.set("isOutbound", false);  
            message.set("channelType", "NOTE");          
            message = await message.save();
            
            customer.set("lastConvoEntryMessagePtr", message);
            customer.set("lastConvoEntryReadBy", []); //--> reset the read list since this is inbound customer activity
            customer.set("lastConvoEntryFeedbackPtr", null);
            customer.set("lastConvoEntryTaskPtr", null);
            customer.set("lastConvoEntryReviewPtr", null);
            customer.set("lastConvoEntryPaymentPtr", null);
            customer.set("lastConvoEntrySystemNotePtr", null);
            customer.set("lastConvoEntryCreatedAtDate", new Date());
            await customer.save();
        }
        
        //--> indicate that a feedback has been submitted, even if not complete yet
        app.set("isFeedbackSubmitted", true);
        app.set("feedbackSubmittedOnDate", new Date());

        let ques = app.get("questions") || [];
        ques.push({ id: values.question.id,
                    question: values.question,
                    positionInList: values.questionNum,
                    format: values.question.get("format"),
                    body: values.question.get("body"),
                    response: values.val
                 })
        
        app.set("questions", ques);
        if (questions && (values.questionNum >= questions.length)) {
            app.set("isComplete", true);
        }

        //--> create an entry for this question in the FeebackQuestionResponse table for per-question dashboard
        let newQues = new (Parse.Object.extend("FeedbackQuestionResponse"))();
        newQues.set("tenantPtr", app.get("tenantPtr"));
        newQues.set("locationPtr", app.get("locationPtr"));
        newQues.set("customerPtr", app.get("customerPtr"));
        newQues.set("feedbackAppInstancePtr", app);
        newQues.set("feedbackQuestionPtr", values.question);
        newQues.set("format", values.question.get("format"));
        newQues.set("body", values.question.get("body"));
        newQues.set("response", values.val.toString());
        newQues.set("submittedOnDate", new Date());
        await newQues.save();

        

        //--> calculate overall feedback sentiment after everyone question answered, keeping a running tally
        let nps = 0;
        let divisor = 0;
        let sentiment = "";

        for (var i = 0; i < ques.length; i++) {
            if (ques[i].format === constants.FORMAT_YES_NO) {
                if (ques[i].response === "Yes") nps++;
                divisor++;
            }
            if (ques[i].format === constants.FORMAT_STARS) {
                if (ques[i].response >= 4) nps++;              
                else if (ques[i].response >= 3) nps = nps + 0.5;  
                divisor++;
            }
            if (ques[i].format === constants.FORMAT_SCORE) {
                if (ques[i].response >= 8) nps++;
                else if (ques[i].response >= 6) nps = nps + 0.5;
                divisor++;
            }            
        }

        if (divisor > 0) {
            nps = nps / divisor;
            //console.log(nps);
            if (nps >= 0.8) sentiment = "Positive";
            else if (nps >= 0.6) sentiment = "Leaning Positive";
            else if (nps >= 0.5) sentiment = "Neutral";
            else if (nps >= 0.4) sentiment = "Leaning Negative";
            else if (nps < 0.4) sentiment = "Negative";

            app.set("sentiment", {text: sentiment, nps: nps, percent: Math.round(nps * Math.pow(10, 2)) / Math.pow(10, 2)}); //--> round to 2 places
        }

        await app.save();        
        

        //--> update question stats
        setStats(values)

        //--> save the question
        await values.question.save();        

        setAnimVal({animation: animations.popOut});
        setTimeout(() => {
            setSaving(false);
            setAnimVal({animation: animations.bounceIn});
            setQuestionNum(questions && questions.length > values.questionNum ? values.questionNum + 1 : -1);
        }, 300)

    }

    function setStats(values) {
        //console.log(values);

        values.question.set("numResponses", values.question.get("numResponses") ? values.question.get("numResponses") + 1 : 1);

        let type = "";
        if (values.question.get("format") === constants.FORMAT_YES_NO) type = "yesNoResponses";
        else if (values.question.get("format") === constants.FORMAT_CHOICES) type = "choicesResponses";
        else if (values.question.get("format") === constants.FORMAT_MULTIPLE_CHOICES) type = "choicesResponses";
        else if (values.question.get("format") === constants.FORMAT_STARS) type = "starsResponses";
        else if (values.question.get("format") === constants.FORMAT_SMILEYS) type = "starsResponses";
        else if (values.question.get("format") === constants.FORMAT_SCORE) type="starsResponses";

        let myChoices = values.question.get(type) || {}; //--> get the current value of this field from the question

        if (Array.isArray(values.val)) { //--> for mult of mult we get an array back of selected values
            values.val.map(item => {
                if (myChoices[item]) myChoices[item]++;
                else myChoices[item] = 1;
            })    
        }
        //--> for all other types, we will get a single value back
        else if (myChoices[values.val]) myChoices[values.val]++; //--> found an entry, just increment       
        else myChoices[values.val] = 1; //--> did not find entry, so start now
        
        values.question.set(type, myChoices);
        myChoices && values.question.set("mostCommonResponse", Object.keys(myChoices).reduce((a, b) => myChoices[a] > myChoices[b] ? a : b)); //--> set most common response
        
        (type != "choicesResponses") && myChoices && values.question.set("averageScore", getAverageStars(myChoices, values.question.get("numResponses")));
    }

    function getAverageStars(data, num) {
        let sum = 0;
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                sum += data[key] * parseInt(key === "Yes" ? 1 : key === "No" ? 0 : key); //--> key is a string but hold the stars number, for example "3" (translate for yes/no questions)
            }
        }
        let final = sum / num;
        return  Math.round(final * Math.pow(10, 2)) / Math.pow(10, 2); //--> round to 2 places
    }

    const [animVal, setAnimVal] = useState({animation: animations.bounceIn});

    var relativeTime = require('dayjs/plugin/relativeTime');
    dayjs.extend(relativeTime);

    let logo = settings && settings.get("logo");
    //console.log(logo);
    let bgColor = settings && settings.get("branding") && settings.get("branding").bgColor ? settings.get("branding").bgColor : "#663399";
    let textColor = settings && settings.get("branding") && settings.get("branding").textColor ? settings.get("branding").textColor : "#FFFFFF";
    let activeColor = settings && settings.get("branding") && settings.get("branding").activeColor ? settings.get("branding").activeColor : "#FFA800";
    let inactiveColor = settings && settings.get("branding") && settings.get("branding").inactiveColor ? settings.get("branding").inactiveColor : "#FFFFFF";
    let whiteLogoBg = settings && settings.get("branding") && settings.get("branding").whiteLogoBg ? "#FFFFFF" : bgColor;

    let colors = {bgColor, textColor, activeColor, inactiveColor, whiteLogoBg, white: "#FFFFFF", black: "#000000"};



    let messaging = settings && settings.get("messaging");    

    let intro = messaging && messaging.intro ? messaging.intro : "";

    let format = messaging && messaging.format ? messaging.format : "";
    let allThanks = messaging && messaging.allThanks ? messaging.allThanks : "";
    let allThanksLink = messaging && messaging.allThanksLink ? messaging.allThanksLink : "";
    let allThanksLinkText = messaging && messaging.allThanksLinkText ? messaging.allThanksLinkText : "";

    let posThanks = messaging && messaging.posThanks ? messaging.posThanks : "";
    let posThanksLink = messaging && messaging.posThanksLink ? messaging.posThanksLink : "";
    let posThanksLinkText = messaging && messaging.posThanksLinkText ? messaging.posThanksLinkText : "";

    let passThanks = messaging && messaging.passThanks ? messaging.passThanks : "";
    let passThanksLink = messaging && messaging.passThanksLink ? messaging.passThanksLink : "";
    let passThanksLinkText = messaging && messaging.passThanksLinkText ? messaging.passThanksLinkText : "";

    let negThanks = messaging && messaging.negThanks ? messaging.negThanks : "";
    let negThanksLink = messaging && messaging.negThanksLink ? messaging.negThanksLink : "";
    let negThanksLinkText = messaging && messaging.negThanksLinkText ? messaging.negThanksLinkText : "";


    const [hoverState, setHoverState] = useState(false);
    const toggleHover = () => setHoverState(!hoverState); 


    return (
    <>
        
        <div id="app-container" style={{backgroundColor: bgColor, border: "3px solid " + bgColor}}>              
                
            <div id="app-header" >
                  <div className="app-header-content pt-5">
                    <div className="font-size-h5 font-weight-light" style={{color: textColor}}>Thanks for choosing</div> 

                    { (!logo || logo === "") && <div className="font-size-h2 font-weight-bold" style={{color: textColor}}>{applink && applink.get("locationPtr") && applink.get("locationPtr").get("name")}</div>}

                    { logo && <div className={`py-2 justify-content-center align-items-center mt-1`} style={{backgroundColor: whiteLogoBg}}><img src={logo._url} style={{maxHeight: "65px", maxWidth: "100%"}} /></div>}
                    
                    { intro && <div className="px-5 py-2 text-white justify-content-center align-items-center font-size-h5 font-weight-normal" style={{display: !questions ? "none" : "flex", animation: questionNum != 1 && animations.popOut}}>{intro}</div>}
                  </div>                    
            </div>

            <div id="main-content" className="px-8 px-lg-15">                    

                    <div className="text-white font-size-h1 font-weight-bold text-center">
                        {!questions && !(app && app.get("isComplete")) && <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-center"><div className="spinner spinner-white spinner-lg" /></div>}

                        {questions && questionNum > 0 && questionNum < questions.length + 1 && (

                            <Formik
                                initialValues = {{
                                                    questionNum: questionNum,
                                                    question: questions[questionNum - 1],
                                                    val: '',
                                                }}
                                onSubmit={handleSubmit}
                                enableReinitialize={true}
                              >
                              { formik => (

                              <Form noValidate onSubmit={formik.handleSubmit} onReset={formik.handleReset}>

                                {questions[questionNum - 1] && (
                                <div style={animVal}>
                                    <div className="font-size-h2 c_fadeIn mb-10" style={{color: colors.textColor}} >{questions[questionNum - 1].get("body")}</div>

                                    {questions[questionNum - 1].get("format") === constants.FORMAT_YES_NO && (
                                        <YesNoWidget name="val" formik={formik} colors={colors} />
                                    )}
                                    {questions[questionNum - 1].get("format") === constants.FORMAT_STARS && (
                                        <StarsWidget name="val" formik={formik} colors={colors} />
                                    )}
                                    {questions[questionNum - 1].get("format") === constants.FORMAT_WRITE_IN && (
                                        <WriteInWidget name="val" formik={formik} colors={settings && settings.get("branding") ? colors : undefined}  />
                                    )}
                                    {questions[questionNum - 1].get("format") === constants.FORMAT_CHOICES && (
                                        <ChoicesWidget name="val" formik={formik} options={questions[questionNum - 1].get("multipleChoices")} colors={settings && settings.get("branding") ? colors : undefined} />
                                    )}
                                    {questions[questionNum - 1].get("format") === constants.FORMAT_MULTIPLE_CHOICES && (
                                        <MultipleChoicesWidget name="val" formik={formik} options={questions[questionNum - 1].get("multipleChoices")} colors={settings && settings.get("branding") ? colors : undefined} />
                                    )}
                                    {questions[questionNum - 1].get("format") === constants.FORMAT_SCORE && (
                                        <ScoreWidget name="val" formik={formik} colors={colors} />
                                    )}
                                    {questions[questionNum - 1].get("format") === constants.FORMAT_SMILEYS && (
                                        <SmileysWidget name="val" formik={formik} colors={colors} />
                                    )}

                                    <button type="button"
                                            className={`btn btn-icon font-weight-bold font-size-h6 mt-25 c_fadeIn`}
                                            onClick={() => formik.handleSubmit()}
                                            disabled={formik.values.val === "" || (formik.values.val && formik.values.val.length < 1)}
                                            style={{border: "1px solid " + textColor, borderRadius: "50px", backgroundColor: (hoverState || saving) ? colors.textColor : bgColor}}
                                            onMouseEnter={!(formik.values.val === "" || (formik.values.val && formik.values.val.length < 1)) ? toggleHover : undefined}                                         
                                            onMouseLeave={!(formik.values.val === "" || (formik.values.val && formik.values.val.length < 1)) ? toggleHover : undefined} 
                                            >

                                            <i className={`${!saving && "flaticon2-right-arrow"} icon-lg ${saving && "flaticon2-reload fa-spin"}`} style={{color: (hoverState || saving ) ? colors.bgColor  : textColor}}/>

                                    </button>

                                </div>
                                )}
                                

                              </Form>

                              )}
                           </Formik>

                        )}

                        {questionNum < 0 && ( //--> all answered or user returned to this link after completion sets questionNum to -1
                            <div style={{animation: animations.bounceIn}}>
                                <div className="font-size-h2 font-weight-bolder" style={{color: textColor}}>Thank you!</div>
                                <div className="font-size-h4 font-weight-bolder" style={{color: textColor}}>We appreciate your feedback</div>
                                {questionNum < -1 && app && app.get("isComplete") && app.get("feedbackSubmittedOnDate") && <div className="font-size-base font-weight-light mt-1" style={{color: textColor}}>You sent feedback {dayjs(app.get("feedbackSubmittedOnDate")).fromNow()}</div>  }       

                                {format && (format === "Same") && (
                                <div className="border-0 border-white rounded shadow-lg px-7 py-10 mt-15 mx-n3">
                                    <div className="font-size-h5 font-weight-normal ">{allThanks}</div>
                                    {allThanksLink && allThanksLinkText && <a href={allThanksLink} className="btn btn-sm btn-white shadow-xs mt-5 font-size-h5 font-weight-bold" style={{color: bgColor}}>{allThanksLinkText}</a>}
                                </div>
                                )}
                                                                
                                {format && (format === "Different") && app && app.get("sentiment") && (       
                                    app.get("sentiment").nps > 0.7 ? 
                                    <div className="border-0 border-white rounded shadow-lg px-7 py-10 mt-15 mx-n3">
                                        <div className="font-size-h5 font-weight-normal ">{posThanks}</div>
                                        {posThanksLink && posThanksLinkText && <a href={posThanksLink} className="btn btn-sm btn-white shadow-xs mt-5 font-size-h5 font-weight-bold" style={{color: bgColor}}>{posThanksLinkText}</a>}
                                    </div> 
                                    : 
                                    app.get("sentiment").nps > 0.4 ? 
                                    <div className="border-0 border-white rounded shadow-lg px-7 py-10 mt-15 mx-n3">
                                        <div className="font-size-h5 font-weight-normal ">{passThanks}</div>
                                        {passThanksLink && passThanksLinkText && <a href={passThanksLink} className="btn btn-sm btn-white shadow-xs mt-5 font-size-h5 font-weight-bold" style={{color: bgColor}}>{passThanksLinkText}</a>}
                                    </div> 
                                    : 
                                    <div className="border-0 border-white rounded shadow-lg px-7 py-10 mt-15 mx-n3">
                                        <div className="font-size-h5 font-weight-normal ">{negThanks}</div>
                                        {negThanksLink && negThanksLinkText && <a href={negThanksLink} className="btn btn-sm btn-white shadow-xs mt-5 font-size-h5 font-weight-bold" style={{color: bgColor}}>{negThanksLinkText}</a>}
                                    </div> 
                                )}
                                 
                                
                            </div>
                        )}
                    </div>

            </div>

            <div id="app-footer">
                <div className="app-footer-content pb-5">
                    <div className="font-size-h5 font-weight-light mb-1 text-white app-logo">Powered By</div>
                    <img src="/media/Textopia/Logos/logo_white.svg" className="w-lg-125px w-100px app-logo" />
                </div>
            </div>

      </div>

    </>
    )
}

function ColorLuminance(hex, lum) {

	// validate hex string
	hex = String(hex).replace(/[^0-9a-f]/gi, '');
	if (hex.length < 6) {
		hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
	}
	lum = lum || 0;

	// convert to decimal and change luminosity
	var rgb = "#", c, i;
	for (i = 0; i < 3; i++) {
		c = parseInt(hex.substr(i*2,2), 16);
		c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
		rgb += ("00"+c).substr(c.length);
	}

	return rgb;
}

function YesNoWidget ({formik, name, colors}) {

    const [hoverStateYes, setHoverStateYes] = useState(false);
    const [hoverStateNo, setHoverStateNo] = useState(false);
    const toggleHoverYes = () => setHoverStateYes(!hoverStateYes);
    const toggleHoverNo = () => setHoverStateNo(!hoverStateNo);

    const setVal = (value, formik) => {
        formik && formik.setFieldValue(name, value);
    }

    return (
        <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-center">
            <span className={`btn shadow-xs ${formik.values[name] === "No" ? "btn-shadow" : ""} btn-xs py-8 px-8 btn-icon mr-10 border border-2 border-white`} 
                  onClick={() => setVal('No', formik)}
                  onMouseEnter={toggleHoverNo} 
                  onMouseLeave={toggleHoverNo} 
                  style={{  backgroundColor: hoverStateNo ? colors.activeColor : (formik && formik.values[name] === "No") ? colors.activeColor: colors.textColor }} 
                  >
                    <i className="la la-thumbs-down icon-3x" style={{color: hoverStateNo ? colors.white : (formik && formik.values[name] === "No") ? colors.white: colors.bgColor}} />
            </span>
            <span className={`btn shadow-xs ${formik.values[name] === "Yes" ? "btn-shadow" : ""} btn-xs py-8 px-8 btn-icon border border-2 border-white`} 
                  onClick={() => setVal('Yes', formik)}
                  onMouseEnter={toggleHoverYes} 
                  onMouseLeave={toggleHoverYes} 
                  style={{  backgroundColor: hoverStateYes ? colors.activeColor : (formik && formik.values[name] === "Yes") ? colors.activeColor: colors.textColor }} 
                  >
                    <i className="la la-thumbs-up icon-3x" style={{color: hoverStateYes ? colors.white : (formik && formik.values[name] === "Yes") ? colors.white: colors.bgColor}} />
            </span>            
        </div>
    )
}

function StarsWidget ({formik, name, colors}) {
    const setVal = (value) => {
        //console.log(value);
        formik && formik.setFieldValue(name, value);
    }
    const arr = [1,2,3,4,5];

    return (
        <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-center">
            {arr.map ( (item, index) =>
                <StarItem key={index} 
                          formik={formik} 
                          name={name} 
                          value={item} 
                          setVal={setVal} 
                          last={index === arr.length - 1 ? true : false} 
                          colors={colors} />
            )}
        </div>
    )
}

function StarItem ({formik, name, value, setVal, last, colors}) {
    const [hoverState, setHoverState] = useState(false);
    const toggleHover = () => setHoverState(!hoverState);

    return <i onMouseEnter={toggleHover} 
              onMouseLeave={toggleHover} 
              style={{color: hoverState ? colors.activeColor : (formik && formik.values[name] >= value) ? colors.activeColor: colors.inactiveColor}} 
              className={`cursor-pointer ${!last && "mr-1"} la  la-star icon-3x`} 
              onClick={() => setVal(value)} />
}

function SmileysWidget ({formik, name, colors}) {
    const setVal = (value) => {
        //console.log(value);
        formik && formik.setFieldValue(name, value);
    }
    const arr = [1,2,3,4,5];

    return (
        <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-center">
            {arr.map ( (item, index) =>
                <SmileyItem key={index} formik={formik} name={name} value={item} setVal={setVal} last={index === arr.length - 1 ? true : false} colors={colors} />
            )}
        </div>
    )
}

function SmileyItem ({formik, name, value, setVal, last, colors}) {

    const [hoverState, setHoverState] = useState(false);
    const toggleHover = () => setHoverState(!hoverState);

    return <i onMouseEnter={toggleHover} 
              onMouseLeave={toggleHover} 
              style={{color: hoverState ? colors.activeColor : (formik && formik.values[name] === value) ? colors.activeColor: colors.inactiveColor}} 
              className={`cursor-pointer ${!last && "mr-3"} far fa-${value === 1 ? "angry" : value === 2 ? "frown" : value === 3 ? "meh" : value === 4 ? "smile" : "laugh"} icon-3x`} 
              onClick={() => setVal(value)} />
}



function ScoreWidget ({formik, name, colors}) {
    const setVal = (value) => {
        //console.log(value);
        formik && formik.setFieldValue(name, value);
    }
    const arr = [0,1,2,3,4,5,6,7,8,9,10];

    return (
        <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-center">
        {arr.map ( (item, index) => 
            <ScoreItem key={index} 
                       formik={formik} 
                       name={name} 
                       value={item} 
                       setVal={setVal} 
                       last={index === arr.length - 1 ? true : false} 
                       colors={colors} />
        )}                       
        </div>
    )
} 

function ScoreItem ({formik, name, value, setVal, last, colors}) {
    const [hoverState, setHoverState] = useState(false);
    const toggleHover = () => setHoverState(!hoverState);
    
    return <span onMouseEnter={toggleHover} 
                 onMouseLeave={toggleHover} 
                 style={{   backgroundColor: hoverState ? colors.activeColor : (formik && formik.values[name] != '' && formik.values[name] >= value) ? colors.activeColor : colors.textColor,
                            color:  hoverState ? colors.white : (formik && formik.values[name] != '' && formik.values[name] >= value) ? colors.white : colors.bgColor,
                            border: "1px solid " + colors.textColor }} 
                 className={`cursor-pointer label border border-2 border-white label-xl label-circle font-weight-bolder font-size-h6 px-3 py-2 ${!last && "mr-1"} `} 
                 onClick={() => setVal(value)}>{value}</span>
}

function WriteInWidget ({formik, name, colors}) {

    const setVal = (e) => {
        formik && formik.setFieldValue(name, e.target.value);
    }

    return (
        <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-center">
            <textarea row="3" className={`form-control font-size-h6 text-${colors ? "dark" : "primary"} font-weight-bold mx-1`} onChange={(e) => setVal(e)} style={{resize: 'none'}}/>
        </div>
    )
}

function ChoicesWidget ({formik, name, options, colors}) {

    const choices = options && options.split(",");
    const setVal = (e) => {
        formik && formik.setFieldValue(name, e.target.value);
    }

    return (
        <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-center">
            <div className="radio-list">
                {choices && choices.map( (choice, index) => 
                    <label key={index} className={`font-size-h6 radio radio-lg radio-${colors ? "gray-600" : "warning"} ${index < choices.length - 1 ? "mb-7" : ""}`} style={{color: colors ? colors.textColor : "white"}}>
                        <input type="radio" name={name} value={choice.trim()} onChange={(e) => setVal(e)} />
                        <span></span>
                        {choice.trim()}
                    </label>
                 )}
             </div>
        </div>
    )
}

function MultipleChoicesWidget ({formik, name, options, colors}) {

    const choices = options && options.split(",");
    const [selected, setSelected] = useState([]);

    const setVal = (e) => {
        let mySel = selected;
        if (e.target.checked) {
            if (!mySel.find(item => item === e.target.name)) mySel.push(e.target.name);            
        }
        else if (!e.target.checked) {
            mySel = mySel.filter(item => item != e.target.name);
        }
        setSelected(mySel);
        formik && formik.setFieldValue(name, mySel);
    }

    return (
        <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-center">
            <div className="checkbox-list">
                {choices && choices.map( (choice, index) => 
                    <label key={index} className={`checkbox checkbox-${colors ? "gray-600" : "warning"} checkbox-lg font-size-h6 ${index < choices.length - 1 ? "mb-7" : ""} `} style={{color: colors ? colors.textColor : "white"}}>
                        <input type="checkbox" name={choice.trim()} onChange={(e) => setVal(e)}/>
                        <span></span>
                        {choice.trim()}
                    </label>
                 )}
             </div>
        </div>
    )
}
