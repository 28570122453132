import React from "react";
import {Link} from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";

export function Brand() {

  return (
    <>
      {/* begin::Brand */}
      <div className="brand flex-column-auto justify-content-between bg-primary" id="kt_brand">
        {/* begin::Logo */}
        <Link to="" className="brand-logo">
          <img alt="logo" src={toAbsoluteUrl("/media/Textopia/Logos/textopia_white_logo.svg")} width="110"/>
        </Link>
        {/* end::Logo */}
        {/* begin::Toggle */}
        <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
          <span className="svg-icon svg-icon-xl svg-icon-white">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
          </span>
        </button>
        {/* end::Toolbar */}
      </div>
      {/* end::Brand */}
      </>
  );
}
