import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Parse } from 'parse';
import dayjs from "dayjs";
import ReportHelper from "../../../../../_metronic/_assets/js/components/reportHelper"
const today = dayjs(new Date());

const initialState = {
  pageStatus: 'idle',
  pageError: null,
  graphStatus: 'idle',
  graphError: null,
  reportData: [],
  totalTaskOpenedChartDataForDashboard: {},
  totalTaskOpenedChartData: {},
  totalTaskClosedChartDataForDashboard: {},
  totalTaskClosedChartData: {},
  tasksTimeToCompleteForDashboard: {},
  tasksTimeToComplete: {},

  taskAssignmentsChartDataForDashboard: {},
  taskAssignmentsChartData: {},
  taskCreationForDashboard: {},
  taskCreationChartData: {},
  monthToDate: 0,
  last12Months: 0,
  tasksTimeToCompleteForDashboard_monthToDate: 0,
  tasksTimeToCompleteForDashboard_last12Month: 0,
  tasksTimeToComplete_monthToDate: 0,
  tasksTimeToComplete_last12Month: 0,
  totalTaskOpenedForDashboard_monthToDate: 0,
  totalTaskClosedForDashboard_monthToDate: 0,
  taskAssignmentsForDashboard_monthToDate: 0,
  taskCreationForDashboard_monthToDate: 0,
  // taskCreationForDashboard_last12Month: 0,
  queryParams: {
    fromDate: dayjs(today).subtract(7, 'day').format("YYYY-MM-DD"),
    toDate: today.format("YYYY-MM-DD"),
  },
  curentPage: {
    pageName: "dashboard",
    pageData: null,
    reportType: "",
    summeryType: "",
    chartType: "",
    reportTitle: ""
  }

}

// server API calling dispatch
export const fetchTasksSummary = createAsyncThunk('tasks/fetchTasksSummary', async (values) => {
  const cloudResponse = await Parse.Cloud.run("fetchTasksSummary", values);
  return cloudResponse;
})

const entityType = "taskReport";
// Then, handle actions in your reducers:
export const taskReportsSlice = createSlice({
  name: entityType,
  initialState: initialState,
  reducers: {
    setParams: (state, action) => {
      state.queryParams = action.payload; // mutate the state all you want with immer
      // state.reportData = [];
      // state.pageStatus = 'idle';
    },
    setPage: (state, action) => {
      state.curentPage = action.payload;
    },
  },
  extraReducers: {
    // all callback from server for each API call
    // state managemet - pending/fulfilled/rejected callback from with API name [method-name.state] 
    [fetchTasksSummary.pending]: (state) => { state.pageStatus = 'loading' },
    [fetchTasksSummary.fulfilled]: (state, action) => fetchTasksSummaryFulfilled(state, action),
    [fetchTasksSummary.rejected]: (state, action) => fetchTasksSummaryRejected(state, action),
  }
})

function getTotal(reportType, result) {
  let total = 0;
  switch (reportType) {

    case ReportHelper.ReportType.totalTaskOpenedForDashboard: {
      total = result.data.reduce((total, data) => total = total + data.totalOpenTask, 0);
      break;
    }
    case ReportHelper.ReportType.totalTaskClosedForDashboard: {
      total = result.data.reduce((total, data) => total = total + data.taskClosedTask, 0);
      break;
    }
    case ReportHelper.ReportType.tasksTimeToCompleteForDashboard: {
      total = result.data[0].timeToComplete;
      break;
    }
    case ReportHelper.ReportType.taskAssignmentsForDashboard: {
      let teamsCount = result.data.assignedToTeamCount;
      let usersCount = result.data.assignedToUserCount;
      Array.prototype.push.apply(teamsCount, usersCount);
      total = teamsCount.filter(d => d.team !== null && d.user !== null).reduce((prev, next) => prev + next.taskCreationCount, 0);
      break;
    }
  }
  return total;
}

const fetchTasksSummaryFulfilled = (state, action) => {
  state.pageStatus = 'succeeded'
  const result = action.payload;
  if (result.groupBy === "") {
    const stateName = result.reportType + "_" + result.filterType;
    switch (result.filterType) {
      case ReportHelper.FilterType.monthToDate: {
        if (result.reportType === "taskAssignmentsForDashboard") {
          state[stateName] = (result.data && result.data.assignedToTeamCount.length > 0 || result.data.assignedToUserCount.length > 0) ? getTotal(result.reportType, result) : 0;
        } else {
          state[stateName] = (result.data && result.data.length > 0) ? getTotal(result.reportType, result) : 0;
        }
        state.reportName = result.reportType;
        break;
      }
      case ReportHelper.FilterType.last12Month: {
        state[stateName] = (result.data && result.data.length > 0) ? getTotal(result.reportType, result) : 0;
        state.reportName = result.reportType;
        break;
      }
    }
  }
  else if (result.reportType === ReportHelper.ReportType.totalTaskOpenedForDashboard) {
    state.totalTaskOpenedChartDataForDashboard = ReportHelper.ConvertTotalTaskOpenedChartDataForDashboard(result);
  }
  else if (result.reportType === ReportHelper.ReportType.totalTaskOpened) {
    state.totalTaskOpenedChartData = ReportHelper.ConvertTaskDetailsChartData(result.data, result.groupBy, ReportHelper.ReportTitle.totalTaskOpened, result.filterType);
  }
  else if (result.reportType === ReportHelper.ReportType.totalTaskClosedForDashboard) {
    state.totalTaskClosedChartDataForDashboard = ReportHelper.ConvertTotalTaskClosedChartDataForDashboard(result);
  }
  else if (result.reportType === ReportHelper.ReportType.totalTaskClosed) {
    state.totalTaskClosedChartData = ReportHelper.ConvertTaskDetailsChartData(result.data, result.groupBy, ReportHelper.ReportTitle.totalTaskClosed, result.filterType);
  }
  else if (result.reportType === ReportHelper.ReportType.tasksTimeToCompleteForDashboard) {
    state.tasksTimeToCompleteForDashboard = ReportHelper.ConvertTasksTimeToCompleteForDashboard(result);
  }
  else if (result.reportType === ReportHelper.ReportType.tasksTimeToComplete) {
    state.tasksTimeToComplete = ReportHelper.ConvertTasksTimeToComplete(result.data, result.groupBy, ReportHelper.ReportTitle.tasksTimeToComplete, result.filterType);
  }
  else if (result.reportType === ReportHelper.ReportType.taskAssignmentsForDashboard) {
    state.taskAssignmentsChartDataForDashboard = ReportHelper.ConvertTaskAssignmentChartDataForDashboard(result.data, result.groupBy);
  }
  else if (result.reportType === ReportHelper.ReportType.taskAssignments) {
    state.taskAssignmentsChartData = ReportHelper.ConvertTaskAssignmentChartData(result.data, result.groupBy, ReportHelper.ReportTitle.taskAssignments, result.filterType);
  }
  else if (result.reportType === ReportHelper.ReportType.taskCreationForDashboard) {
    state.taskCreationForDashboard = ReportHelper.ConvertTaskCreationForDashboard(result.data, result.groupBy);
  }
  else if (result.reportType === ReportHelper.ReportType.taskCreation) {
    state.taskCreationChartData = ReportHelper.ConvertTaskCreationChartData(result.data, result.groupBy, ReportHelper.ReportTitle.taskCreation, result.filterType);
  }
}

const fetchTasksSummaryRejected = (state, action) => {
  state.pageStatus = 'failed'
  state.pageError = action.error.message
  console.log(action.error.message);
}

export default taskReportsSlice.reducer