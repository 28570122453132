import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";
import * as Queries from "../../../../../_metronic/_assets/js/textopia/TextopiaQueries";
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";
import {KTUtil} from "../../../../../_metronic/_assets/js/components/util";


const initialState = {

  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,
  teams: [],
  updatedAt: new Date(),
};

export const fetchTeams = createAsyncThunk('teams/fetchTeams', async () => {
  const cloudResponse = await Parse.Cloud.run("fetchTeams");
  return cloudResponse;  
})

//--> this one used by LQ
export const updateTeam = createAsyncThunk('teams/updateTeam', async () => {
  //--> even though one team is updated, since users may have moved around, more than one team could be affected, so we get all teams and replace list
  const cloudResponse = await Parse.Cloud.run("fetchTeams");
  return cloudResponse;  
})

export const saveTeam = createAsyncThunk('teams/saveTeam', async (values) => {
  const cloudResponse = await Parse.Cloud.run("saveTeam", values);
  const cloudResponse2 = await Parse.Cloud.run("fetchTeams");
  return cloudResponse2; 
})

export const deleteTeam = createAsyncThunk('teams/deleteTeam', async (id) => {
  const cloudResponse = await Parse.Cloud.run("deleteTeam", {id: id} ); // Sending of Parse objects is not allowed here for some reason, so have to send ID only
  return cloudResponse;
})

const entityType = "teams";

export const teamsSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
      // All teams for a location
      [fetchTeams.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchTeams.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state[entityType] = action.payload //Query returns an array of objects
        state.updatedAt = new Date();
      },
      [fetchTeams.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },

      // Edit team
      [saveTeam.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveTeam.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'

        // Since one team edit can affect others, we'll replace all the teams
        state[entityType] = action.payload //Query returns an array of objects
        state.updatedAt = new Date();
      },
      [saveTeam.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      //  update team //--> used by LQ
      [updateTeam.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updateTeam.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'

        // Since one team edit can affect others, we'll replace all the teams
        // not looking for LQ mode because all changes can affect other teams due to shared user pool, so just replace the whole set on any change
        state[entityType] = action.payload 
        state.updatedAt = new Date();
      },
      [updateTeam.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Delete team
      [deleteTeam.pending]: (state, action) => {
        state.actionStatus = 'deleting'
      },
      [deleteTeam.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded';

        // Remove the deleted user from the users list
        state[entityType] = state[entityType].filter(o => o.id !== action.payload.id);
        state.updatedAt = new Date();
      },
      [deleteTeam.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      }


    },


    // BELOW NOT USED - KEPT FOR REFERENCE ONLY

    reducers: {

    }
});

export default teamsSlice.reducer
