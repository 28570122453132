/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, {useState, useEffect} from "react";
import Parse from "parse"; //--> yet again, stale Parse.User because I had not included this here!!
import { useDispatch} from "react-redux";
import { Form } from "react-bootstrap";
import * as Textopia  from "../../../_assets/js/textopia/textopia_utils.js";
import { TextopiaSpinnerCardless, TextopiaSearchList } from "../../../_assets/js/textopia/TextopiaControls";
import { saveLocation } from "./locationSlice.js";
import { Formik} from "formik";

export function ChangeLocationForm({pullout, setShow}) {   
 
  const [locations, setLocations] = useState([{label: "Loading ...", value: ""}]);
  useEffect(() => Textopia.loadCurrentUserLocationsForUI(setLocations), []);

  const [actionStatus, setActionStatus] = useState(''); 
  
  let dispatch = useDispatch();
  function handleSubmit(values) {
    
    if (actionStatus !== "saving") {      
      
      values.showConf = setShow; // The location slice will show the confirmation once the location change is complete.
      
      //setActionStatus("saving");
      
      //--> this does wonders, we place the location into redux and out main LQ Loader guys is listening to location changes, it immediately runs to refetch all the records for the new location

      //setTimeout(() => {
        pullout && pullout.hide();
        dispatch(saveLocation(values))
      //  setActionStatus("idle");
      //}, 400);              

    }
  }

  

  var initialValues = ({
    primaryLocationId:  Parse.User.current() && Parse.User.current().get("primaryLocationPtr").id,   
  })

  return (
    <>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize={true}      
        >
        { formik => (

          <Form className="pt-8" noValidate onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <TextopiaSpinnerCardless saving={actionStatus} color="warning" type="track" style={{zIndex: 2}}/>

            <TextopiaSearchList name="primaryLocationId" skipItemId={Parse.User.current() && Parse.User.current().get("primaryLocationPtr").id} formik={formik} items={locations} takeAction={true} icon="flaticon2-location icon-xl ml-n2"/>   

          </Form>
         )}
         </Formik>


          
   
    </>
  )
}