import React, { useLayoutEffect } from "react";
import {Brand} from "../brand/Brand";
import {AsideMenu} from "./aside-menu/AsideMenu";
import KTLayoutAside from "../../../_assets/js/layout/base/aside";
import {KTUtil} from "../../../_assets/js/components/util";

export function Aside() {


  return (
      <>
        {/* begin::Aside */}
        <div id="kt_aside"
             className="aside aside-left aside-fixed d-flex flex-column flex-row-auto">
          <Brand/>

          {/* begin::Aside Menu */}
          <div id="kt_aside_menu_wrapper" className="aside-menu-wrapper flex-column-fluid">

            <AsideMenu />
          </div>
          {/* end::Aside Menu */}
        </div>
        {/* end::Aside */}
      </>
  );
}
