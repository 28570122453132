import React, {useLayoutEffect} from "react";
import {useLocation} from "react-router-dom";
import {Topbar} from "./Topbar";
import {BreadCrumbs} from "./BreadCrumbs";
import {getBreadcrumbsAndTitle, useSubheader} from "../../_core/MetronicSubheader";

export function Header() {

  const location = useLocation();
  const subheader = useSubheader();

  // Moved breadcrumbs into the header instead of the subheader - still left the logic inside subheader
  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    subheader.setBreadcrumbs(aside.breadcrumbs);
    subheader.setTitle(aside.title);
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <>
      {/*begin::Header*/}
      <div className="header header-fixed" id="kt_header">
        {/*begin::Container*/}
        <div className="container-fluid d-flex align-items-center justify-content-between">
          {/*<AnimateLoading />*/}
          <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
            <BreadCrumbs items={subheader.breadcrumbs} />
          </div>
          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}
