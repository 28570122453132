import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import React  from "react";
import { useDispatch } from "react-redux";
import { addRelatedTask } from "../../../Feedback/pages/responses/responsesSlice";
import { Parse} from 'parse';
import * as Textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";
import * as Queries from "../../../../../_metronic/_assets/js/textopia/TextopiaQueries.js";
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants.js";
import {KTUtil} from "../../../../../_metronic/_assets/js/components/util";



const initialState = {
  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,

  tasks: [],
  updatedAt: new Date(),

};

export const fetchTasks = createAsyncThunk('tasks/fetchTasks', async () => {
  const cloudResponse = await Parse.Cloud.run("fetchTasks");
  return cloudResponse;
})

export const updateTask = createAsyncThunk('tasks/updateTask', async (values) => { //--> this is called by LQ so we simply return the value (which will be affected task obj provided by LQ) - so that below we can place it into redux properly
  return values;
})

export const saveTask = createAsyncThunk('tasks/saveTask', async (values) => {
  const cloudResponse = await Parse.Cloud.run("saveTask", values);
  return cloudResponse;  
})

export const followupTask = createAsyncThunk('tasks/followupTask', async (values) => {
  const cloudResponse = await Parse.Cloud.run("followupTask", values);
  return cloudResponse;
})

export const deleteTask = createAsyncThunk('tasks/deleteTask', async (id) => {
  const cloudResponse = await Parse.Cloud.run("deleteTask", {taskId: id} ); // Sending of Parse objects is not allowed here for some reason, so have to send ID only
  return cloudResponse;  
})

const entityType = "tasks";

export const tasksSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
      [fetchTasks.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchTasks.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state[entityType] = action.payload //Query returns an array of objects
        state.updatedAt = new Date();
      },
      [fetchTasks.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },

      [saveTask.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveTask.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        // If this is a new task, push into the task Array
        if (state && state[entityType] && !state[entityType].find(task => task.id == action.payload.id)) {
          state[entityType].unshift(action.payload);
        } 
        state.updatedAt = new Date();
         
      },
      [saveTask.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(state.actionError);
      },

      //--> this is called by LQ with the affected task object loaded into action.payload.obj
      [updateTask.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updateTask.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'

        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
          // nothing
          state.updatedAt = new Date();
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          //--> check for duplicates
          if (state && state[entityType] && !state[entityType].find(task => task.id == action.payload.obj.id)) {
            state[entityType].unshift(action.payload.obj);
          } 
          state.updatedAt = new Date();
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {
           state.tasks = state.tasks && state.tasks.filter(o => o.id !== action.payload.obj.id);
           state.updatedAt = new Date();
        }
         
      },
      [updateTask.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(state.actionError);
      },

      [followupTask.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [followupTask.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
        // The edited task should be updated by itself
      },
      [followupTask.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(state.actionError);
      },

      [deleteTask.pending]: (state, action) => {
        state.actionStatus = 'deleting'
      },
      [deleteTask.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded';

        // Remove the deleted task from the task list
        state[entityType] = state[entityType].filter(o => o.id !== action.payload.id);
        state.updatedAt = new Date();
      },
      [deleteTask.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      }

    },


    // BELOW NOT USED - KEPT FOR REFERENCE ONLY

    reducers: {

    catchError: (state, action) => {
      state.error = action.payload.error;
      console.log(state.error);
    },
  }
});

export default tasksSlice.reducer
