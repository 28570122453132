"use strict";

import React from "react";

// ********* CONSTANTS **************

//--> Types of attributes
export const ATTR_WRITE_IN              =   "Write-In";
export const ATTR_ONE_OF_MULT           =   "One-Of-Multiple";
export const ATTR_MULT_OF_MULT          =   "Multiple-Of-Multiple";


export const ATTR_CAT_CONTACT           =   "CONTACT";
export const ATTR_CAT_CONNECTIONS       =   "CONNECTIONS";
export const ATTR_CAT_PERSONAL          =   "PERSONAL";
export const ATTR_CAT_GENERAL           =   "GENERAL";
export const ATTR_CAT_PREFS             =   "PREFERENCES";
export const ATTR_CAT_BIZ_RELATIONSHIP  =   "RELATIONSHIP";


// Types of convo entries
export const CONVO_ENTRY_TYPE_MESSAGE        =   "MESSAGE";
export const CONVO_ENTRY_TYPE_REVIEW         =   "REVIEW";
export const CONVO_ENTRY_TYPE_FEEDBACK       =   "FEEDBACK";
export const CONVO_ENTRY_TYPE_TASK           =   "TASK";
export const CONVO_ENTRY_TYPE_CUSTOMER_NOTE  =   "CUSTOMER_NOTE";
export const CONVO_ENTRY_TYPE_SYSTEM_NOTE    =   "SYSTEM_NOTE";
export const CONVO_ENTRY_TYPE_PAYMENT        =   "PAYMENT";

export const SMS                =   "SMS";
export const MMS                =   "MMS";
export const FACEBOOK_MESSENGER =   "FB";
export const INSTAGRAM          =   "IG";
export const TWITTER            =   "TW";
export const EMAIL              =   "EMAIL";
export const WEB2TXT            =   "WEB2TXT";
export const NOTE               =   "NOTE";

export const getChannelTypeString = (type) => {
    if (type === SMS) return "SMS"
    else if (type === FACEBOOK_MESSENGER) return "FB Message"
    else if (type === EMAIL) return "Email"
    else if (type === MMS) return "MMS"
    else if (type === "TW") return "Twitter DM"
    else if (type === "IG") return "Reply"
     
}

// Types of system notes
export const CONVO_OPENED         =   "CONVO_OPENED";
export const CONVO_CLOSED         =   "CONVO_CLOSED";
export const CONVO_ASSIGNED       =   "CONVO_ASSIGNED";
export const CONVO_EPISODE_HEADER =   "CONVO_EPISODE_HEADER";
export const CONVO_TRANSFERRED    =   "CONVO_TRANSFERRED";
export const CONVO_CREATED        =   "CONVO_CREATED";
export const CONVO_MERGED         =   "CONVO_MERGED";
export const CONVO_UNMERGED       =   "CONVO_UNMERGED";

// Strings to use for the various system notes
export const CONVO_OPENED_STRING        =   "opened this convo";
export const CONVO_CLOSED_STRING        =   "closed this convo";
export const CONVO_ASSIGNED_STRING      =   "Assigned to";
export const CONVO_TRANSFERRED_STRING   =   "Transferred to";
export const CONVO_CREATED_STRING       =   "New convo created";
export const CONVO_MERGED_STRING        =   "Merged with";
export const CONVO_UNMERGED_STRING      =   "Un-merged from";

export const FEEDBACK_QUESTION_TYPE_CHOICES         =   "Choices";
export const FEEDBACK_SENTIMENT_POSITIVE            =   "Positive";
export const FEEDBACK_SENTIMENT_LEANING_POSITIVE    =   "Leaning Positive";
export const FEEDBACK_SENTIMENT_NEGATIVE            =   "Negative";
export const FEEDBACK_SENTIMENT_LEANING_NEGATIVE    =   "Leaning Negative";
export const FEEDBACK_SENTIMENT_NEUTRAL             =   "Neutral";

export const QUESTION_FORMAT_WRITE_IN             =   "Write-In";
export const QUESTION_FORMAT_YES_NO               =   "Yes/No";
export const QUESTION_FORMAT_SMILEYS              =   "Smileys";
export const QUESTION_FORMAT_CHOICES              =   "Choices";
export const QUESTION_FORMAT_MULTIPLE_CHOICES     =   "Multiple Choices";
export const QUESTION_FORMAT_SCORE                =   "Score";
export const QUESTION_FORMAT_STARS                =   "Stars";

export const DATE_FORMAT_1          = "MMM DD, YYYY";
export const DATE_FORMAT_2          = "MM/DD/YYYY";
export const DATE_FORMAT_3          = "DD MMM YYYY";
export const DATE_FORMAT_4          = "MM/DD/YY HH:mm A";
export const DATE_FORMAT_5          = "MMM D";
export const DATE_FORMAT_DEFAULT    = "MMM DD, YYYY";

export const INBOX_MINE         =   "MINE";
export const INBOX_ALL          =   "ALL";
export const INBOX_MY_TEAM      =   "MY_TEAM";
export const INBOX_UNASSIGNED   =   "UNASSIGNED";
export const INBOX_OPEN         =   "open";
export const INBOX_CLOSED       =   "closed";

export const INBOX_MINE_OPEN         =   "MINE_OPEN";
export const INBOX_ALL_OPEN          =   "ALL_OPEN";
export const INBOX_MY_TEAM_OPEN      =   "MY_TEAM_OPEN";
export const INBOX_UNASSIGNED_OPEN   =   "UNASSIGNED_OPEN";

export const INBOX_MINE_CLOSED         =   "MINE_CLOSED";
export const INBOX_ALL_CLOSED          =   "ALL_CLOSED";
export const INBOX_MY_TEAM_CLOSED      =   "MY_TEAM_CLOSED";
export const INBOX_UNASSIGNED_CLOSED   =   "UNASSIGNED_CLOSED";

export const INIT_CONVO_LOAD           =    50;
export const INCR_CONVO_LOAD           =    50;

export const INIT_CONVO_ENTRY_LOAD           =    50;
export const INCR_CONVO_ENTRY_LOAD           =    50;

export const INIT_REVIEWS_LOAD           =    20;
export const INCR_REVIEWS_LOAD           =    20;

export const INIT_PAYMENTS_LOAD           =    20;
export const INCR_PAYMENTS_LOAD           =    20;


export const DONT_ORDER_ENTITIES    = "DONT_ORDER_ENTITIES";

export const LQ_CREATE      =   "create";
export const LQ_DELETE      =   "delete";
export const LQ_UPDATE      =   "update";
export const LQ_ENTER       =   "enter";
export const LQ_LEAVE       =   "leave";
export const LQ_OPEN        =   "open";
export const LQ_CLOSE       =   "close";

export const ACCEPT_MEDIA   =   "audio/basic, audio/L24, audio/mp4, audio/mpeg, audio/ogg, audio/vorbis, audio/vnd.rn-realaudio, audio/vnd.wave, audio/3gpp, audio/3gpp2, audio/ac3, audio/vnd.wave, audio/webm, audio/amr-nb, audio/amr, video/mpeg, video/mp4, video/quicktime, video/webm, video/3gpp, video/3gpp2, video/3gpp-tt, video/H261, video/H263, video/H263-1998, video/H263-2000, video/H264, image/jpeg, image/gif, image/png, image/bmp, text/vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory, application/pdf";
export const MAX_MEDIA_SIZE =   524288;
export const MAX_EMAIL_MEDIA_SIZE = 5242880;

//--> NOTIFICATION PREFS 

export const PREFS_DAILY_EMAIL          =       "daily";
export const PREFS_WEEKLY_EMAIL         =       "weekly";
export const PREFS_MY_CONVOS            =       "myConvos";
export const PREFS_MY_TEAMS_CONVOS      =       "myTeamsConvos";
export const PREFS_UNASSIGNED_CONVOS    =       "unassignedConvos";
export const PREFS_CONVO_ASSIGNED       =       "convoAssigned";
export const PREFS_TASK_ASSIGNED        =       "taskAssigned";
export const PREFS_NEW_REVIEW           =       "newReview";
export const PREFS_NEW_FEEDBACK         =       "newFeedback";
export const PREFS_NEW_PAYMENT          =       "newPayment";


//--> TEMPLATES

export const TEMPL_CAT_REVIEWS          =       "Reviews";
export const TEMPL_CAT_FEEDBACK         =       "Feedback";
export const TEMPL_REVIEW_INVITE        =       "Review Invite";
export const TEMPL_FEEDBACK_INVITE      =       "Feedback Invite";


//--> APPS REGISTRY //--> used to identify which app to generate a link for when sending a message

export const REVIEW_APP                =       "Review Invite Link";
export const FEEDBACK_APP              =       "Feedback Invite Link";
export const PAYMENT_APP               =       "Payment Request";




export const NGROK          =   "Textopia.ngrok.io";
export const NGROK_URL      =   "http://Textopia.ngrok.io/";
export const SERVER_URL     =   "http://Textopia.ngrok.io/";
export const APPLINKS_PATH  =   "/";
export const LOCAL_URL      =   "http://127.0.0.1:1337/";


export const COUNTRIES      = [
    {"label": "Afghanistan", "value": "AF"},
    {"label": "Åland Islands", "value": "AX"},
    {"label": "Albania", "value": "AL"},
    {"label": "Algeria", "value": "DZ"},
    {"label": "American Samoa", "value": "AS"},
    {"label": "Andorra", "value": "AD"},
    {"label": "Angola", "value": "AO"},
    {"label": "Anguilla", "value": "AI"},
    {"label": "Antarctica", "value": "AQ"},
    {"label": "Antigua and Barbuda", "value": "AG"},
    {"label": "Argentina", "value": "AR"},
    {"label": "Armenia", "value": "AM"},
    {"label": "Aruba", "value": "AW"},
    {"label": "Australia", "value": "AU"},
    {"label": "Austria", "value": "AT"},
    {"label": "Azerbaijan", "value": "AZ"},
    {"label": "Bahamas (the)", "value": "BS"},
    {"label": "Bahrain", "value": "BH"},
    {"label": "Bangladesh", "value": "BD"},
    {"label": "Barbados", "value": "BB"},
    {"label": "Belarus", "value": "BY"},
    {"label": "Belgium", "value": "BE"},
    {"label": "Belize", "value": "BZ"},
    {"label": "Benin", "value": "BJ"},
    {"label": "Bermuda", "value": "BM"},
    {"label": "Bhutan", "value": "BT"},
    {"label": "Bolivia (Plurinational State of)", "value": "BO"},
    {"label": "Bonaire, Sint Eustatius and Saba", "value": "BQ"},
    {"label": "Bosnia and Herzegovina", "value": "BA"},
    {"label": "Botswana", "value": "BW"},
    {"label": "Bouvet Island", "value": "BV"},
    {"label": "Brazil", "value": "BR"},
    {"label": "British Indian Ocean Territory (the)", "value": "IO"},
    {"label": "Brunei Darussalam", "value": "BN"},
    {"label": "Bulgaria", "value": "BG"},
    {"label": "Burkina Faso", "value": "BF"},
    {"label": "Burundi", "value": "BI"},
    {"label": "Cabo Verde", "value": "CV"},
    {"label": "Cambodia", "value": "KH"},
    {"label": "Cameroon", "value": "CM"},
    {"label": "Canada", "value": "CA"},
    {"label": "Cayman Islands (the)", "value": "KY"},
    {"label": "Central African Republic (the)", "value": "CF"},
    {"label": "Chad", "value": "TD"},
    {"label": "Chile", "value": "CL"},
    {"label": "China", "value": "CN"},
    {"label": "Christmas Island", "value": "CX"},
    {"label": "Cocos (Keeling) Islands (the)", "value": "CC"},
    {"label": "Colombia", "value": "CO"},
    {"label": "Comoros (the)", "value": "KM"},
    {"label": "Congo (the Democratic Republic of the)", "value": "CD"},
    {"label": "Congo (the)", "value": "CG"},
    {"label": "Cook Islands (the)", "value": "CK"},
    {"label": "Costa Rica", "value": "CR"},
    {"label": "Croatia", "value": "HR"},
    {"label": "Cuba", "value": "CU"},
    {"label": "Curaçao", "value": "CW"},
    {"label": "Cyprus", "value": "CY"},
    {"label": "Czechia", "value": "CZ"},
    {"label": "Côte d'Ivoire", "value": "CI"},
    {"label": "Denmark", "value": "DK"},
    {"label": "Djibouti", "value": "DJ"},
    {"label": "Dominica", "value": "DM"},
    {"label": "Dominican Republic (the)", "value": "DO"},
    {"label": "Ecuador", "value": "EC"},
    {"label": "Egypt", "value": "EG"},
    {"label": "El Salvador", "value": "SV"},
    {"label": "Equatorial Guinea", "value": "GQ"},
    {"label": "Eritrea", "value": "ER"},
    {"label": "Estonia", "value": "EE"},
    {"label": "Eswatini", "value": "SZ"},
    {"label": "Ethiopia", "value": "ET"},
    {"label": "Falkland Islands (the) [Malvinas]", "value": "FK"},
    {"label": "Faroe Islands (the)", "value": "FO"},
    {"label": "Fiji", "value": "FJ"},
    {"label": "Finland", "value": "FI"},
    {"label": "France", "value": "FR"},
    {"label": "French Guiana", "value": "GF"},
    {"label": "French Polynesia", "value": "PF"},
    {"label": "French Southern Territories (the)", "value": "TF"},
    {"label": "Gabon", "value": "GA"},
    {"label": "Gambia (the)", "value": "GM"},
    {"label": "Georgia", "value": "GE"},
    {"label": "Germany", "value": "DE"},
    {"label": "Ghana", "value": "GH"},
    {"label": "Gibraltar", "value": "GI"},
    {"label": "Greece", "value": "GR"},
    {"label": "Greenland", "value": "GL"},
    {"label": "Grenada", "value": "GD"},
    {"label": "Guadeloupe", "value": "GP"},
    {"label": "Guam", "value": "GU"},
    {"label": "Guatemala", "value": "GT"},
    {"label": "Guernsey", "value": "GG"},
    {"label": "Guinea", "value": "GN"},
    {"label": "Guinea-Bissau", "value": "GW"},
    {"label": "Guyana", "value": "GY"},
    {"label": "Haiti", "value": "HT"},
    {"label": "Heard Island and McDonald Islands", "value": "HM"},
    {"label": "Holy See (the)", "value": "VA"},
    {"label": "Honduras", "value": "HN"},
    {"label": "Hong Kong", "value": "HK"},
    {"label": "Hungary", "value": "HU"},
    {"label": "Iceland", "value": "IS"},
    {"label": "India", "value": "IN"},
    {"label": "Indonesia", "value": "ID"},
    {"label": "Iran (Islamic Republic of)", "value": "IR"},
    {"label": "Iraq", "value": "IQ"},
    {"label": "Ireland", "value": "IE"},
    {"label": "Isle of Man", "value": "IM"},
    {"label": "Israel", "value": "IL"},
    {"label": "Italy", "value": "IT"},
    {"label": "Jamaica", "value": "JM"},
    {"label": "Japan", "value": "JP"},
    {"label": "Jersey", "value": "JE"},
    {"label": "Jordan", "value": "JO"},
    {"label": "Kazakhstan", "value": "KZ"},
    {"label": "Kenya", "value": "KE"},
    {"label": "Kiribati", "value": "KI"},
    {"label": "Korea (the Democratic People's Republic of)", "value": "KP"},
    {"label": "Korea (the Republic of)", "value": "KR"},
    {"label": "Kuwait", "value": "KW"},
    {"label": "Kyrgyzstan", "value": "KG"},
    {"label": "Lao People's Democratic Republic (the)", "value": "LA"},
    {"label": "Latvia", "value": "LV"},
    {"label": "Lebanon", "value": "LB"},
    {"label": "Lesotho", "value": "LS"},
    {"label": "Liberia", "value": "LR"},
    {"label": "Libya", "value": "LY"},
    {"label": "Liechtenstein", "value": "LI"},
    {"label": "Lithuania", "value": "LT"},
    {"label": "Luxembourg", "value": "LU"},
    {"label": "Macao", "value": "MO"},
    {"label": "Madagascar", "value": "MG"},
    {"label": "Malawi", "value": "MW"},
    {"label": "Malaysia", "value": "MY"},
    {"label": "Maldives", "value": "MV"},
    {"label": "Mali", "value": "ML"},
    {"label": "Malta", "value": "MT"},
    {"label": "Marshall Islands (the)", "value": "MH"},
    {"label": "Martinique", "value": "MQ"},
    {"label": "Mauritania", "value": "MR"},
    {"label": "Mauritius", "value": "MU"},
    {"label": "Mayotte", "value": "YT"},
    {"label": "Mexico", "value": "MX"},
    {"label": "Micronesia (Federated States of)", "value": "FM"},
    {"label": "Moldova (the Republic of)", "value": "MD"},
    {"label": "Monaco", "value": "MC"},
    {"label": "Mongolia", "value": "MN"},
    {"label": "Montenegro", "value": "ME"},
    {"label": "Montserrat", "value": "MS"},
    {"label": "Morocco", "value": "MA"},
    {"label": "Mozambique", "value": "MZ"},
    {"label": "Myanmar", "value": "MM"},
    {"label": "Namibia", "value": "NA"},
    {"label": "Nauru", "value": "NR"},
    {"label": "Nepal", "value": "NP"},
    {"label": "Netherlands (the)", "value": "NL"},
    {"label": "New Caledonia", "value": "NC"},
    {"label": "New Zealand", "value": "NZ"},
    {"label": "Nicaragua", "value": "NI"},
    {"label": "Niger (the)", "value": "NE"},
    {"label": "Nigeria", "value": "NG"},
    {"label": "Niue", "value": "NU"},
    {"label": "Norfolk Island", "value": "NF"},
    {"label": "Northern Mariana Islands (the)", "value": "MP"},
    {"label": "Norway", "value": "NO"},
    {"label": "Oman", "value": "OM"},
    {"label": "Pakistan", "value": "PK"},
    {"label": "Palau", "value": "PW"},
    {"label": "Palestine, State of", "value": "PS"},
    {"label": "Panama", "value": "PA"},
    {"label": "Papua New Guinea", "value": "PG"},
    {"label": "Paraguay", "value": "PY"},
    {"label": "Peru", "value": "PE"},
    {"label": "Philippines (the)", "value": "PH"},
    {"label": "Pitcairn", "value": "PN"},
    {"label": "Poland", "value": "PL"},
    {"label": "Portugal", "value": "PT"},
    {"label": "Puerto Rico", "value": "PR"},
    {"label": "Qatar", "value": "QA"},
    {"label": "Republic of North Macedonia", "value": "MK"},
    {"label": "Romania", "value": "RO"},
    {"label": "Russian Federation (the)", "value": "RU"},
    {"label": "Rwanda", "value": "RW"},
    {"label": "Réunion", "value": "RE"},
    {"label": "Saint Barthélemy", "value": "BL"},
    {"label": "Saint Helena, Ascension and Tristan da Cunha", "value": "SH"},
    {"label": "Saint Kitts and Nevis", "value": "KN"},
    {"label": "Saint Lucia", "value": "LC"},
    {"label": "Saint Martin (French part)", "value": "MF"},
    {"label": "Saint Pierre and Miquelon", "value": "PM"},
    {"label": "Saint Vincent and the Grenadines", "value": "VC"},
    {"label": "Samoa", "value": "WS"},
    {"label": "San Marino", "value": "SM"},
    {"label": "Sao Tome and Principe", "value": "ST"},
    {"label": "Saudi Arabia", "value": "SA"},
    {"label": "Senegal", "value": "SN"},
    {"label": "Serbia", "value": "RS"},
    {"label": "Seychelles", "value": "SC"},
    {"label": "Sierra Leone", "value": "SL"},
    {"label": "Singapore", "value": "SG"},
    {"label": "Sint Maarten (Dutch part)", "value": "SX"},
    {"label": "Slovakia", "value": "SK"},
    {"label": "Slovenia", "value": "SI"},
    {"label": "Solomon Islands", "value": "SB"},
    {"label": "Somalia", "value": "SO"},
    {"label": "South Africa", "value": "ZA"},
    {"label": "South Georgia and the South Sandwich Islands", "value": "GS"},
    {"label": "South Sudan", "value": "SS"},
    {"label": "Spain", "value": "ES"},
    {"label": "Sri Lanka", "value": "LK"},
    {"label": "Sudan (the)", "value": "SD"},
    {"label": "Suriname", "value": "SR"},
    {"label": "Svalbard and Jan Mayen", "value": "SJ"},
    {"label": "Sweden", "value": "SE"},
    {"label": "Switzerland", "value": "CH"},
    {"label": "Syrian Arab Republic", "value": "SY"},
    {"label": "Taiwan (Province of China)", "value": "TW"},
    {"label": "Tajikistan", "value": "TJ"},
    {"label": "Tanzania, United Republic of", "value": "TZ"},
    {"label": "Thailand", "value": "TH"},
    {"label": "Timor-Leste", "value": "TL"},
    {"label": "Togo", "value": "TG"},
    {"label": "Tokelau", "value": "TK"},
    {"label": "Tonga", "value": "TO"},
    {"label": "Trinidad and Tobago", "value": "TT"},
    {"label": "Tunisia", "value": "TN"},
    {"label": "Turkey", "value": "TR"},
    {"label": "Turkmenistan", "value": "TM"},
    {"label": "Turks and Caicos Islands (the)", "value": "TC"},
    {"label": "Tuvalu", "value": "TV"},
    {"label": "Uganda", "value": "UG"},
    {"label": "Ukraine", "value": "UA"},
    {"label": "United Arab Emirates (the)", "value": "AE"},
    {"label": "United Kingdom of Great Britain and Northern Ireland (the)", "value": "GB"},
    {"label": "United States Minor Outlying Islands (the)", "value": "UM"},
    {"label": "United States of America (the)", "value": "US"},
    {"label": "Uruguay", "value": "UY"},
    {"label": "Uzbekistan", "value": "UZ"},
    {"label": "Vanuatu", "value": "VU"},
    {"label": "Venezuela (Bolivarian Republic of)", "value": "VE"},
    {"label": "Viet Nam", "value": "VN"},
    {"label": "Virgin Islands (British)", "value": "VG"},
    {"label": "Virgin Islands (U.S.)", "value": "VI"},
    {"label": "Wallis and Futuna", "value": "WF"},
    {"label": "Western Sahara", "value": "EH"},
    {"label": "Yemen", "value": "YE"},
    {"label": "Zambia", "value": "ZM"},
    {"label": "Zimbabwe", "value": "ZW"},
]

export const STATES   = 
[
    {"label":"Alabama","value":"AL"},
    {"label":"Alaska","value":"AK"},
    {"label":"Arizona","value":"AZ"},
    {"label":"Arkansas","value":"AR"},
    {"label":"California","value":"CA"},
    {"label":"Colorado","value":"CO"},
    {"label":"Connecticut","value":"CT"},
    {"label":"Delaware","value":"DE"},
    {"label":"District of Columbia","value":"DC"},
    {"label":"Florida","value":"FL"},
    {"label":"Georgia","value":"GA"},
    {"label":"Hawaii","value":"HI"},
    {"label":"Idaho","value":"ID"},
    {"label":"Illinois","value":"IL"},
    {"label":"Indiana","value":"IN"},
    {"label":"Iowa","value":"IA"},
    {"label":"Kansa","value":"KS"},
    {"label":"Kentucky","value":"KY"},
    {"label":"Lousiana","value":"LA"},
    {"label":"Maine","value":"ME"},
    {"label":"Maryland","value":"MD"},
    {"label":"Massachusetts","value":"MA"},
    {"label":"Michigan","value":"MI"},
    {"label":"Minnesota","value":"MN"},
    {"label":"Mississippi","value":"MS"},
    {"label":"Missouri","value":"MO"},
    {"label":"Montana","value":"MT"},
    {"label":"Nebraska","value":"NE"},
    {"label":"Nevada","value":"NV"},
    {"label":"New Hampshire","value":"NH"},
    {"label":"New Jersey","value":"NJ"},
    {"label":"New Mexico","value":"NM"},
    {"label":"New York","value":"NY"},
    {"label":"North Carolina","value":"NC"},
    {"label":"North Dakota","value":"ND"},
    {"label":"Ohio","value":"OH"},
    {"label":"Oklahoma","value":"OK"},
    {"label":"Oregon","value":"OR"},
    {"label":"Pennsylvania","value":"PA"},
    {"label":"Rhode Island","value":"RI"},
    {"label":"South Carolina","value":"SC"},
    {"label":"South Dakota","value":"SD"},
    {"label":"Tennessee","value":"TN"},
    {"label":"Texas","value":"TX"},
    {"label":"Utah","value":"UT"},
    {"label":"Vermont","value":"VT"},
    {"label":"Virginia","value":"VA"},
    {"label":"Washington","value":"WA"},
    {"label":"West Virginia","value":"WV"},
    {"label":"Wisconsin","value":"WI"},
    {"label":"Wyoming","value":"WY"}
]