import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../../../_metronic/layout/components/header/locationSlice";
import { clearUserId } from "../../../../../_metronic/layout/components/header/userSlice";
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import { Parse} from 'parse';
import * as Textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";
import * as Queries from "../../../../../_metronic/_assets/js/textopia/TextopiaQueries";
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";
import {KTUtil} from "../../../../../_metronic/_assets/js/components/util";


const initialState = {
  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,
  general: null,

  locations: [],
  updatedAt: new Date(),
  onboardingUrl: null,
};

export const createStripeAccount = createAsyncThunk('general/createStripeAccount', async () => {
  const cloudResponse = await Parse.Cloud.run("createStripeAccount");
  return cloudResponse;  //--> we wont use the returned value since LQ will issue an update action once the location is updated, and the loop to the UI will be automtically closed and updated
})

export const onboardStripeAccount = createAsyncThunk('general/onboardStripeAccount', async () => {
  const cloudResponse = await Parse.Cloud.run("onboardStripeAccount");
  return cloudResponse.url;  //--> we are interested to send the user into the onboarding flow, and this url is very temporary anyway
})

export const fetchGeneral = createAsyncThunk('general/fetchGeneral', async () => {
  return;
})

//--> this one used by LQ
export const updateGeneral = createAsyncThunk('general/updateGeneral', async (values) => {  
  return values;
})

export const fetchLocations = createAsyncThunk('general/fetchLocations', async () => {
  const cloudResponse = await Parse.Cloud.run("fetchLocations");
  return cloudResponse;
})

//--> this one used by LQ
export const updateLocation = createAsyncThunk('general/updateLocation', async (values) => {  
  return values;
})

export const saveGeneral = createAsyncThunk('general/saveGeneral', async (values) => {
  const cloudResponse = await Parse.Cloud.run("saveGeneral", values);
  return cloudResponse;
})

const entityType = "general";

export const generalSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
      // General settings for a location
      [fetchGeneral.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchGeneral.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        //state[entityType] = action.payload //Query returns one object

        let general = state.locations && state.locations.find(location => location.id === Parse.User.current().get("primaryLocationPtr").id); //--> find the current location from our list of locations and assign it to general
        state.general = general;
        state.updatedAt = new Date();

      },
      [fetchGeneral.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
        console.log(state.pageError)

      },

      // used by LQ
      [updateGeneral.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [updateGeneral.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state[entityType] = action.payload.obj; //--> there is only one object returned with updates from LQ, and that should replace the current object
        state.updatedAt = new Date();
      },
      [updateGeneral.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
        console.log(state.pageError)

      },

      // createStripeAccount
      [createStripeAccount.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [createStripeAccount.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
      },
      [createStripeAccount.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(state.actionError)
      },

      // onboardStripeAccount
      [onboardStripeAccount.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [onboardStripeAccount.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.onboardingUrl = action.payload;
      },
      [onboardStripeAccount.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(state.actionError)
      },



      // Edit settings
      [saveGeneral.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveGeneral.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state[entityType] = action.payload //Query returns one object
        state.updatedAt = new Date();
      },
      [saveGeneral.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(state.actionError)
      },

      [fetchLocations.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchLocations.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state.locations = action.payload //setup array of locations
        state.updatedAt = new Date();
        //console.log(action.payload);
      },
      [fetchLocations.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
        console.log(state.pageError)
      },

      //--> this one used by LQ
      [updateLocation.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updateLocation.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'        
        
        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
          console.log("Location LQ update");
          
          state.updatedAt = new Date();
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          console.log('new location');
          if(!state.locations.find(location => location.id == action.payload.obj.id)) {
            state.locations.unshift(action.payload.obj); // Add the new one to the list of users
          }
          
          state.updatedAt = new Date();

        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {
          console.log('location removed');          
          state.locations = state.locations && state.locations.filter(o => o.id !== action.payload.obj.id);
          state.updatedAt = new Date();
        }

      },
      [updateLocation.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },




    },

    reducers: {

    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      console.log(state.error);
    },
  }
});

export default generalSlice.reducer
