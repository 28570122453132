import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import { Parse } from "parse";

import 'react-animation/dist/keyframes.css';
import { animations } from 'react-animation';
import { AnimateOnChange } from 'react-animation';

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);

  const [loading, setLoading] = useState(false);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        let q = new Parse.Query("User");
        q.equalTo("email", values.email);
        q.first()

          .then((user) => {
            //console.log(user);
            if (user) { //--> this email address was found
              setLoading(false);
              setSubmitting(false);
              setStatus(
                intl.formatMessage(
                  { id: "AUTH.VALIDATION.PASSWORD_CHANGE_EMAIL_SENT" },
                  { name: values.email }
                )
              );
              setIsRequested(true);  
              Parse.User.requestPasswordReset(values.email); 
                
            } else { //--> email address not found, tell the user about the error
              setLoading(false);
              setSubmitting(false);
              setStatus(
                intl.formatMessage(
                  { id: "AUTH.VALIDATION.NOT_FOUND" },
                  { name: values.email }
                )
              )  
              setIsRequested(false); 
            }          
          })

          .catch(() => {
            setLoading(false);
            setSubmitting(false);
            setStatus(
              intl.formatMessage(
                { id: "AUTH.VALIDATION.NOT_FOUND" },
                { name: values.email }
              )
            );
            setIsRequested(false); 
          });
        }, 500);
    },
  });

  return (
    <>

        <div className="login-form login-forgot" style={{ display: "block", animation: animations.fadeInUp }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Forgot Password ?</h3>
            <div className="text-muted font-weight-bold">
              Enter your email to reset your password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp px-0 mx-0"
            noValidate
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible c_fadeIn">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting || loading}
              >
                <span>Submit</span>
                {(loading || formik.isSubmitting) && <span className="ml-3 spinner spinner-white"></span>}

              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  {isRequested ? "Login" : "Cancel"}
                </button>
              </Link>
            </div>
          </form>
        </div>
    </>
  );
}

export default injectIntl(ForgotPassword);
