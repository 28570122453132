import React, {useState, useEffect} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, Form, Image, Modal, Tab, Nav } from "react-bootstrap";
import { KTUtil } from "../../../_assets/js/components/util.js";
import * as Textopia  from "../../../_assets/js/textopia/textopia_utils.js";
import * as Constants  from "../../../_assets/js/textopia/TextopiaConstants.js";
import { TextopiaPhoneInput, TextopiaSavingSpinner, TextopiaSpinnerCardless, TextopiaButton, TextopiaConfBox, TextopiaTextInput, TextopiaNotice, TextopiaOKModal, TextopiaButtonSet } from "../../../_assets/js/textopia/TextopiaControls";

import { Formik, useFormik } from "formik";
import * as yup from "yup";
import Parse from "parse";


export function QuickOptions({pullout}) {    

  const { actionStatus, actionError, businessAttributes, reviewInvite, feedbackInvite, reviewSettings, feedbackSettings  } = useSelector(
    (state) =>  ({      
      actionStatus: state["convos"]["actionStatus"],
      actionError: state["convos"]["actionError"],
      businessAttributes: state["location"]["attributes"],
      reviewInvite: state.templates && state.templates.templates && state.templates.templates.find(item => (item.get("isStandardTemplate") && item.get("category") === "Reviews")),
      feedbackInvite: state.templates && state.templates.templates && state.templates.templates.find(item => (item.get("isStandardTemplate") && item.get("category") === "Feedback")),
      feedbackSettings: state.feedbackSettings.settings,
      reviewSettings: state.reviewsSettings.settings,
    }), shallowEqual
  )
  

  const [conf, setConf] = useState(false);
  const [processing, setProcessing] = useState(false);  

  async function handleInviteSubmit(values, actions) { //--> for both review and feedback we pretty much do the same things

    if (actionStatus && actionStatus != "saving") {
          setProcessing(true);

          //--> get the invite template
          let templ;

          if (values.mode === "FEEDBACK") templ = feedbackInvite;
          else if (values.mode === "REVIEW") templ = reviewInvite;

          //--> process the template content to get message text and applinks
          let attributes = Textopia.useGetCustomerAndBusinessAttributes(null, null, businessAttributes, "BUSINESS");
          let message = await Textopia.getMessageTextFromMixed(templ && templ.get("content"), attributes, null);
          let messageText = message && message.messageText;
          let applinks = message && message.applinks; //--> links generated from template content

          //--> we'll let cloud code run the sendSMS and return customer to us, so we can create the connection between customer and applink
          //--> note that cloud code will try to lookup or create a new customer, if needed, based on the supplied to: phone

          //--> figure out any attached image
          let mediaUrl;  
          let settings;
          if(values.mode === "FEEDBACK") settings = feedbackSettings;
          else if (values.mode === "REVIEW") settings = reviewSettings;

          if (settings.get("includeImage") && settings.get("includedImage")) {
            mediaUrl = settings.get("includedImage").url();
            mediaUrl = mediaUrl.replace(Constants.LOCAL_URL, Constants.NGROK_URL);
          }

          //console.log(message && message.messageText);

          let messageObj = {
            to: values.phone,
            from: Parse.User.current().get("primaryLocationPtr").get("smsAddress"),
            text: message.messageText, 
            location: Parse.User.current().get("primaryLocationPtr").id,
            tenant: Parse.User.current().get("tenantPtr").id,
            mode: "SMS",
            mediaUrl: [mediaUrl], //--> remember this has to be an array!!
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email
          }

          //console.log(messageObj);

          const cloudResponse = await Parse.Cloud.run("sendMessage", messageObj); 

          //--> process template tags applinks, if any
          
          let myVar = await Textopia.processApplinks(applinks, messageText, cloudResponse && cloudResponse.customer);  
          setProcessing(false);
          actions.resetForm();
          setConf(true);
          setTimeout(() => setConf(false), 1000);
    }
  }

  var initialValues = ({firstName: '', lastName: '', email: '', phone: '', mode: "REVIEW"})


  let dispatch = useDispatch();  
  
  const [activePill, setActivePill] = useState("0");

  return (
    <>
      <div className="pt-lg-5 pt-3 mx-n5 mx-lg-n7">
  
      <div className="px-5 px-lg-7">

      <TextopiaNotice notice="Send rapid Review & Feedback invites over SMS.  Phone number below is required." />
      <Tab.Container defaultActiveKey={activePill}>
        <Nav variant="pills" className="d-flex flex-row align-items-center w-100 justify-content-middle py-3 px-3 px-lg-5 bg-white mb-3 mb-lg-3" onSelect={(eventKey) => setActivePill(eventKey)}>

          <Nav.Item className="nav-item mr-7">
            <Nav.Link eventKey="0" className={`m-0 pb-5 pt-0 px-3 rounded-lg ${activePill == "0" ? "border border-2 border-light-primary bg-primary-o-20" : "border border-white border-2"} `}>
              <TextopiaButton icon="flaticon-star" text="Review Invite" iconColor={` ${activePill == "0" ? "text-primary" : "text-warning"} `} textColor={` ${activePill == "0" ? "text-primary" : ""} `}  />   
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item mr-0">
            <Nav.Link eventKey="1" className={`m-0 pb-5 pt-0 px-3 rounded-lg  ${activePill == "1" ? "border border-2 border-light-primary bg-primary-o-20" : "border border-white border-2"} `}>
              <TextopiaButton icon="flaticon2-reload" text="Feedback Invite"  iconColor={` ${activePill == "1" ? "text-primary" : "text-primary-d-3"} `} textColor={` ${activePill == "1" ? "text-primary" : ""} `}/> 
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <div className="separator separator-solid separator-secondary mb-8 mt-6" />
      
        <Tab.Content>                 

          <Tab.Pane eventKey="0">
            
            <Formik
              validationSchema={yup.object({phone: yup.string().required("Required")})}
              initialValues = {initialValues}
              onSubmit={handleInviteSubmit}
              enableReinitialize={true}
            >
            { formik => (   
              <>
                  <Form noValidate onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    {conf && (<TextopiaConfBox message="Review Invite Sent!"/>)}                    

                    <TextopiaPhoneInput label="Phone" name="phone" formik={formik} />

                    <TextopiaTextInput label="First Name" name="firstName" formik={formik} helpText="Optional"/>
                    <TextopiaTextInput label="Last Name" name="lastName" formik={formik} helpText="Optional"/>
                    <TextopiaTextInput label="Email" name="email" formik={formik} helpText="Optional"/>
                                    
                    
                    <button type="submit" disabled={formik.isSubmitting || actionStatus === "saving" || processing} className={`btn font-weight-bold font-size-base btn-primary px-9 my-3 `} >
                        <span>Send</span>
                        {((actionStatus === "saving") || processing) && <span className="ml-4 spinner spinner-white"></span>}
                    </button>
                  </Form>

              </>
            )}
            </Formik>

          </Tab.Pane>

          <Tab.Pane eventKey="1">
            <Formik
              validationSchema={yup.object({phone: yup.string().required("Required")})}
              initialValues = {{firstName: '', lastName: '', email: '', phone: '', mode: "FEEDBACK"}}
              onSubmit={handleInviteSubmit}
              enableReinitialize={true}
            >
            { formik => (   
              <>
                  <Form noValidate onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    {conf && (<TextopiaConfBox message="Feedback Invite Sent!"/>)}                    

                    <TextopiaPhoneInput label="Phone" name="phone" formik={formik} />

                    <TextopiaTextInput label="First Name" name="firstName" formik={formik} helpText="Optional"/>
                    <TextopiaTextInput label="Last Name" name="lastName" formik={formik} helpText="Optional"/>
                    <TextopiaTextInput label="Email" name="email" formik={formik} helpText="Optional"/>
                                    
                    
                    <button type="submit" disabled={formik.isSubmitting || actionStatus === "saving" || processing} className={`btn font-weight-bold font-size-base btn-primary px-9 my-3 `} >
                        <span>Send</span>
                        {((actionStatus === "saving") || processing ) && <span className="ml-4 spinner spinner-white"></span>}
                    </button>
                  </Form>

              </>
            )}
            </Formik>
          </Tab.Pane>

        </Tab.Content>
      </Tab.Container>
        

      </div>
     </div>
    </>
  )
}

 