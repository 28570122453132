import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { useParams, Route } from "react-router-dom";
import {ContentRoute} from "../../../_metronic/layout"
import { FeedbackApp } from "./FeedbackApp";
import Parse from "parse";



export function Apps ({}) {

    const constants = {
        REVIEW_APP      : "Review Invite Link",
        FEEDBACK_APP    : "Feedback Invite Link",
    }

    let { id } = useParams();

    const [error, setError] = useState(false);
    const [appId, setAppId] = useState('');
    const [applink, setApplink] = useState('');
    const [settings, setSettings] = useState('');

    useEffect(() => {
        setError( id ? false : true); //--> make sure an id is passed in, otherwise error
        setAppId( id ? id : ''); //--> set the appId for the next stage
    }, [id])

    
    const lookupApplink = async () => {
        if (appId) {
            //--> lookup the app instance
            let q = new Parse.Query("Applink");
            q.equalTo("appId", appId); 
            q.include("locationPtr");           
            let applink = await q.first();
            if (!applink) setError(true); //--> make sure appId translates to a real app, otherwise error
            if (applink) {
                setError(false); 
                setApplink(applink);

                if (applink.get("appType") === constants.FEEDBACK_APP) {
                    //--> also lookup this tenant's feedback settings to see if branding is needed
                    let setQ = new Parse.Query("FeedbackAppSettings");
                    setQ.equalTo("tenantPtr", applink.get("tenantPtr"));
                    let settings = await setQ.first();
                    if (settings) {
                        setSettings(settings);
                    }
                }
            }
            //console.log(appLink);
        }
    };

    useEffect(() => {
        lookupApplink();        
    }, [appId])


    

    

    return (
            <>
              {error && (
                <div id="app-container" className="bg-primary">              
                
                <div id="app-header">
                      <div className="app-header-content pt-5">
                      </div>                    
                </div>

                <div id="main-content" className="px-8 px-lg-15">                                        
                    <div className="text-white font-size-h1 font-weight-bold text-center">
                        There seems to be an error!
                    </div>
                </div>
                
                <div id="app-footer">
                    <div className="app-footer-content pb-5">
                        <div className="text-primary-l-1 font-size-h5 font-weight-normal mb-1">Powered By</div>
                        <img src="/media/Textopia/Logos/logo_white.svg" className="w-lg-125px w-100px" />
                    </div>
                </div>

              </div>
              )}
              
              {!error && applink && settings && applink.get("appType") === constants.FEEDBACK_APP && (
                <FeedbackApp applink={applink} settings={settings} setError={setError} />
              )}              
         </>

    )
}