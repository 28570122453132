import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as Textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";
import * as Queries from "../../../../../_metronic/_assets/js/textopia/TextopiaQueries";
import {KTUtil} from "../../../../../_metronic/_assets/js/components/util";


const initialState = {

  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,

  questions: [],
  updatedAt: new Date(),
  questionData: [],
  questionDataUpdatedAt: new Date(),

};

export const fetchQuestionData = createAsyncThunk('feedbackSettings/fetchQuestionData', async (values) => {
  let cloudResponse = await Parse.Cloud.run("fetchQuestionData", {questionId: values && values.selectedQuestion.id});
  return cloudResponse;
});

export const toggleQuestion = createAsyncThunk('questions/toggleQuestion', async (id) => {
  const cloudResponse = await Parse.Cloud.run("toggleQuestion", {questionId: id});
  return cloudResponse;  
})

export const fetchQuestions = createAsyncThunk('questions/fetchQuestions', async () => {
  const cloudResponse = await Parse.Cloud.run("fetchQuestions");
  return cloudResponse;  
})

export const saveQuestion = createAsyncThunk('questions/saveQuestion', async (values) => {
  const cloudResponse = await Parse.Cloud.run("saveQuestion", {values: values});
  return cloudResponse;
})

export const deleteQuestion = createAsyncThunk('questions/deleteQuestion', async (id) => {
  const cloudResponse = await Parse.Cloud.run("deleteQuestion", {questionId: id} ); // Sending of Parse objects is not allowed here for some reason, so have to send ID only
  return cloudResponse;  
})

//--> this one used by LQ
export const updateQuestionData = createAsyncThunk('feedbackSettings/updateQuestionData', async (values) => { 
  return values;
});

//--> this one used by LQ and it sends us the affected object as values
export const updateQuestion = createAsyncThunk('questions/updateQuestion', async (values) => {
  return values;
})



const entityType = "questions";

export const questionsSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
      // All questions for a location
      [fetchQuestions.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchQuestions.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state[entityType] = action.payload //Query returns an array of objects
        state.updatedAt = new Date();
      },
      [fetchQuestions.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },

      // Data for one question
      [fetchQuestionData.pending]: (state, action) => {
        state.actionStatus = 'loading'
      },
      [fetchQuestionData.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.questionData = action.payload //Query returns the settings record for this question
        state.questionDataUpdatedAt = new Date();
      },
      [fetchQuestionData.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },  

       // Data for one question - this one used by LQ
      [updateQuestionData.pending]: (state, action) => {
        state.actionStatus = 'loading'
      },
      [updateQuestionData.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        
        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
          // nothing
          state.questionDataUpdatedAt = new Date();
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          console.log('new question response');
          //--> check for duplicate since user could be adding and get an update from above edit question call
          if(!state.questionData.find(response => response.id === action.payload.obj.id)) {
            state.questionData.unshift(action.payload.obj);
          } 
          state.questionDataUpdatedAt = new Date();
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {
          console.log('question response removed');          
          state.questionData = state.questionData && state.questionData.filter(o => o.id !== action.payload.obj.id);
          state.questionDataUpdatedAt = new Date();
        }

      },
      [updateQuestionData.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },
             

      // Save question
      [saveQuestion.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveQuestion.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'

        if(!state[entityType].find(question => question.id == action.payload.id)) {
          state[entityType].unshift(action.payload);
        } 
        state.updatedAt = new Date();
      },
      [saveQuestion.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Toggle question
      [toggleQuestion.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [toggleQuestion.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [toggleQuestion.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Update question //--> this one used by LQ
      [updateQuestion.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updateQuestion.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'

        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
          // nothing
          state.updatedAt = new Date();
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          console.log('new question');
          //--> check for duplicate since user could be adding and get an update from above edit question call
          if(!state[entityType].find(question => question.id === action.payload.obj.id)) {
            state[entityType].unshift(action.payload.obj);
          } 
          state.updatedAt = new Date();
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {
          console.log('question removed');          
          state.questions = state.questions && state.questions.filter(o => o.id !== action.payload.obj.id);
          state.updatedAt = new Date();
        }

      },
      [updateQuestion.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },


      // Delete question
      [deleteQuestion.pending]: (state, action) => {
        state.actionStatus = 'deleting'
      },
      [deleteQuestion.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded';

        // Remove the deleted user from the users list
        state[entityType] = state[entityType].filter(o => o.id !== action.payload.id);
        state.updatedAt = new Date();
      },
      [deleteQuestion.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      }


    },


   

    reducers: {

    
  }
});

export default questionsSlice.reducer
