/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, {useState, useEffect} from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import {Tab, Nav} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../../_helpers";


import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { saveUser, savePassword, saveNotifPrefs, logoutUser } from "../../header/locationSlice";
import { clearUserId } from "../../header/userSlice";
import { KTUtil } from "../../../../../_metronic/_assets/js/components/util.js";
import * as Textopia  from "../../../../../_metronic/_assets/js/textopia/textopia_utils.js";
import * as Constants  from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants.js";
import { TextopiaPhoneInput, TextopiaErrorMessage, TextopiaTextInput, TextopiaConfBox, TextopiaButtonSet, TextopiaSpinnerCardless, TextopiaSingleImageUpload, TextopiaOnOffSwitchListItem } from "../../../../../_metronic/_assets/js/textopia/TextopiaControls";

import 'react-animation/dist/keyframes.css';
import { animations } from 'react-animation';
import { AnimateOnChange } from 'react-animation';

import { Formik } from "formik";
import * as yup from "yup";

import Parse from "parse"; //--> OMG - I had forgotten to import Parse, and it didn't complain for some reason, and tearing my hear out that Parse.User.current() was returning some old stale value!!!  Check this import everywhere!


export function QuickUser({store}) {

  const history = useHistory();
  const role = Parse.User.current().get("userRolePtr").attributes;

  const [selectedTab, setSelectedTab] = useState("Profile");
  const setTab = _tabName => {
    setSelectedTab(_tabName);
  };

  const logout = (e) => {
    e.preventDefault();
    if (actionStatus && actionStatus != "saving") {
      window.Beacon('destroy'); //--> remove the helpscout beacon
      setConf(true)
      setTimeout(() => {
        
        setConf(false);
        KTUtil.getById("kt_quick_user_close").click(); 
        
        dispatch(clearUserId(false)).then(() => { //--> the order of these two commands makes a huge difference because dispatch is async, so make sure user is cleared out first, then push the history state so we end up on login page instead of trying to render the main page again with a use who is gone
          dispatch(logoutUser()).then(() => {
           
            //--> you have to call dispatch which then affects the state of redux, calling the function directly has not impact
            //--> we want to make sure that inbetween logins, the complete state is cleared out
            dispatch({type: 'LOGOUT'}); //--> this is a very important dispatch, it goes to the rootReducer and that clears out the entire redux state, except the userId is persisted, and we dont want to clear that - that and Parse.User.current() manage the logged/in/out state
            history.push("/logout");                            
          })          
        });  
      }, 1200)
      
    }
  }

  useEffect(() => {}, []); //--> to help with the re-render, and actionStatus is already looked for below

  let user = Parse.User.current();

  let initialValues = ({
                        firstName: user && user.get("firstName"),
                        lastName: user && user.get("lastName"),
                        email: user && user.get("email"),
                        phone: user && user.get("phone"),
                        jobTitle: user && user.get("jobTitle"),
                        role: user && user.get("userRolePtr").get("name"),
                        profilePic: user && user.get("profilePic"),
                        locationList: user && user.get("locationList") && user.get("locationList").length > 0 && user.get("locationList").map((loc, index) => (index > 0 ? " " : "") + loc.get("shortName"))
                      })

  let changePasswordInitialValues = ({
                                      currentPassword: '',
                                      newPassword: '',
                                      verifyPassword: '',                                      
                                    })

  let notifsInitialValues =   ({ //--> all prefs might exist in the profile (except first time when it will be empty) - so the pref has to exist and it has to be true - simply existing is not enough since it could be false
                                daily:            user && user.get("notifPrefs") && user.get("notifPrefs").find(item => item.prefName === Constants.PREFS_DAILY_EMAIL && item.prefValue)      ? true : false,
                                weekly:           user && user.get("notifPrefs") && user.get("notifPrefs").find(item => item.prefName === Constants.PREFS_WEEKLY_EMAIL && item.prefValue)     ? true : false,
                                myConvos:         user && user.get("notifPrefs") && user.get("notifPrefs").find(item => item.prefName === Constants.PREFS_MY_CONVOS && item.prefValue)        ? true : false,
                                myTeamsConvos:    user && user.get("notifPrefs") && user.get("notifPrefs").find(item => item.prefName === Constants.PREFS_MY_TEAMS_CONVOS && item.prefValue)  ? true : false,
                                unassignedConvos: user && user.get("notifPrefs") && user.get("notifPrefs").find(item => item.prefName === Constants.PREFS_UNASSIGNED_CONVOS && item.prefValue) ? true : false,
                                convoAssigned:    user && user.get("notifPrefs") && user.get("notifPrefs").find(item => item.prefName === Constants.PREFS_CONVO_ASSIGNED && item.prefValue)   ? true : false,
                                taskAssigned:     user && user.get("notifPrefs") && user.get("notifPrefs").find(item => item.prefName === Constants.PREFS_TASK_ASSIGNED && item.prefValue)    ? true : false,
                                newReview:        user && user.get("notifPrefs") && user.get("notifPrefs").find(item => item.prefName === Constants.PREFS_NEW_REVIEW && item.prefValue)       ? true : false,
                                newFeedback:      user && user.get("notifPrefs") && user.get("notifPrefs").find(item => item.prefName === Constants.PREFS_NEW_FEEDBACK && item.prefValue)     ? true : false,
                                newPayment:       user && user.get("notifPrefs") && user.get("notifPrefs").find(item => item.prefName === Constants.PREFS_NEW_PAYMENT && item.prefValue)      ? true : false,
                              })
  
  const {actionStatus, actionError} = Textopia.useGetActionStatusAndError("location");

  let dispatch = useDispatch();
  async function handleProfileSubmit(values, actions) {
    if (actionStatus && actionStatus != "saving") {
          await dispatch(saveUser(values));

          if (actionStatus && actionStatus != "saving") {
                    setConf(true);
                    setTimeout(() => setConf(false), 1000);
          }
    }
  }

  async function handlePasswordSubmit(values, actions) {
    if (actionStatus && actionStatus != "saving") {
          await dispatch(savePassword(values));

          if (actionStatus && actionStatus != "saving") {
                    setConf(true);
                    setTimeout(() => { //--> briefly shows confirmation screen and resets everything (form and visibility state)
                      setConf(false); 
                      actions.resetForm(); 
                      setVisible(false)
                    }, 1000);
          }
    }
  }

  async function handleNotifsSubmit(values, actions) {
    if (actionStatus && actionStatus != "saving") {
          await dispatch(saveNotifPrefs(values));

          if (actionStatus && actionStatus != "saving") {
                    setConf(true);
                    setTimeout(() => { //--> briefly shows confirmation screen and resets everything (form and visibility state)
                      setConf(false); 
                    }, 1000);
          }
    }
  }

  const [visible, setVisible] = useState(false);
  function toggleVisibility() { //--> for the visibility button to work, the field must be supplied an ID
    var x = document.getElementById("current_pwd");
    var y = document.getElementById("new_pwd");
    var z = document.getElementById("verify_pwd");

    if (x.type === "password") {
      x.type = "text";
      setVisible(true);
    } else {
      x.type = "password";
      setVisible(false);
    }

    if (y.type === "password") {
      y.type = "text";
    } else {
      y.type = "password";
    }

    if (z.type === "password") {
      z.type = "text";
    } else {
      z.type = "password";
    }
    
  }


  const userValidationSchema = yup.object({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    email: yup.string().email('Invalid email address!').required('Required'),
    role: yup.string().required('Required'),
    locationList: yup.array().of(
      yup.string().required('Required')
    ).required('Required')
  });

  const changePasswordValidationSchema = yup.object({
    currentPassword:  yup.string()
                      .required("Required")
                      .test(  'matchRequired',
                              'Current Password is incorrect',
                              function (value) {      
                                return new Promise((resolve, reject) => {
                                  Parse.User.verifyPassword(Parse.User.current().get("username"), value).then(() => {
                                    resolve(true);
                                  }).catch(() => {
                                    resolve(false);
                                  })                                
                                })                                
                              }
                      ),

    newPassword:      yup.string()
                      .required("Required")
                      .min(8, "Min 8 characters"),

    verifyPassword:   yup.string()
                      .oneOf([yup.ref("newPassword")], "Passwords do not match")
                      .required("Required"),
  });



  const [conf, setConf] = useState(false);

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-right pt-5 pb-10 overlay overlay-block">
      <Tab.Container
          defaultActiveKey={selectedTab}
      >

      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">

        <Nav onSelect={setTab} as="ul" role="tablist" className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10">

          <Nav.Item className="nav-item" as="li">
            <Nav.Link eventKey="Profile" className={`nav-link ${
                selectedTab === "Profile" ? "active" : ""
              }`}
            >
              Profile
            </Nav.Link>
          </Nav.Item>

          <Nav.Item className="nav-item" as="li">
            <Nav.Link eventKey="Notifs" className={`nav-link ${
                selectedTab === "Notifs" ? "active" : ""
              }`}
            >
              Notifs
            </Nav.Link>
          </Nav.Item>

          <Nav.Item className="nav-item" as="li">
            <Nav.Link eventKey="Password" className={`nav-link ${
                selectedTab === "Password" ? "active" : ""
              }`}
            >
              Password
            </Nav.Link>
          </Nav.Item>

          <Nav.Item className="nav-item" as="li">
            <Nav.Link eventKey="Logout" className={`nav-link ${
                selectedTab === "Logout" ? "active" : ""
              }`}
            >
              Logout
            </Nav.Link>
          </Nav.Item>

        </Nav>

        <div className="offcanvas-close" style={{position: "absolute", top: "-13px", right: "5px"}}>
          <a
              href=""
              className="btn btn-xs btn-clean bg-transparent btn-icon btn-icon-muted btn-hover-icon-primary"
              id="kt_quick_user_close"
          >
            <i className="la la-close icon-lg"></i>
          </a>
        </div>
      </div>

      <div className="offcanvas-content px-10 overlay-wrapper">
          <div className="tab-content">

            <div className={`tab-pane fade pt-3 pr-5 mr-n5 ${selectedTab === "Profile" ? "active show" : ""}`} id="aaaa" role="tabpanel">
            <>
                        
                        <Formik
                          validationSchema={userValidationSchema}
                          initialValues={initialValues}
                          onSubmit={handleProfileSubmit}
                          enableReinitialize={true}
                        >
                        { formik => (   //--> assumption is that indiv users can change their name, email, phone, but not their job title (unless allowed, like the super users) or their roles or locations
                                        //--> highest user will be given a role by Textopia, and he cannot lower that role, also, locations will be setup by Textopia and cannot be removed/added by user
                                        //--> admin users can assign role/title/locations to other users
                          <>
                                   
                              <Form id="my_user_form" className="pt-5   " noValidate onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                                {conf && (<div className="overlay-layer"><TextopiaConfBox /></div>)}

                                <TextopiaSpinnerCardless saving={actionStatus} color="warning" type="track" />
                                <div className="text-center">
                                  <div className="symbol symbol-60 symbol-circle symbol-xxl-100 mr-5 align-self-center align-self-xxl-center">
                                    <TextopiaSingleImageUpload className="c_fadeIn" name="profilePic" placeholder="/media/users/blank.png" formik={formik} />                  
                                  </div>
                                </div>

                                <div className="separator separator-solid mt-5 mb-5" />

                                <TextopiaTextInput label="First Name" name="firstName" formik={formik}/>
                                <TextopiaTextInput label="Last Name" name="lastName" formik={formik}/>
                                <TextopiaPhoneInput label="Phone" name="phone" formik={formik} initialVal={initialValues.phone}/>
                                
                                <TextopiaTextInput label="Email" disabled name="email" formik={formik} helpText="Contact admin to change"/>
                                <TextopiaTextInput label="Job Title" disabled name="jobTitle" formik={formik} helpText="Contact admin to change"/>                            
                                <TextopiaTextInput label="Role" disabled name="role" formik={formik} helpText="Contact admin to change"/>
                                <TextopiaTextInput label="Locations" disabled name="locationList" formik={formik} helpText="Contact admin to change"/>
                                <TextopiaButtonSet save />
                              </Form>
                                                                      
                          </>
                        )}
                        </Formik>

               </>
               </div>

            <div className={`tab-pane fade pt-3 pr-5 mr-n5  ${selectedTab === "Notifs" ? "active show" : ""}`} id="bbbb" role="tabpanel">
              
              <Formik
                //validationSchema={changePasswordValidationSchema}
                initialValues={notifsInitialValues}
                onSubmit={handleNotifsSubmit}
                enableReinitialize={true}
              >
              { formik => (   //--> assumption is that indiv users can change their name, email, phone, but not their job title (unless allowed, like the super users) or their roles or locations
                              //--> highest user will be given a role by Textopia, and he cannot lower that role, also, locations will be setup by Textopia and cannot be removed/added by user
                              //--> admin users can assign role/title/locations to other users
                <>
                
                <Form id="notif_prefs_form" className="pt-5" noValidate onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    
                    {conf && (<div className="overlay-layer"><TextopiaConfBox /></div>)}
                    <TextopiaSpinnerCardless saving={actionStatus} color="warning" type="track" />
                    {["SUPER_USER", "OWNER", "LOCATION_MANAGER"].includes(role.name_id) &&
                    <>
                    <div>                    
                      <h5 className="font-weight-bold mb-3">Standard Email Summaries</h5>
                      <div className="form-group mb-0 row align-items-center">
                        <label className="col-8 col-form-label">Daily every morning
                          <span className="form-text text-muted">Previous day's activity</span>
                        </label>
                        <div className="col-4 d-flex justify-content-end">
                          <TextopiaOnOffSwitchListItem name="daily" formik={formik} />
                        </div>
                      </div>
                      <div className="form-group mb-0 row align-items-center">
                        <label className="col-8 col-form-label">Weekly monday morning
                          <span className="form-text text-muted">Previous week's activity</span>
                        </label>
                        <div className="col-4 d-flex justify-content-end">
                          <TextopiaOnOffSwitchListItem name="weekly" formik={formik} />
                        </div>
                      </div>
                    </div>
                    {/*
                    <div className="separator separator-dashed my-6" />
                    <div className="pt-2">
                      <h5 className="font-weight-bold mb-3">New Message Notifs</h5>
                      <div className="form-group mb-0 row align-items-center">
                        <label className="col-8 col-form-label">My convos
                        </label>
                        <div className="col-4 d-flex justify-content-end">
                          <TextopiaOnOffSwitchListItem name="myConvos" formik={formik} />
                        </div>
                      </div>
                      <div className="form-group mb-0 row align-items-center">
                        <label className="col-8 col-form-label">My team's convos
                        </label>
                        <div className="col-4 d-flex justify-content-end">
                          <TextopiaOnOffSwitchListItem name="myTeamsConvos" formik={formik} />
                        </div>
                      </div>
                      <div className="form-group mb-0 row align-items-center">
                        <label className="col-8 col-form-label">Unassigned convos
                        </label>
                        <div className="col-4 d-flex justify-content-end">
                          <TextopiaOnOffSwitchListItem name="unassignedConvos" formik={formik} />
                        </div>
                      </div>
                    </div>
                    */}
                    <div className="separator separator-dashed my-6" />
                    </>
                    }

                
                    <div className="pt-2">
                      <h5 className="font-weight-bold mb-3">New Assignment Notifs</h5>
                      <div className="form-group mb-0 row align-items-center">
                        <label className="col-8 col-form-label">Convo assigned
                        </label>
                        <div className="col-4 d-flex justify-content-end">
                          <TextopiaOnOffSwitchListItem name="convoAssigned" formik={formik} />
                        </div>
                      </div>
                      <div className="form-group mb-0 row align-items-center">
                        <label className="col-8 col-form-label">Task assigned
                        </label>
                        <div className="col-4 d-flex justify-content-end">
                          <TextopiaOnOffSwitchListItem name="taskAssigned" formik={formik} />
                        </div>
                      </div>
                    </div>
                    

                    <div className="separator separator-dashed my-6" />

                    {["SUPER_USER", "OWNER", "LOCATION_MANAGER"].includes(role.name_id) &&
                    <>
                    <div className="pt-2">
                      <h5 className="font-weight-bold mb-3">New Item Notifs</h5>
                      {/*
                      <div className="form-group mb-0 row align-items-center">
                        <label className="col-8 col-form-label">Review</label>
                        <div className="col-4 d-flex justify-content-end">
                          <TextopiaOnOffSwitchListItem name="newReview" formik={formik} />
                        </div>
                      </div>
                      <div className="form-group mb-0 row align-items-center">
                        <label className="col-8 col-form-label">Feedback</label>
                        <div className="col-4 d-flex justify-content-end">
                          <TextopiaOnOffSwitchListItem name="newFeedback" formik={formik} />
                        </div>
                      </div>
                      */}
                      <div className="form-group mb-0 row align-items-center">
                        <label className="col-8 col-form-label">Payment received</label>
                        <div className="col-4 d-flex justify-content-end">
                          <TextopiaOnOffSwitchListItem name="newPayment" formik={formik} />
                        </div>
                      </div>

                    </div>
                    
                    <div className="separator separator-dashed my-6" />
                    </>
                    }
                    
                    
                    {/*
                    
                    <div className="pt-2">
                      <h5 className="font-weight-bold">Custom Dashboard Reports</h5>
                      <span className="form-text text-muted mb-3">These can be setup in App dashboards</span>
                      <div className="form-group mb-0 row align-items-center">
                        <label className="col-9 col-form-label">Forms - Total Responses
                          <div>
                            <label className="label label-inline text-dark-50 label-light-warning label-sm">Last 12 months </label>
                            <label className="label label-inline text-dark-50 label-light-warning label-sm">By month</label>
                            <label className="label label-inline text-dark-50 label-light-warning label-sm">All locations</label>
                          </div>
                        </label>
                        <div className="col-3 d-flex justify-content-end">
                          <label>
                            <a href="" className="btn btn-icon btn-light-danger btn-hover-danger btn-sm" id="n_custom_report">
                             <i className="la la-trash icon-lg" />
                            </a>
                            <span />
                          </label>
                        </div>
                      </div>
                      <div className="form-group mb-0 row align-items-center">
                        <label className="col-9 col-form-label">Reviews - Average review rating
                          <div>
                            <label className="label label-inline text-dark-50 label-light-warning label-sm">Month-to-date</label>
                            <label className="label label-inline text-dark-50 label-light-warning label-sm">By week</label>
                            <label className="label label-inline text-dark-50 label-light-warning label-sm">San Francisco, CA</label>
                          </div>
                        </label>
                        <div className="col-3 d-flex justify-content-end">
                          <label>
                            <a href="" className="btn btn-icon btn-light-danger btn-hover-danger btn-sm" id="n_custom_report">
                              <i className="la la-trash icon-lg" />
                            </a>
                            <span />
                          </label>
                        </div>
                      </div>
                      <div className="form-group mb-0 row align-items-center">
                        <label className="col-9 col-form-label">Feedback - Surveys sent
                          <div>
                            <label className="label label-inline text-dark-50 label-light-warning label-sm">Last week</label>
                            <label className="label label-inline text-dark-50 label-light-warning label-sm">By week</label>
                            <label className="label label-inline text-dark-50 label-light-warning label-sm">Atlanta, GA</label>
                          </div>
                        </label>
                        <div className="col-3 d-flex justify-content-end">
                          <label>
                            <a href="" className="btn btn-icon btn-light-danger btn-hover-danger btn-sm" id="n_custom_report">
                              <i className="la la-trash icon-lg" />
                            </a>
                            <span />
                          </label>
                        </div>
                      </div>
                    </div>
                    */}


                    <TextopiaButtonSet save />
                      
                  </Form>
                                  
              </>
              )}
              </Formik>


            </div>

            <div className={`tab-pane fade pt-3 pr-5 mr-n5  ${selectedTab === "Password" ? "active show" : ""}`} id="cccc" role="tabpanel">
              
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex flex-row align-items-center justify-content-start flex-grow-1">
                      <div className="symbol symbol-45 symbol-white mr-3">
                        <span className="symbol-label">
                          <i className="flaticon2-lock icon-2x text-danger" />
                        </span>
                      </div>
                      <div className="font-weight-bold font-size-h5 text-dark">Change Password</div>                    
                    </div>
                    <span className="btn btn-icon btn-clean" onClick={() => toggleVisibility()}><i className={`la la-${visible ? "eye" : "eye-slash"} icon-lg`} /></span>
                  </div>
                  <div className="separator separator-solid my-6" />


                  <>
                        
                        <Formik
                          validationSchema={changePasswordValidationSchema}
                          initialValues={changePasswordInitialValues}
                          onSubmit={handlePasswordSubmit}
                          enableReinitialize={true}
                        >
                        { formik => (   //--> assumption is that indiv users can change their name, email, phone, but not their job title (unless allowed, like the super users) or their roles or locations
                                        //--> highest user will be given a role by Textopia, and he cannot lower that role, also, locations will be setup by Textopia and cannot be removed/added by user
                                        //--> admin users can assign role/title/locations to other users
                          <>
                        
                            <Form id="change_password_form" className="pt-5" noValidate onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                                {conf && (<div className="overlay-layer"><TextopiaConfBox /></div>)}
                                <TextopiaSpinnerCardless saving={actionStatus} color="warning" type="track" />                                                

                                <TextopiaTextInput password={true} label="Current Password" name="currentPassword" formik={formik} id="current_pwd"/>
                                <TextopiaTextInput password={true} label="New Password" name="newPassword" formik={formik} id="new_pwd"/>
                                <TextopiaTextInput password={true} label="Verify Password" name="verifyPassword" formik={formik} id="verify_pwd"/>                                               
                                <TextopiaButtonSet clear save cleanup={() => formik.handleReset}/>
                            </Form>
                                    
                          </>
                        )}
                        </Formik>

                   </>
                  
                
            </div>

            <div className={`tab-pane fade pt-10 pr-5 mr-n5  ${selectedTab === "Logout" ? "active show" : ""}`} id="dddd" role="tabpanel">
              {conf && (<div className="overlay-layer"><TextopiaConfBox message="Logged Out!"/></div>)}
              <TextopiaSpinnerCardless saving={actionStatus} color="warning" type="track" />
              <div className="d-flex flex-row flex-grow-1"><span className="btn btn-block btn-md btn-light-primary font-weight-bold" onClick={(e) => logout(e)}><i className="la la-lock-open mr-1" />Logout</span></div>
            </div>

          </div>
        </div>

      </Tab.Container>

    </div>


  );
}


