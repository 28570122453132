import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import { Parse } from "parse";

import 'react-animation/dist/keyframes.css';
import { animations } from 'react-animation';
import { AnimateOnChange } from 'react-animation';

const initialValues = {
  new_password: "",
  confirm_new_password: "",
};


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ResetPassword(props) {
  const { intl } = props;
    
  let query = useQuery();
  let status = query && query.get('status');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const ResetPasswordSchema = Yup.object().shape({
    new_password: Yup.string()
      .min(8, "Minimum 8 chars")
      .max(50, "Maximum 50 chars")
      .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    confirm_new_password: Yup.string()
      .min(8, "Minimum 8 chars")
      .max(50, "Maximum 50 chars")
      .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  var base = "https://textopia.b4a.app";
  var id = "6AJfa2enUPtiqpzcGWzeGenVVUMeI9JUkxCuz5H8";
  var urlParams = {};

  useEffect(() => {
   
    if (!status) {
               
        var pair, // Really a match. Index 0 is the full match; 1 & 2 are the key & val.
            tokenize = /([^&=]+)=?([^&]*)/g,
            // decodeURIComponents escapes everything but will leave +s that should be ' '
            re_space = function (s) { return decodeURIComponent(s.replace(/\+/g, " ")); },
            // Substring to cut off the leading '?'
            querystring = window.location.search.substring(1);

        while (pair = tokenize.exec(querystring))
           urlParams[re_space(pair[1])] = re_space(pair[2]);
                
        if (!urlParams['username'] || !urlParams['token']) return;
        console.log(base, id);
        
        document.getElementById('form').setAttribute('action', base + '/apps/' + id + '/request_password_reset');
        document.getElementById('form').onsubmit = () => setLoading(true);
        document.getElementById('username').value = urlParams['username'];
        document.getElementById('username_label').appendChild(document.createTextNode(urlParams['username']));
        document.getElementById("password").oninput = validatePassword;
        document.getElementById("password_confirm").oninput = validatePassword;
        document.getElementById("change_password").disabled = true;

        document.getElementById('token').value = urlParams['token'];
        if (urlParams['error']) {
          setError(urlParams['error']);
          //document.getElementById('error').appendChild(document.createTextNode(urlParams['error']));

        }
        if (urlParams['app']) {
          //document.getElementById('app').appendChild(document.createTextNode(' for ' + urlParams['app']));
        }

        function validatePassword() {
          var pass2 = document.getElementById("password").value;
          var pass1 = document.getElementById("password_confirm").value;
          if(pass1 !== pass2) {
            document.getElementById("change_password").disabled = true;
            if(document.getElementById("password_confirm").value) {          
              document.getElementById("password_match_info").innerHTML = "Passwords must match";
            }
          } else {
            document.getElementById("change_password").disabled = false;	 
            document.getElementById("password_match_info").innerHTML = "";
          }
          //empty string means no validation error
        }
    }
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
  });

  

  return (
    <>

        <div className="login-form login-forgot" style={{ display: "block", animation: animations.fadeInUp }}>
          <div className="text-center mb-10 mb-lg-10">
            <h3 className="font-size-h1">{ (status && status.startsWith("success")) ? "Password Changed!" : (status && status.startsWith("invalid")) ? "Invalid Link" : "Reset Password"}</h3>
            {!status &&
            <div className="text-muted font-weight-bold">
              Please choose a new password for
              <div id="username_label" className="text-muted"></div>
            </div>
            }
          </div>
          {!status &&
          <form action="" method="POST" id="form" className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp px-0 mx-0" noValidate>

            {error && <div id="error" className="mb-10 alert alert-custom alert-light-danger alert-dismissible c_fadeIn alert-text text-danger font-weight-bold">{error}</div>}
            
            <div className="form-group fv-plugins-icon-container">
              <input type="password" placeholder="new password" className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("new_password")}`} name="new_password" id="password"
                {...formik.getFieldProps("new_password")}
              />
              {formik.touched.new_password && formik.errors.new_password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.new_password}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                type="password" placeholder="confirm password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "confirm_new_password"
                )}`}
                name="confirm_new_password"
                id="password_confirm"
                {...formik.getFieldProps("confirm_new_password")}
              />
              <div id="password_match_info" className="text-danger font-size-sm"></div>
              {formik.touched.confirm_new_password && formik.errors.confirm_new_password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.confirm_new_password}</div>
                </div>
              ) : null}
            </div>
            
            <input name="utf-8" type="hidden" value="✓" />
            <input name="username" id="username" type="hidden" />
            <input name="token" id="token" type="hidden" />

            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="change_password"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={loading}
              >
                <span>Submit</span>
                {(loading) && <span className="ml-3 spinner spinner-white"></span>}

              </button>
              <Link to="/auth">
                <button type="button" id="kt_login_forgot_cancel" className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">Cancel</button>                  
              </Link>
            </div>
          </form>
          }
          {status && status.startsWith("success") && 
            <div className="form-group d-flex flex-wrap flex-center">
              <Link to="/auth">
                <button type="button" id="kt_login" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">Login</button>                 
              </Link>              
            </div>
          }
          {status && status.startsWith("invalid") && 
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center">        
              <Link to="/auth/forgot-password" className="text-dark-50 text-hover-primary my-3 mr-2" id="kt_login_forgot">
                <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
              </Link>              
            </div>
          }
        </div>
  
    </>
  );

}

export default injectIntl(ResetPassword);
