/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import {checkIsActive} from "../../../../_helpers";

export function AsideMenuListLevel2({thisLevel}) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>

          {/*begin::2 Level*/}

          <li
              className={`menu-item ${getMenuItemActive(thisLevel.levelPath, false)}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link" to={thisLevel.levelPath}>
              <i className="menu-icon fa fa-chevron-right icon-xs">
                <span></span>
              </i>
              <span className={`menu-text ${thisLevel.fontInfo ? thisLevel.fontInfo : ''}`}>{thisLevel.title}</span>
            </NavLink>
          </li>

      </>
  );

}
