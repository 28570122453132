import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Parse } from 'parse';
// import moment from 'moment'
import dayjs from "dayjs";
import ReportHelper from "../../../../../_metronic/_assets/js/components/reportHelper"
// initial state which is shared by all component using 

const today = dayjs(new Date());

const initialState = {
  pageStatus: 'idle',
  pageError: null,
  graphStatus: 'idle',
  graphError: null,
  reportData: [],
  feedbackScoreChartData: {},
  feedbackScoreForDashboardChartData: {},
  surveysSendForDashboardChartData: {},
  surveysSendForChartData: {},
  totalResponseChartData: {},
  totalResponseForDashboardChartData: {},

  customerResponseTimeChartData: {},
  customerResponseTimeForDashboardChartData: {},
  feedbackInviteForDashboardChartData: {},


  monthToDate: 0,
  last12Months: 0,

  feedbackScore_monthToDate: 0,
  feedbackScore_last12Month: 0,
  surveysSend_monthToDate: 0,
  surveysSend_last12Month: 0,
  surveysSendForDashboard_monthToDate: 0,
  surveysSendForDashboard_last12Month: 0,
  feedbackScoreForDashboard_monthToDate: 0,
  feedbackScoreForDashboard_last12Month: 0,
  totalResponsesForDashboard_monthToDate: 0,
  totalResponsesForDashboard_last12Month: 0,

  customerResponseTimeForDashboard_monthToDate: 0,
  customerResponseTimeForDashboard_last12Month: 0,
  feedbackInviteForDashboard_monthToDate: 0,

  queryParams: {
    fromDate: dayjs(today).subtract(7, 'day').format("YYYY-MM-DD"),
    toDate: today.format("YYYY-MM-DD"),
  },
  curentPage: {
    pageName: "dashboard",
    pageData: null,
    reportType: "",
    summeryType: "",
    chartType: "",
    reportTitle: ""
  }

}

// server API calling dispatch
export const fetchFeedbackSummary = createAsyncThunk('questions/fetchFeedbackSummary', async (values) => {
  const cloudResponse = await Parse.Cloud.run("fetchFeedbackSummary", values);
  return cloudResponse;
})

const entityType = "feedbackReport";
// Then, handle actions in your reducers:
export const feedBackReportsSlice = createSlice({
  name: entityType,
  initialState: initialState,
  reducers: {
    setParams: (state, action) => {
      state.queryParams = action.payload; // mutate the state all you want with immer
      // state.reportData = [];
      // state.pageStatus = 'idle';
    },
    setPage: (state, action) => {
      state.curentPage = action.payload;
    },
  },
  extraReducers: {
    // all callback from server for each API call
    // state managemet - pending/fulfilled/rejected callback from with API name [method-name.state] 
    [fetchFeedbackSummary.pending]: (state) => { state.pageStatus = 'loading' },
    [fetchFeedbackSummary.fulfilled]: (state, action) => fetchFeedbackSummaryFulfilled(state, action),
    [fetchFeedbackSummary.rejected]: (state, action) => fetchFeedbackSummaryRejected(state, action),
  }
})

function getTotal(reportType, result) {
  let total = 0;
  switch (reportType) {

    case ReportHelper.ReportType.surveysSendForDashboard: {
      total = result.data[0].totalSurveysSend;
      break;
    }

    case ReportHelper.ReportType.feedbackScoreForDashboard: {
      total = result.data[0].score;
      break;
    }
    case ReportHelper.ReportType.totalResponseForDashboard: {
      total = Number(result.data[0].promoter + result.data[0].passive + result.data[0].detractor);
      break;
    }
    case ReportHelper.ReportType.customerResponseTimeForDashboard: {
      total = result.data[0].responseTime;
      break;
    }
    case ReportHelper.ReportType.feedbackInviteForDashboard: {
      total = result.data[0].totalInvites;
      break;
    }
  }
  return total;
}

const fetchFeedbackSummaryFulfilled = (state, action) => {
  state.pageStatus = 'succeeded'
  const result = action.payload;
  if (result.groupBy === "") {
    const stateName = result.reportType + "_" + result.filterType;
    switch (result.filterType) {
      case ReportHelper.FilterType.monthToDate: {
        state[stateName] = (result.data && result.data.length > 0) ? getTotal(result.reportType, result) : 0;
        state.reportName = result.reportType;
        break;
      }
      case ReportHelper.FilterType.last12Month: {
        state[stateName] = (result.data && result.data.length > 0) ? getTotal(result.reportType, result) : 0;
        state.reportName = result.reportType;
        break;
      }
    }
  }
  else if (result.reportType === ReportHelper.ReportType.surveysSendForDashboard) {
    state.surveysSendForDashboardChartData = ReportHelper.ConvertSurveysSendTotalChartDataForDashboard(result);
  }
  else if (result.reportType === ReportHelper.ReportType.surveysSend) {
    state.surveysSendForChartData = ReportHelper.ConvertSurveysSendTotalChartData(result.data, result.groupBy, ReportHelper.ReportTitle.surveysSend, result.filterType);
  }
  else if (result.reportType === ReportHelper.ReportType.feedbackScoreForDashboard) {
    state.feedbackScoreForDashboardChartData = ReportHelper.ConvertFeedbackScoreChartDataForDashboard(result);
  }
  else if (result.reportType === ReportHelper.ReportType.feedbackScore) {
    state.feedbackScoreChartData = ReportHelper.ConvertFeedbackScoreChartData(result.data, result.groupBy, ReportHelper.ReportTitle.feedbackScore, result.filterType);
  }
  else if (result.reportType === ReportHelper.ReportType.totalResponseForDashboard) {
    state.totalResponseForDashboardChartData = ReportHelper.ConvertTotalResponsesForDashboardChartData(result);
  }
  else if (result.reportType === ReportHelper.ReportType.totalResponse) {
    state.totalResponseChartData = ReportHelper.ConvertTotalResponsesChartData(result.data, result.groupBy, ReportHelper.ReportTitle.totalResponse, result.filterType);
  }

  else if (result.reportType === ReportHelper.ReportType.customerResponseTimeForDashboard) {
    state.customerResponseTimeForDashboardChartData = ReportHelper.ConvertCustomerResponseTimeForDashboard(result);
  }
  else if (result.reportType === ReportHelper.ReportType.customerResponseTime) {
    state.customerResponseTimeChartData = ReportHelper.ConvertCustomerResponseTime(result.data, result.groupBy, ReportHelper.ReportTitle.customerResponseTime, result.filterType);
  }

  else if (result.reportType === ReportHelper.ReportType.inviteActivity) {
    state.inviteActivityData = result.data;
  }
  else if (result.reportType === ReportHelper.ReportType.feedbackInviteForDashboard) {
    state.feedbackInviteForDashboardChartData = ReportHelper.ConvertFeedbackInviteChartDataForDashboard(result);
  }
}

const fetchFeedbackSummaryRejected = (state, action) => {
  state.pageStatus = 'failed'
  state.pageError = action.error.message
}

export default feedBackReportsSlice.reducer