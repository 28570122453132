import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";
import * as Queries from "../../../../../_metronic/_assets/js/textopia/TextopiaQueries";
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";
import {KTUtil} from "../../../../../_metronic/_assets/js/components/util";


const initialState = {

  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,

  settings: null,
  updatedAt: new Date(),

};

export const savePaymentsSettingsLook = createAsyncThunk('payments/savePaymentsSettingsLook', async (values) => {
  //if (!values.settings) return; //--> relying on the settings object coming from the form
  let settings;
  if (!values.settings) {
    settings = new (Parse.Object.extend("PaymentAppSettings"))();
    settings.set("tenantPtr", Parse.User.current().get("tenantPtr"));
    settings.set("updatedByUserPtr", Parse.User.current());
  }
  else {
    settings = values.settings;
  }

  settings.set("updatedByUserPtr", Parse.User.current());

  let branding = textopia.getBranding(values)
  settings.set("branding", branding);
  settings = textopia.setLogo(settings, values);

  let savedSettings = await settings.save();
  return savedSettings;

})

export const savePaymentsSettingsMessaging = createAsyncThunk('payments/savePaymentsSettingsMessaging', async (values) => {
  if (values.settings) {
    values.settingsId = values.settings.id;
    values.settings = null;
  }
  let cloudResponse = await Parse.Cloud.run("savePaymentsSettingsMessaging", {values: values})
  return cloudResponse;
})

export const savePaymentsSettingsReminders = createAsyncThunk('payments/savePaymentsSettingsReminders', async (values) => {

  if (values.settings) {
    values.settingsId = values.settings.id;
    values.settings = null;
  }
  let cloudResponse = await Parse.Cloud.run("savePaymentsSettingsReminders", {values: values});
  return cloudResponse;
})

export const fetchPaymentsSettings = createAsyncThunk('payments/fetchPaymentsSettings', async () => {
  let cloudResponse = await Parse.Cloud.run("fetchPaymentsSettings");

  return cloudResponse;  
})

//--> this one used by LQ
export const updatePaymentsSettings = createAsyncThunk('payments/updatePaymentsSettings', async (values) => {
  
  return values;
})


const entityType = "paymentsSettings";

export const paymentsSettingsSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {    

      [fetchPaymentsSettings.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchPaymentsSettings.fulfilled]: (state, action) => {        
        state.settings = action.payload //Query returns the settings record for this tenant
        state.updatedAt = new Date();
        state.pageStatus = 'succeeded'
      },
      [fetchPaymentsSettings.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },  

      // Update settings //--> this one used by LQ
      [updatePaymentsSettings.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updatePaymentsSettings.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'

        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
          // nothing
          state.updatedAt = new Date();
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          state.settings = action.payload.obj; // should not really happen since a record should always exist for every tenant
          state.updatedAt = new Date();
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {   
          // nothing, should never happen
          state.updatedAt = new Date();
        }

      },
      [updatePaymentsSettings.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },


      [savePaymentsSettingsLook.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [savePaymentsSettingsLook.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.settings = action.payload //Query returns the settings record for this tenant
        state.updatedAt = new Date();
      },
      [savePaymentsSettingsLook.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      [savePaymentsSettingsMessaging.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [savePaymentsSettingsMessaging.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.settings = action.payload //Query returns the settings record for this tenant
        state.updatedAt = new Date();
      },
      [savePaymentsSettingsMessaging.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      [savePaymentsSettingsReminders.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [savePaymentsSettingsReminders.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.settings = action.payload //Query returns the settings record for this tenant
        state.updatedAt = new Date();
      },
      [savePaymentsSettingsReminders.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },


    },


    // BELOW NOT USED - KEPT FOR REFERENCE ONLY

    reducers: {

  }
});

export default paymentsSettingsSlice.reducer
