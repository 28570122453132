import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse } from 'parse';
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";


const initialUsersState = {
  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,
  
  users: [],
  updatedAt: new Date(),
};

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const cloudResponse = await Parse.Cloud.run("fetchAllUsers"); //--> have to use cloud code here to fetch users because User is a protected entity type and email will not be returned if we don't use master key which is done in cloud code
  return cloudResponse;
})

//--> this one used by LQ
export const updateUser = createAsyncThunk('users/updateUser', async (values) => { //--> this however, works, after the above fetch, meaning new entries will have their emails displayed
  return values;
})

export const saveUser = createAsyncThunk('users/saveUser', async (values) => {
  const cloudResponse = await Parse.Cloud.run("saveUserForAccount", values);
  return cloudResponse;
})

export const changeUserPassword = createAsyncThunk('users/changeUserPassword', async (values) => {
  const cloudResponse = await Parse.Cloud.run("changeUserPassword", values);
  return cloudResponse;
})

export const deleteUser = createAsyncThunk('users/deleteUser', async (id) => {
  const cloudResponse = await Parse.Cloud.run("deleteUserForAccount", {id: id}); // Sending of Parse objects is not allowed here for some reason, so have to send ID only
  return cloudResponse;
})

export const searchUsers = createAsyncThunk('users/searchUsers', async (searchTerm) => {

})


const entityType = "users";
export const usersSlice = createSlice({
  name: "users",
  initialState: initialUsersState,

    extraReducers: {
      // All users
      [fetchUsers.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchUsers.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state.users = action.payload //Query returns an array of User objects
        state.updatedAt = new Date();
      },
      [fetchUsers.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },

      // Edit user
      [saveUser.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveUser.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        // If this is a new user, push into the users Array
        if(!state.users.find(user => user.id === action.payload.id)) {
          state.users.push(action.payload); // Add the new one to the list of users
        }
        state.updatedAt = new Date();
      },
      [saveUser.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Change Password
      [changeUserPassword.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [changeUserPassword.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [changeUserPassword.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Delete user
      [deleteUser.pending]: (state, action) => {
        state.actionStatus = 'deleting'
      },
      [deleteUser.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded';

        // Remove the deleted user from the users list
        state.users = state.users.filter(o => o.id !== action.payload.id);
        state.updatedAt = new Date();
      },
      [deleteUser.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // update user //--> used by LQ
      [updateUser.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updateUser.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded';

        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
          // nothing
          state.updatedAt = new Date();
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          console.log('new user');
          //--> check for duplicate before adding - this is for snappy local UI but to avoid duplicates
          if(!state[entityType].find(user => user.id === action.payload.obj.id)) {
            state[entityType].unshift(action.payload.obj); // Add the new one to the list of topics
          }     
          state.updatedAt = new Date();     
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {
          console.log('user removed');          
          state.users = state.users && state.users.filter(o => o.id !== action.payload.obj.id);
          state.updatedAt = new Date();
        }
      },
      [updateUser.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },


      // searchUsers user
      [searchUsers.pending]: (state, action) => {
        state.pageStatus = 'deleting'
      },
      [searchUsers.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded';

        state.users = action.payload //Query returns an array of User objects
      },
      [searchUsers.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      }


    },


    // BELOW NOT USED - KEPT FOR REFERENCE ONLY

    reducers: {
    
    }
});

export default usersSlice.reducer
