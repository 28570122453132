/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen } from "../_metronic/layout";


import Parse from 'parse';

const parseConfig = (env) => {
    if (env === "dev") return {
        APPLICATION_ID: 'GROONZY',
        SERVER_URL: 'http://127.0.0.1:1337/parse',
        //SERVER_URL: 'https://textopia.ngrok.io/parse',
    }
    else if (env === "prod") return {
        SERVER_URL: 'https://textopia.b4a.app/',
        APPLICATION_ID: '6AJfa2enUPtiqpzcGWzeGenVVUMeI9JUkxCuz5H8',
        JAVASCRIPT_KEY: 'HyqMpblsidWCv10X2cUvcHavsblCldY45log2cmW',
    }
}

const parseInitParams = parseConfig("prod"); //prod 

Parse.initialize(parseInitParams.APPLICATION_ID, parseInitParams.JAVASCRIPT_KEY);
Parse.serverURL = parseInitParams.SERVER_URL;
//Parse.enableEncryptedUser();
//Parse.secret = 'EEsbkcg3lFH8pgm5kFgt0AQsYSaPoTU4cl5Tp9CJ';

// A global error handler for us
window.addEventListener('unhandledrejection', function(event) {
  console.log("Textopia Error: " + event.promise);
  console.log("Textopia Error: " + event.reason);
});

export default function App({ store, persistor, basename }) {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <Routes />
              </I18nProvider>
    
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>

  );
}
