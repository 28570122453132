import React from "react";

export function Footer() {
  const today = new Date().getFullYear();

  return (

    <div className="d-none d-lg-block">
      <div className="footer bg-white py-4 d-flex flex-lg-column" style={{zIndex: '1'}} id="kt_footer">
        <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted font-weight-bold mr-2">{today} ©</span>
            <a href="http://www.textopia.com" target="_blank" rel="noopener noreferrer" className="text-dark-75 text-hover-primary">Textopia.com</a>
          </div>
        </div>
      </div>
    </div>
  );
}
