import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";
import * as Queries from "../../../../../_metronic/_assets/js/textopia/TextopiaQueries";
import {KTUtil} from "../../../../../_metronic/_assets/js/components/util";


const initialState = {

  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,

  channels: null,
  updatedAt: new Date(),

  tenant: null,
  introAlreadyShown: false,

};

export const setIntroAlreadyShown = createAsyncThunk('convos/setIntroAlreadyShown', async (values) => {
    return true;
})

export const dontShowAgain = createAsyncThunk('convos/dontShowAgain', async (values) => {
    let tenant = Parse.User.current().get("tenantPtr");    
    tenant.set("showIntroModal", false);
    await tenant.save();
    return tenant;
})



export const assignSMSAddress = createAsyncThunk('channels/assignSMSAddress', async (values) => {
  let cloudResponse = await Parse.Cloud.run("assignSMSAddress", {areacode: values.areacode});
  return cloudResponse;
});

export const assignEmailAddress = createAsyncThunk('channels/assignEmailAddress', async (values) => {
  let cloudResponse = await Parse.Cloud.run("assignEmailAddress", {prefix: values.prefix, domain: values.domain});
  return cloudResponse;
});

export const getTenant = createAsyncThunk('channels/getTenant', async () => {
  let cloudResponse = await Parse.Cloud.run("getTenant");
  return cloudResponse;
});

//--> this one used by LQ
export const updateTenant = createAsyncThunk('channels/updateTenant', async (values) => {
  return values;
})

export const registerFacebook = createAsyncThunk('channels/registerFacebook', async (values) => {

  const cloudResponse = await Parse.Cloud.run("registerFacebook", values);
  return cloudResponse;
});

export const registerInstagram = createAsyncThunk('channels/registerInstagram', async (values) => {

  const cloudResponse = await Parse.Cloud.run("registerInstagram", values);
  return cloudResponse;
});

export const registerInstagram2 = createAsyncThunk('channels/registerInstagram2', async (values) => {
  console.log(values);
  const cloudResponse = await Parse.Cloud.run("registerInstagram2", values);
  return cloudResponse;
});

export const saveLocationSpecificFBPage = createAsyncThunk('channels/saveLocationSpecificFBPage', async (values) => {  
  const cloudResponse = await Parse.Cloud.run("saveLocationSpecificFBPage", values);
  return cloudResponse;
});

export const saveLocationSpecificShop = createAsyncThunk('channels/saveLocationSpecificShop', async (values) => {  
  console.log(values);
  const cloudResponse = await Parse.Cloud.run("saveLocationSpecificShop", values);
  return cloudResponse;
});



export const twitterDisconnect = createAsyncThunk('channels/twitterDisconnect', async (values) => {
  
  const cloudResponse = await Parse.Cloud.run("twitterDisconnect", values);
  console.log(cloudResponse);
});

export const shopifyConnect = createAsyncThunk('channels/shopifyConnect', async (values) => {
  
  const cloudResponse = await Parse.Cloud.run("shopifyConnect", values);
  console.log(cloudResponse);
});

export const saveWeb2TxtLook = createAsyncThunk('channels/saveWeb2TxtLook', async (values) => {
  //--> web2txt settings are contained in the tenant record itself
  let tenant = Parse.User.current().get("tenantPtr");

  let config = {};
  if (values.primaryColor) {
    config.primaryColor = values.primaryColor;
  }
  else config.primaryColor = "#663399";

  if (values.showAvatar) config.showAvatar = true;
  else config.showAvatar = false;

  if (values.showAvatar && values.stockAvatar) {
    config.stockAvatar = values.stockAvatar;
  } else config.stockAvatar = null;

  if (values.showAvatar && values.customAvatar) {
    //--> if this is not a new file, meaning, avatar hasn't changed, do nothing --> new filed have a file.name property, exiting files have a file._name property --> weird!
    if (values.customAvatar._url) { }
    else {
      let ext = values.customAvatar.name.endsWith("jpg") ? "jpg" : values.customAvatar.name.endsWith("png") ? "png" : values.customAvatar.name.endsWith("gif") ? "gif" : values.customAvatar.name.endsWith("jpeg") ? "jpeg" : "";
      let parseFile = new Parse.File("avatar." + ext, values.customAvatar);
      config.customAvatar = parseFile;
    }
  } else config.customAvatar = null;

  if (values.country) config.country = values.country;

  if (values.invitation && values.invitation.trim() != "") config.invitation = values.invitation;

  if (values.title && values.title.trim() != "") config.title = values.title;

  /*
  if (values.stockIcon) {
    config.stockIcon = values.stockIcon;
  } else config.stockIcon = null;

  if (values.customIcon) {
    //--> if this is not a new file, meaning, avatar hasn't changed, do nothing --> new filed have a file.name property, exiting files have a file._name property --> weird!
    if (values.customIcon._url) { }
    else {
      let ext = values.customIcon.name.endsWith("jpg") ? "jpg" : values.customIcon.name.endsWith("png") ? "png" : values.customIcon.name.endsWith("gif") ? "gif" : values.customIcon.name.endsWith("jpeg") ? "jpeg" : "";
      let parseFile = new Parse.File("avatar." + ext, values.customIcon);
      config.customIcon = parseFile;
    }
  } else config.customIcon = null;
  */
  
  console.log(values);

  tenant.set("webchatConfig", config);

  let savedTenant = await tenant.save();
  Parse.User.current().set("tenantPtr", savedTenant);
  return savedTenant;

})


const entityType = "channels";

export const channelsSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {

      // getTenant
      [getTenant.pending]: (state, action) => {
        state.pageStatus = 'saving'
      },
      [getTenant.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state.tenant = action.payload //Query returns the settings record for this tenant
        state.updatedAt = new Date();
      },
      [getTenant.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      },

      // updateTenant --> used by LW
      [updateTenant.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updateTenant.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.tenant = action.payload.obj //Query returns the settings record for this tenant
        state.updatedAt = new Date();
      },
      [updateTenant.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      },

      // assign sms and mms addresses
      [assignSMSAddress.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [assignSMSAddress.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        //state.tenant = action.payload //Query returns the tenant
        state.updatedAt = new Date();
      },
      [assignSMSAddress.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      },

      // assign email address
      [assignEmailAddress.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [assignEmailAddress.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        //state.tenant = action.payload //Query returns the tenant
        state.updatedAt = new Date();
      },
      [assignEmailAddress.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      },



      
      // save custom look
      [saveWeb2TxtLook.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveWeb2TxtLook.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.tenant = action.payload //Query returns the settings record for this tenant
        state.updatedAt = new Date();
      },
      [saveWeb2TxtLook.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      },

      // register facebook
      [registerFacebook.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [registerFacebook.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        if (action.payload && action.payload === "error") {
          console.log("Error in registering Facebook");
        }
        else {
          state.tenant = action.payload //cloud response returns either an updated tenant object or an error message
          state.updatedAt = new Date();  
        }        
      },
      [registerFacebook.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      },

      // register instagram (1)
      [registerInstagram.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [registerInstagram.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        if (action.payload && action.payload === "error") {
          console.log("Error in registering Instagram");
        }
        else {
          state.fbConfig = action.payload // here we are just getting an interim list of pages so user can select the specific instagram connected page
          state.updatedAt = new Date();  
        }        
      },
      [registerInstagram.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      },

      // register instagram (2)
      [registerInstagram2.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [registerInstagram2.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        if (action.payload && action.payload === "error") {
          console.log("Error in registering Instagram");
        }
        else {
          state.tenant = action.payload // instagram registeration is now complete so update the tenant object in state
          state.updatedAt = new Date();  
        }        
      },
      [registerInstagram2.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      },

      // save locationspecific fb pages
      [saveLocationSpecificFBPage.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveLocationSpecificFBPage.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        if (action.payload && action.payload === "error") {
          console.log("Error in assigning FB page(s) to this location");
        }
        else {
          //state.updatedAt = new Date();  
        }        
      },
      [saveLocationSpecificFBPage.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      },

      // disconnect twitter
      [twitterDisconnect.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [twitterDisconnect.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        if (action.payload && action.payload === "error") {
          console.log("Error in disconnecting twitter");
        }
        else {
          //state.updatedAt = new Date();  
        }        
      },
      [twitterDisconnect.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      },

      // save locationspecific shop
      [saveLocationSpecificShop.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveLocationSpecificShop.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        if (action.payload && action.payload === "error") {
          console.log("Error in assigning Shopify store to this location");
        }
        else {
          //state.updatedAt = new Date();  
        }        
      },
      [saveLocationSpecificShop.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      },
      

       // Intro modal
      [setIntroAlreadyShown.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [setIntroAlreadyShown.fulfilled]: (state, action) => {
        state.introAlreadyShown = true;          
      },
      [setIntroAlreadyShown.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Intro modal
      [dontShowAgain.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [dontShowAgain.fulfilled]: (state, action) => {
        state.introAlreadyShown = true;
        state.tenant = action.payload;          
      },
      [dontShowAgain.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },


    

    },




    reducers: {


  }
});

export default channelsSlice.reducer
