import dayjs from "dayjs";
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

export default class reportHelper {

    static Colors = {
        White: '#ffffff',
        Secondary: '#ff8c00',
        LightGrey: '#f4f4f4',
        Green: '#1ed368',
        Red: '#ea4756',
        Yellow: '#ffc44f',
        dark75: '#3F4254',
        h4size: '17.55px',


        primary: "#6993FF",
        secondary: "#E5EAEE",
        success: "#1BC5BD",
        info: "#8950FC",
        warning: "#FFA800",
        danger: "#F64E60",
        light: "#F3F6F9",
        dark: "#212121",
        passive: "#7E8299"
    };

    static FilterType = {
        today: 'today',
        yesterday: 'yesterday',
        weekToDate: 'weekToDate',
        lastWeek: 'lastWeek',
        monthToDate: 'monthToDate',
        lastMonth: 'lastMonth',
        yearToDate: "yearToDate",
        lastyear: "lastyear",
        last12Month: "last12Month",
        last30Days: "last30Days",
    };

    static ReportType = {
        //Feedback
        "feedbackScore": "feedbackScore",
        "feedbackScoreForDashboard": "feedbackScoreForDashboard",
        "surveysSendForDashboard": "surveysSendForDashboard",
        "surveysSend": "surveysSend",
        "totalResponseForDashboard": "totalResponsesForDashboard",
        "totalResponse": "totalResponses",
        "customerResponseTime": "customerResponseTime",
        "customerResponseTimeForDashboard": "customerResponseTimeForDashboard",
        "feedbackInviteForDashboard": "feedbackInviteForDashboard",

        //Review
        "totalReviewInvitesForDashboard": "totalReviewInvitesForDashboard",
        "totalReviewInvites": "totalReviewInvites",
        "totalReviewsForDashboard": "totalReviewsForDashboard",
        "totalReviews": "totalReviews",
        "detailsPerPageForDashboard": "detailsPerPageForDashboard",
        "detailsPerPage": "detailsPerPage",
        "detailsPerPageNameForDashboard": "detailsPerPageNameForDashboard",
        "detailsPerPageName": "detailsPerPageName",
        "reviewInviteForDashboard": "reviewInviteForDashboard",
        "avgReviewRatingForDashboard": "avgReviewRatingForDashboard",
        "avgReviewRating": "avgReviewRating",

        //Inbox Report
        "totalInboundLead": "totalInboundLead",
        "totalConvoCount": "totalConvoCount",
        "avgResponseTimeForDashboard": "avgResponseTimeForDashboard",
        "totalResponseTimeForDashboard": "totalResponseTimeForDashboard",
        "medianResponseTimeForDashboard": "medianResponseTimeForDashboard",
        "inboundOutbound": "inboundOutbound",
        "totalResponseTime": "totalResponseTime",
        "inboundLead": "inboundLead",
        "medianResponseTime": "medianResponseTime",
        "avgResponseTime": "avgResponseTime",

        //Feedback and Review
        "inviteActivity": "inviteActivity",

        //Task
        "totalTaskOpened": "totalTaskOpened",
        "totalTaskOpenedForDashboard": "totalTaskOpenedForDashboard",
        "totalTaskClosed": "totalTaskClosed",
        "totalTaskClosedForDashboard": "totalTaskClosedForDashboard",
        "tasksTimeToComplete": "tasksTimeToComplete",
        "tasksTimeToCompleteForDashboard": "tasksTimeToCompleteForDashboard",
        "taskAssignmentsForDashboard": "taskAssignmentsForDashboard",
        "taskAssignments": "taskAssignments",
        "taskCreationForDashboard": "taskCreationForDashboard",
        "taskCreation": "taskCreation",


        //Payment Report
        "paymentSendAndPaidForDashboard": "paymentSendAndPaidForDashboard",
        "paymentResponseTimeForDashboard": "paymentResponseTimeForDashboard",
        "amountProcessedForDashboard": "amountProcessedForDashboard",
        "paymentActivityForDashboard": "paymentActivityForDashboard",
        "paymentSendAndPaid": "paymentSendAndPaid",
        "paymentResponseTime": "paymentResponseTime",
        "amountProcessed": "amountProcessed",
        "paymentActivity": "paymentActivity",

        //Get Time Format All report
        "timeData": "timeData"
    }

    static ReportTitle = {
        //Feedback Report
        "feedbackScore": "Feedback Score",
        "surveysSend": "Surveys Sent",
        "totalResponse": "Total Responses",
        "customerResponseTime": "Customer Response Time",
        "inviteActivity": "Invite Activity",

        //Review Report
        "totalReviewInvites": "Review Invites",
        "totalReviews": "Total Reviews",
        "averageReviewRating": "Average Review Rating",
        "detailsPerPage": "Rating By Site",

        //Inbox Report
        "inboundLeads": "Inbound Leads",
        "totalConversations": "Total Conversations",
        "averageResponseTime": "Average Response Time",
        "totalResponseTime": "Total Response Time",
        "medianFirstResponse": "Median First Response",

        //Task Report
        "tasksTimeToComplete": "Average Completed Time",
        "totalTaskOpened": "Total Tasks Opened",
        "totalTaskClosed": "Total Tasks Closed",
        "taskAssignments": "Task Assignments",
        "taskCreation": "Task Creation",
        "Customer": "Customer",
        "General": "General",

        //Payment Report
        "totalReqSendAndPaid": "No of Requests Sent & Paid",
        "paymentResponseTime": "Time to Payment",
        "amountProcessed": "Amount Processed",
        "paymentActivity": "Payment Activity",
    }

    static ChartType = {
        line: "line",
        bar: "bar",
        area: "area"
    }

    static GroupBy = {
        "day": "day",
        "week": "week",
        "month": "month",
        "dayAndChannel": "dayAndChannel",
        "weekAndChannel": "weekAndChannel",
        "monthAndChannel": "monthAndChannel",
        "dayAndSite": "dayAndSite",
        "weekAndSite": "weekAndSite",
        "monthAndSite": "monthAndSite",
        "site": "site",
        "siteAndRating": "siteAndRating",
        //Task
        "dayAndType": "dayAndType",
        "weekAndType": "weekAndType",
        "monthAndType": "monthAndType",
        "dayAndUser": "dayAndUser",
        "weekAndUser": "weekAndUser",
        "monthAndUser": "monthAndUser",
        "createByUser": "createByUser"
    }

    static npsTypeColorMatch = {
        Detractor: '#ea4756',
        Passive: '#ffc44f',
        Promoter: '#19c9ae'
    }

    static PageName = {
        "dashboard": "dashboard",
        "report": "report",
    }

    static getStarColor = (selectedStars) => {
        var lscolor = "";
        if (selectedStars < 3) {
            lscolor = "danger"
        }
        else if (selectedStars >= 3 && selectedStars < 4) {
            lscolor = "warning"
        }
        else if (selectedStars >= 4 && selectedStars < 5) {
            lscolor = "primary-d-3"
        }
        else if (selectedStars == 5) {
            lscolor = "success"
        }
        return lscolor
    }

    static FormatCount(value, reportType) {
        const COUNT_FORMATS =
            [
                { // 0 - 999
                    letter: '',
                    limit: 1e3
                },
                { // 1,000 - 999,999
                    letter: 'K',
                    limit: 1e6
                },
                { // 1,000,000 - 999,999,999
                    letter: 'M',
                    limit: 1e9
                },
                { // 1,000,000,000 - 999,999,999,999
                    letter: 'B',
                    limit: 1e12
                },
                { // 1,000,000,000,000 - 999,999,999,999,999
                    letter: 'T',
                    limit: 1e15
                }
            ];
        // Format Method:
        if ((
            // reportType === this.ReportType.totalInboundLead ||
            reportType === this.ReportType.avgResponseTimeForDashboard ||
            reportType === this.ReportType.totalResponseTimeForDashboard ||
            reportType === this.ReportType.customerResponseTimeForDashboard ||
            reportType == this.ReportType.paymentResponseTimeForDashboard ||
            reportType === this.ReportType.tasksTimeToCompleteForDashboard ||
            reportType === this.ReportType.timeData
            // reportType === this.ReportType.taskCreationForDashboard
        )) {
            var hours = Math.floor(value / 60);
            var minutes = Math.round(value % 60);

            if (minutes <= 0) {
                minutes = '0'
            }

            if (hours > 0) {
                return hours + "h" + " " + minutes + "m";
            }
            else {
                return minutes + "m";
            }
        }
        else if (reportType === "amountProcessedForDashboard") {
            return "$" + value
        }
        else {
            const format = COUNT_FORMATS.find(format => (value < format.limit));
            value = (1000 * value / format.limit);
            value = Math.round(value * 10) / 10; // keep one decimal number, only if needed
            return (value + ' ' + format.letter);
        }
    }

    static getDateRange(reportBy) {
        const today = dayjs(new Date())
        var toDate = dayjs(today).format("YYYY-MM-DD");
        var fromDate;
        switch (reportBy) {
            case this.FilterType.today: {
                fromDate = dayjs(today).format("YYYY-MM-DD");
                break;
            }
            case this.FilterType.yesterday: {
                fromDate = dayjs(today).subtract(1, 'day').format("YYYY-MM-DD")
                toDate = dayjs(new Date()).subtract(1, 'day').format("YYYY-MM-DD")
                break;
            }
            case this.FilterType.weekToDate: {
                fromDate = dayjs(today).startOf("week").format("YYYY-MM-DD");
                break;
            }
            case this.FilterType.lastWeek: {
                fromDate = dayjs(new Date()).subtract(1, "week").startOf('week').format("YYYY-MM-DD");
                toDate = dayjs(new Date()).subtract(1, "week").endOf('week').format("YYYY-MM-DD");
                break;
            }
            case this.FilterType.monthToDate: {
                fromDate = dayjs(new Date()).startOf('month').format("YYYY-MM-DD");
                break;
            }
            case this.FilterType.lastMonth: {
                fromDate = dayjs(new Date()).subtract(1, "month").startOf('month').format("YYYY-MM-DD");
                toDate = dayjs(new Date()).subtract(1, "month").endOf('month').format("YYYY-MM-DD");
                break;
            }
            case this.FilterType.yearToDate: {
                fromDate = dayjs(new Date()).startOf('year').format("YYYY-MM-DD");
                break;
            }
            case this.FilterType.lastyear: {
                fromDate = dayjs(new Date()).subtract(1, "year").startOf('year').format("YYYY-MM-DD");
                toDate = dayjs(new Date()).subtract(1, "year").endOf('year').format("YYYY-MM-DD");
                break;
            }
            case this.FilterType.last12Month: {
                fromDate = dayjs(new Date()).subtract(1, "year").format("YYYY-MM-DD");
                break;
            }
            case this.FilterType.last30Days: {
                fromDate = dayjs(new Date()).subtract(30, 'day').format("YYYY-MM-DD")
                break;
            }
        }
        return {
            fromDate: fromDate,
            toDate: toDate
        }
    }

    static typeDaysSelection = [
        { value: "day", label: 'Daily', "position": 0 },
        { value: "week", label: 'Weekly', "position": 1 },
        { value: "month", label: 'Monthly', "position": 2 },
    ];

    static reportBy = [
        { value: 'today', label: 'Today' },
        { value: 'yesterday', label: 'Yesterday' },
        { value: 'weekToDate', label: 'Week to Date' },
        { value: 'lastWeek', label: 'Last Week' },
        { value: 'monthToDate', label: 'Month to Date' },
        { value: 'lastMonth', label: 'Last Month' },
        { value: 'yearToDate', label: 'Year to Date' },
        { value: 'lastyear', label: 'Last Year' },
        { value: 'last12Month', label: 'Last 12 Months' },
        { value: 'last30Days', label: 'Last 30 Days' },
        { value: 'custom', label: 'Custom' },

    ]

    static constants = {
        IncrActivityLoad: 20
    }

    //****Feedback Chart//

    static ConvertSurveysSendTotalChartDataForDashboard(result) {

        let totalSurveysSend = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;

                    totalSurveysSend.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), Math.round(reportData[isDateExists].totalSurveysSend)]);
                } else {
                    totalSurveysSend.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => (
                totalSurveysSend.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), Math.round(data.totalSurveysSend)])
            ));
        }

        let series = [
            {
                name: "Surveys Sent",
                data: totalSurveysSend
            },
        ];

        let options = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#1dc4bd'],

            fill: {
                colors: ['#94f5f5']
            },
            stroke: {
                width: 2,
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        };


        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertSurveysSendTotalChartData(reportData, groupBy, reportTitle, filterBy) {
        let totalSurveysSend = []
        let totalResponses = []
        let date = []
        let totalSurveyCount = 0, totalResponsesCount = 0;
        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")
        reportData && reportData.map(data => (
            (
                totalSurveysSend.push(Math.round(data.totalSurveysSend)),
                totalResponses.push(Math.round(data.totalResponses)),
                date.push(dayjs(Date.UTC(data.year, (data.month - 1), groupBy === "month" ? 1 : data.day)).valueOf())
            )
        ))

        totalSurveyCount = totalSurveysSend.reduce((total, data) => total = total + data, 0)
        totalResponsesCount = totalResponses.reduce((total, data) => total = total + data, 0)

        let series = [
            {
                name: "Surveys Sent: " + totalSurveyCount,
                data: totalSurveysSend
            },
            {
                name: "Responses: " + totalResponsesCount,
                data: totalResponses
            }
        ]

        let options = {
            chart: {
                id: 'surveysSend' + groupBy,
                type: "bar",
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false
                },
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
            dataLabels: {
                enabled: false,
            },
            colors: [this.Colors.success, this.Colors.warning],
            xaxis: {
                type: "category",
                categories: date,
                labels: {
                    hideOverlappingLabels: true,
                    format: undefined,
                    formatter: function (val) {
                        if (groupBy === "month") {
                            return dayjs(val).format("MMM 'YY")
                        } else {
                            return dayjs(val).format("MMM D 'YY")
                        }
                    }
                },
                tickAmount: date.length <= 13 ? date.length : 9, //6 is the default behaviour
                tickPlacement: 'on',
            },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return val;
                    }
                }
            },
            legend: {
                position: 'top',
                itemMargin: {
                    vertical: 10,
                }
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    formatter: function (val) {
                        return val
                    },
                    title: {
                        formatter: function (value) {
                            return value.split(":")[0]
                        },
                    },
                }
            },
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertFeedbackScoreChartDataForDashboard(result) {

        let feedbackScore = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;

                    feedbackScore.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), Math.round(reportData[isDateExists].score)]);
                } else {
                    feedbackScore.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => (
                feedbackScore.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), Math.round(data.score)])
            ));
        }

        let series = [
            {
                name: this.ReportTitle.feedbackScore,
                data: feedbackScore
            }
        ]

        let options = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                show: true,
                width: 3,
            },
            colors: ['#f64f60'],

            fill: {
                colors: ['#fabcc3']
            },
            xaxis: {
                type: 'datetime',
                tickPlacement: 'between',
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        }

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertFeedbackScoreChartData(reportData, groupBy, reportTitle, filterBy) {

        let feedbackScore = [], date = [];

        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")

        reportData && reportData.map(data => (
            (
                feedbackScore.push(Math.round(data.score)),
                date.push(dayjs(Date.UTC(data.year, (data.month - 1), groupBy === "month" ? 1 : data.day)).valueOf())
            )
        ))

        const series = [{
            name: this.ReportTitle.feedbackScore,
            data: feedbackScore
        }]

        let options = {
            chart: {
                id: 'feedbackScore' + groupBy,
                type: 'bar',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false
                },
            },
            dataLabels: {
                enabled: false,
            },
            //colors: [this.Colors.danger],
            colors: [
                function ({ value }) {
                    if (value >= 8) {
                        return "#1BC5BD"
                    } else if (value < 6) {
                        return "#F64E60"
                    } else {
                        return "#7E8299"
                    }
                }
            ],
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                type: "category",
                categories: date,
                labels: {
                    hideOverlappingLabels: true,
                    format: undefined,
                    formatter: function (val) {
                        if (groupBy === "month") {
                            return dayjs(val).format("MMM 'YY")
                        } else {
                            return dayjs(val).format("MMM D 'YY")
                        }
                    }
                },
                tickAmount: date.length <= 13 ? date.length : 9, //6 is the default behaviour
                tickPlacement: 'on',
            },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return val;
                    }
                }
            },
            legend: {
                position: 'top',
                itemMargin: {
                    vertical: 10,
                }
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                }
            },
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertTotalResponsesForDashboardChartData(result) {

        let totalResponsesData = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);
        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;
                    const promoter = reportData[isDateExists].promoter;
                    const passive = reportData[isDateExists].passive;
                    const detractor = reportData[isDateExists].detractor;
                    totalResponsesData.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), Number(promoter + passive + detractor)]);
                } else {
                    totalResponsesData.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => (
                totalResponsesData.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), Number(data.promoter + data.passive + data.detractor)])
            ));
        }

        const series = [{
            name: this.ReportTitle.totalResponse,
            data: totalResponsesData
        }]

        let options = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#248bf6'],

            fill: {
                colors: ['#8fbeea'],
            },
            stroke: {
                width: 2,
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'MMM d \'yy'
                },

            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertTotalResponsesChartData(reportData, groupBy, reportTitle, filterBy) {

        let date = [], promoterData = [], passiveData = [], detractorData = [];
        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")

        reportData && reportData.map(data => (
            (
                promoterData.push(data.promoter),
                passiveData.push(data.passive),
                detractorData.push(data.detractor),
                date.push(dayjs(Date.UTC(data.year, (data.month - 1), groupBy === "month" ? 1 : data.day)).valueOf())
            )
        ))

        const promoterCount = promoterData.reduce((total, data) => total = total + data, 0)
        const passiveCount = passiveData.reduce((total, data) => total = total + data, 0)
        const detractorCount = detractorData.reduce((total, data) => total = total + data, 0)

        const series = [{
            name: promoterCount > 1 ? "Promoters: " + promoterCount : "Promoter: " + promoterCount,
            data: promoterData
        }, {
            name: passiveCount > 1 ? "Passives: " + passiveCount : "Passive: " + passiveCount,
            data: passiveData
        }, {
            name: detractorCount > 1 ? "Detractors: " + detractorCount : "Detractor: " + detractorCount,
            data: detractorData
        }]



        let maxValue = 1;
        series.map(x => {
            let current = Math.max(...x.data);
            if (current > maxValue) {
                maxValue = current;
            }
        });


        const options = {
            chart: {
                id: 'feedbackTotalResponses' + groupBy,
                type: 'bar',
                stacked: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
            xaxis: {
                type: "category",
                categories: date,
                labels: {
                    hideOverlappingLabels: true,
                    format: undefined,
                    formatter: function (val) {
                        if (groupBy === "month") {
                            return dayjs(val).format("MMM 'YY")
                        } else {
                            return dayjs(val).format("MMM D 'YY")
                        }
                    }
                },
                tickAmount: date.length <= 13 ? date.length : 9, //6 is the default behaviour
                tickPlacement: 'on',
            },
            yaxis: {
                tickAmount: maxValue < 6 ? 1 : "",
                labels: {
                    show: true,
                    formatter: function (val) {
                        // return val;
                        const isInteger = Number.isInteger(val)
                        let yaxisValue = isInteger === true ? val : val.toFixed(0)
                        return yaxisValue;
                    }
                }
            },
            legend: {
                position: 'top',
                itemMargin: {
                    vertical: 10,
                }
            },
            fill: {
                opacity: 1
            },
            colors: [this.Colors.success, this.Colors.passive, this.Colors.danger],
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    title: {
                        formatter: function (value) {
                            return value.split(":")[0]
                        },
                    },
                },
            }
        }

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertCustomerResponseTimeForDashboard(result) {

        let responseTime = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;

                    responseTime.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), Math.round(reportData[isDateExists].responseTime)]);
                } else {
                    responseTime.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => (
                responseTime.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), Math.round(data.responseTime)])
            ));
        }

        let chartMinimumStartDate = "";
        if (responseTime.length > 0) {
            let [startDateUnixTime] = responseTime[0]
            chartMinimumStartDate = dayjs(startDateUnixTime).format('DD MMM YYYY')
        }

        let series = [
            {
                name: this.ReportTitle.customerResponseTime,
                data: responseTime
            }
        ]

        let options = {
            chart: {
                type: 'area',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                show: true,
                width: 3,
            },
            colors: ['#663399'],

            fill: {
                colors: ['#a683e9']
            },
            xaxis: {
                type: 'datetime',
                //min: new Date(chartMinimumStartDate).getTime(),
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            yaxis: {
                show: false,
                labels: {
                    formatter: (value) => {
                        return this.FormatCount(Math.round(value), this.ReportType.timeData)
                    },
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                // y: {
                //     formatter: function (val) {
                //         return val
                //     }
                // }
            }
        }

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertCustomerResponseTime(reportData, groupBy, reportTitle, filterBy) {

        let responseTime = []
        let date = []

        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")

        reportData && reportData.map(data => (
            (
                responseTime.push(Math.round(data.responseTime !== null ? data.responseTime : 0)),
                date.push(dayjs(Date.UTC(data.year, (data.month - 1), groupBy === "month" ? 1 : data.day)).valueOf())
            )
        ))

        let series = [
            {
                name: this.ReportTitle.customerResponseTime,
                data: responseTime
            }
        ]

        let maxMinutes = Math.max(...responseTime);

        const options = {
            chart: {
                id: 'customerResponseTime' + groupBy,
                type: 'area',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            stroke: {
                width: 2,
                curve: 'straight'

            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 2,
                style: 'hollow',
                strokeColors: [this.Colors.info],
            },
            colors: [this.Colors.info],
            fill: {
                colors: [this.Colors.info]
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                type: "category",
                categories: date,
                labels: {
                    hideOverlappingLabels: true,
                    format: undefined,
                    formatter: function (val) {
                        if (groupBy === "month") {
                            return dayjs(val).format("MMM 'YY")
                        } else {
                            return dayjs(val).format("MMM D 'YY")
                        }
                    }
                },
                tickAmount: date.length <= 13 ? date.length : 9, //6 is the default behaviour
                tickPlacement: 'on',
            },
            yaxis: {
                tickAmount: maxMinutes < 6 ? 1 : "",
                labels: {
                    formatter: (value) => {
                        return this.FormatCount(Math.round(value), this.ReportType.timeData)
                    },
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 100]
                }
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertFeedbackInviteChartDataForDashboard(result) {

        let totalInvitesData = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;

                    totalInvitesData.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), Math.round(reportData[isDateExists].totalInvites)]);
                } else {
                    totalInvitesData.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => (
                totalInvitesData.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), Math.round(data.totalInvites)])
            ));
        }

        let chartMinimumStartDate = "";
        if (totalInvitesData.length > 0) {
            let [startDateUnixTime] = totalInvitesData[0]
            chartMinimumStartDate = dayjs(startDateUnixTime).format('DD MMM YYYY')
        }

        let series = [
            {
                name: "Invites",
                data: totalInvitesData
            }
        ]

        let options = {
            chart: {
                type: 'area',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                show: true,
                width: 3,
            },
            colors: ['#ffc44f'],

            fill: {
                colors: ['#ffc44f']
            },
            xaxis: {
                type: 'datetime',
                //min: new Date(chartMinimumStartDate).getTime(),
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        }

        return Object.create({
            series: series,
            options: options,
        })
    }

    //Review Chart

    static ConvertTotalReviewInvitesChartDataForDashboard(result) {

        let totalInvitesSent = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;

                    totalInvitesSent.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), Math.round(reportData[isDateExists].totalInvitesSent)]);
                } else {
                    totalInvitesSent.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => (
                totalInvitesSent.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), Math.round(data.totalInvitesSent)])
            ));
        }

        let series = [
            {
                name: "Invites Sent",
                data: totalInvitesSent
            },
        ]

        let options = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },

            stroke: {
                width: 2,
            },
            colors: ['#f64f60'],

            fill: {
                colors: ['#fabcc3']
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            yaxis: {
                labels: {
                    show: false,
                    formatter: (value) => { return Math.round(value) },
                    style: {
                        fontSize: '12px',
                    }
                }
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertTotalReviewInvitesTotalChartData(reportData, groupBy, reportTitle, filterBy) {
        let totalInvitesSent = [], totalInvitesClicked = [], date = [];
        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")
        reportData && reportData.map(data => (
            (totalInvitesSent.push(Math.round(data.totalInvitesSent)),
                totalInvitesClicked.push(Math.round(data.totalInvitesClicked)),
                date.push(dayjs(Date.UTC(data.year, (data.month - 1), groupBy === "month" ? 1 : data.day)).valueOf()))
        ))

        const inviteSentCount = totalInvitesSent.reduce((sum, item) => sum + item, 0);
        const inviteClickedCount = totalInvitesClicked.reduce((sum, item) => sum + item, 0);

        let series = [
            {
                name: "Invites Sent: " + inviteSentCount,
                data: totalInvitesSent
            },
            {
                name: "Invites Clicked: " + inviteClickedCount,
                data: totalInvitesClicked
            }
        ]
        const options = {
            chart: {
                id: 'totalReviewInvites' + groupBy,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false
                },
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
            dataLabels: {
                enabled: false,
            },
            grid: {
                row: {
                    colors: ['#F3F3F3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                type: "category",
                categories: date,
                labels: {
                    hideOverlappingLabels: true,
                    format: undefined,
                    formatter: function (val) {
                        if (groupBy === "month") {
                            return dayjs(val).format("MMM 'YY")
                        } else {
                            return dayjs(val).format("MMM D 'YY")
                        }
                    }
                },
                tickAmount: date.length <= 13 ? date.length : 9, //6 is the default behaviour
                tickPlacement: 'on',
            },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return val;
                    }
                }
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    title: {
                        formatter: function (value) {
                            return value.split(":")[0]
                        },
                    },
                },
            },
            colors: [this.Colors.danger, this.Colors.success],
            legend: {
                position: 'top',
                itemMargin: {
                    vertical: 10,
                }
            }
        };
        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertTotalReviewsForDashboardChartData(result) {
        let totalReviewData = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;

                    totalReviewData.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), Math.round(reportData[isDateExists].totalReview)]);
                } else {
                    totalReviewData.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => (
                totalReviewData.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), Math.round(data.totalReview)])
            ));
        }

        let series = [
            {
                name: this.ReportTitle.totalReviews,
                data: totalReviewData
            }
        ]

        let options = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },

            stroke: {
                width: 2,
            },
            colors: ['#248bf6'],

            fill: {
                colors: ['#8fbeea']
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            yaxis: {
                labels: {
                    show: false,
                    formatter: (value) => { return Math.round(value) },
                    style: {
                        fontSize: '12px',
                    }
                }
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertTotalReviewsChartData(reportData, groupBy, reportTitle, filterBy) {
        let date = [], responsesData = [];
        const uniqueType = [...new Set(reportData && reportData.map(item => item.site != null ? item.site.title : ""))];
        var channelType = [];

        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")

        uniqueType.map(type => {
            channelType[type] = [];
        });

        reportData.map(d => {
            let day = d.day;
            let month = d.month;
            let year = d.year
            const orderDate = dayjs(Date.UTC(year, (month - 1), groupBy === "month" || groupBy === "monthAndSite" ? 1 : day));
            const dateTimeStamp = orderDate.valueOf()
            var isDate = date.filter(x => x == dateTimeStamp);
            if ((isDate && isDate.length == 0) || date && date.length == 0) {
                date.push(dateTimeStamp)
                let valueByDate = reportData.filter(x => x.day == day && x.month == month && x.year == year)

                for (let i = 0; i < uniqueType.length; i++) {
                    let responsesSeries = {};
                    let channel = uniqueType[i];
                    let valueByChannel = valueByDate.filter(x => x.site.title == channel)
                    if (valueByChannel && valueByChannel.length > 0) {
                        let value = Math.round(valueByChannel[0].totalReview);
                        channelType[channel].push(value);
                    } else {
                        channelType[channel].push(0);

                    }
                    responsesSeries.name = channel;
                    responsesSeries.data = channelType[channel];
                    var isData = responsesData.filter(x => x.name == channel);
                    if (isData && isData.length == 0) {
                        responsesData.push(responsesSeries)
                    }
                }
            }
        })

        if (responsesData && responsesData.length > 0) {
            responsesData.map(x => {
                let total = x.data.reduce((sum, item) => sum + item, 0);
                x.name = x.name + ": " + total
            });
        }
        let series = responsesData;

        let maxValue = 1;
        if (responsesData && responsesData.length > 0) {
            responsesData.map(x => {
                let current = Math.max(...x.data);
                if (current > maxValue) {
                    maxValue = current;
                }
            });
        }


        const options = {
            chart: {
                id: 'totalReviews' + groupBy,
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
            dataLabels: {
                enabled: false,

            },
            colors: [this.Colors.success, this.Colors.danger, this.Colors.warning, this.Colors.primary, this.Colors.secondary, this.Colors.dark],
            stroke: {
                curve: 'smooth',
                width: 3
            },

            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            xaxis: {
                type: "category",
                categories: date,
                labels: {
                    hideOverlappingLabels: true,
                    format: undefined,
                    formatter: function (val) {
                        if (groupBy === "month" || groupBy === "monthAndSite") {
                            return dayjs(val).format("MMM 'YY")
                        } else {
                            return dayjs(val).format("MMM D 'YY")
                        }
                    }
                },
                tickAmount: date.length <= 13 ? date.length : 9, //6 is the default behaviour
                tickPlacement: 'on',
            },
            yaxis: {
                tickAmount: maxValue < 6 ? 1 : "",
                labels: {
                    show: true,
                    formatter: function (val) {
                        // return val;
                        const isInteger = Number.isInteger(val)
                        let yaxisValue = isInteger === true ? val : val.toFixed(0)
                        return yaxisValue;
                    }
                }
            },
            legend: {
                position: 'top',
                itemMargin: {
                    vertical: 10,
                }
            },
            tooltip: {
                style: {
                    fontSize: '12px',
                },
                y: {
                    title: {
                        formatter: function (value) {
                            return value.split(":")[0]
                        },
                    },
                },
                x: {
                    format: 'MMM d \'yy'
                }
            }
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertDetailsPerPageForDashboardChartData(reportData, groupBy) {

        let avgRatingData = [];

        reportData.map(data => {
            const isInteger = Number.isInteger(data.avgRatings)
            let averageRating = isInteger === true ? data.avgRatings : data.avgRatings !== null ? data.avgRatings.toFixed(1) : 0
            avgRatingData.push(
                {
                    name: data.site != null ? data.site.title + ": " : "",
                    data: [Number(averageRating)]
                }
            )
        })
        if (avgRatingData && avgRatingData && avgRatingData.length > 0) {
            let sortDatabyRatings = avgRatingData.sort((a, b) => b.data - a.data);
            avgRatingData = sortDatabyRatings
        }

        let series = avgRatingData

        let options = {
            chart: {
                type: 'bar',
                // height: 430,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    // barHeight: '20%',
                }
            },
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            grid: {
                padding: {
                    right: 40,
                    left: -5,
                },
            },
            xaxis: {
                categories: reportData && reportData.map(data => (data.site != null ? data.site.title : "")),
                labels: {
                    show: false,
                }

            },
            yaxis: {
                labels: {
                    show: false,
                    formatter: (value) => {
                        return value;
                    }
                }
            },
            stroke: {
                show: true,
                width: 4,
                colors: ['#fff']
            },
            colors: ['#6993FF', '#1BC5BD', '#8950FC', '#FFA800', '#F64E60'],
            tooltip: {
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (value) {
                            return value
                        }
                    }
                }
            }
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertAverageReviewRatingForDashboardChartData(result) {

        let averageReviewRating = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;
                    const isInteger = Number.isInteger(reportData[isDateExists].avgReviewRating)
                    const avgRating = isInteger === true ? reportData[isDateExists].avgReviewRating : reportData[isDateExists].avgReviewRating !== null ? reportData[isDateExists].avgReviewRating.toFixed(1) : 0
                    averageReviewRating.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), avgRating]);
                } else {
                    averageReviewRating.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => {
                const isInteger = Number.isInteger(data.avgReviewRating)
                const avgRating = isInteger === true ? data.avgReviewRating : data.avgReviewRating !== null ? data.avgReviewRating.toFixed(1) : 0
                averageReviewRating.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), avgRating])

            })
        }

        let series = [
            {
                name: this.ReportTitle.averageReviewRating,
                data: averageReviewRating
            }
        ];

        let options = {
            chart: {
                id: 'averageReview' + data.groupBy,
                type: 'area',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },

            stroke: {
                curve: 'smooth',
                show: true,
                width: 2,
            },
            colors: [this.Colors.success],

            fill: {
                colors: [this.Colors.success]
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            yaxis: {
                labels: {
                    show: false,
                    formatter: (value) => { return Math.round(value) },
                    style: {
                        fontSize: '12px',
                    }
                }
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy'
                },
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
        };
        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertAverageReviewRatingChartData(reportData, groupBy, reportTitle, filterBy) {

        let date = [], reviewRatingData = [];
        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")

        reportData.map(data => {
            const isInteger = Number.isInteger(data.avgReviewRating)
            const avgRating = isInteger === true ? data.avgReviewRating : data.avgReviewRating !== null ? Number(data.avgReviewRating.toFixed(1)) : 0
            reviewRatingData.push(avgRating);
            date.push(dayjs(Date.UTC(data.year, (data.month - 1), groupBy === "month" ? 1 : data.day)).valueOf())
        })

        const series = [
            {
                name: "Average Review Rating",
                data: reviewRatingData
            }
        ]

        const options = {
            chart: {
                id: 'averageReviewRating' + groupBy,
                type: 'area',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            stroke: {
                width: 2,
                curve: 'straight'

            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 2,
                style: 'hollow',
                strokeColors: [this.Colors.success],
            },
            colors: [this.Colors.success],

            fill: {
                colors: [this.Colors.success]
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                type: "category",
                categories: date,
                labels: {
                    hideOverlappingLabels: true,
                    format: undefined,
                    formatter: function (val) {
                        if (groupBy === "month") {
                            return dayjs(val).format("MMM 'YY")
                        } else {
                            return dayjs(val).format("MMM D 'YY")
                        }
                    }
                },
                tickAmount: date.length <= 13 ? date.length : 9, //6 is the default behaviour
                tickPlacement: 'on',
            },
            yaxis: {
                min: 0,
                max: 5,
                labels: {
                    formatter: function (val) {
                        return val;
                    }
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 100]
                }
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertReviewInviteForDashboardChartData(result) {

        let totalInvitesData = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;

                    totalInvitesData.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), Math.round(reportData[isDateExists].totalInvites)]);
                } else {
                    totalInvitesData.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => (
                totalInvitesData.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), Math.round(data.totalInvites)])
            ));
        }

        let series = [{
            name: "Invites",
            data: totalInvitesData
        }];

        let options = {
            chart: {
                id: 'reviewInviteForDashboard' + data.groupBy,
                type: 'area',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },

            stroke: {
                curve: 'smooth',
                show: true,
                width: 3,
            },
            colors: ['#ffc44f'],

            fill: {
                colors: ['#ffc44f']
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            yaxis: {
                labels: {
                    show: false,
                    formatter: (value) => { return Math.round(value) },
                    style: {
                        fontSize: '12px',
                    }
                }
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy'
                },
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
        };
        return Object.create({
            series: series,
            options: options,
        })
    }

    //Inbox Report
    static ConvertTotalConvoDataToChartDataForDashboard(result) {
        let totalConvoData = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;

                    totalConvoData.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), Math.round(reportData[isDateExists].noOfConvos)]);
                } else {
                    totalConvoData.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => (
                totalConvoData.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), Math.round(data.noOfConvos)])
            ));
        }
        const series = [{
            name: 'Total Conversations',
            data: totalConvoData
        }]

        let options = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#f64f60'],
            fill: {
                colors: ['#fabcc3']
            },
            stroke: {
                width: 2,
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        };
        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertTotalConvoDataToChartData(reportData, groupBy, reportTitle, filterBy) {
        let outBoundData = [], inBoundData = [], date = [], inBountTotalCount = 0, outBoundTotalCount = 0;
        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")
        reportData && reportData.map(data => (
            (outBoundData.push(Math.round(data.noOfOutboundMsg)),
                inBoundData.push(Math.round(data.noOfInboundMsg)),
                date.push(dayjs(Date.UTC(data.year, (data.month - 1), groupBy === "month" ? 1 : data.day)).valueOf()))
        ))

        outBoundTotalCount = outBoundData.reduce((total, data) => total = total + data, 0)
        inBountTotalCount = inBoundData.reduce((total, data) => total = total + data, 0)

        let series = [
            {
                name: "Out Bound: " + outBoundTotalCount,
                data: outBoundData
            },
            {
                name: "In Bound: " + inBountTotalCount,
                data: inBoundData
            },
        ];

        let options = {
            chart: {
                id: 'totalConversation' + groupBy,
                type: "bar",
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false
                },
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
            dataLabels: {
                enabled: false,
            },
            colors: [this.Colors.info, this.Colors.danger],
            xaxis: {
                type: "category",
                categories: date,
                labels: {
                    hideOverlappingLabels: true,
                    format: undefined,
                    formatter: function (val) {
                        if (groupBy === "month") {
                            return dayjs(val).format("MMM")
                        } else {
                            return dayjs(val).format("MMM D 'YY")
                        }
                    }
                },
                tickAmount: date.length <= 13 ? date.length : 6, //6 is the default behaviour
                tickPlacement: 'on',
            },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return val !== null ? val.toFixed(0) : 0;
                    }
                }
            },
            legend: {
                position: 'top',
                itemMargin: {
                    vertical: 10,
                }
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    formatter: function (val) {
                        return val
                    },
                    title: {
                        formatter: function (value) {
                            return value.split(":")[0]
                        },
                    },
                }
            },
        };
        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertAverageTimeReportDataForDashboard(result) {

        let averageResponseData = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;

                    averageResponseData.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), Math.round(reportData[isDateExists].avgResponseTime)]);
                } else {
                    averageResponseData.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => (
                averageResponseData.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), Math.round(data.avgResponseTime)])
            ));
        }

        let chartMinimumStartDate = "";
        if (averageResponseData.length > 0) {
            let [startDateUnixTime] = averageResponseData[0]
            chartMinimumStartDate = dayjs(startDateUnixTime).format('DD MMM YYYY')
        }

        let series = [
            {
                name: "Average Response Time",
                data: averageResponseData
            }
        ]

        let options = {
            chart: {
                type: 'area',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                show: true,
                width: 3,
            },
            colors: ['#248bf6'],

            fill: {
                colors: ['#8fbeea']
            },
            xaxis: {
                type: 'datetime',
                //min: new Date(chartMinimumStartDate).getTime(),
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            yaxis: {
                show: false,
                labels: {
                    formatter: (value) => {
                        return this.FormatCount(Math.round(value), this.ReportType.timeData)
                    },
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
            }
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertAverageTimeReportData(reportData, groupBy, reportTitle, filterBy) {

        let averageResponseData = [], date = [];
        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")

        reportData && reportData.map(data => (
            (
                averageResponseData.push(Math.round(data.avgResponseTime)),
                date.push(dayjs(Date.UTC(data.year, (data.month - 1), groupBy === "month" ? 1 : data.day)).valueOf())
            )
        ))

        let chartMinimumStartDate = "";
        if (averageResponseData.length > 0) {
            let startDateUnixTime = date[0]
            chartMinimumStartDate = dayjs(startDateUnixTime).format('DD MMM YYYY')
        }
        let series = [
            {
                name: reportTitle,
                data: averageResponseData
            }
        ]
        const options = {
            chart: {
                id: 'inboxAverageTime' + groupBy,
                type: 'area',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            stroke: {
                width: 2,
                curve: 'straight'

            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 2,
                style: 'hollow',
                strokeColors: '#248bf6',
            },
            colors: ['#248bf6'],
            fill: {
                colors: ['#8fbeea']
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: groupBy !== "month" ? {
                type: 'datetime',
                //min: new Date(chartMinimumStartDate).getTime(),
                categories: date,
                tickAmount: date.length >= 12 ? 12 : date.length,
                tickPlacement: 'on',
                labels: {
                    showDuplicates: false,
                    formatter: undefined,
                    format: 'MMM d \'yy'
                },
            } :
                {
                    type: 'category',
                    categories: date,
                    labels: {
                        show: true,
                        format: undefined,
                        formatter: function (val) {
                            return dayjs(val).format("MMM")
                        }
                    },
                },
            yaxis: {
                labels: {
                    formatter: (value) => {
                        return this.FormatCount(Math.round(value), this.ReportType.timeData)
                    },
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 100]
                }
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
        };
        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertTotalResponseTimeReportDataForDashboard(result) {

        let totalResponseData = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;

                    totalResponseData.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), Math.round(reportData[isDateExists].totalResponseTime)]);
                } else {
                    totalResponseData.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => (
                totalResponseData.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), Math.round(data.totalResponseTime)])
            ));
        }

        let chartMinimumStartDate = "";
        if (totalResponseData.length > 0) {
            let [startDateUnixTime] = totalResponseData[0]
            chartMinimumStartDate = dayjs(startDateUnixTime).format('DD MMM YYYY')
        }

        let series = [
            {
                name: "Total Response Time",
                data: totalResponseData
            }
        ]

        let options = {
            chart: {
                type: 'area',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                show: true,
                width: 3,
            },
            colors: ['#8346fc'],
            fill: {
                colors: ['#ccb3e6']
            },
            xaxis: {
                type: 'datetime',
                //min: new Date(chartMinimumStartDate).getTime(),
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            yaxis: {
                show: false,
                labels: {
                    formatter: (value) => {
                        return this.FormatCount(Math.round(value), this.ReportType.timeData)
                    },
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
            }
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertTotalResponseTimeReportData(reportData, groupBy, reportTitle, filterBy) {

        let totalResponseData = [], date = [];
        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")

        reportData && reportData.map(data => (
            (
                totalResponseData.push(Math.round(data.totalResponseTime)),
                date.push(dayjs(Date.UTC(data.year, (data.month - 1), groupBy === "month" ? 1 : data.day)).valueOf())
            )
        ))

        let chartMinimumStartDate = "";
        if (totalResponseData.length > 0) {
            let startDateUnixTime = date[0]
            chartMinimumStartDate = dayjs(startDateUnixTime).format('DD MMM YYYY')
        }

        let series = [
            {
                name: "Total Response Time",
                data: totalResponseData
            }
        ]

        const options = {
            chart: {
                id: 'inboxTotalResponseTime' + groupBy,
                type: 'area',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            stroke: {
                width: 2,
                curve: 'straight'

            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 2,
                style: 'hollow',
                strokeColors: '#8346fc',
            },
            colors: ['#8346fc'],
            fill: {
                colors: ['#ccb3e6']
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: groupBy !== "month" ? {
                type: 'datetime',
                //min: new Date(chartMinimumStartDate).getTime(),
                categories: date,
                tickAmount: date.length >= 12 ? 12 : date.length,
                tickPlacement: 'on',
                labels: {
                    showDuplicates: false,
                    formatter: undefined,
                    format: 'MMM d \'yy'
                },
            } :
                {
                    type: 'category',
                    categories: date,
                    labels: {
                        show: true,
                        format: undefined,
                        formatter: function (val) {
                            return dayjs(val).format("MMM")
                        }
                    },
                },
            yaxis: {
                labels: {
                    formatter: (value) => {
                        return this.FormatCount(Math.round(value), this.ReportType.timeData)
                    },
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 100]
                }
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertTotalInboundLeadDataToChartDataForDashboard(result) {
        let totalInboundLeadData = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;

                    totalInboundLeadData.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), Math.round(reportData[isDateExists].noOfInboundLeadMsg)]);
                } else {
                    totalInboundLeadData.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => (
                totalInboundLeadData.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), Math.round(data.totalInvites)])
            ));
        }
        const series = [{
            name: 'Inbound Leads',
            data: totalInboundLeadData
        }]

        let options = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#1dc4bd'],
            fill: {
                colors: ['#94f5f5']
            },
            stroke: {
                width: 2,
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertTotalInboundLeadDataToChartData(reportData, groupBy, reportTitle, filterBy) {

        let date = [], inboundLeadData = [];
        const uniqueChannelType = [...new Set(reportData && reportData.map(item => item.channelType))];
        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")

        var channelType = [];
        uniqueChannelType.map(channel => {
            channelType[channel] = [];
        });

        reportData.map(d => {
            let day = d.day;
            let month = d.month;
            let year = d.year
            const orderDate = dayjs(Date.UTC(year, (month - 1), groupBy === "month" || groupBy === "monthAndChannel" ? 1 : day));
            const dateTimeStamp = orderDate.valueOf()
            var isDate = date.filter(x => x == dateTimeStamp);
            if ((isDate && isDate.length == 0) || date && date.length == 0) {
                date.push(dateTimeStamp)
                let valueByDate = reportData.filter(x => x.day == day && x.month == month && x.year == year) //&& x.channelType == channel
                for (let i = 0; i < uniqueChannelType.length; i++) {
                    let inboundLeadSeries = {};
                    let channel = uniqueChannelType[i];
                    let valueByChannel = valueByDate.filter(x => x.channelType == channel) //&& x.channelType == channel
                    if (valueByChannel && valueByChannel.length > 0) {
                        let inboundLead = valueByChannel[0].noOfInboundLeadMsg;
                        channelType[channel].push(inboundLead);
                    } else {
                        channelType[channel].push(0);
                    }
                    inboundLeadSeries.name = channel;
                    inboundLeadSeries.data = channelType[channel];
                    var isData = inboundLeadData.filter(x => x.name == channel);
                    if (isData && isData.length == 0) {
                        inboundLeadData.push(inboundLeadSeries)
                    }
                }
            }
        })

        if (inboundLeadData && inboundLeadData.length > 0) {
            inboundLeadData.map(x => {
                let total = x.data.reduce((sum, item) => sum + item, 0);
                x.name = x.name + ": " + total
            });
        }
        // let minDate = inboundLeadData[0].data[0]
        // // let maxDate = inboundLeadData[0].data[((inboundLeadData[0].data.length - 1))]

        // let minDateUnixTime = dayjs(minDate[0]).format('DD MMM YYYY'); 
        // // let maxDateUnixTime = dayjs(maxDate[0]).format('DD MMM YYYY'); 

        let series = inboundLeadData;
        let options = {
            chart: {
                id: 'totalInboundLead' + groupBy,
                type: 'bar',
                stacked: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
            xaxis: groupBy !== "monthAndChannel" ? {
                type: 'datetime',
                categories: date,
                tickAmount: date.length >= 12 ? 12 : date.length,
                tickPlacement: 'on',
                labels: {
                    showDuplicates: false,
                    formatter: undefined,
                    format: 'MMM d \'yy'
                },
            } :
                {
                    type: 'category',
                    categories: date,
                    labels: {
                        show: true,
                        format: undefined,
                        formatter: function (val) {
                            return dayjs(val).format("MMM")
                        }
                    },
                },
            yaxis: {
                forceNiceScale: true,
                show: true,
                labels: {
                    formatter: function (val) {
                        return val !== null ? val.toFixed(0) : 0;
                    },
                    align: 'right',
                    style: {
                        colors: '#3F4254',
                    },
                },
            },
            legend: {
                position: 'top',
                itemMargin: {
                    vertical: 10,
                }
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    title: {
                        formatter: function (value) {
                            return value.split(":")[0]
                        },
                    },
                },
            },
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertMedianResponseTimeReportDataForDashboard(reportData, groupBy) {

        let totalResponseData = []
        let date = []

        reportData && reportData.map(data => (
            totalResponseData.push(data.median)
            && date.push(dayjs(Date.UTC(data.year, (data.month - 1), groupBy === "month" ? 1 : data.day)))
        ))

        let series = [
            {
                name: "Median Response Time",
                data: totalResponseData
            }
        ]

        const options = {
            chart: {
                height: 350,
                type: 'line',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 2,
                colors: [this.Colors.Red]
            },

            grid: {
                show: false
            },
            xaxis: {
                categories: date,
                labels: {
                    show: false,
                    formatter: function (value) {
                        return dayjs(value).format("MMM'DD");
                    }
                }
            },
            yaxis: {
                show: false,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        }

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertMedianResponseTimeReportData(reportData, groupBy) {

        let totalResponseData = []
        let date = []


        reportData && reportData.map(data => (
            totalResponseData.push(data.median)
            && date.push(dayjs(Date.UTC(data.year, (data.month - 1), groupBy === "month" ? 1 : data.day)))
        ))

        let series = [
            {
                name: "Median Response Time",
                data: totalResponseData
            }
        ]

        const options = {
            chart: {
                id: 'medianResponseTime' + groupBy,
                type: 'line',
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight',
                width: 2
            },
            colors: ['#EA5765'],
            xaxis: {
                labels: {
                    formatter: function (value) {
                        return dayjs(value).format("MMM'DD");
                    },
                },
                categories: date
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " Minutes"
                    }
                }
            }
        }

        return Object.create({
            series: series,
            options: options,
        })
    }

    //Task Report

    static ConvertTotalTaskOpenedChartDataForDashboard(result) {

        let totalTaskOpened = [], date = [], dates = [], totalTasks = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        let sortedTask = reportData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        let sortedTaskAscendingByDate = sortedTask.length > 0 ? sortedTask.reverse() : []

        sortedTaskAscendingByDate.map(d => {
            let day = d.day;
            let month = d.month;
            let year = d.year
            const orderDate = dayjs(Date.UTC(year, (month - 1), day));
            const dateTimeStamp = orderDate.valueOf()
            var isDate = date.filter(x => x == dateTimeStamp);
            if ((isDate && isDate.length == 0) || date && date.length == 0) {
                date.push(dateTimeStamp)
                let valueByDate = reportData.filter(x => x.day == day && x.month == month && x.year == year)
                let total = valueByDate.reduce((total, data) => total = total + data.totalOpenTask, 0)
                totalTasks.push({ dateTimeStamp: dateTimeStamp, totalTask: Math.round(total) });

                if (data.filterType === "last30Days" && reportData.length === 30) {
                    totalTaskOpened.push([dateTimeStamp, Math.round(total)]);
                }
            }
        })

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                const isDateExists = totalTasks.findIndex(d => d.dateTimeStamp === dateTimeStamp);
                if (isDateExists != -1) {
                    totalTaskOpened.push([totalTasks[isDateExists].dateTimeStamp, Math.round(totalTasks[isDateExists].totalTask)]);
                } else {
                    totalTaskOpened.push([dateTimeStamp, 0]);
                }
            });
        }
        let series = [
            {
                name: this.ReportTitle.totalTaskOpened,
                data: totalTaskOpened
            },
        ]

        let options = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#1dc4bd'],

            fill: {
                colors: ['#94f5f5']
            },
            stroke: {
                width: 2,
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertTaskDetailsChartData(reportData, groupBy, reportTitle, filterBy) {
        let date = [], taskData = [];
        const uniqueChannelType = [...new Set(reportData && reportData.map(item => item.type))];
        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")

        let sortedTask = reportData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        let sortedTaskAscendingByDate = sortedTask.length > 0 ? sortedTask.reverse() : []

        var channelType = [];
        uniqueChannelType.map(channel => {
            channelType[channel] = [];
        });

        sortedTaskAscendingByDate.map(d => {
            let day = d.day;
            let month = d.month;
            let year = d.year
            const orderDate = dayjs(Date.UTC(year, (month - 1), groupBy === "month" || groupBy === "monthAndType" ? 1 : day));
            const dateTimeStamp = orderDate.valueOf()
            var isDate = date.filter(x => x == dateTimeStamp);
            if ((isDate && isDate.length == 0) || date && date.length == 0) {
                date.push(dateTimeStamp)

                let valueByDate = reportData.filter(x => x.day == day && x.month == month && x.year == year) //&& x.channelType == channel

                for (let i = 0; i < uniqueChannelType.length; i++) {
                    let series = {};
                    let channel = uniqueChannelType[i];
                    let valueByChannel = valueByDate.filter(x => x.type == channel) //&& x.channelType == channel
                    if (valueByChannel && valueByChannel.length > 0) {
                        let data = reportTitle === this.ReportTitle.totalTaskOpened ? valueByChannel[0].totalOpenTask : valueByChannel[0].taskClosedTask;
                        channelType[channel].push(data);

                    } else {
                        channelType[channel].push(0);
                    }
                    series.name = channel;
                    series.data = channelType[channel];
                    var isData = taskData.filter(x => x.name == channel);
                    if (isData && isData.length == 0) {
                        taskData.push(series)
                    }
                }
            }
        })

        if (taskData && taskData.length > 0) {
            taskData.map(x => {
                let total = x.data.reduce((sum, item) => sum + item, 0);
                x.name = x.name + ": " + total
            });
        }

        let series = taskData;

        let options = {
            chart: {
                id: 'taskOpenCloseDetails' + groupBy,
                type: 'bar',
                stacked: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
            xaxis: groupBy !== "monthAndType" ? {
                type: 'datetime',
                categories: date,
                tickAmount: date.length >= 12 ? 12 : date.length,
                tickPlacement: 'on',
                labels: {
                    showDuplicates: false,
                    formatter: undefined,
                    format: 'MMM d \'yy'
                },
            } :
                {
                    type: 'category',
                    categories: date,
                    labels: {
                        show: true,
                        format: undefined,
                        formatter: function (val) {
                            return dayjs(val).format("MMM")
                        }
                    },
                },
            yaxis: {
                forceNiceScale: true,
                labels: {
                    show: true,
                    formatter: function (val) {
                        return val !== null ? val.toFixed(0) : 0;
                    },
                    align: 'right',
                    style: {
                        colors: '#3F4254',
                    },
                },
            },
            legend: {
                position: 'top',
                itemMargin: {
                    vertical: 10,
                }
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    title: {
                        formatter: function (value) {
                            return value.split(":")[0]
                        },
                    },
                },
            },
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertTotalTaskClosedChartDataForDashboard(result) {

        let totalTaskClosed = [], date = [], dates = [], totalTasks = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        let sortedTask = reportData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        let sortedTaskAscendingByDate = sortedTask.length > 0 ? sortedTask.reverse() : []

        sortedTaskAscendingByDate.map(d => {
            let day = d.day;
            let month = d.month;
            let year = d.year
            const orderDate = dayjs(Date.UTC(year, (month - 1), day));
            const dateTimeStamp = orderDate.valueOf()
            var isDate = date.filter(x => x == dateTimeStamp);
            if ((isDate && isDate.length == 0) || date && date.length == 0) {
                date.push(dateTimeStamp)
                let valueByDate = reportData.filter(x => x.day == day && x.month == month && x.year == year)
                let total = valueByDate.reduce((total, data) => total = total + data.taskClosedTask, 0)
                totalTasks.push({ dateTimeStamp: dateTimeStamp, totalTask: Math.round(total) });

                if (data.filterType === "last30Days" && reportData.length === 30) {
                    totalTaskClosed.push([dateTimeStamp, Math.round(total)]);
                }
            }
        });

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                const isDateExists = totalTasks.findIndex(d => d.dateTimeStamp === dateTimeStamp);
                if (isDateExists != -1) {

                    totalTaskClosed.push([totalTasks[isDateExists].dateTimeStamp, Math.round(totalTasks[isDateExists].totalTask)]);
                } else {
                    totalTaskClosed.push([dateTimeStamp, 0]);
                }
            });
        }

        let series = [
            {
                name: this.ReportTitle.totalTaskClosed,
                data: totalTaskClosed
            },
        ]

        let options = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2
            },
            colors: ['#f64f60'],

            fill: {
                colors: ['#fabcc3']
            },
            xaxis: {
                type: 'datetime',
                // categories: date,
                labels: {
                    show: false,

                    formatter: function (value) {
                        return dayjs(value).format("MMM DD 'YY");
                    }
                }
            },
            yaxis: {
                labels: {
                    show: false,
                    formatter: (value) => { return Math.round(value) },
                    style: {
                        fontSize: '12px',
                    }
                }
            },
            tooltip: {
                style: {
                    fontSize: '12px',
                },
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertTotalTaskClosedChartData(reportData, groupBy, reportTitle, filterBy) {

        let totalTaskClosed = []

        let totalTaskClosedCount = 0;
        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")

        reportData && reportData.map(data => (
            totalTaskClosed.push([dayjs(Date.UTC(data.year, (data.month - 1), groupBy === "month" ? 1 : data.day)).valueOf(), Math.round(data.totalOpenTask)])
        ))
        if (totalTaskClosed && totalTaskClosed.length > 0) {
            totalTaskClosed.map(d => {
                totalTaskClosedCount = totalTaskClosedCount + d[1]
            })
        }

        let chartMinimumStartDate = "";
        if (totalTaskClosed.length > 0) {
            let [startDateUnixTime] = totalTaskClosed[0]
            chartMinimumStartDate = dayjs(startDateUnixTime).format('DD MMM YYYY')
        }
        let series = [
            {
                name: this.ReportTitle.totalTaskClosed + ": " + totalTaskClosedCount,
                data: totalTaskClosed
            }
        ]

        const options = {

            chart: {
                id: 'totalTaskClosed' + groupBy,
                toolbar: {
                    show: true,
                    autoSelected: 'zoom'
                },
                zoom: {
                    enabled: true
                },
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },

            dataLabels: {
                enabled: false,
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                type: 'datetime',
                //min: new Date(chartMinimumStartDate).getTime(),
                //tickAmount: 2,
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                },

                y: {
                    formatter: undefined,
                    title: {
                        formatter: function (value) {
                            return value.split(":")[0]
                        },
                    },
                },
            },
            colors: ['#f64f60'],

            fill: {
                colors: ['#fabcc3']
            },
            legend: {
                position: 'top',
            }
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertTasksTimeToCompleteForDashboard(result) {

        let totalTasks = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;

                    totalTasks.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), Math.round(reportData[isDateExists].timeToComplete)]);
                } else {
                    totalTasks.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => (
                totalTasks.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), Math.round(data.timeToComplete)])
            ));
        }

        let chartMinimumStartDate = "";
        if (totalTasks.length > 0) {
            let [startDateUnixTime] = totalTasks[0]
            chartMinimumStartDate = dayjs(startDateUnixTime).format('DD MMM YYYY')
        }

        let series = [
            {
                name: this.ReportTitle.tasksTimeToComplete,
                data: totalTasks
            }
        ]

        let options = {
            chart: {
                type: 'area',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                show: true,
                width: 3,
            },
            colors: ['#248bf6'],

            fill: {
                colors: ['#8fbeea']
            },
            xaxis: {
                type: 'datetime',
                //min: new Date(chartMinimumStartDate).getTime(),
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            yaxis: {
                show: false,
                labels: {
                    formatter: (value) => {
                        return this.FormatCount(Math.round(value), this.ReportType.timeData)
                    },
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                // y: {
                //     formatter: function (val) {
                //         return val
                //     }
                // }
            }
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertTasksTimeToComplete(reportData, groupBy, reportTitle, filterBy) {

        let totalTasks = [], date = [];
        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")

        reportData && reportData.map(data => (
            (
                totalTasks.push(Math.round(data.timeToComplete)),
                date.push(dayjs(Date.UTC(data.year, (data.month - 1), groupBy === "month" ? 1 : data.day)).valueOf())
            )
        ))

        let chartMinimumStartDate = "";
        if (totalTasks.length > 0) {
            let startDateUnixTime = date[0]
            chartMinimumStartDate = dayjs(startDateUnixTime).format('DD MMM YYYY')
        }

        const series = [{
            name: reportTitle,
            data: totalTasks
        }]

        const options = {
            chart: {
                id: 'tasksTimeToComplete' + groupBy,
                type: 'area',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            stroke: {
                width: 2,
                curve: 'straight'

            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 2,
                style: 'hollow',
                strokeColors: '#248bf6',
            },
            colors: ['#248bf6'],
            fill: {
                colors: ['#8fbeea']
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: groupBy !== "month" ? {
                type: 'datetime',
                // min: new Date(chartMinimumStartDate).getTime(),
                categories: date,
                tickAmount: date.length >= 12 ? 12 : date.length,
                tickPlacement: 'on',
                labels: {
                    showDuplicates: false,
                    formatter: undefined,
                    format: 'MMM d \'yy'
                },
            } :
                {
                    type: 'category',
                    categories: date,
                    labels: {
                        show: true,
                        format: undefined,
                        formatter: function (val) {
                            return dayjs(val).format("MMM")
                        }
                    },
                },
            yaxis: {
                labels: {
                    formatter: (value) => {
                        return this.FormatCount(Math.round(value), this.ReportType.timeData)
                    },
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 100]
                }
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertTaskAssignmentChartDataForDashboard(reportData, groupBy) {

        let taskAssignments = [];
        reportData.assignedToTeamCount.map(data => {
            if (data.team) {
                taskAssignments.push(
                    {
                        name: data.team.title + ": " + Math.round(data.taskCreationCount),
                        data: [Math.round(parseFloat(data.taskCreationCount))]
                    }
                )
            }
        });

        reportData.assignedToUserCount.map(data => {
            if (data.user) {
                taskAssignments.push(
                    {
                        name: data.user.firstName + " " + data.user.lastName + ": " + Math.round(data.taskCreationCount),
                        data: [Math.round(parseFloat(data.taskCreationCount))]
                    }
                )
            }
        });

        let series = taskAssignments;

        let options = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: { bar: { horizontal: true } },
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },

            xaxis: {
                categories: taskAssignments && taskAssignments.map(data => (data.name)),
                labels: {
                    show: false,
                }

            },
            yaxis: {
                labels: {
                    show: false,
                    formatter: (value) => { return Math.round(value) },
                    style: {
                        fontSize: '12px',
                    }
                }
            },
            stroke: {
                show: true,
                width: 4,
                colors: ['#fff']
            },
            colors: ['#6993FF', '#1BC5BD', '#8950FC', '#FFA800', '#F64E60'],
            tooltip: {
                style: {
                    fontSize: '12px',
                },
                x: {
                    show: false,
                    title: "Task Assignment"
                },
                y: {
                    title: {
                        formatter: function (value) {
                            return value.split(":")[0]
                        },
                    },
                },
            },
        };

        return Object.create({
            series: series,
            options: options,
        })

    }

    static ConvertTaskAssignmentChartData(reportData, groupBy, reportTitle, filterBy) {
        let taskCount = [], taskByMember = [];
        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")
        reportData.assignedToTeamCount.map(data => {
            if (data.team) {
                taskCount.push(Math.round(parseFloat(data.taskCreationCount)))
                taskByMember.push(data.team.title)
            }
        });

        reportData.assignedToUserCount.map(data => {
            if (data.user) {
                taskCount.push(Math.round(parseFloat(data.taskCreationCount)))
                taskByMember.push(data.user.firstName + " " + data.user.lastName)
            }
        });

        let series = [{
            data: taskCount
        }]

        let options = {
            chart: {
                id: 'taskAssignment' + groupBy,
                type: 'bar',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            plotOptions: { bar: { horizontal: true, distributed: true } },
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: taskByMember,
                labels: {
                    show: true,
                }

            },

            stroke: {
                show: true,
                width: 4,
                colors: ['#fff']
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            tooltip: {
                theme: 'dark',
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function () {
                            return ''
                        }
                    }
                }
            }
        };

        return Object.create({
            series: series,
            options: options,
        })

    }

    static ConvertTaskCreationForDashboard(reportData, groupBy) {

        let series = reportData && reportData.map(data => (
            {
                name: data.user.firstName,
                data: [Math.round(parseFloat(data.taskCreationCount))]
            }
        ));

        let options = {
            chart: {
                type: 'bar',
                height: 430,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '30%',
                }
            },
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },

            xaxis: {
                categories: reportData && reportData.map(data => (data.user.firstName)),
                labels: {
                    show: false,
                }

            },
            yaxis: {
                labels: {
                    show: false,
                    formatter: (value) => { return Math.round(value) },
                    style: {
                        fontSize: '12px',
                    }
                }
            },
            stroke: {
                show: true,
                width: 4,
                colors: ['#fff']
            },
            colors: ['#ffebc1', '#ffb1ba', '#a0c8ed', '#dfd3f7', '#a3f4f4', '#f1cbe7', '#a9a8f5'],
            tooltip: {
                style: {
                    fontSize: '12px',
                },
                x: {
                    show: false,
                    title: "Task Creation"
                },
                y: {
                    title: {
                        formatter: function (value) {
                            return value
                        },
                    },
                },
            },
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertTaskCreationChartData(reportData, groupBy, reportTitle, filterBy) {

        let taskCount = [], taskCreationBy = [];
        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")

        reportData.map(data => {
            if (data.user) {
                taskCount.push(Math.round(parseFloat(data.taskCreationCount)))
                taskCreationBy.push(data.user.firstName + " " + data.user.lastName)
            }
        });

        let series = [{
            data: taskCount
        }]

        let options = {
            chart: {
                id: 'taskCreation' + groupBy,
                type: 'bar',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            plotOptions: { bar: { horizontal: true, distributed: true } },
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: taskCreationBy,
                labels: {
                    show: true,
                }

            },

            stroke: {
                show: true,
                width: 4,
                colors: ['#fff']
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            tooltip: {
                theme: 'dark',
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function () {
                            return ''
                        }
                    }
                }
            }
        };

        return Object.create({
            series: series,
            options: options,
        })

    }

    //Payment Report

    static ConvertPaymentResponseTimeForDashboardChart(result) {

        // let responseTime = []

        // reportData && reportData.map(data => (
        //     responseTime.push([dayjs(Date.UTC(data.year, (data.month - 1), groupBy === "month" ? 1 : data.day)).valueOf(), Math.round(data.responseTime !== null ? data.responseTime : 0)])
        // ))

        let responseTime = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;

                    responseTime.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), Math.round(reportData[isDateExists].responseTime)]);
                } else {
                    responseTime.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => (
                responseTime.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), Math.round(data.responseTime)])
            ));
        }
        let series = [
            {
                name: this.ReportTitle.paymentResponseTime,
                data: responseTime
            }
        ]
        let options = {
            chart: {
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },

            stroke: {
                curve: 'smooth',
                show: true,
                width: 3,
            },
            colors: ['#663399'],

            fill: {
                colors: ['#a683e9']
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            yaxis: {
                show: false,
                labels: {
                    formatter: (value) => {
                        return this.FormatCount(Math.round(value), this.ReportType.timeData)
                    },
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                // y: {
                //     formatter: function (val) {
                //         return val
                //     }
                // }
            }
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertPaymentResponseTimeChart(reportData, groupBy, reportTitle, filterBy) {

        let responseTime = [], date = [];

        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")

        reportData && reportData.map(data => (
            (responseTime.push(Math.round(data.responseTime !== null ? data.responseTime : 0)),
                date.push(dayjs(Date.UTC(data.year, (data.month - 1), groupBy === "month" ? 1 : data.day)).valueOf())
            )
        ))

        let chartMinimumStartDate = "";
        if (reportData.length > 0) {
            let startDateUnixTime = date[0]
            chartMinimumStartDate = dayjs(startDateUnixTime).format('DD MMM YYYY')
        }

        let series = [
            {
                name: this.ReportTitle.paymentResponseTime,
                data: responseTime
            }
        ]

        const options = {
            chart: {
                id: 'paymentResponseTime' + groupBy,
                type: 'area',

                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
            },
            stroke: {
                width: 2,
                curve: 'straight'
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
                style: 'hollow',
            },
            colors: ['#8346fc'],

            fill: {
                colors: ['#ccb3e6']
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            xaxis: groupBy !== "month" ? {
                type: 'datetime',
                //min: new Date(chartMinimumStartDate).getTime(),
                categories: date,
                tickAmount: date.length >= 12 ? 12 : date.length,
                tickPlacement: 'on',
                labels: {
                    showDuplicates: false,
                    formatter: undefined,
                    format: 'MMM d \'yy'
                },
            } :
                {
                    type: 'category',
                    categories: date,
                    labels: {
                        show: true,
                        format: undefined,
                        formatter: function (val) {
                            return dayjs(val).format("MMM")
                        }
                    },
                },

            yaxis: {
                labels: {
                    formatter: (value) => {
                        return this.FormatCount(Math.round(value), this.ReportType.timeData)
                    },
                },
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 100]
                }
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertPaymentSendAndPaidForDashboardChart(result) {

        let totalPaymentsSend = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;

                    totalPaymentsSend.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), Math.round(reportData[isDateExists].totalPaymentsSend)]);
                } else {
                    totalPaymentsSend.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => (
                totalPaymentsSend.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), Math.round(data.totalPaymentsSend)])
            ));
        }

        let series = [
            {
                name: "Request Sent",
                data: totalPaymentsSend
            }
        ];

        let options = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },

            stroke: {
                width: 2
            },
            colors: ['#1dc4bd'],

            fill: {
                colors: ['#94f5f5']
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            yaxis: {
                labels: {
                    show: false,
                    formatter: (value) => { return Math.round(value) },
                    style: {
                        fontSize: '12px',
                    }
                }
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertPaymentSendAndPaidChart(reportData, groupBy, reportTitle, filterBy) {

        let totalPaymentsSend = []
        let totalPaid = []
        let date = []
        let noofReqSend = 0, noofReqPaid = 0;
        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")


        reportData && reportData.map(data => (
            (totalPaymentsSend.push(Math.round(data.totalPaymentsSend)),
                totalPaid.push(Math.round(data.totalPaid)),
                date.push(dayjs(Date.UTC(data.year, (data.month - 1), groupBy === "month" ? 1 : data.day)).valueOf()))
        ))


        if (totalPaymentsSend && totalPaymentsSend.length > 0) {
            totalPaymentsSend.map(d => {
                noofReqSend = noofReqSend + d
            })
        }
        if (totalPaid && totalPaid.length > 0) {
            totalPaid.map((d) => {
                noofReqPaid = noofReqPaid + d
            }
            );
        }

        let series = [
            {
                name: "Request Sent" + ": " + noofReqSend,
                data: totalPaymentsSend
            },
            {
                name: "Request Paid" + ": " + noofReqPaid,
                data: totalPaid
            }
        ]

        const options = {

            chart: {
                id: 'paymentSendAndPaid' + groupBy,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false
                },
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },

            dataLabels: {
                enabled: false,
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            xaxis: groupBy !== "month" ? {
                type: 'datetime',
                categories: date,
                tickAmount: date.length >= 12 ? 12 : date.length,
                tickPlacement: 'on',
                labels: {
                    showDuplicates: false,
                    formatter: undefined,
                    format: 'MMM d \'yy'
                },
            } :
                {
                    type: 'category',
                    categories: date,
                    labels: {
                        show: true,
                        format: undefined,
                        formatter: function (val) {
                            return dayjs(val).format("MMM")
                        }
                    },
                },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return val !== null ? val.toFixed(0) : 0;
                    }
                }
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },

                y: {
                    formatter: undefined,
                    title: {
                        formatter: function (value) {
                            return value.split(":")[0]
                        },
                    },
                },
            },
            colors: ['#ccd8fc', '#28cbad'],
            legend: {
                position: 'top',
                itemMargin: {
                    vertical: 10,
                }
            }
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertAmountProcessedForDashboardChart(result) {

        let totalAmount = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;

                    totalAmount.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), reportData[isDateExists].totalAmount]);
                } else {
                    totalAmount.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => (
                totalAmount.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), data.totalAmount])
            ));
        }

        let series = [
            {
                name: "Amount Processed",
                data: totalAmount
            }
        ];

        let options = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },

            stroke: {
                width: 2,
            },
            colors: ['#248bf6'],

            fill: {
                colors: ['#8fbeea']
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            yaxis: {
                labels: {
                    show: false,
                    formatter: (value) => { return Math.round(value) },
                    style: {
                        fontSize: '12px',
                    }
                }
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    formatter: function (val) {
                        return "$" + val
                    }
                }
            }
        };
        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertAmountProcessedChart(reportData, groupBy, reportTitle, filterBy) {

        let totalAmounts = []
        let date = []
        let totalPaidAmounts = []
        let totalAmount = 0, totalPaidAmount = 0;
        const dateRange = this.getDateRange(filterBy);
        const fromDate = dayjs(dateRange.fromDate).format("ll")
        const toDate = dayjs(dateRange.toDate).format("ll")

        reportData && reportData.map(data => (
            (totalAmounts.push(data.totalAmount),
                totalPaidAmounts.push(data.totalPaidAmount),
                date.push(dayjs(Date.UTC(data.year, (data.month - 1), groupBy === "month" ? 1 : data.day)).valueOf()))
        ))

        if (totalAmounts && totalAmounts.length > 0) {
            totalAmounts.map(d => {
                totalAmount = totalAmount + d
            })
        }
        if (totalPaidAmounts && totalPaidAmounts.length > 0) {
            totalPaidAmounts.map((d) => {
                totalPaidAmount = totalPaidAmount + d
            }
            );
        }

        let series = [
            {
                name: "Amount Requested" + ": " + "$" + totalAmount,
                data: totalAmounts
            },
            {
                name: "Amount Paid" + ": " + "$" + totalPaidAmount,
                data: totalPaidAmounts
            }
        ]

        const options = {

            chart: {
                id: 'amountProcessed' + groupBy,
                toolbar: {
                    show: false,
                    autoSelected: 'zoom'
                },
                zoom: {
                    enabled: false
                },
            },
            title: {
                text: reportTitle,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: this.Colors.dark75,
                    size: 50
                },
            },
            subtitle: {
                text: fromDate + " - " + toDate,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 27,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },

            dataLabels: {
                enabled: false,
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return "$" + value;
                    }
                },
            },
            xaxis: groupBy !== "month" ? {
                type: 'datetime',
                categories: date,
                tickAmount: date.length >= 12 ? 12 : date.length,
                tickPlacement: 'on',
                labels: {
                    showDuplicates: false,
                    formatter: undefined,
                    format: 'MMM d \'yy'
                },
            } :
                {
                    type: 'category',
                    categories: date,
                    labels: {
                        show: true,
                        format: undefined,
                        formatter: function (val) {
                            return dayjs(val).format("MMM")
                        }
                    },
                },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {

                    formatter: function (value) {
                        return "$" + value;
                    },
                    title: {
                        formatter: function (value) {
                            return value.split(":")[0]
                        },
                    },
                },
            },
            colors: ['#ccd8fc', '#28cbad'],
            legend: {
                position: 'top',
                itemMargin: {
                    vertical: 10,
                }
            }
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static ConvertPaymentActivityForDashboardChart(result) {

        let totalPaymentsData = [], dates = [];
        const data = result, reportData = result.data, fromDateTime = new Date(data.fromDate), endDateTime = new Date(data.toDate);

        if (data.filterType === "last30Days" && reportData.length < 30) {

            while (fromDateTime < endDateTime) {
                dates = [...dates, new Date(fromDateTime).valueOf()]
                fromDateTime.setDate(fromDateTime.getDate() + 1)
            }

            dates = [...dates, endDateTime.valueOf()];

            dates && dates.map(dateTimeStamp => {
                var isDateExists = reportData.findIndex(d => dayjs(Date.UTC(d.year, (d.month - 1), d.day)).valueOf() === dateTimeStamp);
                if (isDateExists != -1) {
                    const year = reportData[isDateExists].year;
                    const month = reportData[isDateExists].month;
                    const day = reportData[isDateExists].day;

                    totalPaymentsData.push([dayjs(Date.UTC(year, (month - 1), day)).valueOf(), Math.round(reportData[isDateExists].totalPayments)]);
                } else {
                    totalPaymentsData.push([dateTimeStamp, 0]);
                }
            });
        } else {
            reportData && reportData.map(data => (
                totalPaymentsData.push([dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf(), Math.round(data.totalPayments)])
            ));
        }

        let series = [
            {
                name: "Activity",
                data: totalPaymentsData
            }
        ];

        let options = {
            chart: {
                type: 'area',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },

            stroke: {
                curve: 'smooth',
                show: true,
                width: 3,
            },
            colors: ['#ffc44f'],

            fill: {
                colors: ['#ffc44f']
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'MMM d \'yy',
                },
            },
            yaxis: {
                labels: {
                    show: false,
                    formatter: (value) => { return Math.round(value) },
                    style: {
                        fontSize: '12px',
                    }
                }
            },
            tooltip: {
                x: {
                    format: 'MMM d \'yy',
                },
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        };

        return Object.create({
            series: series,
            options: options,
        })
    }

    static getChartStateName(reportType) {
        let name = "";
        switch (reportType) {
            //Feedback Report
            case this.ReportType.surveysSendForDashboard: {
                name = "surveysSendForDashboardChartData";
                break;
            }
            case this.ReportType.surveysSend: {
                name = "surveysSendForChartData";
                break;
            }
            case this.ReportType.feedbackScore: {
                name = "feedbackScoreChartData";
                break;
            }
            case this.ReportType.feedbackScoreForDashboard: {
                name = "feedbackScoreForDashboardChartData";
                break;
            }
            case this.ReportType.totalResponseForDashboard: {
                name = "totalResponseForDashboardChartData";
                break;
            }
            case this.ReportType.totalResponse: {
                name = "totalResponseChartData";
                break;
            }
            case this.ReportType.customerResponseTime: {
                name = "customerResponseTimeChartData";
                break;
            }
            case this.ReportType.customerResponseTimeForDashboard: {
                name = "customerResponseTimeForDashboardChartData";
                break;
            }
            case this.ReportType.feedbackInviteForDashboard: {
                name = "feedbackInviteForDashboardChartData";
                break;
            }

            //Review Report
            case this.ReportType.totalReviewInvitesForDashboard: {
                name = "totalReviewInvitesForDashboard";
                break;
            }
            case this.ReportType.totalReviewInvites: {
                name = "totalReviewInvitesForChartData";
                break;
            }
            case this.ReportType.totalReviewsForDashboard: {
                name = "totalReviewsForDashboardChartData";
                break;
            }
            case this.ReportType.totalReviews: {
                name = "totalReviewsForChartData";
                break;
            }

            case this.ReportType.avgReviewRatingForDashboard: {
                name = "avgReviewRatingForDashboardChart";
                break;
            }
            case this.ReportType.avgReviewRating: {
                name = "avgReviewRatingForChartData";
                break;
            }
            case this.ReportType.detailsPerPageForDashboard: {
                name = "detailsPerPageForDashboard";
                break;
            }
            case this.ReportType.reviewInviteForDashboard: {
                name = "reviewInviteForDashboardChartData";
                break;
            }
            case this.ReportType.detailsPerPageNameForDashboard: {
                name = "detailsPerPageNameForDashboard";
                break;
            }
            //Inbox Report
            case this.ReportType.inboundOutbound: {
                name = "inBoundOutboundChartData";
                break;
            }
            case this.ReportType.avgResponseTime: {
                name = "avgResponseTimeChartData";
                break;
            }
            case this.ReportType.inboundLead: {
                name = "inboundLeadChartData";
                break;
            }
            case this.ReportType.totalInboundLead: {
                name = "totalInboundLeadChartData";
                break;
            }
            case this.ReportType.totalConvoCount: {
                name = "totalConvoChartData";
                break;
            }
            case this.ReportType.avgResponseTimeForDashboard: {
                name = "avgResponseTimeChartDataForDashboard";
                break;
            }
            case this.ReportType.totalResponseTimeForDashboard: {
                name = "totalResponseTimeChartDataForDashboard";
                break;
            }
            case this.ReportType.totalResponseTime: {
                name = "totalResponseTimeChartData";
                break;
            }
            case this.ReportType.medianResponseTimeForDashboard: {
                name = "medianResponseTimeChartDataForDashboard";
                break;
            }
            case this.ReportType.medianResponseTime: {
                name = "medianResponseTimeChartData";
                break;
            }

            //Task 

            case this.ReportType.totalTaskOpenedForDashboard: {
                name = "totalTaskOpenedChartDataForDashboard";
                break;
            }
            case this.ReportType.totalTaskOpened: {
                name = "totalTaskOpenedChartData";
                break;
            }

            case this.ReportType.totalTaskClosedForDashboard: {
                name = "totalTaskClosedChartDataForDashboard";
                break;
            }
            case this.ReportType.totalTaskClosed: {
                name = "totalTaskClosedChartData";
                break;
            }

            case this.ReportType.tasksTimeToCompleteForDashboard: {
                name = "tasksTimeToCompleteForDashboard";
                break;
            }
            case this.ReportType.tasksTimeToComplete: {
                name = "tasksTimeToComplete";
                break;
            }

            case this.ReportType.taskAssignments: {
                name = "taskAssignmentsChartData";
                break;
            }

            case this.ReportType.taskAssignmentsForDashboard: {
                name = "taskAssignmentsChartDataForDashboard";
                break;
            }

            case this.ReportType.taskCreationForDashboard: {
                name = "taskCreationForDashboard";
                break;
            }
            case this.ReportType.taskCreation: {
                name = "taskCreationChartData";
                break;
            }


            // Payment Report Chart Type

            case this.ReportType.paymentResponseTime: {
                name = "paymentResponseTimeChart";
                break;
            }
            case this.ReportType.paymentResponseTimeForDashboard: {
                name = "paymentResponseTimeForDashboardChart";
                break;
            }
            case this.ReportType.paymentSendAndPaid: {
                name = "paymentSendAndPaidChart";
                break;
            }
            case this.ReportType.paymentSendAndPaidForDashboard: {
                name = "paymentSendAndPaidForDashboardChart";
                break;
            }
            case this.ReportType.amountProcessed: {
                name = "amountProcessedChart";
                break;
            }
            case this.ReportType.amountProcessedForDashboard: {
                name = "amountProcessedForDashboardChart";
                break;
            }
            case this.ReportType.paymentActivityForDashboard: {
                name = "paymentActivityForDashboardChart";
                break;
            }
        }
        return name;
    }
}
