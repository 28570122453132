import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Parse } from 'parse';
// import moment from 'moment'
import dayjs from "dayjs";

import ReportHelper from "../../../../../_metronic/_assets/js/components/reportHelper"


// initial state which is shared by all component using 

const today = dayjs(new Date());

const initialState = {
  pageStatus: 'idle',
  pageError: null,
  graphStatus: 'idle',
  graphError: null,
  reportData: [],
  inBoundOutboundChartData: {},
  avgResponseTimeChartData: {},
  inboundLeadChartData: {},
  totalInboundLeadChartData: {},
  totalConvoChartData: {},
  avgResponseTimeChartDataForDashboard: {},
  totalResponseTimeChartDataForDashboard: {},
  totalResponseTimeChartData: {},
  medianResponseTimeChartDataForDashboard: {},
  medianResponseTimeChartData: {},
  monthToDate: 0,
  last12Months: 0,
  totalConvoCount_monthToDate: 0,
  totalConvoCount_last12Month: 0,
  avgResponseTimeForDashboard_monthToDate: 0,
  avgResponseTimeForDashboard_last12Month: 0,
  totalInboundLead_monthToDate: 0,
  totalInboundLead_last12Month: 0,
  totalResponseTimeForDashboard_monthToDate: 0,
  totalResponseTimeForDashboard_last12Month: 0,
  medianResponseTimeForDashboard_monthToDate: 0,
  medianResponseTimeForDashboard_last12Month: 0,
  queryParams: {
    fromDate: dayjs(today).subtract(7, 'day').format("YYYY-MM-DD"),
    toDate: today.format("YYYY-MM-DD"),
  },
  curentPage: {
    pageName: "dashboard",
    pageData: null,
    reportType: "",
    summeryType: "",
    chartType: "",
    reportTitle: ""
  }

}

// server API calling dispatch
export const fetchConvoReports = createAsyncThunk('report/fetchConvosDailyTotals', async (values) => {
  const cloudResponse = await Parse.Cloud.run("fetchConvosDailyTotals", values);
  return cloudResponse;
})

export const fetchConvoSummary = createAsyncThunk('report/fetchConvosSummary', async (values) => {
  const cloudResponse = await Parse.Cloud.run("fetchConvosSummary", values);
  return cloudResponse;
})

export const generateGraph = createAsyncThunk('report/generateGraph', async (series) => {
  return series;
})

const entityType = "report";
// Then, handle actions in your reducers:
export const reportSlice = createSlice({
  name: entityType,
  initialState: initialState,
  reducers: {
    setParams: (state, action) => {
      state.queryParams = action.payload; // mutate the state all you want with immer
      // state.reportData = [];
      // state.pageStatus = 'idle';
    },
    setPage: (state, action) => {
      state.curentPage = action.payload;
    },
  },
  extraReducers: {
    // all callback from server for each API call
    // state managemet - pending/fulfilled/rejected callback from with API name [method-name.state] 
    [fetchConvoReports.pending]: (state) => { state.pageStatus = 'loading' },
    [fetchConvoReports.fulfilled]: (state, action) => fetchConvoReportfulfilled(state, action),
    [fetchConvoReports.rejected]: (state, action) => fetchConvoReportRejected(state, action),

    [fetchConvoSummary.pending]: (state) => { state.pageStatus = 'loading' },
    [fetchConvoSummary.fulfilled]: (state, action) => fetchConvoSummaryFulfilled(state, action),
    [fetchConvoSummary.rejected]: (state, action) => fetchConvoSummaryRejected(state, action),

    [generateGraph.fulfilled]: (state, action) => graphDataCallback(state, action),
  }
})


function getTotal(reportType, result) {
  let total = 0;
  switch (reportType) {
    case ReportHelper.ReportType.totalConvoCount: {
      total = result.data[0].noOfConvos;
      break;
    }
    case ReportHelper.ReportType.totalInboundLead: {
      total = result.data[0].noOfInboundLeadMsg;
      break;
    }
    case ReportHelper.ReportType.totalResponseTimeForDashboard: {
      total = result.data[0].totalResponseTime;
      break;
    }
    case ReportHelper.ReportType.avgResponseTimeForDashboard: {
      total = result.data[0].avgResponseTime;
      break;
    }
    case ReportHelper.ReportType.medianResponseTimeForDashboard: {
      total = result.totalMedianResponseTime;
      break;
    }
    case ReportHelper.ReportType.medianResponseTime: {
      total = result.totalMedianResponseTime;
      break;
    }
  }
  return total;
}

const fetchConvoSummaryFulfilled = (state, action) => {
  state.pageStatus = 'succeeded'
  const result = action.payload;
  if (result.groupBy === "") {
    if (result.reportType == ReportHelper.ReportType.medianResponseTimeForDashboard) {
      console.log(result.filterType, " filterType")
      console.log(result, " result")
    }
    const stateName = result.reportType + "_" + result.filterType;
    switch (result.filterType) {
      case ReportHelper.FilterType.monthToDate: {
        state[stateName] = (result.data && result.data.length > 0) ? getTotal(result.reportType, result) : 0;
        state.reportName = result.reportType;
        break;
      }
      case ReportHelper.FilterType.last12Month: {
        state[stateName] = (result.data && result.data.length > 0) ? getTotal(result.reportType, result) : 0;
        state.reportName = result.reportType;
        break;
      }
    }
  }
  else if (result.reportType === ReportHelper.ReportType.totalConvoCount) { //Total conversation for dashboard
    state.totalConvoChartData = ReportHelper.ConvertTotalConvoDataToChartDataForDashboard(result);
  }
  else if (result.reportType === ReportHelper.ReportType.inboundOutbound) { //When click total conversation in dashboard 
    state.inBoundOutboundChartData = ReportHelper.ConvertTotalConvoDataToChartData(result.data, result.groupBy, ReportHelper.ReportTitle.totalConversations, result.filterType);
  }
  else if (result.reportType === ReportHelper.ReportType.avgResponseTimeForDashboard) {
    state.avgResponseTimeChartDataForDashboard = ReportHelper.ConvertAverageTimeReportDataForDashboard(result);
  }
  else if (result.reportType === ReportHelper.ReportType.avgResponseTime) {
    state.avgResponseTimeChartData = ReportHelper.ConvertAverageTimeReportData(result.data, result.groupBy, ReportHelper.ReportTitle.averageResponseTime, result.filterType);
  }
  else if (result.reportType === ReportHelper.ReportType.totalResponseTimeForDashboard) {
    state.totalResponseTimeChartDataForDashboard = ReportHelper.ConvertTotalResponseTimeReportDataForDashboard(result);
  }
  else if (result.reportType === ReportHelper.ReportType.totalResponseTime) {
    state.totalResponseTimeChartData = ReportHelper.ConvertTotalResponseTimeReportData(result.data, result.groupBy, ReportHelper.ReportTitle.totalResponseTime, result.filterType);
  }
  else if (result.reportType === ReportHelper.ReportType.totalInboundLead) { // Inbound lead dash
    state.totalInboundLeadChartData = ReportHelper.ConvertTotalInboundLeadDataToChartDataForDashboard(result);
  }
  else if (result.reportType === ReportHelper.ReportType.inboundLead) {
    state.inboundLeadChartData = ReportHelper.ConvertTotalInboundLeadDataToChartData(result.data, result.groupBy, ReportHelper.ReportTitle.inboundLeads, result.filterType);
  }

  else if (result.reportType === ReportHelper.ReportType.medianResponseTimeForDashboard) {
    state.medianResponseTimeChartDataForDashboard = ReportHelper.ConvertMedianResponseTimeReportDataForDashboard(result.data, result.groupBy);

  } else if (result.reportType === ReportHelper.ReportType.medianResponseTime) {
    state.medianResponseTimeChartData = ReportHelper.ConvertMedianResponseTimeReportData(result.data, result.groupBy);
  }
  console.log('fetchConvoSummary.fulfilled')
}

const fetchConvoSummaryRejected = (state, action) => {
  state.pageStatus = 'failed'
  state.pageError = action.error.message
  console.log(action.error.message);
}

const fetchConvoReportfulfilled = (state, action) => {
  state.pageStatus = 'succeeded'
  state.reportData = action.payload;
  console.log('fetchConvoReports.fulfilled')
  console.log(state.reportData)
}

const fetchConvoReportRejected = (state, action) => {
  state.pageStatus = 'failed'
  state.pageError = action.error.message
  console.log(action.error.message);
}

const graphDataCallback = (state, action) => {
  state.graphStatus = 'succeeded'
  state.graphData = Object.create(action.payload);
}

export default reportSlice.reducer