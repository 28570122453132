import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as Textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";
import * as Queries from "../../../../../_metronic/_assets/js/textopia/TextopiaQueries";
import {KTUtil} from "../../../../../_metronic/_assets/js/components/util";


const initialState = {
  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,

  attributes: [],
  updatedAt: new Date(), //--> this serves as a flag to tell whoever is asking that something has changed in the attributes secion.  react useEffects are listening for this, since they cannot look deeper into parse objects

};


export const fetchAttributes = createAsyncThunk('attributes/fetchAttributes', async () => {
  let cloudResponse = await Parse.Cloud.run("fetchTenantAttributes");
  return cloudResponse;
})

//--> this one used by LQ to give realtime updates
export const updateAttribute = createAsyncThunk('templates/updateAttribute', async (values) => {
  return values;
})

export const saveAttribute = createAsyncThunk('attributes/saveAttribute', async (values) => {
  const cloudResponse = await Parse.Cloud.run("saveAttribute", values);
  return cloudResponse;
})

export const deleteAttribute = createAsyncThunk('attributes/deleteAttribute', async (id) => {
  const cloudResponse = await Parse.Cloud.run("deleteAttribute", {attributeId: id} ); // Sending of Parse objects is not allowed here for some reason, so have to send ID only
  return cloudResponse;  
})

const entityType = "attributes";

export const attributesSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
      [fetchAttributes.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchAttributes.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state[entityType] = action.payload //Query returns an array of objects
        state.updatedAt = new Date();
      },
      [fetchAttributes.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },

      [saveAttribute.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveAttribute.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        // If this is a new attribute, push into the attribute Array
        if (!state[entityType].find(attribute => attribute.id == action.payload.id)) {
          state[entityType].push(action.payload); 
        } 
        state.updatedAt = new Date(); //--> set the flag that something changed
      },
      [saveAttribute.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      //--> this one used by LQ to give realtime updates
      [updateAttribute.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updateAttribute.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        
        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
          // nothing
          state.updatedAt = new Date();
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          //console.log('new attribute');

          //--> check for duplicates before adding
          if (!state[entityType].find(attribute => attribute.id == action.payload.obj.id)) {
            state[entityType].unshift(action.payload.obj); 
          } 
          state.updatedAt = new Date(); //--> set the timestamp flag that something has changed
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {
          //console.log('attribute removed');          
          state.attributes = state.attributes && state.attributes.filter(o => o.id !== action.payload.obj.id);
          state.updatedAt = new Date();
        }


      },
      [updateAttribute.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },


      [deleteAttribute.pending]: (state, action) => {
        state.actionStatus = 'deleting'
      },
      [deleteAttribute.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded';

        // Remove the deleted user from the users list
        state[entityType] = state[entityType].filter(o => o.id !== action.payload.id);
        state.updatedAt = new Date(); //--> set the timestamp flag 
      },
      [deleteAttribute.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      }


    },


    // BELOW NOT USED - KEPT FOR REFERENCE ONLY

    reducers: {

    catchError: (state, action) => {
      state.error = action.payload.error;
      console.log(state.error);
    },
  }
});

export default attributesSlice.reducer
