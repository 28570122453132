import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Parse } from 'parse';
// import moment from 'moment'
import dayjs from "dayjs";

import ReportHelper from "../../../../../_metronic/_assets/js/components/reportHelper"


// initial state which is shared by all component using 

const today = dayjs(new Date());

const initialState = {
  pageStatus: 'idle',
  pageError: null,
  graphStatus: 'idle',
  graphError: null,
  reportData: [],
  paymentSendAndPaidForDashboardChart: {},
  paymentSendAndPaidChart: {},
  paymentResponseTimeForDashboardChart: {},
  paymentResponseTimeChart: {},
  amountProcessedForDashboardChart: {},
  amountProcessedChart: {},
  paymentActivityForDashboardChart: {},

  monthToDate: 0,
  last12Months: 0,

  paymentSendAndPaidForDashboard_monthToDate: 0,
  paymentSendAndPaidForDashboard_last12Month: 0,

  paymentResponseTimeForDashboard_monthToDate: 0,
  paymentResponseTimeForDashboard_last12Month: 0,

  amountProcessedForDashboard_monthToDate: 0,
  amountProcessedForDashboard_last12Month: 0,
  paymentActivityForDashboard_monthToDate: 0,


  queryParams: {
    fromDate: dayjs(today).subtract(7, 'day').format("YYYY-MM-DD"),
    toDate: today.format("YYYY-MM-DD"),
  },
  curentPage: {
    pageName: "dashboard",
    pageData: null,
    reportType: "",
    summeryType: "",
    chartType: "",
    reportTitle: ""
  }
}

// server API calling dispatch
export const fetchPaymentSummary = createAsyncThunk('payments/fetchPaymentSummary', async (values) => {
  const cloudResponse = await Parse.Cloud.run("fetchPaymentSummary", values);
  return cloudResponse;
})

const entityType = "paymentReport";
// Then, handle actions in your reducers:
export const paymentReportsSlice = createSlice({
  name: entityType,
  initialState: initialState,
  reducers: {
    setParams: (state, action) => {
      state.queryParams = action.payload; // mutate the state all you want with immer
      // state.reportData = [];
      // state.pageStatus = 'idle';
    },
    setPage: (state, action) => {
      state.curentPage = action.payload;
    },
  },
  extraReducers: {
    // all callback from server for each API call
    // state managemet - pending/fulfilled/rejected callback from with API name [method-name.state] 
    [fetchPaymentSummary.pending]: (state) => { state.pageStatus = 'loading' },
    [fetchPaymentSummary.fulfilled]: (state, action) => fetchPaymentSummaryFulfilled(state, action),
    [fetchPaymentSummary.rejected]: (state, action) => fetchPaymentSummaryRejected(state, action)

  }
})

function getTotal(reportType, result) {
  let total = 0;
  switch (reportType) {
    case ReportHelper.ReportType.paymentSendAndPaidForDashboard: {
      total = result.data[0].totalPaymentsSend;
      break;
    }
    case ReportHelper.ReportType.paymentResponseTimeForDashboard: {
      total = result.data[0].responseTime;
      break;
    }
    case ReportHelper.ReportType.amountProcessedForDashboard: {
      total = result.data[0].totalAmount;
      break;
    }
    case ReportHelper.ReportType.paymentActivityForDashboard: {
      total = result.data[0].totalPayments;
      break;
    }
  }
  return total;
}

const fetchPaymentSummaryFulfilled = (state, action) => {
  state.pageStatus = 'succeeded'
  const result = action.payload;
  if (result.groupBy === "") {
    const stateName = result.reportType + "_" + result.filterType;
    switch (result.filterType) {
      case ReportHelper.FilterType.monthToDate: {
        state[stateName] = (result.data && result.data.length > 0) ? getTotal(result.reportType, result) : 0;
        state.reportName = result.reportType;
        break;
      }
      case ReportHelper.FilterType.last12Month: {
        state[stateName] = (result.data && result.data.length > 0) ? getTotal(result.reportType, result) : 0;
        state.reportName = result.reportType;
        break;
      }
    }
  }

  else if (result.reportType === ReportHelper.ReportType.paymentSendAndPaidForDashboard) {
    state.paymentSendAndPaidForDashboardChart = ReportHelper.ConvertPaymentSendAndPaidForDashboardChart(result);
  }

  else if (result.reportType === ReportHelper.ReportType.paymentSendAndPaid) {
    state.paymentSendAndPaidChart = ReportHelper.ConvertPaymentSendAndPaidChart(result.data, result.groupBy, ReportHelper.ReportTitle.totalReqSendAndPaid, result.filterType);
  }

  else if (result.reportType === ReportHelper.ReportType.paymentResponseTimeForDashboard) {
    state.paymentResponseTimeForDashboardChart = ReportHelper.ConvertPaymentResponseTimeForDashboardChart(result);
  }

  else if (result.reportType === ReportHelper.ReportType.paymentResponseTime) {
    state.paymentResponseTimeChart = ReportHelper.ConvertPaymentResponseTimeChart(result.data, result.groupBy, ReportHelper.ReportTitle.paymentResponseTime, result.filterType);
  }

  else if (result.reportType === ReportHelper.ReportType.amountProcessedForDashboard) {
    state.amountProcessedForDashboardChart = ReportHelper.ConvertAmountProcessedForDashboardChart(result);
  }
  else if (result.reportType === ReportHelper.ReportType.amountProcessed) {
    state.amountProcessedChart = ReportHelper.ConvertAmountProcessedChart(result.data, result.groupBy, ReportHelper.ReportTitle.amountProcessed, result.filterType);
  }
  else if (result.reportType === ReportHelper.ReportType.paymentActivity) {
    state.paymentActivityData = result;
  }
  else if (result.reportType === ReportHelper.ReportType.paymentActivityForDashboard) {
    state.paymentActivityForDashboardChart = ReportHelper.ConvertPaymentActivityForDashboardChart(result);
  }
  console.log('fetchPaymentSummary.fulfilled')
}

const fetchPaymentSummaryRejected = (state, action) => {
  state.pageStatus = 'failed'
  state.pageError = action.error.message
  console.log(action.error.message);
}

export default paymentReportsSlice.reducer