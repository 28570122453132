import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as Textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";
import * as Queries from "../../../../../_metronic/_assets/js/textopia/TextopiaQueries";
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";
import {KTUtil} from "../../../../../_metronic/_assets/js/components/util";
import * as dayjs from "dayjs";


const initialState = {

  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,
  responses: [],
  updatedAt: new Date(),

};


//--> this is used my LQ and it provides the affected obj
export const updateResponse = createAsyncThunk('responses/updateResponse', async (values) => {
  //console.log('update called by lq');
  return values;
});


export const addRelatedTask = createAsyncThunk('reviews/addRelatedTask', async (values) => {
  // First save the task
  let cloudResponse = await Parse.Cloud.run("saveTask", values);
  let cloudResponse2 = await Parse.Cloud.run("addRelatedTask", {type: "FeedbackAppInstance", values: values, taskId: cloudResponse && cloudResponse.id})
  return cloudResponse2;
});

export const addRelatedNote = createAsyncThunk('reviews/addRelatedNote', async (values) => {
  let cloudResponse = await Parse.Cloud.run("addRelatedNote", {type: "FeedbackAppInstance", values: values});
  return cloudResponse;  
});

export const addReply = createAsyncThunk('reviews/addReply', async (values) => {
  let cloudResponse = await Parse.Cloud.run("addReply", {type: "FeedbackAppInstance", values: values});
  return cloudResponse;
});

export const addUserTags = createAsyncThunk('reviews/addUserTags', async (values) => {
  let cloudResponse = await Parse.Cloud.run("addUserTags", {type: "FeedbackAppInstance", values: values})
  return cloudResponse;
});

export const removeUserTag = createAsyncThunk('reviews/removeUserTag', async (values) => {  
  let cloudResponse = await Parse.Cloud.run("removeUserTag", {type: "FeedbackAppInstance", values: values})
  return cloudResponse;   
});


export const addTopics = createAsyncThunk('reviews/addTopics', async (values) => {
  let cloudResponse = await Parse.Cloud.run("addTopics", {type: "FeedbackAppInstance", values: values});
  return cloudResponse;
});

export const removeTopic = createAsyncThunk('reviews/removeTopic', async (values) => {
  let cloudResponse = await Parse.Cloud.run("removeTopic", {type: "FeedbackAppInstance", values: values});
  return cloudResponse;
});

export const toggleRead = createAsyncThunk('reviews/toggleRead', async (values) => {
  let cloudResponse = await Parse.Cloud.run("toggleRead", {type: "FeedbackAppInstance", id: values.id});
  return cloudResponse;  
})

export const fetchResponses = createAsyncThunk('responses/fetchResponses', async (values) => { 
  let cloudResponse = await Parse.Cloud.run("fetchResponses");
  return cloudResponse;
})

const entityType = "responses";

export const responsesSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
      // All responses for a location
      [fetchResponses.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchResponses.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state[entityType] = action.payload //Query returns an array of objects
        state.updatedAt = new Date();
      },
      [fetchResponses.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },

      // toggle read state
      [toggleRead.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [toggleRead.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'  
        // nothing to do.      
        //state.updatedAt = new Date();

      },
      [toggleRead.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },
           
      // Add related task
      [addRelatedTask.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [addRelatedTask.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'

        state.updatedAt = new Date();
      },
      [addRelatedTask.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      //--> this is used by LQ to provide alerts
      [updateResponse.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updateResponse.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'   

        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
          // nothing
          state.updatedAt = new Date();
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          console.log('new responses');
          //--> a user cannot create a feedback response so we dont need to check for duplicates, this must be coming from the backend
          state.responses && state.responses.unshift(action.payload.obj); //--> TBD - check  if unshift puts it in the right place
          state.updatedAt = new Date();
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {
          console.log('responses removed');
          //--> responses should typically never be deleted, but it is possible that a review might 'leave' this set if transferred to another location or something like that
          state.responses = state.responses && state.responses.filter(o => o.id !== action.payload.obj.id);
          state.updatedAt = new Date();
        }

      },
      [updateResponse.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },


      // Add related note
      [addRelatedNote.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [addRelatedNote.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();

      },
      [addRelatedNote.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Add user tags
      [addUserTags.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [addUserTags.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();

      },
      [addUserTags.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Remove user tag
      [removeUserTag.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [removeUserTag.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();

      },
      [removeUserTag.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Add topic
      [addTopics.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [addTopics.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();

      },
      [addTopics.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Remove topic
      [removeTopic.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [removeTopic.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();

      },
      [removeTopic.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },
      

    },
    reducers: {
  }
});

export default responsesSlice.reducer
