import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";
import * as Queries from "../../../../../_metronic/_assets/js/textopia/TextopiaQueries";
import {KTUtil} from "../../../../../_metronic/_assets/js/components/util";


const initialState = {

  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,

  settings: null,
  updatedAt: new Date(),


};



export const fetchReviewsSettings = createAsyncThunk('reviewsSettings/fetchReviewsSettings', async () => {
  let cloudResponse = await Parse.Cloud.run("fetchReviewsSettings");
  return cloudResponse;
  
});

export const saveReviewsSettingsLook = createAsyncThunk('reviewsSettings/saveReviewsSettingsLook', async (values) => {
  //if (!values.settings) return; //--> relying on the settings object coming from the form
  let settings;
  if (!values.settings) {
    settings = new (Parse.Object.extend("ReviewAppSettings"))();
    settings.set("tenantPtr", Parse.User.current().get("tenantPtr"));
    settings.set("updatedByUserPtr", Parse.User.current());
  }
  else {
    settings = values.settings;
  }

  settings.set("updatedByUserPtr", Parse.User.current());
  let branding = textopia.getBranding(values)
  settings.set("branding", branding);
  settings = textopia.setLogo(settings, values);
 
  let savedSettings = await settings.save();
  return savedSettings;

})

export const saveReviewsSettingsMessaging = createAsyncThunk('reviewsSettings/saveReviewsSettingsMessaging', async (values) => {
  let settingsId = values.settings && values.settings.id;
  values.settings = null;
  let cloudResponse = await Parse.Cloud.run("saveReviewsSettingsMessaging", {settingsId: settingsId, values: values})
  return cloudResponse;
})

export const saveReviewsSettingsImage = createAsyncThunk('reviewsSettings/saveReviewsSettingsImage', async (values) => {

  let settings;
  if (!values.settings) {
    settings = new (Parse.Object.extend("ReviewAppSettings"))();
    settings.set("tenantPtr", Parse.User.current().get("tenantPtr"));
    settings.set("updatedByUserPtr", Parse.User.current());
  }
  else {
    settings = values.settings;
  }

  settings.set("updatedByUserPtr", Parse.User.current());

  settings.set("includeImage", values.includeImage);
  if (values.includeImage && values.includedImage) {    
    if (values.includedImage._url) { //--> using one of our stock images, not uploaded by user, so just set the field
      settings.set("includedImage", values.includedImage);
    }
    else {
      let ext = values.includedImage.name.endsWith("jpg") ? "jpg" : values.includedImage.name.endsWith("png") ? "png" : values.includedImage.name.endsWith("gif") ? "gif" : values.includedImage.name.endsWith("jpeg") ? "jpeg" : "";
      let parseFile = new Parse.File("reviewInvite." + ext, values.includedImage);      
      settings.set("includedImage", parseFile);
    }      
  } else if (!values.includeImage) {
    settings.set("includedImage", null);
  }     

  let savedSettings = await settings.save();
  return savedSettings;

})

export const saveReviewsSettingsDrip = createAsyncThunk('reviewsSettings/saveReviewsSettingsDrip', async (values) => {
  let settingsId = values.settings && values.settings.id;
  values.settings = null;
  let cloudResponse = await Parse.Cloud.run("saveReviewsSettingsDrip", {settingsId: settingsId, values: values})
  return cloudResponse;
})





//--> this one used by LQ and it sends us the affected object as values
export const updateReviewsSettings = createAsyncThunk('reviewsSettings/updateReviewsSettings', async (values) => {
  return values;
})

const entityType = "reviewsSettings";

export const reviewsSettingsSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
      // All questions for a location
      [fetchReviewsSettings.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchReviewsSettings.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state.settings = action.payload //Query returns the settings record for this tenant
        state.updatedAt = new Date();
      },
      [fetchReviewsSettings.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },  

      
      // save custom look
      [saveReviewsSettingsLook.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveReviewsSettingsLook.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.settings = action.payload //Query returns the settings record for this tenant
        state.updatedAt = new Date();
      },
      [saveReviewsSettingsLook.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      }, 

      // save custom messaging
      [saveReviewsSettingsMessaging.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveReviewsSettingsMessaging.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.settings = action.payload //Query returns the settings record for this tenant
        state.updatedAt = new Date();
      },
      [saveReviewsSettingsMessaging.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      },  

      // save included image
      [saveReviewsSettingsImage.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveReviewsSettingsImage.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.settings = action.payload //Query returns the settings record for this tenant
        state.updatedAt = new Date();
      },
      [saveReviewsSettingsImage.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      },   

      // save drip campaign
      [saveReviewsSettingsDrip.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveReviewsSettingsDrip.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.settings = action.payload //Query returns the settings record for this tenant
        state.updatedAt = new Date();
      },
      [saveReviewsSettingsDrip.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      },           


      // Update settings //--> this one used by LQ
      [updateReviewsSettings.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updateReviewsSettings.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'

        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
          // nothing
          state.updatedAt = new Date();
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          state.settings = action.payload.obj; // should not really happen since a record should always exist for every tenant
          state.updatedAt = new Date();
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {   
          // nothing, should never happen
          state.updatedAt = new Date();
        }

      },
      [updateReviewsSettings.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },


    },


   

    reducers: {

    
  }
});

export default reviewsSettingsSlice.reducer
