import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import { usersSlice } from "../app/modules/BusinessSettings/pages/users/usersSlice";
import { teamsSlice } from "../app/modules/BusinessSettings/pages/teams/teamsSlice";
import { hoursSlice } from "../app/modules/BusinessSettings/pages/hours/hoursSlice";
import { generalSlice } from "../app/modules/BusinessSettings/pages/general/generalSlice";
import { templatesSlice } from "../app/modules/Templates/pages/templates/templatesSlice";
import { attributesSlice } from "../app/modules/Templates/pages/attributes/attributesSlice";
import { tasksSlice } from "../app/modules/Tasks/pages/tasks/tasksSlice";
import { questionsSlice } from "../app/modules/Feedback/pages/questions/questionsSlice";
import { feedbackSettingsSlice } from "../app/modules/Feedback/pages/settings/feedbackSettingsSlice";
import { responsesSlice } from "../app/modules/Feedback/pages/responses/responsesSlice";
import { reviewsSlice } from "../app/modules/Reviews/pages/reviews/reviewsSlice";
import { sitesSlice } from "../app/modules/Reviews/pages/sites/sitesSlice";
import { reviewsSettingsSlice } from "../app/modules/Reviews/pages/settings/reviewsSettingsSlice";
import { topicsSlice } from "../app/modules/Topics/pages/topics/topicsSlice";
import { locationSlice } from "../_metronic/layout/components/header/locationSlice";
import { competitorsSlice } from "../app/modules/Reviews/pages/competitors/competitorsSlice";
import { convosSlice } from "../app/modules/Convos/pages/convos/convosSlice";
import { reportSlice } from "../app/modules/Convos/pages/reports/reportsSlice";
import { feedBackReportsSlice } from "../app/modules/Feedback/pages/reports/feedBackReportsSlice";
import { reviewReportsSlice } from "../app/modules/Reviews/pages/reports/reviewReportsSlice";
import { taskReportsSlice } from "../app/modules/Tasks/pages/reports/taskReportsSlice";
import { paymentReportsSlice } from "../app/modules/Payments/pages/reports/paymentReportsSlice";

import { userSlice } from "../_metronic/layout/components/header/userSlice";
import { channelsSlice } from "../app/modules/Channels/pages/channels/channelsSlice";
import { paymentsSlice } from "../app/modules/Payments/pages/payments/paymentsSlice";
import { paymentsSettingsSlice } from "../app/modules/Payments/pages/settings/paymentsSettingsSlice";
import { tenantsSlice } from "../app/modules/TextopiaAdmin/pages/tenants/tenantsSlice";
import { leaderboardSlice } from "../app/modules/Leaderboard/pages/leaderboard/leaderboardSlice";

export const appReducer = combineReducers({
  users: usersSlice.reducer,
  teams: teamsSlice.reducer,
  hours: hoursSlice.reducer,
  general: generalSlice.reducer,
  templates: templatesSlice.reducer,
  attributes: attributesSlice.reducer,
  tasks: tasksSlice.reducer,
  questions: questionsSlice.reducer,
  feedbackSettings: feedbackSettingsSlice.reducer,
  responses: responsesSlice.reducer,
  reviews: reviewsSlice.reducer,
  sites: sitesSlice.reducer,
  reviewsSettings: reviewsSettingsSlice.reducer,
  topics: topicsSlice.reducer,
  location: locationSlice.reducer,
  competitors: competitorsSlice.reducer,
  convos: convosSlice.reducer,
  user: userSlice.reducer,
  channels: channelsSlice.reducer,
  payments: paymentsSlice.reducer,
  paymentsSettings: paymentsSettingsSlice.reducer,
  tenants: tenantsSlice.reducer,
  report: reportSlice.reducer,
  feedbackReport: feedBackReportsSlice.reducer,
  reviewReport: reviewReportsSlice.reducer,
  taskReport: taskReportsSlice.reducer,
  paymentReport: paymentReportsSlice.reducer,
  leaderboard: leaderboardSlice.reducer,
});

export const rootReducer = (state, action) => {

  if (action.type === "LOGOUT") { //--> this here is used to null out the complete state upon user logout called in QuickUser logout method
    state = undefined;
  }

  return appReducer(state, action);
}
