import React , { useState, useEffect } from "react";
import Parse from "parse";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";
import { QuickUserToggler } from "../extras/QuickUserToggler";
import { TextopiaButton, TextopiaLink, TextopiaPullout } from "../../../_assets/js/textopia/TextopiaControls";
import * as Textopia from "../../../_assets/js/textopia/textopia_utils";
import { KTUtil } from "../../../_assets/js/components/util.js";
import { TextopiaChangeLocation } from "./TextopiaChangeLocation";
import { QuickOptions } from "./QuickOptions";
import { Beacon } from "../Beacon";

export function Topbar() {

  const { orderedConvos } = useSelector(
    (state) =>  ({
      orderedConvos: state.convos.orderedConvos 
    }),
    //shallowEqual //--> an important discovery that shallowEqual prevents us from looking deeper into objects like Parse objects or even lists of items
  ) 

    let unreadCount;
    let allUnreadCount = orderedConvos && Textopia.getAllUnreadCount(orderedConvos);
    allUnreadCount = allUnreadCount ? allUnreadCount : 0;
    let mineUnreadCount = orderedConvos && Textopia.getMineUnreadCount(orderedConvos);
    let myTeamUnreadCount = orderedConvos && Textopia.getTeamUnreadCount(orderedConvos);
    if (["SUPER_USER", "OWNER", "LOCATION_MANAGER"].includes(Parse.User.current().get("userRolePtr").get("name_id"))) {
        unreadCount = allUnreadCount;          
    }
    else {
        unreadCount = (mineUnreadCount ? mineUnreadCount : 0) + (myTeamUnreadCount ? myTeamUnreadCount : 0);
    }

  const { tasks } = useSelector(
    (state) =>  ({
      tasks: state.tasks.tasks  
    }),
    //shallowEqual //--> an important discovery that shallowEqual prevents us from looking deeper into objects like Parse objects or even lists of items
  ) 

  let myTasks, myTeamTasks, totalTasks;
  myTasks = tasks && tasks.filter(task => task.get("assignedToUserPtr") && task.get("assignedToUserPtr").id === Parse.User.current().id && !task.get("isMarkedDone")); //-- only open tasks
  myTeamTasks = tasks && Parse.User.current().get("teamPtr") && tasks.filter(task => task.get("assignedToTeamPtr") && task.get("assignedToTeamPtr").id === Parse.User.current().get("teamPtr").id && !task.get("isMarkedDone")); //--> only open tasks
  totalTasks = (myTasks ? myTasks.length : 0) + (myTeamTasks ? myTeamTasks.length : 0); //--> the badge will turn on on the tasks button in topbar if there are OPEN tasks assigned to me or my team, (meaning, all tasks are not considered, only mine or my team's)
  

  const [showQuickOptionsPullout, setShowQuickOptionsPullout] = useState(false);
  const [quickOptionsPullout, setQuickOptionsPullout] = useState(''); //--> this allows the pullout to hold a reference to the underlying pullout object to call methods on it - sort of a ref
  
  const showBeacon = () => {
    if (!KTUtil.isBreakpointUp('lg')) {
        window.Beacon('init', 'fdebe83a-808a-4fd1-a37e-99de494e4afe')       
        window.Beacon('on', 'close', () => window.Beacon('destroy')); //--> when the close button is clicked, remove the beacon also
    }

    window.Beacon('toggle'); 
  }


  return (
    <>
    <div className="topbar">

      <TextopiaChangeLocation componentEl="topbar_change_location" className="d-none d-lg-block topbar-item mr-3 "/>

      <div className="topbar-item mr-3">
        <TextopiaButton text="Help" icon="flaticon2-help" iconColor="text-success"  addClasses="btn-dropdown" onClick={() => showBeacon()} />
      </div>


      <div className="topbar-item mr-3">
        <TextopiaLink text="New" icon="flaticon2-writing" iconColor="text-danger" linkTo={{pathname: "/convos/convos", obj: {mode: "new"}}}/>
      </div>

      <div className="topbar-item mr-3">
        <TextopiaLink text="Convos" icon="flaticon2-chat-1" iconColor="text-warning" badge={unreadCount > 0 ? true : false} pulse={unreadCount > 0 ? true : false} linkTo={{pathname: "/convos/convos"}}/>
      </div>      
      <div className="topbar-item mr-3">
        <TextopiaLink text="Tasks" icon="flaticon2-notepad" iconColor="text-primary-d-3" badge={totalTasks > 0 ? true : false} pulse={totalTasks > 0 ? true : false} linkTo={{pathname:"/tasks/tasks"}}/>
      </div>
      <div className="topbar-item mr-3">
        <TextopiaButton text="Quick" icon="flaticon2-cube-1" iconColor="text-success"  addClasses="btn-dropdown" onClick={() => setShowQuickOptionsPullout(true)} />
      </div>      
     

      <QuickUserToggler />

    </div>

    <TextopiaPullout show={showQuickOptionsPullout} //--> have to embed this here due to zIndex reasons, this was hiding behind some headers if placed in the SMS panel // in a way it is good, since now it can be shared across panels
                    setPullout={setQuickOptionsPullout}
                    componentEl="qo_form_pullout"
                    scrollEl="qo_form"
                    title="Quick Actions"
                    titleIcon="flaticon2-cube-1 icon-lg"                      
                    onHide={() => setShowQuickOptionsPullout(false)} 
                    >
         <div id="qo_form"  className="mr-n5 pr-5"> {/* this div holds the key to embedding the form as a conditional and therefore preventing unused re-renders */}
            { showQuickOptionsPullout && (  
                <QuickOptions pullout={quickOptionsPullout} /> 
            )} 
        </div>
    </TextopiaPullout>

      
  </>
  )
}




