/* eslint-disable */
"use strict";

import { KTUtil } from "./../../components/util.js";
import KTOffcanvas from "./../../components/offcanvas.js";

var KTLayoutQuickPanel = function() {
    // Private properties
    var _element;
    var _offcanvasObject;

    // Private functions
    var _getContentHeight = function() {
        var height;

        var header = KTUtil.find(_element, '.offcanvas-header');
        var content = KTUtil.find(_element, '.offcanvas-content');

        var height = parseInt(KTUtil.getViewPort().height);

        if (header) {
            height = height - parseInt(KTUtil.actualHeight(header));
            height = height - parseInt(KTUtil.css(header, 'marginTop'));
            height = height - parseInt(KTUtil.css(header, 'marginBottom'));
        }

        if (content) {
            height = height - parseInt(KTUtil.css(content, 'marginTop'));
            height = height - parseInt(KTUtil.css(content, 'marginBottom'));
        }

        height = height - parseInt(KTUtil.css(_element, 'paddingTop'));
        height = height - parseInt(KTUtil.css(_element, 'paddingBottom'));

        height = height - 2;

        return height;
    }

    var _init = function() {
        _offcanvasObject = new KTOffcanvas(_element, {
            overlay: true,
            baseClass: 'offcanvas',
            placement: 'right',
            closeBy: 'kt_quick_panel_close',
            toggleBy: ['kt_quick_panel_toggle', 'kt_quick_panel_toggle2', 'c_location_toggle', 'c_location_toggle_2']
        });

        var _locationsList = KTUtil.getById("locations_list");

        KTUtil.scrollInit(_locationsList, {
            mobileNativeScroll: true,
            resetHeightOnDestroy: true,
            handleWindowResize: true,
            height: function() {
                return _getContentHeight();
            }
        });

    }

    var _updateScrollbars = function() {
      KTUtil.scrollUpdate(_element);
    }

    // Public methods
    return {
        init: function(id) {
            _element = KTUtil.getById(id);

            _init();

          //  _updateScrollbars();
        }
    };
}();

// Webpack support
if (typeof module !== 'undefined') {
	//module.exports = KTLayoutQuickPanel;
}


export default KTLayoutQuickPanel;
