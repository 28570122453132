import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as Textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";

const initialState = {

  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,
  cLoadStatus: 'idle',
  ocStatus: 'idle',
  allLoaded: false,

  convoPageStatus: 'idle',
  convoPageError: null,
  convoActionStatus: 'idle',
  convoActionError: null,

  convos: [],
  convoListUpdatedAt: new Date(),
  convoEntries: [],
  messages: [],
  tasks: [],
  customerNotes: [],
  systemNotes: [],
  reviews: [],
  feedback: [],
  payments: [],

  convoSelected: null,
  inboxSelected: null,
  openClosed: '',

  shopifyStatus: 'idle',
  shopifyError: null,
  shopifyOrders: null,
  shopifyUpdatedAt: new Date(),

  scrollPosition: '',

};

export const saveScrollPosition = createAsyncThunk('convos/saveScrollPosition', async (values) => {
  return values;
})

export const mergeConvos = createAsyncThunk('convos/mergeConvos', async (values) => {
  
  console.log(values);
  values.pullout && values.pullout.hide();
  values.pullout = null;
  const cloudResponse = await Parse.Cloud.run("mergeConvos", {values: values});
  return cloudResponse;  
})

export const undoConvoMerger = createAsyncThunk('convos/undoConvoMerger', async (values) => {
  console.log(values);
  const cloudResponse = await Parse.Cloud.run("undoConvoMerger", {values: values});
  return cloudResponse;  
})


export const saveNote = createAsyncThunk('convos/saveNote', async (values) => {
  const cloudResponse = await Parse.Cloud.run("convoSaveNote", {values: values});
  return cloudResponse;  
})

export const saveTopics = createAsyncThunk('reviews/saveTopics', async (values) => {
  let cloudResponse = await Parse.Cloud.run("addTopics", {type: "Message", values: values});
  return cloudResponse;
});

export const saveTask = createAsyncThunk('convos/saveTask', async (values) => {
  // First save the task
  let cloudResponse = await Parse.Cloud.run("saveTask", values);
  await Parse.Cloud.run("setLastConvoEntryPtr", {type: "Task", objId: cloudResponse && cloudResponse.id, customerId: values.convoSelected});
  return cloudResponse;
})

export const saveCustomerAttribute = createAsyncThunk('convos/saveCustomerAttribute', async (values) => {
  const cloudResponse = await Parse.Cloud.run("saveCustomerAttribute", {values: values});
  return cloudResponse;  
})

export const markConvoRead = createAsyncThunk('convos/markConvoRead', async (values) => {
  const cloudResponse = await Parse.Cloud.run("markConvoRead", {convoId: values});
  return cloudResponse;
})

export const toggleFave = createAsyncThunk('convos/toggleFave', async (values) => {
 
  let templates = Parse.User.current().get("favoriteTemplates") || []; 
  
  if (templates && templates.indexOf(values.id) >= 0) 
    templates = templates.filter(item => item !== values.id) //--> if the id is currently found, then remoev it
  else 
    templates.push(values.id); //--> otherwise add the id to the list of faves - so between this and above, we toggle a template from fave or not
    
  Parse.User.current().set("favoriteTemplates", templates);
  
  values.setFaves(Parse.User.current().get("favoriteTemplates"));

  await Parse.User.current().save();  
  return;
})

//--> this one used by LQ
export const updateConvoEntry = createAsyncThunk('convos/updateConvoEntry', async (values) => {
  //console.log('update convo entry called ...');

  return values;
})

export const toggleRead = createAsyncThunk('convos/toggleRead', async (values) => {
  const cloudResponse = await Parse.Cloud.run("convoToggleRead", {convoId: values.id});
  return cloudResponse;
})

//--> these 3 dispatches control the state of the convos screen - V. IMPORTANT
export const changeConvoSelected = createAsyncThunk('convos/changeConvoSelected', async (values) => {
  return values;
})
export const changeInboxSelected = createAsyncThunk('convos/changeInboxSelected', async (values) => {
  return values;
})
export const changeOpenClosed = createAsyncThunk('convos/changeOpenClosed', async (values) => {
  return values;
})



export const fetchOneConvo = createAsyncThunk('convos/fetchOneConvo', async (convoSelected) => {
  if (!convoSelected) return {}; //--> this is an important statement here, do not delete!
  const cloudResponse = await Parse.Cloud.run("fetchOneConvo", {convoId: convoSelected && convoSelected.id});
  return cloudResponse;
})

export const fetchShopifyOrders = createAsyncThunk('convos/fetchShopifyOrders', async (values) => {
  const cloudResponse = await Parse.Cloud.run("fetchShopifyOrders", {customerId: values.id}); //--> this is the id of the convoSelected (cannot send the object)  
  return cloudResponse;
})


export const cancelShopifyOrder = createAsyncThunk('convos/cancelShopifyOrder', async (values) => {
  await Parse.Cloud.run("cancelShopifyOrder", values);   
  const cloudResponse2 = await Parse.Cloud.run("fetchShopifyOrders", {customerId: values.customerId}); //--> fetch all the orders again with the updates
  return cloudResponse2;
})

export const refundShopifyOrder = createAsyncThunk('convos/refundShopifyOrder', async (values) => {
  await Parse.Cloud.run("refundShopifyOrder", values);   
  const cloudResponse2 = await Parse.Cloud.run("fetchShopifyOrders", {customerId: values.customerId}); //--> fetch all the orders again with the updates
  return cloudResponse2;
})



export const fetchConvos = createAsyncThunk('convos/fetchConvos', async (values) => {
  const cloudResponse = await Parse.Cloud.run("fetchConvos", {values: values, initLoad: Constants.INIT_CONVO_LOAD});
  return cloudResponse;
})

//--> this one used by LQ
export const updateConvo = createAsyncThunk('convos/updateConvo', async (values) => {
  return values;
})

export const transferConvo = createAsyncThunk('convos/transferConvo', async (values) => {  
  const cloudResponse = await Parse.Cloud.run("transferConvo", {convoId: values.convoSelected && values.convoSelected.id, locationId: values.locationPtr && values.locationPtr.id, transferNote: values.transferNote});

  // Show the confirmation modal
  if (values.showConf) {
    values.showConf({show: true, value: cloudResponse && cloudResponse.get("locationPtr").get("shortName")});   
  }

  return cloudResponse;    
})

export const assignConvo = createAsyncThunk('convos/assignConvo', async (values) => {  

  const cloudResponse = await Parse.Cloud.run("assignConvo", {  convoId: values && values.convoSelected.id, 
                                                                assignedTo: values && values.assignedTo && values.assignedTo.className, 
                                                                assignedToId: values && values.assignedTo && values.assignedTo.id, 
                                                                transferNote: values && values.transferNote
                                                             })
  
  // Now zero our the convoSelected before making any further changes
  if (values.setConvoSelected) {
     values.setConvoSelected(false);
  }
  
  // Show the confirmation modal
  if (values.showConf) values.showConf({show: true, value: values.assignedTo.className === "Team" ? cloudResponse.get("assignedToTeamPtr").get("title") : Textopia.getUserName(cloudResponse.get("assignedToUserPtr"))});   

  return cloudResponse;

})


export const toggleConvo = createAsyncThunk('convos/toggleConvo', async (values) => {  
  const cloudResponse = await Parse.Cloud.run("toggleConvo", {convoId: values.id});
  return cloudResponse;
})

export const addContact = createAsyncThunk('convos/addContact', async (values) => {  

  const cloudResponse = await Parse.Cloud.run("addContact", {values: values})

  if(values.setConvoSelected) values.dispatch(values.setConvoSelected(cloudResponse)); // set the new convo as convoSelected
  if(values.resetAddContact) values.resetAddContact(); // clear the new contact state to go back to normal browsing
  return cloudResponse;  

})


const entityType = "convos";

export const convosSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
      // All convos for a location
      [fetchConvos.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchConvos.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state.convos = action.payload;  // all the convos for this location are loaded  
        state.orderedConvos = Textopia.orderConvos(action.payload);
      },
      [fetchConvos.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
        console.log(action.error.message);
      },

      // Update convo - this is related to updates via LQ
      [updateConvo.pending]: (state, action) => {
        state.actionStatus = 'loading'
      },
      [updateConvo.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'

        

        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {  
          //state.orderedConvos = Textopia.orderConvos(state.convos); //--> we are holding ordered convos in redux, so have to update the ordered set on every update to convos
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          //--> a user can in fact create a convo by creating a new message to a contact, so interface will prompt for a new convo and LQ will also get a new convo alert - need to check for dupes          
          if(state[entityType] && !state[entityType].find(convo => convo.id === action.payload.obj.id)) {
            state[entityType].unshift(action.payload.obj); // Add the new one to the list of convos
          }              
          state.orderedConvos = Textopia.orderConvos(state.convos); //--> we are holding ordered convos in redux, so have to update the ordered set on every update to convos
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {
          //--> deletion of convo only happens when transferred to another location
          state[entityType] = state[entityType] && state[entityType].filter(o => o.id !== action.payload.obj.id);
          state.orderedConvos = Textopia.orderConvos(state.convos); //--> we are holding ordered convos in redux, so have to update the ordered set on every update to convos (unlikelye since convos arent deleted)
        }
        
      },
      [updateConvo.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message);
      },

      // Update convo entry - this is related to updates via LQ
      [updateConvoEntry.pending]: (state, action) => {
        state.actionStatus = 'loading'
      },
      [updateConvoEntry.fulfilled]: (state, action) => {
        

        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
           //console.log("LQ update " + action.payload.obj);

          if (action.payload && action.payload.obj.className === "Message") {
            
            let index = state.messages && state.messages.findIndex(obj => obj.id === action.payload.obj.id); 
            if (index !== -1 && state.messages) { state.messages[index] = action.payload.obj; }
            
          }

          if (action.payload && action.payload.obj.className === "PaymentAppInstance") {
            
            let index = state.payments && state.payments.findIndex(obj => obj.id === action.payload.obj.id); 
            if (index !== -1 && state.payments) { state.payments[index] = action.payload.obj; }
            
          }

          if (action.payload && action.payload.obj.className === "CustomerAttribute") { //--> of all the things in a convo, CustomerAttributes can be updated, so handle that explicitly
            let index = state.attributes && state.attributes.findIndex(attr => attr.id === action.payload.obj.id); 
            if (index !== -1 && state.attributes) { state.attributes[index] = action.payload.obj; }
          }
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
  
          if (action.payload && action.payload.obj.className === "Message") {
            state.messages && state.messages.unshift(action.payload.obj);
            if (action.payload.mode === Constants.LQ_ENTER) {
                state.messages = Textopia.sortNewestFirst(state.messages);
            }
          }
          else if (action.payload && action.payload.obj.className === "CustomerAttribute") {
            state.attributes && state.attributes.unshift(action.payload.obj);
          }
          else if (action.payload && action.payload.obj.className === "CustomerNote") {
            state.customerNotes && state.customerNotes.unshift(action.payload.obj);
            if (action.payload.mode === Constants.LQ_ENTER) {
                state.customerNotes = Textopia.sortNewestFirst(state.customerNotes);
            }
          }
          else if (action.payload && action.payload.obj.className === "Task") {
            state.tasks && state.tasks.unshift(action.payload.obj);
          }
          else if (action.payload && action.payload.obj.className === "SystemNote") {
            state.systemNotes && state.systemNotes.unshift(action.payload.obj);
          }
          else if (action.payload && action.payload.obj.className === "PaymentAppInstance") {
            state.payments && state.payments.unshift(action.payload.obj);
          }
          else if (action.payload && action.payload.obj.className === "ReviewAppInstance") {
            state.reviews && state.reviews.unshift(action.payload.obj);
            state.ratings && state.ratings.unshift(action.payload.obj);
          }
          else if (action.payload && action.payload.obj.className === "FeedbackAppInstance") {
            state.feedbacks && state.feedbacks.unshift(action.payload.obj);
            state.ratings && state.ratings.unshift(action.payload.obj);
          }
          if (action.payload) {
            if (action.payload.obj.className !== "CustomerAttribute") //--> Customer Attributes are not added to convoEntries, they are only handled in the side panel (customer details)
              state.convoEntries && state.convoEntries.unshift(action.payload.obj); // add to the list of current convo entries
          }

          //--> on a convo merge, LQ will send each convoEntry of the merged in convo to the convo we are currently vieweing (if still viewing) - ALL will be LQ_ENTER since the entries already exist in the other convo
          //--> we have to sort on addition of each entry to place it correctly on the convo timeline
          //--> in general also, if any entry LQ_ENTERs, we need to add and then sort the list to ensure timeline correctness
          if (action.payload && action.payload.mode === Constants.LQ_ENTER) {
              state.convoEntries = Textopia.sortNewestFirst(state.convoEntries);
          }

         
         
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {
          // convo entries cannot generally be deleted, so this should never be called
          // customer attributes can be deleted, but only by going into the db, in the UI, their values can be zeroes out, but that will be an UPDATE call, not a delete 

          //--> on an unmerge, items from the leaving convo will leave the convo we are currently viewing, if still viewing
          //--> all of them will be a LQ_LEAVE, we just to find and remove 

          if (action.payload && action.payload.mode === Constants.LQ_LEAVE) {
              state.convoEntries = state.convoEntries.filter(item => item.id !== action.payload.obj.id);
          }

        }

        state.actionStatus = 'succeeded'; 
        
        
      },
      [updateConvoEntry.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message);
      },



      // transfer convo
      [transferConvo.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [transferConvo.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded' 

        // Remove the transferred convo out of this location's convo list   
        // this is a convo deletion scenario, and LQ will also report this, but 2 deletions of the same id have to impact, so no check needed     
        state[entityType] = state[entityType].filter(o => o.id !== action.payload.id); 
      },
      [transferConvo.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // assign convo
      [assignConvo.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [assignConvo.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'        

      },
      [assignConvo.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // toggle read state
      [toggleRead.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [toggleRead.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'  
        // nothing to do.      

      },
      [toggleRead.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },


      // Fetch one convo
      [fetchOneConvo.pending]: (state, action) => {
        state.convoPageStatus = 'loading'
      },
      [fetchOneConvo.fulfilled]: (state, action) => {
        state.convoPageStatus = 'succeeded'
        
        //state.convoSelected = action.payload.convoSelected;
        state.convoEntries = action.payload.convoEntries;

        state.messages = action.payload.messages;
        state.attributes = action.payload.attributes;
        state.tasks = action.payload.tasks;
        state.customerNotes = action.payload.customerNotes;
        state.systemNotes = action.payload.systemNotes;
        state.payments = action.payload.payments;
        state.reviews = action.payload.reviews;
        state.feedbacks = action.payload.feedbacks;
        state.ratings = action.payload.ratings;
        //console.log(action.payload);
      },
      [fetchOneConvo.rejected]: (state, action) => {
        state.convoPageStatus = 'failed'
        state.convoPageError = action.error.message
        console.log(action.error.message);
      },

      // Mark convo read
      [markConvoRead.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [markConvoRead.fulfilled]: (state, action) => {
        //state.convoListUpdatedAt = new Date();
        state.actionStatus = 'succeeded';        
        // Nothing to do here        
      },
      [markConvoRead.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Toggle convo between open and closed state
      [toggleConvo.pending]: (state, action) => {
        state.ocStatus = 'saving'
      },
      [toggleConvo.fulfilled]: (state, action) => {
        state.ocStatus = 'succeeded';  
        // remember that everytime a convo is opened or closed it will vanish from the current view, so no point in adding any convoEntries 
        state.convoSelected = ""; //--> remove reassigned convo from current view                 
      },
      [toggleConvo.rejected]: (state, action) => {
        state.ocStatus = 'failed'
        state.actionError = action.error.message
      },

      // Toggle fave (nothing to do here, just managing state)
      [toggleFave.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [toggleFave.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded';                          
      },
      [toggleFave.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Save customer attribute
      [saveCustomerAttribute.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveCustomerAttribute.fulfilled]: (state, action) => {        
        state.actionStatus = 'succeeded';                         
      },
      [saveCustomerAttribute.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Save customer note
      [saveNote.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveNote.fulfilled]: (state, action) => {        
        state.actionStatus = 'succeeded';   
        //--> LQ takes care of alerting all components about a new note, so we dont need to add anythign else, othewise we'll see duplicates
      },
      [saveNote.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Save task
      [saveTask.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveTask.fulfilled]: (state, action) => {        
        state.actionStatus = 'succeeded';   
        //--> LQ takes care of alerting all components about a new task, so we dont need to add anythign else, othewise we'll see duplicates
      },
      [saveTask.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Save topics
      [saveTopics.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveTopics.fulfilled]: (state, action) => {        
        state.actionStatus = 'succeeded';   
        //--> we'll let LQ take care of updates
      },
      [saveTopics.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },


      // Add contact
      [addContact.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [addContact.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded';   
        //--> check for dupes becaue LQ will also report a new addition, but we have left this here for snappy UI
        if(state[entityType] && !state[entityType].find(convo => convo.id === action.payload.id)) {
          state[entityType].unshift(action.payload); // Add the new one to the list of convos
        } 
      },
      [addContact.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Merge Convos
      [mergeConvos.pending]: (state, action) => {
        state.convoPageStatus = 'saving'
      },
      [mergeConvos.fulfilled]: (state, action) => {
        state.convoPageStatus = 'succeeded';   
        
      },
      [mergeConvos.rejected]: (state, action) => {
        state.convoPageStatus = 'failed'
        state.convoPageError = action.error.message
      },

      // Undo merge Convos
      [undoConvoMerger.pending]: (state, action) => {
        state.convoPageStatus = 'saving'
      },
      [undoConvoMerger.fulfilled]: (state, action) => {
        state.convoPageStatus = 'succeeded';   
        
      },
      [undoConvoMerger.rejected]: (state, action) => {
        state.convoPageStatus = 'failed'
        state.convoPageError = action.error.message
      },

      //--> to control the state of the convos screen
      [changeConvoSelected.fulfilled]: (state, action) => {
        state.convoSelected = action.payload;
      },
      [changeInboxSelected.fulfilled]: (state, action) => {
        state.inboxSelected = action.payload;
      },
      [changeOpenClosed.fulfilled]: (state, action) => {
        state.openClosed = action.payload;
      },

      // Shopify order data
      [fetchShopifyOrders.pending]: (state, action) => {
        state.shopifyStatus = 'saving'
      },
      [fetchShopifyOrders.fulfilled]: (state, action) => {          
        state.shopifyOrders = action.payload;
        state.shopifyStatus = 'succeeded'; 
      },
      [fetchShopifyOrders.rejected]: (state, action) => {
        state.shopifyStatus = 'failed'
        state.shopifyError = action.error.message
      },

      // Cancel shopify order
      [cancelShopifyOrder.pending]: (state, action) => {
        state.shopifyStatus = 'saving'
      },
      [cancelShopifyOrder.fulfilled]: (state, action) => {
        state.shopifyOrders = action.payload;
        state.shopifyUpdatedAt = new Date();        
        state.shopifyStatus = 'succeeded';           
      },
      [cancelShopifyOrder.rejected]: (state, action) => {
        state.shopifyStatus = 'failed'
        state.shopifyError = action.error.message
      },

      // Refund shopify order
      [refundShopifyOrder.pending]: (state, action) => {
        state.shopifyStatus = 'saving'
      },
      [refundShopifyOrder.fulfilled]: (state, action) => {
        state.shopifyOrders = action.payload;
        state.shopifyUpdatedAt = new Date();        
        state.shopifyStatus = 'succeeded';           
      },
      [refundShopifyOrder.rejected]: (state, action) => {
        state.shopifyStatus = 'failed'
        state.shopifyError = action.error.message
      },

      // convo list scroll position save
      [saveScrollPosition.pending]: (state, action) => {
        
      },
      [saveScrollPosition.fulfilled]: (state, action) => {
        state.scrollPosition = action.payload;
      },
      [saveScrollPosition.rejected]: (state, action) => {
        
      },

     

    },

    reducers: {      

      
  }
});

export default convosSlice.reducer
