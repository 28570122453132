import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";
import * as Queries from "../../../../../_metronic/_assets/js/textopia/TextopiaQueries";
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";
import {KTUtil} from "../../../../../_metronic/_assets/js/components/util";


const initialState = {

  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,

  payments: [],
  updatedAt: new Date(),

};



export const fetchPayments = createAsyncThunk('payments/fetchPayments', async () => {
  let cloudResponse = await Parse.Cloud.run("fetchPayments");
  return cloudResponse;
})

//--> this one used by LQ
export const updatePayment = createAsyncThunk('payments/updatePayment', async (values) => {

  return values;

})

export const addRelatedTask = createAsyncThunk('reviews/addRelatedTask', async (values) => {
  // First save the task
  let cloudResponse = await Parse.Cloud.run("saveTask", values);
  let cloudResponse2 = await Parse.Cloud.run("addRelatedTask", {type: "PaymentAppInstance", values: values, taskId: cloudResponse && cloudResponse.id})
  return cloudResponse2;
});

export const addRelatedNote = createAsyncThunk('reviews/addRelatedNote', async (values) => {
  let cloudResponse = await Parse.Cloud.run("addRelatedNote", {type: "PaymentAppInstance", values: values});
  return cloudResponse;  
});

export const addReply = createAsyncThunk('reviews/addReply', async (values) => {
  let cloudResponse = await Parse.Cloud.run("addReply", {type: "PaymentAppInstance", values: values});
  return cloudResponse;
});

export const addUserTags = createAsyncThunk('reviews/addUserTags', async (values) => {
  let cloudResponse = await Parse.Cloud.run("addUserTags", {type: "PaymentAppInstance", values: values})
  return cloudResponse;
});

export const removeUserTag = createAsyncThunk('reviews/removeUserTag', async (values) => {  
  let cloudResponse = await Parse.Cloud.run("removeUserTag", {type: "PaymentAppInstance", values: values})
  return cloudResponse;   
});


export const addTopics = createAsyncThunk('reviews/addTopics', async (values) => {
  let cloudResponse = await Parse.Cloud.run("addTopics", {type: "PaymentAppInstance", values: values});
  return cloudResponse;
});

export const removeTopic = createAsyncThunk('reviews/removeTopic', async (values) => {
  let cloudResponse = await Parse.Cloud.run("removeTopic", {type: "PaymentAppInstance", values: values});
  return cloudResponse;
});

export const toggleRead = createAsyncThunk('reviews/toggleRead', async (values) => {
  let cloudResponse = await Parse.Cloud.run("toggleRead", {type: "PaymentAppInstance", id: values.id});
  return cloudResponse;  
})

export const cancelPayment = createAsyncThunk('payments/cancelPayment', async (values) => {    
  const cloudResponse = await Parse.Cloud.run("cancelPayment", values);  
  return cloudResponse;
});

export const markPaymentPaidOutside = createAsyncThunk('payments/markPaymentPaidOutside', async (values) => {
  const cloudResponse = await Parse.Cloud.run("markPaid", values);  
  return cloudResponse;
});

export const sendPaymentReminder = createAsyncThunk('payments/sendPaymentReminder', async (values) => {
  const cloudResponse = await Parse.Cloud.run("sendPaymentReminder", {paymentId: values.paymentId});
  if (values.setSaving) values.setSaving('');
  return cloudResponse;
});

export const refundPayment = createAsyncThunk('payments/refundPayment', async (values) => {  
  const cloudResponse = await Parse.Cloud.run("refundPayment", values);
  return cloudResponse;
});


const entityType = "payments";

export const paymentsSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
      // All payments
      [fetchPayments.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchPayments.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state[entityType] = action.payload //Query returns an array of objects
        state.updatedAt = new Date();
      },
      [fetchPayments.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },


      //--> this one used by LQ
      [updatePayment.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updatePayment.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'

        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
          // nothing
          state.updatedAt = new Date();
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          console.log('new payment');
          //--> check for duplicate before adding - this is for snappy local UI but to avoid duplicates
          if(!state[entityType].find(payment => payment.id == action.payload.obj.id)) {
            state[entityType].unshift(action.payload.obj); // Add the new one to the list of payments
          }
          state.updatedAt = new Date();
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {
          console.log('payment removed');
          state.payments = state.payments && state.payments.filter(o => o.id !== action.payload.obj.id);
          state.updatedAt = new Date();
        }


      },
      [updatePayment.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Add related task
      [addRelatedTask.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [addRelatedTask.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [addRelatedTask.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Add related note
      [addRelatedNote.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [addRelatedNote.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [addRelatedNote.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Add user tags
      [addUserTags.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [addUserTags.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [addUserTags.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Remove user tag
      [removeUserTag.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [removeUserTag.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [removeUserTag.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Add topic
      [addTopics.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [addTopics.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [addTopics.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Remove topic
      [removeTopic.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [removeTopic.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [removeTopic.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Cancel payment request
      [cancelPayment.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [cancelPayment.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [cancelPayment.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // MArk paid outside
      [markPaymentPaidOutside.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [markPaymentPaidOutside.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [markPaymentPaidOutside.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Send reminder
      [sendPaymentReminder.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [sendPaymentReminder.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [sendPaymentReminder.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Refund payment
      [refundPayment.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [refundPayment.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [refundPayment.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },




    },


    // BELOW NOT USED - KEPT FOR REFERENCE ONLY

    reducers: {

  }
});

export default paymentsSlice.reducer
