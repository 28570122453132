import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector, shallowEqual } from "react-redux";
import { FormattedMessage } from "react-intl";
import { loginUser } from "../../../../_metronic/layout/components/header/locationSlice";
import { setUserId } from "../../../../_metronic/layout/components/header/userSlice";

import { setLocation } from "../../../../_metronic/layout/components/header/locationSlice";
import Parse from "parse";


import 'react-animation/dist/keyframes.css';
import { animations } from 'react-animation';
import { AnimateOnChange } from 'react-animation';

const axios = require('axios');

const initialValues = {
  email: "",
  password: "",
};

export default function Login(props) {



  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 chars")
      .max(50, "Maximum 50 symbols")
      .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    password: Yup.string()
      .min(8, "Minimum 8 symbols")
      .max(50, "Maximum 50 symbols")
      .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const [error, setError] = useState('');
      
  let dispatch = useDispatch();

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,

    onSubmit: async (values, { setStatus, setSubmitting }) => { //--> decided to move login process from slice to here for better user experience - error reporting is easier and more direct
      
  
      enableLoading();
      
      if (Parse.User.current()) {     
        await Parse.User.logOut();
      }

     let result = new Promise((resolve, reject) => {

        Parse.User.logIn(values.email, values.password).then(async (user) => {

          //--> load up the various things we need for this user
          //--> this special version for user saves these items to local storage
          await user.fetchWithInclude(["userRolePtr", "locationList", "primaryLocationPtr", "tenantPtr"], {sessionToken: user.getSessionToken()});     
          
          await Promise.all([
            
            dispatch(setLocation(Parse.User.current().get("primaryLocationPtr"))),             
          ]).then(() => {
            dispatch(setUserId(true))
            disableLoading();
            resolve(user);
          }).catch(() => {
            disableLoading();
            let error = {message: "Error in login process."}
            resolve(error);
          }); 

        }).catch((error) => {
          setError(error.message);
          disableLoading();
          resolve(error);   //--> on invalid login, send the error info to be stored in redux for the login form to hook into  
        })                                       
      })
    },

  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form" style={{ animation: animations.bounceIn }}>
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your email and password
        </p>
      </div>
      {error && (
          <div className="mb-10 mx-0 alert alert-custom alert-light-danger alert-dismissible c_fadeIn">
            <div className="alert-text font-weight-bold">{error}</div>
          </div>
          )}
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework mx-0 px-0"
        noValidate
      >


        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("email")}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("password")}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">        
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting || loading}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>

            {loading && <span className="ml-3 spinner spinner-white"></span>}

          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

//export default injectIntl((Login));
