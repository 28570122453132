import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as textopia from "../../../_assets/js/textopia/textopia_utils";
import {KTUtil} from "../../../_assets/js/components/util";

const initialState = {
  userId: undefined,
};

export const setUserId = createAsyncThunk('location/setUserId', async (values) => {
  return;
})

export const clearUserId = createAsyncThunk('location/clearUserId', async (values) => {
  return;
})

const entityType = "user";

export const userSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
      
      [setUserId.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [setUserId.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state.userId = true; 
      },
      [setUserId.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },

      [clearUserId.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [clearUserId.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'   
        state.userId = false;        
      },
      [clearUserId.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

    },


    // BELOW NOT USED - KEPT FOR REFERENCE ONLY

    reducers: {
   
  }
});
export default userSlice.reducer;

