import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";
import * as Queries from "../../../../../_metronic/_assets/js/textopia/TextopiaQueries";
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";
import {KTUtil} from "../../../../../_metronic/_assets/js/components/util";


const initialState = {

  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,
  reviews: [],
  updatedAt: new Date(),
};

const statusChangeDelay = 500;

export const addRelatedTask = createAsyncThunk('reviews/addRelatedTask', async (values) => {
  // First save the task
  let cloudResponse = await Parse.Cloud.run("saveTask", values);
  let cloudResponse2 = await Parse.Cloud.run("addRelatedTask", {type: "ReviewAppInstance", values: values, taskId: cloudResponse && cloudResponse.id})
  return cloudResponse2;
});

export const addRelatedNote = createAsyncThunk('reviews/addRelatedNote', async (values) => {
  let cloudResponse = await Parse.Cloud.run("addRelatedNote", {type: "ReviewAppInstance", values: values});
  return cloudResponse;  
});

export const addReply = createAsyncThunk('reviews/addReply', async (values) => {
  let cloudResponse = await Parse.Cloud.run("addReply", {type: "ReviewAppInstance", values: values});
  return cloudResponse;
});

export const addUserTags = createAsyncThunk('reviews/addUserTags', async (values) => {
  let cloudResponse = await Parse.Cloud.run("addUserTags", {type: "ReviewAppInstance", values: values})
  return cloudResponse;
});

export const removeUserTag = createAsyncThunk('reviews/removeUserTag', async (values) => {  
  let cloudResponse = await Parse.Cloud.run("removeUserTag", {type: "ReviewAppInstance", values: values})
  return cloudResponse;   
});


export const addTopics = createAsyncThunk('reviews/addTopics', async (values) => {
  let cloudResponse = await Parse.Cloud.run("addTopics", {type: "ReviewAppInstance", values: values});
  return cloudResponse;
});

export const removeTopic = createAsyncThunk('reviews/removeTopic', async (values) => {
  let cloudResponse = await Parse.Cloud.run("removeTopic", {type: "ReviewAppInstance", values: values});
  return cloudResponse;
});

export const toggleRead = createAsyncThunk('reviews/toggleRead', async (values) => {
  let cloudResponse = await Parse.Cloud.run("toggleRead", {type: "ReviewAppInstance", id: values.id});
  return cloudResponse;  
})

export const fetchReviews = createAsyncThunk('reviews/fetchReviews', async (values) => {
  let cloudResponse = await Parse.Cloud.run("fetchReviewsForTenant");
  return cloudResponse;
})

//--> this is used by LQ to alert us about review changes, or new review addition, etc.
export const updateReview = createAsyncThunk('reviews/updateReview', async (values) => {  
  //--> do nothing, just return the object that LQ sends us
  return values;
})


const entityType = "reviews";

export const reviewsSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
      // All reviews for a location
      [fetchReviews.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchReviews.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state[entityType] = action.payload //Query returns an array of objects
        state.updatedAt = new Date();
      },
      [fetchReviews.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },

      // toggle read state
      [toggleRead.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [toggleRead.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'  
        // nothing to do.      
        //state.updatedAt = new Date();

      },
      [toggleRead.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

     
      // Update review //--> used by LQ
      [updateReview.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updateReview.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'

        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
          // nothing
          state.updatedAt = new Date();
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          console.log('new review');
          //--> a user cannot create a review so this must be coming from the backend, meaning, duplicates are not possible, no need to check
          state[entityType].unshift(action.payload.obj);    
          state.updatedAt = new Date();      
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {
          console.log('review removed');
          //--> reviews should typically never be deleted, but it is possible that a review might 'leave' this set if transferred to another location or something like that
          state.reviews = state.reviews && state.reviews.filter(o => o.id !== action.payload.obj.id);
          state.updatedAt = new Date();
        }


      },
      [updateReview.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },


      // Add related task
      [addRelatedTask.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [addRelatedTask.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'

        if(!state[entityType].find(review => review.id == action.payload.id)) {
          state[entityType].push(action.payload);
        }
        state.updatedAt = new Date();
      },
      [addRelatedTask.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Add related note
      [addRelatedNote.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [addRelatedNote.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [addRelatedNote.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Add user tags
      [addUserTags.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [addUserTags.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [addUserTags.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Remove user tag
      [removeUserTag.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [removeUserTag.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [removeUserTag.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Add topic
      [addTopics.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [addTopics.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [addTopics.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Remove topic
      [removeTopic.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [removeTopic.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [removeTopic.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Add reply
      [addReply.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [addReply.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.updatedAt = new Date();
      },
      [addReply.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },


    },
    reducers: {
  }
});

export default reviewsSlice.reducer
