import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";
import * as Queries from "../../../../../_metronic/_assets/js/textopia/TextopiaQueries";
import {KTUtil} from "../../../../../_metronic/_assets/js/components/util";


const initialState = {

  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,

  settings: null,
  updatedAt: new Date(),


};



export const fetchFeedbackSettings = createAsyncThunk('feedbackSettings/fetchFeedbackSettings', async () => {
  const cloudResponse = await Parse.Cloud.run("fetchFeedbackSettings");
  return cloudResponse;
});

export const saveFeedbackSettingsLook = createAsyncThunk('feedbackSettings/saveFeedbackSettingsLook', async (values) => {
  //if (!values.settings) return; //--> relying on the settings object coming from the form
  let settings;
  if (!values.settings) {
    settings = new (Parse.Object.extend("FeedbackAppSettings"))();
    settings.set("tenantPtr", Parse.User.current().get("tenantPtr"));
    settings.set("updatedByUserPtr", Parse.User.current());
  }
  else {
    settings = values.settings;
  }

  settings.set("updatedByUserPtr", Parse.User.current());

  let branding = textopia.getBranding(values)
  settings.set("branding", branding);
  settings = textopia.setLogo(settings, values);
  
  let savedSettings = await settings.save();
  return savedSettings;

})

export const saveFeedbackSettingsMessaging = createAsyncThunk('feedbackSettings/saveFeedbackSettingsMessaging', async (values) => {
  if (values.settings) {
    values.settingsId = values.settings.id;
    values.settings = null;
  }
  const cloudResponse = await Parse.Cloud.run("saveFeedbackSettingsMessaging", {values: values})
  return cloudResponse;

})

export const saveFeedbackSettingsImage = createAsyncThunk('feedbackSettings/saveFeedbackSettingsImage', async (values) => {
  //if (!values.settings) return; //--> relying on the settings object coming from the form
  let settings;
  if (!values.settings) {
    settings = new (Parse.Object.extend("FeedbackAppSettings"))();
    settings.set("tenantPtr", Parse.User.current().get("tenantPtr"));
    settings.set("updatedByUserPtr", Parse.User.current());
  }
  else {
    settings = values.settings;
  }

  settings.set("updatedByUserPtr", Parse.User.current());

  settings.set("includeImage", values.includeImage);
  if (values.includeImage && values.includedImage) {    
    if (values.includedImage._url) { //--> using one of our stock images, not uploaded by user, so just set the field
      settings.set("includedImage", values.includedImage);
    }
    else {
      let ext = values.includedImage.name.endsWith("jpg") ? "jpg" : values.includedImage.name.endsWith("png") ? "png" : values.includedImage.name.endsWith("gif") ? "gif" : values.includedImage.name.endsWith("jpeg") ? "jpeg" : "";
      let parseFile = new Parse.File("feedbackInvite." + ext, values.includedImage);      
      settings.set("includedImage", parseFile);
    }      
  } else if (!values.includeImage) {
    settings.set("includedImage", null);
  }     

  let savedSettings = await settings.save();
  return savedSettings;

})

export const saveFeedbackSettingsDrip = createAsyncThunk('feedbackSettings/saveFeedbackSettingsDrip', async (values) => {
  
  if (values.settings) {
    values.settingsId = values.settings.id;
    values.settings = null;
  }
  const cloudResponse = await Parse.Cloud.run("saveFeedbackSettingsDrip", {values: values})
  return cloudResponse;  
})

//--> this one used by LQ and it sends us the affected object as values
export const updateFeedbackSettings = createAsyncThunk('feedbackSettings/updateFeedbackSettings', async (values) => {
  return values;
})

const entityType = "feedbackSettings";

export const feedbackSettingsSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
      // All questions for a location
      [fetchFeedbackSettings.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchFeedbackSettings.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state.settings = action.payload //Query returns the settings record for this tenant
        state.updatedAt = new Date();
      },
      [fetchFeedbackSettings.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },  

      
      // save custom look
      [saveFeedbackSettingsLook.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveFeedbackSettingsLook.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.settings = action.payload //Query returns the settings record for this tenant
        state.updatedAt = new Date();
      },
      [saveFeedbackSettingsLook.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      }, 

      // save custom messaging
      [saveFeedbackSettingsMessaging.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveFeedbackSettingsMessaging.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.settings = action.payload //Query returns the settings record for this tenant
        state.updatedAt = new Date();
      },
      [saveFeedbackSettingsMessaging.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      },  

      // save included image
      [saveFeedbackSettingsImage.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveFeedbackSettingsImage.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.settings = action.payload //Query returns the settings record for this tenant
        state.updatedAt = new Date();
      },
      [saveFeedbackSettingsImage.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      },   

      // save drip campaign
      [saveFeedbackSettingsDrip.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveFeedbackSettingsDrip.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.settings = action.payload //Query returns the settings record for this tenant
        state.updatedAt = new Date();
      },
      [saveFeedbackSettingsDrip.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(action.error.message)
      },           


      // Update settings //--> this one used by LQ
      [updateFeedbackSettings.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updateFeedbackSettings.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'

        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
          // nothing
          state.updatedAt = new Date();
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          state.settings = action.payload.obj; // should not really happen since a record should always exist for every tenant
          state.updatedAt = new Date();
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {   
          // nothing, should never happen
          state.updatedAt = new Date();
        }

      },
      [updateFeedbackSettings.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },


    },


   

    reducers: {

    
  }
});

export default feedbackSettingsSlice.reducer
