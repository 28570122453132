import React, { useEffect, Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import {useLocation} from "react-router";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { Beacon } from "../_metronic/layout/components/Beacon";

const BusinessSettingsPage = lazy(() =>
  import("./modules/BusinessSettings/pages/businessSettingsPage")
);
const TemplatesLibraryPage = lazy(() =>
  import("./modules/Templates/pages/TemplatesLibraryPage")
);
const AttributesListPage = lazy(() =>
  import("./modules/Attributes/pages/AttributesListPage")
);
const TaskListPage = lazy(() =>
  import("./modules/Tasks/pages/TaskListPage")
);
const FeedbackPage = lazy(() =>
  import("./modules/Feedback/pages/FeedbackPage")
);
const ReviewListPage = lazy(() =>
  import("./modules/Reviews/pages/ReviewListPage")
);
const TopicsListPage = lazy(() =>
  import("./modules/Topics/pages/TopicsListPage")
);
const ConvosListPage = lazy(() =>
  import("./modules/Convos/pages/ConvosListPage")
);
const ChannelsListPage = lazy(() =>
  import("./modules/Channels/pages/ChannelsListPage")
);
const Web2TxtPage = lazy(() =>
  import("./modules/Web2Txt/pages/Web2TxtPage")
);
const PaymentsListPage = lazy(() =>
  import("./modules/Payments/pages/PaymentsListPage")
);
const TextopiaAdminPage = lazy(() =>
  import("./modules/TextopiaAdmin/pages/TextopiaAdminPage")
);
const LeaderboardListPage = lazy(() =>
  import("./modules/Leaderboard/pages/LeaderboardListPage.js")
);

export default function BasePage() {
   //useEffect(() => {
   //  console.log('Base page router');
   //}, [])    

   let location = useLocation();
   if(window.ReactNativeWebView) {
   window.ReactNativeWebView.postMessage(location.pathname);    }
      
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/convos/convos" />
          }

          <Route path="/business-settings" component={BusinessSettingsPage} />
          <Route path="/templates" component={TemplatesLibraryPage} />
          <Route path="/attributes" component={AttributesListPage} />
          <Route path="/tasks" component={TaskListPage} />
          <Route path="/feedback" component={FeedbackPage} />
          <Route path="/reviews" component={ReviewListPage} />
          <Route path="/topics" component={TopicsListPage} />

          <Route path="/convos" component={ConvosListPage} />
          <Route path="/channels" component={ChannelsListPage} />
          <Route path="/web2txt" component={Web2TxtPage} />
          <Route path="/payments" component={PaymentsListPage} />
          <Route path="/admin" component={TextopiaAdminPage} />
          <Route path="/leaderboard" component={LeaderboardListPage} />

          <Redirect to="error/error-v1" />
        </Switch>
        <Beacon />
      </>
    </Suspense>
  );
}

