import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Parse } from 'parse';
// import moment from 'moment'
import dayjs from "dayjs";

import ReportHelper from "../../../../../_metronic/_assets/js/components/reportHelper"


// initial state which is shared by all component using 

const today = dayjs(new Date());

const initialState = {
  pageStatus: 'idle',
  pageError: null,
  graphStatus: 'idle',
  graphError: null,
  reportData: [],
  avgReviewRatingForDashboardChart: {},
  avgReviewRatingForChartData: {},
  totalAverageRatingChart: {},
  totalReviewInvitesForDashboard: {},
  totalReviewInvitesForChartData: {},
  totalReviewsForDashboardChartData: {},
  totalReviewsForChartData: {},
  detailsPerPageForDashboard: {},
  reviewInviteForDashboardChartData: {},
  detailsPerPageNameForDashboard: {},
  detailsPerPageNameForDashboard_monthToDate: 0,
  detailsPerPageNameForDashboard_last12Month: 0,
  monthToDate: 0,
  last12Months: 0,

  avgReviewRatingForDashboard_monthToDate: 0,
  avgReviewRatingForDashboard_last12Month: 0,

  totalReviewInvitesForDashboard_monthToDate: 0,
  totalReviewInvitesForDashboard_last12Month: 0,

  totalReviewsForDashboard_monthToDate: 0,
  totalReviewsForDashboard_last12Month: 0,

  detailsPerPageForDashboard_monthToDate: 0,
  reviewInviteForDashboard_monthToDate: 0,

  queryParams: {
    fromDate: dayjs(today).subtract(7, 'day').format("YYYY-MM-DD"),
    toDate: today.format("YYYY-MM-DD"),
  },
  curentPage: {
    pageName: "dashboard",
    pageData: null,
    reportType: "",
    summeryType: "",
    chartType: "",
    reportTitle: ""
  }
}

// server API calling dispatch
export const fetchReviewSummary = createAsyncThunk('questions/fetchReviewSummary', async (values) => {
  const cloudResponse = await Parse.Cloud.run("fetchReviewSummary", values);
  return cloudResponse;
})

const entityType = "reviewReport";
// Then, handle actions in your reducers:
export const reviewReportsSlice = createSlice({
  name: entityType,
  initialState: initialState,
  reducers: {
    setParams: (state, action) => {
      state.queryParams = action.payload; // mutate the state all you want with immer
      // state.reportData = [];
      // state.pageStatus = 'idle';
    },
    setPage: (state, action) => {
      state.curentPage = action.payload;
    },
  },
  extraReducers: {
    // all callback from server for each API call
    // state managemet - pending/fulfilled/rejected callback from with API name [method-name.state] 
    [fetchReviewSummary.pending]: (state) => { state.pageStatus = 'loading' },
    [fetchReviewSummary.fulfilled]: (state, action) => fetchReviewSummaryFulfilled(state, action),
    [fetchReviewSummary.rejected]: (state, action) => fetchReviewSummaryRejected(state, action),
  }
})

function getTotal(reportType, result) {
  let total = 0;
  switch (reportType) {
    case ReportHelper.ReportType.totalReviewInvitesForDashboard: {
      total = result.data[0].totalInvitesSent;
      break;
    }
    case ReportHelper.ReportType.totalReviewsForDashboard: {
      total = result.data[0].totalReview;
      break;
    }
    case ReportHelper.ReportType.reviewInviteForDashboard: {
      total = result.data[0].totalInvites;
      break;
    }
    case ReportHelper.ReportType.avgReviewRatingForDashboard: {
      if (result.data[0].avgReviewRating > 0) {
        const isInteger = Number.isInteger(result.data[0].avgReviewRating)
        total = isInteger === true ? result.data[0].avgReviewRating : result.data[0].avgReviewRating !== null ? Number(result.data[0].avgReviewRating.toFixed(1)): 0
      }
      else {
        total = 0
      }
      break;
    }
    case ReportHelper.ReportType.detailsPerPageNameForDashboard: {
      if (result.data[0].avgRatings > 0) {
        const isInteger = Number.isInteger(result.data[0].avgRatings)
        total = isInteger === true ? result.data[0].avgRatings : result.data[0].avgRatings !== null ? Number(result.data[0].avgRatings.toFixed(1)): 0
      }
      else {
        total = 0
      }
      break;
    }
  }
  return total;
}

const fetchReviewSummaryFulfilled = (state, action) => {
  state.pageStatus = 'succeeded'
  const result = action.payload;
  if (result.groupBy === "" && result.reportType !== ReportHelper.ReportType.detailsPerPage) {
    const stateName = result.reportType + "_" + result.filterType;
    switch (result.filterType) {
      case ReportHelper.FilterType.monthToDate: {
        state[stateName] = (result.data && result.data.length > 0) ? getTotal(result.reportType, result) : 0;
        state.reportName = result.reportType;
        break;
      }
      case ReportHelper.FilterType.last12Month: {
        state[stateName] = (result.data && result.data.length > 0) ? getTotal(result.reportType, result) : 0;
        state.reportName = result.reportType;
        break;
      }
    }
  }
  else if (result.reportType === ReportHelper.ReportType.avgReviewRatingForDashboard) {
    state.avgReviewRatingForDashboardChart = ReportHelper.ConvertAverageReviewRatingForDashboardChartData(result);
  }

  else if (result.reportType === ReportHelper.ReportType.avgReviewRating) {
    state.avgReviewRatingForChartData = ReportHelper.ConvertAverageReviewRatingChartData(result.data, result.groupBy, ReportHelper.ReportTitle.averageReviewRating, result.filterType);
  }

  else if (result.reportType === ReportHelper.ReportType.totalReviewInvitesForDashboard) {
    state.totalReviewInvitesForDashboard = ReportHelper.ConvertTotalReviewInvitesChartDataForDashboard(result);
  }

  else if (result.reportType === ReportHelper.ReportType.totalReviewInvites) {
    state.totalReviewInvitesForChartData = ReportHelper.ConvertTotalReviewInvitesTotalChartData(result.data, result.groupBy, ReportHelper.ReportTitle.totalReviewInvites, result.filterType);
  }
  else if (result.reportType === ReportHelper.ReportType.inviteActivity) {
    state.inviteActivityData = result.data;
  }
  else if (result.reportType === ReportHelper.ReportType.totalReviewsForDashboard) {
    state.totalReviewsForDashboardChartData = ReportHelper.ConvertTotalReviewsForDashboardChartData(result);
  }
  else if (result.reportType === ReportHelper.ReportType.totalReviews) {
    state.totalReviewsForChartData = ReportHelper.ConvertTotalReviewsChartData(result.data, result.groupBy, ReportHelper.ReportTitle.totalReviews, result.filterType);
  }

  else if (result.reportType === ReportHelper.ReportType.detailsPerPageForDashboard) {
    //state.detailsPerPageForDashboard = ReportHelper.ConvertDetailsPerPageForDashboardChartData(result.data, result.groupBy);
  }
  else if (result.reportType === ReportHelper.ReportType.detailsPerPage) {
    state.overallRatingData = result.data;
  }
  else if (result.reportType === ReportHelper.ReportType.detailsPerPageName) {
    state.siteData = result.data;
  }
  else if (result.reportType === ReportHelper.ReportType.detailsPerPageNameForDashboard) {
    state.detailsPerPageNameForDashboard = ReportHelper.ConvertDetailsPerPageForDashboardChartData(result.data, result.groupBy);
  }
  else if (result.reportType === ReportHelper.ReportType.reviewInviteForDashboard) {
    state.reviewInviteForDashboardChartData = ReportHelper.ConvertReviewInviteForDashboardChartData(result);
  }
  console.log('fetchReviewSummary.fulfilled')
}

const fetchReviewSummaryRejected = (state, action) => {
  state.pageStatus = 'failed'
  state.pageError = action.error.message
  console.log(action.error.message);
}

export default reviewReportsSlice.reducer