import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as Textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";

const initialState = {

  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,

  autoResponder: null,
  hours: [],
  updatedAt: new Date(),
  autoResponderUpdatedAt: new Date(),

};


export const fetchAutoResponder = createAsyncThunk('hours/fetchAutoResponder', async () => {
  const cloudResponse = await Parse.Cloud.run("fetchAutoResponder");
  return cloudResponse; 
})

//--> used by LQ
export const updateAutoResponder = createAsyncThunk('hours/updateAutoResponder', async (values) => {
  return values;
})

export const saveAutoResponder = createAsyncThunk('hours/saveAutoResponder', async (values) => {
  const cloudResponse = await Parse.Cloud.run("saveAutoResponder", values);
  return cloudResponse;    
})

export const fetchHours = createAsyncThunk('hours/fetchHours', async () => {
  const cloudResponse = await Parse.Cloud.run("fetchHours");
  return cloudResponse;
})

//--> used by LQ
export const updateHours = createAsyncThunk('hours/updateHours', async (values) => {
  //--> hours is a complex setup of multiple records, so LQ returns one record after another, we'll just go ahead and fetch all the hours info
  const cloudResponse = await Parse.Cloud.run("fetchHours");
  return cloudResponse;
})

export const deleteSpecialHours = createAsyncThunk('hours/deleteSpecialHours', async (values) => {
  // We will get an array of hours objects to delete,
  const cloudResponse = await Parse.Cloud.run("deleteSpecialHours", {idArray: values.map(item => item.id)}); //--> cannot send complex objs (especially parse objs) over the wire to cloud, need to send simple ids
  return cloudResponse;  
})


export const saveSpecialHours = createAsyncThunk('hours/saveSpecialHours', async (values) => {
  const cloudResponse = await Parse.Cloud.run("saveSpecialHours", values); //--> cannot send complex objs (especially parse objs) over the wire to cloud, need to send simple ids
  return cloudResponse;  
})

export const saveHours = createAsyncThunk('hours/saveHours', async (values) => {
  const cloudResponse = await Parse.Cloud.run("saveHours", values);
  return cloudResponse;

  
})

const entityType = "hours";

export const hoursSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
      // Fetch all hours (regular and special for a location)
      [fetchHours.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchHours.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state[entityType] = action.payload //Query returns an array of objects
        state.updatedAt = new Date();
      },
      [fetchHours.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },

      // Update hours //--> used by LQ
      [updateHours.pending]: (state, action) => {
        state.actionStatus = 'loading'
      },
      [updateHours.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state[entityType] = action.payload //We are getting all the hours info again to assign here because hrs page is made of complex records
        state.updatedAt = new Date();
      },
      [updateHours.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Edit regular hours
      [saveHours.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveHours.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state[entityType] = state[entityType].filter(o => o.get("priority") !== "1"); // First filter out old records, and then add new ones // This leaves special hours unchanged
        // Now add all the returned numbers
        for (var i = 0; i < action.payload.length; i++) {
          state[entityType].push(action.payload[i]) //Query returns one or more records that need to be pushed into the list
        }
        state.updatedAt = new Date();
      },
      [saveHours.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Special hours
      [saveSpecialHours.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveSpecialHours.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state[entityType] = state[entityType].filter(o => {
          if (!Textopia.checkIfDatesAreEqual(o.get("specialDate"), action.payload[0].get("specialDate"))) {
            return true;
          }
          return false;
        })

        // Now add all the returned numbers
        for (var i = 0; i < action.payload.length; i++) {
          state[entityType].push(action.payload[i]) //Query returns one or more records that need to be pushed into the list
        }

        state.updatedAt = new Date();

      },
      [saveSpecialHours.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Delete special hours
      [deleteSpecialHours.pending]: (state, action) => {
        state.actionStatus = 'deleting'
      },
      [deleteSpecialHours.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded';

        // Remove the deleted hour records (will be an array)
        state[entityType] = state[entityType].filter(o => !action.payload.find(item => o.id === item.id));
        state.updatedAt = new Date();

      },
      [deleteSpecialHours.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Fetch Auto Responder
      [fetchAutoResponder.pending]: (state, action) => {
        state.actionStatus = 'loading'
      },
      [fetchAutoResponder.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.autoResponder = action.payload //Query returns one item
        state.autoResponderUpdatedAt = new Date();
      },
      [fetchAutoResponder.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(state.actionError);
      },

      // update Auto Responder //--> used by LQ
      [updateAutoResponder.pending]: (state, action) => {
        state.actionStatus = 'loading'
      },
      [updateAutoResponder.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.autoResponder = action.payload.obj //LQ upate gives us one item and we over-write with it
        state.autoResponderUpdatedAt = new Date();
      },
      [updateAutoResponder.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(state.actionError);
      },

      // Save Auto Responder
      [saveAutoResponder.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveAutoResponder.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        state.autoResponder = action.payload //Query returns one item
        state.autoResponderUpdatedAt = new Date();
      },
      [saveAutoResponder.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
        console.log(state.actionError);
      },





    },


    // BELOW NOT USED - KEPT FOR REFERENCE ONLY

    reducers: {

    }
});

export default hoursSlice.reducer
