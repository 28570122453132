/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {AsideMenuListSection} from "./AsideMenuListSection";
import {AsideMenuListLevel1} from "./AsideMenuListLevel1";
import Parse from 'parse';

export function AsideMenuList() {

  const role = Parse.User.current().get("userRolePtr").attributes;

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className="menu-nav pt-10">

          <AsideMenuListLevel1
            thisLevel={{
                title: "Conversations",
                iconInfo: "flaticon2-chat-1 icon-lg",
                fontInfo: "font-size-h5 font-weight-bold ",
                label: true,
                spacingInfo: "mr-4",
                style: {borderRadius: '0 30px 30px 0'},
                levelPath: "/convos",
            }}
          />

          <AsideMenuListSection sectionTitle="APPS" />

            {role.VIEW_REVIEWS && 
            <AsideMenuListLevel1
                thisLevel={{
                    title: "Reviews",
                    iconInfo: "flaticon-star icon-md",
                    levelPath: "/reviews",
                    hasSubmenu: true,
                    label: true,
                }}

                subLevels={[
                  {title: "Reviews Received", levelPath: "/reviews/reviews"},
                  role.VIEW_SITES && {title: "Review Sites", levelPath: "/reviews/sites"},
                  role.VIEW_REVIEWS_DASH && {title: "Reviews Dash", levelPath: "/reviews/reports"},
                  role.VIEW_COMPETITORS && {title: "Competitors", levelPath: "/reviews/competitors"},
                  role.REVIEWS_SETTINGS && {title: "Settings", levelPath: "/reviews/settings"},

                ]}
            />
            }

            {role.VIEW_FEEDBACK && 
            <AsideMenuListLevel1
              thisLevel={{
                  title: "Feedback",
                  iconInfo: "flaticon2-reload icon-md",
                  levelPath: "/feedback",
                  hasSubmenu: true,
                  label: true,
              }}

              subLevels={[
                role.VIEW_FEEDBACK && {title: "Responses", levelPath: "/feedback/responses"},
                role.VIEW_FEEDBACK_QUESTIONS && {title: "Questions", levelPath: "/feedback/questions"},
                role.VIEW_FEEDBACK_DASH && {title: "Feedback Dash", levelPath: "/feedback/reports"},                
                role.FEEDBACK_SETTINGS && {title: "Settings", levelPath: "/feedback/settings"},
              ]}
              />
            }

            {role.VIEW_PAYMENTS && 
            <AsideMenuListLevel1
              thisLevel={{
                  title: "Payments",
                  iconInfo: "fas fa-dollar-sign icon-md",
                  levelPath: "/payments",
                  hasSubmenu: true,
                  //label: true,
              }}

              subLevels={[
                role.VIEW_PAYMENTS && {title: "Activity", levelPath: "/payments/payments"},
                role.SETUP_PAYMENTS && {title: "Setup", levelPath: "/payments/setup"},
                role.VIEW_PAYMENTS_DASH1 && {title: "Dash", levelPath: "/payments/dash"},     
                role.VIEW_PAYMENTS_DASH && {title: "Payments Dash", levelPath: "/payments/reports"},
                role.PAYMENTS_SETTINGS && {title: "Settings", levelPath: "/payments/settings"}, 
              ]}
              />
            }

            {role.VIEW_TASKS &&
            <AsideMenuListLevel1
              thisLevel={{
                  title: "Tasks",
                  iconInfo: "flaticon2-notepad icon-md",
                  levelPath: "/tasks",
                  hasSubmenu: true
              }}

              subLevels={[
                role.VIEW_TASKS && {title: "Task List", levelPath: "/tasks/tasks"},                
                role.VIEW_TASKS_DASH && {title: "Task Dash", levelPath: "/tasks/reports"},
              
              ]}
              />
            }

            {role.VIEW_CHANNELS &&
            <AsideMenuListLevel1
              thisLevel={{
                  title: "Web2Txt",
                  iconInfo: "flaticon2-rectangular icon-md",
                  levelPath: "/web2txt",
                  hasSubmenu: true
              }}     
              subLevels={[
                role.VIEW_CHANNELS && {title: "Installation", levelPath: "/web2txt/web2txt"},                
                role.VIEW_WEB2TXT_DASH1 && {title: "Dash", levelPath: "/somewhere"},
              ]}
                       
              />
            }     

            <AsideMenuListSection sectionTitle="OVERVIEW" />
            
            {role.VIEW_OVERVIEW_DASH1 && 
            <AsideMenuListLevel1
              thisLevel={{
                  title: "Overview Dash",
                  iconInfo: "flaticon2-graphic icon-md",
                  levelPath: "/somewhere",
              }}
            />
            }

            {role.VIEW_CONVOS_DASH && 
            <AsideMenuListLevel1
              thisLevel={{
                  title: "Conversations Dash",
                  iconInfo: "flaticon2-graph icon-md",
                  levelPath: "/convos/reports",
              }}
            />
            }

            {role.VIEW_LEADERBOARD &&
            <AsideMenuListLevel1
              thisLevel={{
                  title: "Leaderboard",
                  iconInfo: "flaticon2-graph-2 icon-md",
                  levelPath: "/leaderboard/leaderboard",
              }}
            />
            }

            

            <AsideMenuListSection sectionTitle="PLATFORM SETTINGS" />

            {role.VIEW_CHANNELS &&
            <AsideMenuListLevel1
              thisLevel={{
                  title: "Channels",
                  iconInfo: "flaticon2-cardiogram icon-md",
                  levelPath: "/channels",
              }}
            />
            }

            {role.VIEW_TOPICS &&
            <AsideMenuListLevel1
              thisLevel={{
                  title: "Topics",
                  iconInfo: "flaticon2-indent-dots icon-md",
                  levelPath: "/topics/topics",
              }}

              subLevels={[
                role.VIEW_TOPICS && {title: "Topics", levelPath: "/topics/topics"},
                role.VIEW_TOPICS_DASH1 && {title: "Dash", levelPath: "/topics/dash"},
              ]}

            />
            }
            
            {role.VIEW_TEMPLATES &&
            <AsideMenuListLevel1
              thisLevel={{
                  title: "Templates",
                  iconInfo: "flaticon2-open-text-book icon-md",
                  levelPath: "/templates",
              }}

             
            />
            }

            {role.VIEW_ATTRIBUTES &&
            <AsideMenuListLevel1
              thisLevel={{
                  title: "Attributes",
                  iconInfo: "flaticon2-ui icon-md",
                  levelPath: "/attributes/attributes",
              }}

              
            />
            }

            
        
            <AsideMenuListSection sectionTitle="BUSINESS SETTINGS" />


            {role.BUSINESS_SETTINGS &&
            <AsideMenuListLevel1
              thisLevel={{
                  title: "Business Settings",
                  iconInfo: "flaticon2-dashboard icon-md",
                  levelPath: "/business-settings",
                  hasSubmenu: true
              }}

              subLevels={[
                role.VIEW_GENERAL && {title: "General", levelPath: "/business-settings/general"},
                role.VIEW_HOURS && {title: "Business Hours", levelPath: "/business-settings/hours"},
                role.VIEW_USERS && {title: "Users", levelPath: "/business-settings/users"},
                role.VIEW_TEAMS && {title: "Teams", levelPath: "/business-settings/teams"},
              ]}
            />
            }
            {role.TEXTOPIA_ADMIN &&
            <AsideMenuListLevel1
              thisLevel={{
                  title: "Textopia Admin",
                  iconInfo: "flaticon2-gear icon-md",
                  levelPath: "/admin",
                  hasSubmenu: true
              }}

              subLevels={[
                role.TEXTOPIA_ADMIN && {title: "Tenants", levelPath: "/admin/tenants"},                
              ]}
            />
            }

        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
