import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse} from 'parse';
import * as textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";
import * as Queries from "../../../../../_metronic/_assets/js/textopia/TextopiaQueries";
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";
import {KTUtil} from "../../../../../_metronic/_assets/js/components/util";


const initialState = {

  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,

  topics: [],
  updatedAt: new Date(),

};

const statusChangeDelay = 500;

export const fetchTopics = createAsyncThunk('topics/fetchTopics', async () => {
  let cloudResponse = await Parse.Cloud.run("fetchTopics");
  return cloudResponse;
})

//--> this one used by LQ
export const updateTopic = createAsyncThunk('topics/updateTopic', async (values) => {
  return values;  
})


export const saveTopics = createAsyncThunk('topics/saveTopics', async (values) => {
  let cloudResponse = await Parse.Cloud.run("saveTopics", values);
  return cloudResponse;
})

export const deleteTopic = createAsyncThunk('topics/deleteTopic', async (values) => {
  let cloudResponse = await Parse.Cloud.run("deleteTopic", {topicId: values});
  return cloudResponse;
})
export const saveTopic = createAsyncThunk('topics/saveTopic', async (values) => {
  let t = new (Parse.Object.extend("Topic"))();
  t.set("tenantPtr", Parse.User.current().get("tenantPtr"));
  t.set("createdByUserPtr", Parse.User.current());
  t.set("topic", values.topic);
  let savedTopic = await t.save(null, {sessionToken: Parse.User.current().getSessionToken()});
  return savedTopic;
})

const entityType = "topics";

export const topicsSlice = createSlice({
  name: entityType,
  initialState: initialState,

    extraReducers: {
      // All topics
      [fetchTopics.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchTopics.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state[entityType] = action.payload //Query returns an array of objects
        state.updatedAt = new Date();
      },
      [fetchTopics.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },

      // Edit topic
      [saveTopics.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveTopics.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'

        // Since one topic edit can affect others, we'll replace all the topics
        state[entityType] = action.payload //Query returns an array of objects
        state.updatedAt = new Date();
      },
      [saveTopics.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },
      
      // Delete topic
      [deleteTopic.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [deleteTopic.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'

        // Since one topic edit can affect others, we'll replace all the topics
        // state[entityType] = action.payload //Query returns an array of objects
        state.updatedAt = new Date();
      },
      [deleteTopic.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Save topic
      [saveTopic.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveTopic.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        //--> we'll let LQ handle the new topic update   
        state.updatedAt = new Date();
      },
      [saveTopic.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // update topic //--> this one used by LQ
      [updateTopic.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updateTopic.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        
        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
          // nothing
          state.updatedAt = new Date();
        }        
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          //console.log('new topic');
          //--> check for duplicate before adding - this is for snappy local UI but to avoid duplicates
          if(!state[entityType].find(topic => topic.id == action.payload.obj.id)) {
            state[entityType].push(action.payload.obj); // Add the new one to the list of topics
          }
          state.updatedAt = new Date();          
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {
          //console.log('topic removed');          
          state.topics = state.topics && state.topics.filter(o => o.id !== action.payload.obj.id);
          state.updatedAt = new Date();
        }

        
      },
      [updateTopic.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

    },


    // BELOW NOT USED - KEPT FOR REFERENCE ONLY

    reducers: {

  }
});

export default topicsSlice.reducer
