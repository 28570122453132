import React, {useEffect, useState} from "react";
import {Portal} from "react-portal";

const animationStartClass = "dialog-shown";
const animationEndClass = "dialog-hidden";
const initCssClasses = [
  "dialog",
  "dialog-default",
  "dialog-loader",
  "dialog-top-center"
];
const overlayStartClass = "d-block";
const overlayEndClass = "d-none";
let cssOverlayClassForAdding = "";


export function LoadingDialog({ isLoading, text }) {

  const [cssClasses, setCssClasses] = useState(initCssClasses);

  useEffect(() => {
    // for animation start should toggle 'dialog-hidden' css class
    setCssClasses(initCssClasses);

    const cssClassForAdding = isLoading ? animationStartClass : animationEndClass;
    cssOverlayClassForAdding = isLoading ? overlayStartClass : overlayEndClass;

    setCssClasses([...initCssClasses, cssClassForAdding]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <>
    <div className={`c_overlay ${cssOverlayClassForAdding}`}></div>
    <Portal node={document && document.getElementById("layout-portal")}>
      <div className={cssClasses.join(" ")}>{text}</div>
    </Portal>
    </>
  );
}
