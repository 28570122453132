/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import Parse from "parse";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import {checkIsActive} from "../../../../_helpers";
import {AsideMenuListLevel2} from "./AsideMenuListLevel2";
import * as Constants from "../../../../_assets/js/textopia/TextopiaConstants";
import * as Textopia from "../../../../_assets/js/textopia/textopia_utils";
import { changeConvoSelected, changeInboxSelected, changeOpenClosed, saveScrollPosition } from "../../../../../app/modules/Convos/pages/convos/convosSlice";


export function AsideMenuListLevel1({thisLevel, subLevels}) {
  const location = useLocation();

  
  
  const getMenuItemActive = (url, hasSubmenu = false) => {  
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu ? "menu-item-active" : ""} menu-item-open `
        : "";
  };

  const { orderedConvos } = useSelector(
    (state) =>  ({
      orderedConvos: state.convos.orderedConvos,  
    }),
    //shallowEqual //--> an important discovery that shallowEqual prevents us from looking deeper into objects like Parse objects or even lists of items
  ) 

  
  let unreadCount;

  if (thisLevel.levelPath === "/convos") {
      let allUnreadCount = orderedConvos && Textopia.getAllUnreadCount(orderedConvos);
      allUnreadCount = allUnreadCount ? allUnreadCount : 0;
      let mineUnreadCount = orderedConvos && Textopia.getMineUnreadCount(orderedConvos);
      let myTeamUnreadCount = orderedConvos && Textopia.getTeamUnreadCount(orderedConvos);
      if (["SUPER_USER", "OWNER", "LOCATION_MANAGER"].includes(Parse.User.current().get("userRolePtr").get("name_id"))) {
        unreadCount = allUnreadCount;          
      }
      else {
        unreadCount = (mineUnreadCount ? mineUnreadCount : 0) + (myTeamUnreadCount ? myTeamUnreadCount : 0);
      }
  }  

  const { reviews } = useSelector( (state) =>  ({ reviews: state.reviews.reviews }), );

  if (thisLevel.levelPath === "/reviews") {
      unreadCount = reviews && (reviews.length - reviews.filter(review => review.get("isPreload") || (review.get("readBy") && review.get("readBy").indexOf(Parse.User.current().id) > -1)).length);
  }

  const { responses } = useSelector( (state) =>  ({ responses: state.responses.responses }), );

  if (thisLevel.levelPath === "/feedback") {
   unreadCount = responses && (responses.length - responses.filter(response => (response.get("readBy") && response.get("readBy").indexOf(Parse.User.current().id) > -1)).length);
  }


  let dispatch = useDispatch();
  const resetAll = () => {
    dispatch(saveScrollPosition(0))
    dispatch(changeInboxSelected(Constants.INBOX_ALL));
    dispatch(changeConvoSelected(''));
    dispatch(changeOpenClosed(Constants.INBOX_OPEN));
  }



  return (
      <>

          {/*begin::1 Level*/}
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(thisLevel.levelPath, thisLevel.hasSubmenu)} ${thisLevel.spacingInfo ? thisLevel.spacingInfo : ""} `}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >

            <NavLink className={`menu-link ${thisLevel.hasSubmenu && "menu-toggle"}`} 
                     { ...(thisLevel.style && {style: thisLevel.style }) } to={thisLevel.levelPath} 
                     {...(thisLevel.levelPath === "/convos" && {onClick: () => resetAll() } )} >
            <span className={`${thisLevel.iconInfo} menu-icon`}>
              <span></span>
            </span>
              <span className={`menu-text ${thisLevel.fontInfo ? thisLevel.fontInfo : ''}`}>{thisLevel.title}</span>
              {thisLevel.label && (
                <span className="menu-label">
                  <span className={`label label-inline border border-danger border-2 font-size-lg font-weight-bold text-dark ${thisLevel.levelPath === "/convos" ? "mr-n3" : ""} `}>{unreadCount}</span>
                </span>
              )}
              {thisLevel.hasSubmenu && (<i className="menu-arrow"/>)}

            </NavLink>

            {thisLevel.hasSubmenu && (
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  
                  <span className="menu-link">
                    <span className="menu-text">{thisLevel.title}</span>
                  </span>
                  </li>

                  {subLevels.map((item, index) => (             
                  item && 
                    <AsideMenuListLevel2 key={`bc${index}`} thisLevel={{
                        title: item.title,
                        levelPath: item.levelPath
                      }}
                      >
                    </AsideMenuListLevel2>                  
                  ))}
                </ul>
              </div>
              )
            }
          </li>
      </>
  );

}
