import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { Parse } from 'parse';
import * as Constants from "../../../../../_metronic/_assets/js/textopia/TextopiaConstants";
import * as Textopia from "../../../../../_metronic/_assets/js/textopia/textopia_utils";


const initialTenantsState = {
  pageStatus: 'idle',
  pageError: null,
  actionStatus: 'idle',
  actionError: null,

  tenants: [],
  locations: [],
  updatedAt: new Date(),
  plans: [],
};

export const fetchTextopiaPlans = createAsyncThunk('tenants/fetchTextopiaPlans', async () => {
  const cloudResponse = await Parse.Cloud.run("fetchTextopiaPlans"); //--> have to use cloud code here to fetch tenants because Tenant is a protected entity type and email will not be returned if we don't use master key which is done in cloud code
  return cloudResponse;
})

export const fetchTenants = createAsyncThunk('tenants/fetchTenants', async () => {
  const cloudResponse = await Parse.Cloud.run("fetchTenants"); //--> have to use cloud code here to fetch tenants because Tenant is a protected entity type and email will not be returned if we don't use master key which is done in cloud code
  return cloudResponse;
})

export const fetchLocations = createAsyncThunk('tenants/fetchLocations', async () => {
  const cloudResponse = await Parse.Cloud.run("fetchSystemwideLocations"); //--> have to use cloud code here to fetch tenants because Tenant is a protected entity type and email will not be returned if we don't use master key which is done in cloud code
  return cloudResponse;
})

export const sendIntroEmail = createAsyncThunk('tenants/sendIntroEmail', async (values) => {
  const cloudResponse = await Parse.Cloud.run("sendIntroEmail", {tenantId: values}); //--> have to use cloud code here to fetch tenants because Tenant is a protected entity type and email will not be returned if we don't use master key which is done in cloud code
  return cloudResponse;
})

//--> this one used by LQ
export const updateTenant = createAsyncThunk('tenants/updateTenant', async (values) => { //--> this however, works, after the above fetch, meaning new entries will have their emails displayed
  return values;
})

export const saveTenant = createAsyncThunk('tenants/saveTenant', async (values) => {
  const cloudResponse = await Parse.Cloud.run("saveNewTenant", values);
  return cloudResponse;
})

export const saveLocation = createAsyncThunk('tenants/saveLocation', async (values) => {
  const cloudResponse = await Parse.Cloud.run("saveNewLocation", values);
  return cloudResponse;
})

export const deleteTenant = createAsyncThunk('tenants/deleteTenant', async (id) => {
  const cloudResponse = await Parse.Cloud.run("deleteTenantForAccount", {id: id}); // Sending of Parse objects is not allowed here for some reason, so have to send ID only
  return cloudResponse;
})

export const searchTenants = createAsyncThunk('tenants/searchTenants', async (searchTerm) => {

})


const entityType = "tenants";
export const tenantsSlice = createSlice({
  name: "tenants",
  initialState: initialTenantsState,

    extraReducers: {
      // All tenants
      [fetchTenants.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchTenants.fulfilled]: (state, action) => {
        state.tenants = action.payload //Query returns an array of Tenant objects
        state.updatedAt = new Date();
        state.pageStatus = 'succeeded'
      },
      [fetchTenants.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },

      // All locations
      [fetchLocations.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchLocations.fulfilled]: (state, action) => {
        state.locations = Textopia.groupByPtr(action.payload, "tenantPtr"); //Query returns an array of Location objects
        state.updatedAt = new Date();
        state.pageStatus = 'succeeded'
      },
      [fetchLocations.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.pageError = action.error.message
      },

      // Send intro email
      [sendIntroEmail.pending]: (state, action) => {
        state.actionStatus = 'loading'
      },
      [sendIntroEmail.fulfilled]: (state, action) => {
        state.updatedAt = new Date();
        state.actionStatus = 'succeeded'
      },
      [sendIntroEmail.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      [fetchTextopiaPlans.pending]: (state, action) => {
        state.pageStatus = 'loading'
      },
      [fetchTextopiaPlans.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded'
        state.plans = action.payload //Query returns an array of Tenant objects
        state.updatedAt = new Date();
      },
      [fetchTextopiaPlans.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      },

      // Edit tenant
      [saveTenant.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveTenant.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        // If this is a new tenant, push into the tenants Array
        if(!state.tenants.find(tenant => tenant.id === (action.payload.tenant && action.payload.tenant.id) )) {
          state.tenants.push(action.payload.tenant); // Add the new one to the list of tenants
        }
        state.updatedAt = new Date();
      },
      [saveTenant.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Edit location
      [saveLocation.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [saveLocation.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded'
        //--> TBD - when location list is shown, handle new additions here
        state.updatedAt = new Date();
      },
      [saveLocation.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // Delete tenant
      [deleteTenant.pending]: (state, action) => {
        state.actionStatus = 'deleting'
      },
      [deleteTenant.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded';

        // Remove the deleted tenant from the tenants list
        state.tenants = state.tenants.filter(o => o.id !== action.payload.id);
        state.updatedAt = new Date();
      },
      [deleteTenant.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },

      // update tenant //--> used by LQ
      [updateTenant.pending]: (state, action) => {
        state.actionStatus = 'saving'
      },
      [updateTenant.fulfilled]: (state, action) => {
        state.actionStatus = 'succeeded';

        if (action.payload && action.payload.mode === Constants.LQ_UPDATE) {
          // nothing
          state.updatedAt = new Date();
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_CREATE || action.payload.mode === Constants.LQ_ENTER)) {
          console.log('new tenant');
          //--> check for duplicate before adding - this is for snappy local UI but to avoid duplicates
          if(!state[entityType].find(tenant => tenant.id === action.payload.obj.id)) {
            state[entityType].unshift(action.payload.obj); // Add the new one to the list of topics
          }
          state.updatedAt = new Date();
        }
        else if (action.payload && (action.payload.mode === Constants.LQ_DELETE || action.payload.mode === Constants.LQ_LEAVE)) {
          console.log('tenant removed');
          state.tenants = state.tenants && state.tenants.filter(o => o.id !== action.payload.obj.id);
          state.updatedAt = new Date();
        }
      },
      [updateTenant.rejected]: (state, action) => {
        state.actionStatus = 'failed'
        state.actionError = action.error.message
      },


      // searchTenants tenant
      [searchTenants.pending]: (state, action) => {
        state.pageStatus = 'deleting'
      },
      [searchTenants.fulfilled]: (state, action) => {
        state.pageStatus = 'succeeded';

        state.tenants = action.payload //Query returns an array of Tenant objects
      },
      [searchTenants.rejected]: (state, action) => {
        state.pageStatus = 'failed'
        state.pageError = action.error.message
      }


    },


    // BELOW NOT USED - KEPT FOR REFERENCE ONLY

    reducers: {

    }
});

export default tenantsSlice.reducer
